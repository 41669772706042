import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import WizardListItem from './WizardListItem';
import MultiAnswersListItem from './MultiAnswersListItem';

import { transformProductPrice, getPriceWithCurrencySymbol } from '../../../utils/CommonUtils';
import {
    getFreeToppingsCount,
    freeToppingsPriceCalc,
    substitutionPriceCalc
} from '../../../utils/ProductWizardUtils';
import { ARABIC } from '../../../utils/Constants';

class MultiAnswersStep extends Component {
    renderInList(group) {
        const { currentQuestion } = this.props;
        const { ulStyle } = styles;
        if (!this.props.currentQuestion.id) { // happens because we use WizardStep state
            return <div />
        }
        let answersArray = currentQuestion.answers.slice(0), updatedUlStyle = { ...ulStyle };
        const innerWidthMobile = window.innerWidth <= 992;

        if (innerWidthMobile && group) {
            updatedUlStyle.gridTemplateColumns = 'repeat(auto-fill, minmax(100%, 1fr))';
            updatedUlStyle.width = '100%';
        } else if (innerWidthMobile) {
            updatedUlStyle.gridTemplateColumns = 'repeat(auto-fill, minmax(100%, 1fr))';
        } else if (group) {
            updatedUlStyle.width = '100%';
        }

        if (innerWidthMobile) {
            updatedUlStyle.paddingBottom = 111; // 111px is the Footer's fixed height
        }

        const renderWizardListItem = (answer, answerIndex) => (
            <WizardListItem
                key={`answer${answerIndex}`}
                // The prop below indicates the changes needed to rerender component
                resetLayout={currentQuestion.selectedAnswers}>
                {this.renderAnswerData(answer, innerWidthMobile)}
            </WizardListItem>
        );

        return (
            <ul style={innerWidthMobile || updatedUlStyle ? updatedUlStyle : ulStyle}>
                {
                    answersArray.map((answer, answerIndex) => {
                        if (group) {
                            if (answer.answerGroup) {
                                if (answer.answerGroup.id !== group.id) return null;

                                return renderWizardListItem(answer, answerIndex);

                            } else if (!answer.answerGroup && group.id === -1) {

                                return renderWizardListItem(answer, answerIndex);
                            } else {
                                return null;
                            }
                        } else {
                            return renderWizardListItem(answer, answerIndex);
                        }

                    })
                }
            </ul>
        );
    }

    renderAnswerData(answer, innerWidthMobile) {
        const { primaryColor, primaryFontColor, type } = this.props.selectedTheme;
        const { product, currentQuestion, currency, areAllItemsDefault, productMainQuestion, restaurant, step, useInnerStep, innerStep } = this.props;
        // Hide the button in case of maximumQuantity reached or if the question DO NOT support multiIngredients
        const hideWhenNoMultiIngr = !currentQuestion.multiIngredients;
        let hideAddButton = false, mainLabelStyles = { display: 'flex', minHeight: 48 }, toppingPrice = 0;
        const isSelected = this.isItemSelected(answer);
        let selectedAnswerCopy = { ...answer };
        const isArabic = this.props.language === ARABIC;

        if (isSelected && !areAllItemsDefault) {
            const { selectedAnswers } = this.props.currentQuestion;
            let index = selectedAnswers.findIndex(a => a.id === answer.id);
            selectedAnswerCopy = { ...selectedAnswers[index] };
        }

        if (!isSelected) {
            mainLabelStyles = { ...mainLabelStyles, marginBottom: 0, width: '100%' };
        } else {
            mainLabelStyles = { ...mainLabelStyles, marginBottom: 0, width: innerWidthMobile ? '53%' : '50%' };
            if (areAllItemsDefault) {
                mainLabelStyles.width = '100%';
            } else if (!areAllItemsDefault && !currentQuestion.multiIngredients) {
                mainLabelStyles.width = '78%';
            }
        }

        if (isSelected) {
            const currProduct = product.mealDeal ? productMainQuestion.selectedProduct : product;
            const currStep = useInnerStep ? innerStep : step;
            const freeToppingsCount = getFreeToppingsCount(currProduct, currStep);
            hideAddButton = selectedAnswerCopy.count >= selectedAnswerCopy.maximumQuantity && selectedAnswerCopy.maximumQuantity > 0;

            if (!restaurant.allowToppingSubstitution) {
                const currProductQuestions = product.mealDeal ? currProduct.subQuestions : currProduct.questions;
                const freeToppingsOnProductLevel = !currProductQuestions[currStep].freeToppingsCount && freeToppingsCount ? true : false;
                toppingPrice = freeToppingsPriceCalc(selectedAnswerCopy, freeToppingsCount, currProductQuestions, currStep, freeToppingsOnProductLevel);
            } else if (restaurant.allowToppingSubstitution) {
                const currProductQuestions = product.mealDeal ? currProduct.subQuestions : currProduct.questions;
                const freeToppingsOnProductLevel = !currProductQuestions[currStep].freeToppingsCount && freeToppingsCount ? true : false;
                toppingPrice = substitutionPriceCalc(selectedAnswerCopy, freeToppingsCount, currProductQuestions, currStep, freeToppingsOnProductLevel);         
            }
        }
        let answerPricePrice = transformProductPrice(toppingPrice);
        let answerPricePriceWithCurrency = getPriceWithCurrencySymbol(answerPricePrice, currency, isArabic);

        return (
            <MultiAnswersListItem
                iOSDetected={this.props.iOSDetected}
                answer={answer}
                selectedAnswerCopy={selectedAnswerCopy}
                toppingPrice={toppingPrice}
                primaryColor={primaryColor}
                primaryFontColor={primaryFontColor}
                type={type}
                answerPricePriceWithCurrency={answerPricePriceWithCurrency}
                mainLabelStyles={mainLabelStyles}
                isSelected={isSelected}
                hideWhenNoMultiIngr={hideWhenNoMultiIngr}
                hideAddButton={hideAddButton}
                // methods
                areAllItemsDefault={this.props.areAllItemsDefault}
                selectItem={this.props.selectItem}
                changeAnswerQty={this.props.changeAnswerQty}
                isArabic={isArabic}
            />
        );
    }

    isItemSelected(item) {
        const { selectedAnswers } = this.props.currentQuestion;
        let isSelected = false;

        if (selectedAnswers) {
            if (this.props.areAllItemsDefault) {
                let itemNotRemoved = true;
                selectedAnswers.map(selectedAnswer => {
                    if (selectedAnswer.id === item.id) {
                        itemNotRemoved = false;
                    }
                });
                isSelected = itemNotRemoved;
            } else {
                selectedAnswers.map((selectedAnswer) => {
                    if (selectedAnswer.id === item.id) {
                        isSelected = true;
                    }
                });
            }
        }

        return isSelected;
    }

    render() {
        return (
            <Fragment>
                {this.renderInList(this.props.group)}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        language: state.storeLocalStorage.language
    }
}

export default connect(mapStateToProps)(MultiAnswersStep);

let styles = {
    ulStyle: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(49%, 1fr))',
        gridAutoRows: 25,
        padding: '10px 15px 0 25px',
        marginBottom: 0
    }
};