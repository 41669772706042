import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, Alert, Row } from 'reactstrap';
import i18n from '../../i18next';

import '../../pwa-app/styles/LoyaltyScreen.css';
import { getLoyaltyForChain, getOrUpdateAutoApplyLoyalty, clearMessageAlert, showMessageAlert, resetMessageAlert } from '../../actions';
import { transformProductPrice, getPriceWithCurrencySymbol } from '../../utils/CommonUtils';
import { ARABIC } from '../../utils/Constants';

class LoyaltyScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            autoApplyLoyalty: true
        }

        this.changeState = this.changeState.bind(this);
    }

    componentDidMount() {
        //Gets all loyalty promo codes for the current chain the customer has logged in
        this.props.getLoyaltyForChain(this.props.brand.id);
        this.props.getOrUpdateAutoApplyLoyalty();

        this.setState({
            ...this.state,
            autoApplyLoyalty: this.props.loyalty.autoApplyLoyalty
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loyalty.autoApplyLoyalty !== this.props.loyalty.autoApplyLoyalty) {
            this.setState({
                ...this.state,
                autoApplyLoyalty: this.props.loyalty.autoApplyLoyalty
            });
        }
    }

    changeState() {
        this.setState({
            ...this.state,
            autoApplyLoyalty: !this.state.autoApplyLoyalty
        }, () => this.props.getOrUpdateAutoApplyLoyalty({ autoApplyLoyalty: this.state.autoApplyLoyalty }));
        this.props.showMessageAlert('success')
    }

    autoApplyLoyaltyCodes() {
        const { primaryFontColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        return (
            <div className='loyaltyCodesTitle'>
                <label htmlFor='autoApplyLoyalty' style={isArabic ? { paddingRight: '20px', userSelect: 'none', color: primaryFontColor, marginLeft: '8px' } : { paddingLeft: '20px', userSelect: 'none', color: primaryFontColor }} >{i18n.t('screens:profileScreen.autoApplyLoyalty')}:</label>
                <input
                    type='checkbox'
                    id='autoApplyLoyalty'
                    className='styled-checkbox loyaltyInput'
                    checked={this.state.autoApplyLoyalty}
                    onChange={this.changeState} />
                {/* Label below is the custom chechbox */}
                <label htmlFor='autoApplyLoyalty' style={{ marginLeft: 5 }}></label>
            </div>
        );
    }

    renderLoyaltyCodes() {
        let { primaryFontColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        return (
            <Fragment>
                <p className='loyaltyCodesTitle' style={isArabic ? { color: primaryFontColor, paddingRight: '20px' } : { color: primaryFontColor, paddingLeft: '20px' }}>{i18n.t('screens:profileScreen.loyaltyCodes')}:</p>
                <Row xs={12} sm={12} md={12} >
                    {this.renderTableRows()}
                </Row>
            </Fragment>
        );
    }

    renderTableRows() {
        const { loyaltyCodes } = this.props;
        const { menuItemsBackground, primaryFontColor, tableHeadersBackground, tableHeadersFont } = this.props.customerThemes.selectedTheme;
        const { mainContainer } = this.props.styles;

        return (
            loyaltyCodes.map((loyalty, index) => {
                const { code, restaurantName, value, expDate, status, statusDisplayName } = loyalty;
                let statusColor = '#000', transformedValue = null, currency = this.props.selectedRestaurant.currency;
                if (status === 'ACTIVE') {
                    statusColor = 'green';
                } else if (status === 'EXPIRED') {
                    statusColor = 'red';
                }

                transformedValue = transformProductPrice(value);

                return (
                    <Col xs={10} sm={8} md={5} style={Object.assign({}, mainContainer, { display: 'flex', paddingLeft: '0', paddingRight: '0', margin: '0 auto' })}>
                        <div style={{ backgroundColor: menuItemsBackground }}>
                            <div style={{ background: tableHeadersBackground, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', color: tableHeadersFont, padding: '5px', width: '130px', minHeight: '100%' }}>
                                <p>{i18n.t('screens:profileScreen.code')}</p>
                                <p>{i18n.t('screens:profileScreen.restaurantName')}</p>
                                <p>{i18n.t('screens:profileScreen.value')}</p>
                                <p>{i18n.t('screens:profileScreen.expDate')}</p>
                                <p>{i18n.t('screens:profileScreen.status')}</p>
                            </div>
                        </div>
                        <div key={`loyaltyCode${index}`} style={{ backgroundColor: menuItemsBackground, display: 'flex', flexDirection: 'column', textAlign: 'center', padding: '5px', justifyContent: 'space-between', margin: '0 auto' }}>
                            <p style={{ fontSize: 14, color: primaryFontColor }}>{code}</p>
                            <p style={{ fontSize: 14, color: primaryFontColor }}>{restaurantName}</p>
                            <p style={{ fontSize: 14, color: primaryFontColor }}>{getPriceWithCurrencySymbol(transformedValue, currency)}</p>
                            <p style={{ fontSize: 14, color: primaryFontColor }}>{expDate}</p>
                            <p style={{ fontSize: 14, color: statusColor }}>{status}</p>
                        </div>
                    </Col>
                );
            })
        );
    }

    renderSuccessAlert() {
        const successAlert = this.props.alertMessage.showSuccessAlert;

        if (successAlert && this.state.autoApplyLoyalty) {
            setTimeout(() => this.props.clearMessageAlert('success'), 1500);
        } else if (successAlert && !this.state.autoApplyLoyalty) {
            setTimeout(() => this.props.clearMessageAlert('success'), 1500);
        }

        const dismiss = this.props.clearMessageAlert.bind(this, 'success');

        if (successAlert && this.state.autoApplyLoyalty) {
            return (
                <div className='alertContainer'>
                    <Alert color='success' className='success-alert' toggle={dismiss}>
                        {i18n.t('screens:profileScreen.codesApplySuccess')}
                    </Alert>
                </div>
            );
        } else if (successAlert && !this.state.autoApplyLoyalty) {
            return (
                <div className='alertContainer'>
                    <Alert color='success' className='success-alert' toggle={dismiss}>
                        {i18n.t('screens:profileScreen.codesApplyFailure')}
                    </Alert>
                </div>
            );
        } else {
            return null;
        }
    }

    renderErrorAlert() {
        const errorAlert = this.props.alertMessage.showErrorAlert;

        const dismiss = this.props.clearMessageAlert.bind(this, 'error');

        if (errorAlert) {
            return (
                <div className='alertContainer'>
                    <Alert color='danger' className='error-alert' toggle={dismiss}>
                        {i18n.t('screens:profileScreen.notificationSendErr')}
                    </Alert>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        const isMobileView = window.innerWidth <= 767;
        return (
            <Fragment>
                <div className='text-center alert-container'>
                    {this.renderSuccessAlert()}
                    {this.renderErrorAlert()}
                </div>
                {this.autoApplyLoyaltyCodes()}
                {this.props.loyaltyCodes.length ? this.renderLoyaltyCodes()
                    : <div style={isMobileView ? { textAlign: 'center' } : null}>{i18n.t('screens:profileScreen.noAvailableCodes')}</div>}
            </Fragment>
        );
    }
}

const mapStateToProp = state => {
    return {
        brand: state.brand,
        loyaltyCodes: state.loyalty.loyaltyCodes,
        loyalty: state.loyalty,
        selectedRestaurant: state.selectedRestaurant,
        alertMessage: state.alertMessage,
        customerThemes: state.customerThemes,
        language: state.storeLocalStorage.language
    }
}

export default connect(mapStateToProp, { getLoyaltyForChain, getOrUpdateAutoApplyLoyalty, clearMessageAlert, showMessageAlert, resetMessageAlert })(LoyaltyScreen);