import axios from '../../utils/AxiosUtils';

import { GET_UPSELL_PRODUCTS_SUCCESS } from '../types';

export const getUpsellProducts = (restaurantId) => {
    return (dispatch, getState) => {

        const isPreviewMode = JSON.parse(getState().menuPreviewMode.previewMode);  //parse is needed because is string
        const url = `/restaurant/${restaurantId}/upsell-product?previewMode=${isPreviewMode}`;

        axios(getState).get(url)
            .then(res => getUpsellProductsSuccess(res, dispatch, getState))
            .catch(err => console.log('Failed to load upsell products.', err));
    };
};

const getUpsellProductsSuccess = (res, dispatch) => {

    dispatch({ type: GET_UPSELL_PRODUCTS_SUCCESS, payload: res.data });
};