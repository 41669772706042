import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { GridLoader } from 'react-spinners';

class MenuuLoader extends Component {
    renderLoaderContainer() {
        const { loadingIndicator } = styles;
        const customerThemeDataNotLoaded = !this.props.customerThemes && !this.props.customerThemes.selectedTheme && !this.props.customerThemes.constantStyles;

        if (customerThemeDataNotLoaded) {
            return null;
        }

        const { primaryColor } = this.props.customerThemes.selectedTheme;
        const { loadingContainer } = this.props.customerThemes.constantStyles;
        const loaderColor = this.props.loaderColor ? this.props.loaderColor : primaryColor;

        if (this.props.loading.backgroundLoader) {
            return (
                <div style={loadingContainer}>
                    <div style={loadingIndicator} className='text-center loadingIndicator'>
                        <GridLoader color={loaderColor} size={20} />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    renderTransparentBackgroundLoaderContainer() {
        const { loadingIndicator } = styles;
        const { primaryColor } = this.props.customerThemes.selectedTheme;
        const { loadingContainerTransparent } = this.props.customerThemes.constantStyles;
        const loaderColor = this.props.loaderColor ? this.props.loaderColor : primaryColor;

        if (this.props.loading.transparentLoader) {
            return (
                <div style={loadingContainerTransparent}>
                    <div style={loadingIndicator} className='text-center loadingIndicator'>
                        <GridLoader color={loaderColor} size={20} />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {

        return (
            <Fragment>
                {this.renderLoaderContainer()}
                {this.renderTransparentBackgroundLoaderContainer()}
            </Fragment>
        );
    }

}

const mapStateToProps = state => {
    return {
        customerThemes: state.customerThemes,
        loading: state.loading
    };
};

export default connect(mapStateToProps, {})(MenuuLoader);

let styles = {
    loadingIndicator: {
        width: '72px',
        height: '72px',
    },
}