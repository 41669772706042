import React, { Fragment } from 'react';
import { Button, Col, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import i18n from '../../../../i18next';

import ComoButton from './ComoButton';

const UserNotLoggedIn = props => {

    const {
        type,
        primaryColor,
        secondaryColor,
        buttonsFont,
        fontFamilyType,
        fontBold,
        fontItalic,
        uppercase,
        checkoutScreenNames,
        primaryFontColor
    } = props.customerThemes.selectedTheme;

    const { status, errors } = props.como;

    const btnStyles = {
        fontFamily: fontFamilyType,
        fontWeight: fontBold === 'bold' ? fontBold : 300,
        fontStyle: fontItalic,
        textTransform: uppercase,
        color: buttonsFont,
    }

    const termsAndPrivacyBtnStyle = {
        fontFamily: fontFamilyType,
        fontWeight: fontBold === 'bold' ? fontBold : 300,
        fontStyle: fontItalic,
        fontSize: '12px',
        color: primaryColor,
        cursor: 'pointer'
    }

    return (
        <Fragment>
            { props.loading.transparentLoader ? null :
                < div >
                    <Col style={{
                        maxWidth: '450px',
                        color: type === "LIGHT" ? checkoutScreenNames : secondaryColor,
                        fontSize: 18,
                        fontFamily: fontFamilyType,
                        fontWeight: fontBold === 'bold' ? fontBold : 300,
                        fontStyle: fontItalic,
                        textTransform: uppercase,
                        marginBottom: 30,
                        textAlign: 'center'
                    }}>
                        <p>{i18n.t('screens:como.signUpText')}</p>
                        <div style={{ fontSize: '12px' }}>
                            <p style={{ marginBottom: '0px' }}>{i18n.t('screens:como.bySigningUpComo')}</p>
                            <span style={termsAndPrivacyBtnStyle} onClick={() => props.openTermsPage()}>{i18n.t('screens:como.termsOfUse')}</span>
                            <span style={{ margin: '0 5px' }}>{`&`}</span>
                            <span style={termsAndPrivacyBtnStyle} onClick={() => props.openPrivacyPolicyPage()}>{i18n.t('screens:como.privacyPolicy')}</span>
                        </div>
                    </Col>
                    <Col style={{ width: '100%', display: 'flex', textAlign: 'center', alignItems: 'center' }}>
                        {/* <NavLink onClick={!props.pwaAppRunning && props.togglePromotionsModal} tag={Link} to={{ pathname: props.pwaAppRunning ? `/brand/${props.brandId}/order/checkout` : "/checkout" }} style={{ margin: '0 auto', backgroundColor: primaryFontColor, width: '50%', padding: '0.375rem 0.75rem' }}>
                            <span style={btnStyles}>{i18n.t('common:buttons.no')}</span>
                        </NavLink> */}
                        <div style={props.isArabic ? { margin: '0 auto', backgroundColor: primaryFontColor, width: '50%', marginRight: '2px' } : { margin: '0 auto', backgroundColor: primaryFontColor, width: '50%', marginLeft: '2px' }}>
                            <ComoButton
                                color={buttonsFont}
                                backgroundColor={primaryFontColor}
                                onClick={props.rejectedRegistrationOnClick}
                                fontFamily={fontFamilyType}
                                fontStyle={fontItalic}
                                fontBold={fontBold}
                                uppercase={uppercase}
                            > {i18n.t('common:buttons.no')}
                            </ComoButton>
                        </div>
                        <div style={props.isArabic ? { margin: '0 auto', backgroundColor: primaryFontColor, width: '50%', marginRight: '2px' } : { margin: '0 auto', backgroundColor: primaryFontColor, width: '50%', marginLeft: '2px' }}>
                            <ComoButton
                                color={buttonsFont}
                                backgroundColor={primaryFontColor}
                                onClick={props.onClick}
                                fontFamily={fontFamilyType}
                                fontStyle={fontItalic}
                                fontBold={fontBold}
                                uppercase={uppercase}
                            > {i18n.t('common:buttons.yes')}
                            </ComoButton>
                        </div>
                    </Col>
                </div>
            }
        </Fragment >
    );
}

export default UserNotLoggedIn;