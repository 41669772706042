import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import MaterialIcon from 'material-icons-react';
import i18n from '../../../../i18next';

class NutritionsCollapse extends Component {
    constructor() {
        super();

        this.state = {
            isCollapsed: true
        };

        this.changeState = this.changeState.bind(this);
    }

    changeState() {
        this.setState({
            ...this.state,
            isCollapsed: !this.state.isCollapsed
        });
    }

    render() {
        const upIconStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            top: 3,
            cursor: 'pointer',
            borderRadius: '100%',
            border: '1px solid #dcdcdc',
            margin: '0px 4px 0 0',
            padding: 2
        }

        const itemName = {
            fontSize: 15,
            lineHeight: '20px',
            position: 'relative',
            top: '-1px'
        }

        const { type, primaryFontColor, fontFamilyType, fontItalic, uppercase, fontBold, fontWeight } = this.props.customerThemes.selectedTheme;
        let qtyBtnColor = type === 'CUSTOM' || 'DARK' || 'LIGHT' ? primaryFontColor : '#656565';

        itemName.fontWeight = fontBold === 'bold' ? fontBold : fontWeight;
        itemName.color = primaryFontColor;
        itemName.fontFamily = fontFamilyType;
        itemName.fontStyle = fontItalic;
        itemName.textTransform = uppercase;

        return (
            <div style={{ paddingTop: 8 }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '0 0 8px 0' }}>
                    <h6 style={{ ...itemName, marginBottom: 0 }}>{i18n.t('common:nutritions.nutrTot')}</h6>
                    <div style={{ padding: '0 0 5px 10px' }}>

                        <div hidden={!this.state.isCollapsed} style={upIconStyle} onClick={this.changeState}>
                            <MaterialIcon icon='remove' color={qtyBtnColor} size={15} />
                        </div>
                        <div hidden={this.state.isCollapsed} style={upIconStyle}
                            onClick={this.changeState}>
                            <MaterialIcon icon='add' color={qtyBtnColor} size={15} />
                        </div>

                    </div>
                </div>
                <Collapse
                    isOpen={this.state.isCollapsed}
                >
                    {this.props.children}
                </Collapse>
            </div>
        );
    }

}

export default NutritionsCollapse;