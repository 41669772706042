import {CLEAR_APP_STATE, RUNNING_PWA} from '../actions/types';

const INITIAL_STATE = false;

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case RUNNING_PWA:
            return action.payload;
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};