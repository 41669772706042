import React, { Component, Fragment } from 'react';
import { COLLECTION_ID, DELIVERY_ID, IN_STORE_ID, FRONT_END_URL } from '../../../../utils/Constants';
import moment from "moment/moment";

import { Button } from 'reactstrap';
import i18n from '../../../../i18next';
import ConfirmationModal from '../../../../components/common/ConfirmationModal';

import { dateAndTimeToUserFriendlyFormat } from '../../../../utils/CommonUtils';
import SelectedOrderTypeInfo from './SelectedOrderTypeInfo';
import LocationIconSVG from '../../common/svgComponents/LocationIconSVG';
import ClockSVG from '../../common/svgComponents/ClockSVG';
import LoyaltyIconSvg from '../../common/svgComponents/LoyaltyIconSVG';
import StarSVG from '../../common/svgComponents/StarSVG';

export default class DetailsScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            openConfirmationDialog: false
        }

        this.onBackKeyDown = this.onBackKeyDown.bind(this);
    }

    componentDidMount() {
        this.applyTheme();

        document.addEventListener("backbutton", this.onBackKeyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener("backbutton", this.onBackKeyDown, false);
    }

    onBackKeyDown(e) {
        e.preventDefault();
        e.stopPropagation();
        this.toggleConfirmationModal();
    }

    toggleCollapse() {
        this.setState({
            ...this.state,
            collapse: !this.state.collapse
        });
    }

    toggleConfirmationModal(i) {
        this.setState({
            ...this.state,
            openConfirmationDialog: !this.state.openConfirmationDialog,
            changeTime: i === 1 ? true : false
        });
    }

    // Method below will not be used anymore
    toggleChangeTimeScreen() {
        this.props.clearTemporaryOrderTimeData();

        const brandId = this.props.brand.id;
        this.props.history.push({ pathname: `/brand/${brandId}/start-order/order-time`, state: window.location.hash });
    }

    confirmOrderReset() {
        this.props.clearWizardData();
        this.props.clearState();
        const brandId = this.props.brand.id;
        if (this.state.changeTime) {
            this.props.history.push({ pathname: `/brand/${brandId}/start-order/order-time`, state: window.location.hash });
        } else {
            this.props.history.push(`/brand/${brandId}/start-order/main-screen`);
        }
    }

    getRestaurantClosingHour(openingHours) {
        let restaurantOpenUntil = '';

        if (openingHours.closeHour) restaurantOpenUntil += openingHours.closeHour + ':';
        if (openingHours.closeMinute) restaurantOpenUntil += openingHours.closeMinute;
        if (openingHours.closeHour === 0) restaurantOpenUntil += '00:';
        if (openingHours.closeMinute === 0) restaurantOpenUntil += '00';

        return restaurantOpenUntil;
    }

    getOrderTimeLabel(selectedOrderType) {
        let orderTimeLabel = '';

        if (selectedOrderType.id === DELIVERY_ID) {
            orderTimeLabel = i18n.t('screens:restaurantDetailsScreen.deliveryTime') + ': ';
        } else if (selectedOrderType.id === COLLECTION_ID) {
            orderTimeLabel = i18n.t('screens:restaurantDetailsScreen.collectionTime') + ': ';
        } else if (selectedOrderType.id === IN_STORE_ID) {
            orderTimeLabel = i18n.t('screens:restaurantDetailsScreen.instoreTime') + ': ';
        }

        return orderTimeLabel;
    }

    openDiscounts(pathParam) {
        const brandId = this.props.brand.id;
        if (pathParam === 'loyalty') {
            this.props.history.push(`/brand/${brandId}/order/loyalty`);
        } else {
            this.props.history.push(`/brand/${brandId}/order/discounts`);
        }
    }

    renderContent() {
        const {
            restaurantDataLabels,
            changeDeliveryInformation,
            disabledLink
        } = styles;

        const { restaurant, selectedOrderType, estimateOrderTime, menuPreviewMode, loyalty } = this.props;
        const { type, primaryColor, secondaryColor, buttonsFont, restInfoBackground, primaryFontColor, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;

        let restaurantFullAddress = this.props.restaurant.displayAddress;
        let restaurantOpenUntil = null;
        if (restaurant.workingDay) {
            restaurantOpenUntil = `${i18n.t('screens:restaurantDetailsScreen.openUntil')} ${moment(`${restaurant.workingDay.openingHour.closeHour}:${restaurant.workingDay.openingHour.closeMinute}`, 'HH:mm').format('HH:mm')}`;
        }

        let orderTime = '';
        if (!estimateOrderTime.timeSlotBased) {
            orderTime = dateAndTimeToUserFriendlyFormat(estimateOrderTime, restaurant);
        } else if (estimateOrderTime.timeSlotBased) {
            let fromTime = moment(`${estimateOrderTime.time.fromHour}:${estimateOrderTime.time.fromMinute}`, 'HH:mm').format('HH:mm');
            let toTime = moment(`${estimateOrderTime.time.toHour}:${estimateOrderTime.time.toMinute}`, 'HH:mm').format('HH:mm');
            orderTime = `${estimateOrderTime.time.date} ${fromTime} - ${toTime}`;
        }

        let orderTimeLabel = this.getOrderTimeLabel(selectedOrderType);

        const changeLinkStyle = (menuPreviewMode == true || menuPreviewMode == 'true') ? disabledLink : changeDeliveryInformation;
        const isDisabled = (menuPreviewMode == true || menuPreviewMode == 'true') ? true : false;

        let discountBtnText = window.innerWidth <= 768 ? i18n.t('screens:restaurantDetailsScreen.discounts') : i18n.t('screens:discountsScreen.heading')
        let isLoyaltyAvailable = false;

        if (loyalty.loyalty.id) {
            loyalty.loyalty.orderTypes.map(loyaltyOrderType => {
                if (loyaltyOrderType.id === selectedOrderType.id) {
                    isLoyaltyAvailable = true;
                }
            });
        }

        const restaurantNameFontStyle = {
            fontFamily: fontFamilyType,
            fontWeight: fontBold === 'bold' ? fontBold : 300,
            fontStyle: fontItalic,
            textTransform: uppercase,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: primaryFontColor,
            paddingRight: 2
        }

        const discountLoyaltyButtonsFontStyle = {
            fontFamily: fontFamilyType,
            fontWeight: fontBold === 'bold' ? fontBold : 300,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        // Hides all discounts if they are only with promo code
        let availableDiscounts = this.props.availableDiscounts;
        let allDiscountWithPromoCode = availableDiscounts.every(function (discount) {
            return discount.withPromoCodes == true;
        });

        return (
            <Fragment>
                <div className="bannerImageContainer">
                    <img src={`${FRONT_END_URL}/${this.props.bannerImagePath}`} alt="brand image" />
                    <div className="bannerImageOverlay" style={{ backgroundColor: restInfoBackground }}>
                        <h5 className='orderDetailsRestaurantName' style={{ display: 'flex' }}>
                            <span style={restaurantNameFontStyle}>{restaurant.name}</span>
                        </h5>
                        {/* Rendering the correct buttons according to the selected orderType */}
                        {
                            <Fragment>
                                <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', marginBottom: 4 }}>
                                    <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-start' }}>
                                        <LocationIconSVG style={this.props.isArabic ? { position: 'absolute', right: -20 } : { position: 'absolute', left: -20 }} width={20} height={20} fill={type === 'DARK' ? secondaryColor : primaryColor} />
                                        <span style={restaurantDataLabels}>{restaurantFullAddress}</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '50%', justifyContent: 'flex-end' }}>
                                        <ClockSVG width={20} height={20} fill={type === 'DARK' ? secondaryColor : primaryColor} style={this.props.isArabic ? {marginLeft: '3px'} : null} />
                                        <span style={restaurantDataLabels}>{restaurantOpenUntil}</span>
                                    </div>
                                </div>
                                <SelectedOrderTypeInfo
                                    selectedOrderType={selectedOrderType}
                                    orderTime={orderTime}
                                    orderTimeLabel={orderTimeLabel}
                                    changeLinkStyle={changeLinkStyle}
                                    changeDeliveryInformation={changeDeliveryInformation}
                                    restaurantFullAddress={restaurantFullAddress}
                                    selectedDeliveryAddress={this.props.selectedDeliveryAddress}
                                    isDisabled={isDisabled}
                                    selectedTheme={this.props.customerThemes.selectedTheme}
                                    toggle={this.toggleConfirmationModal.bind(this)}
                                    changeTime={this.toggleChangeTimeScreen.bind(this)}
                                />
                            </Fragment>

                        }
                    </div>
                </div>
                <div 
                    className="discountsAndMoreInfoContainer" 
                    // hidden={!isLoyaltyAvailable && !this.props.availableDiscounts.length}
                    hidden={true}>

                    {
                        this.props.availableDiscounts.length > 0 && !allDiscountWithPromoCode ?
                            <Button className="availableDiscountsButton pwa" style={{ width: isLoyaltyAvailable ? '50%' : '100%' }}
                                onClick={() => this.openDiscounts()}
                            >
                                <span style={discountLoyaltyButtonsFontStyle}>{discountBtnText}</span>
                                <StarSVG width={22} height={22} fill={buttonsFont} style={this.props.isArabic ? { marginRight: 5} : {marginLeft: 5}} />
                            </Button> : null
                    }

                    {
                        isLoyaltyAvailable ?
                            <Button
                                className="availableDiscountsButton pwa"
                                onClick={() => this.openDiscounts('loyalty')}
                                style={{ width: this.props.availableDiscounts.length > 0 ?  allDiscountWithPromoCode ? '100%': '50%' : '100%', borderLeftColor: '#fff', borderLeftWidth: 1, borderLeftStyle: 'solid' }}>
                                <span style={discountLoyaltyButtonsFontStyle}>{i18n.t('screens:profileScreen.loyalty')}</span>
                                <LoyaltyIconSvg width={22} height={20} fill={buttonsFont} style={this.props.isArabic ? { marginRight: 5, transform: 'scaleX(-1)' } : { marginLeft: 5 }} />
                            </Button> : null
                    }
                </div>
            </Fragment>
        );
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { textDecoration, primaryColor, primaryFontColor } = this.props.customerThemes.selectedTheme;
        customizedStyles.changeDeliveryInformation.color = primaryColor;
        customizedStyles.changeDeliveryInformation.textDecoration = textDecoration;
        customizedStyles.restaurantDataLabels.color = primaryFontColor;
        styles = customizedStyles;

        return styles;
    }

    render() {
        const changeTimeMsg = i18n.t('screens:restaurantDetailsScreen.changeTimeMsg');
        const changeLocationMsg = i18n.t('screens:restaurantDetailsScreen.informationMessage');

        const textData = {
            informationMessage: this.state.changeTime ? changeTimeMsg : changeLocationMsg,
            confirmationMessage: i18n.t('screens:restaurantDetailsScreen.confirmMsg')
        };

        return (
            <Fragment>
                {this.renderContent()}
                <ConfirmationModal
                    isOpen={this.state.openConfirmationDialog}
                    onToggle={this.toggleConfirmationModal.bind(this)}
                    onConfirm={this.confirmOrderReset.bind(this)}
                    message={textData}
                    customerThemes={this.props.customerThemes}
                />
            </Fragment>
        );
    }
}

let styles = {
    locationIconStyle: {
        marginRight: 10,
        width: 15,
        height: 15,
        color: 'red'
    },
    clockIconStyle: {
        marginRight: 10,
        width: 17,
        height: 17
    },
    deliveryOptionsContainer: {
        margin: '10px 0'
    },
    changeDeliveryInformation: {
        fontSize: 13,
        color: '#62B522',
        display: 'inline-block',
        cursor: 'pointer',
        padding: 0
    },
    disabledLink: {
        fontSize: 13,
        color: 'grey',
        display: 'inline-block',
        padding: 0
    },
    restaurantDataContainer: {
        margin: '22px 0 32px 0'
    },
    restaurantDataLabels: {
        position: 'relative',
        top: 2,
        fontSize: 13,
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis'
    },
    restaurantPlaceholderContainer: {
        paddingRight: 0,
        maxHeight: 350
    },
    discountIconStyle: {
        height: 20,
        width: 20,
        marginLeft: 10,
        position: 'relative',
        bottom: 2
    },
    restaurantNameLabelStyle: {
        marginLeft: 5,
        color: '#a7a7a7',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '50%'
    },
    centredItem: {
        margin: '0 auto',
        fontSize: 20,
        textAlign: 'center'
    }
};