import React from 'react';

const EmailIconSVG = ({ width, height, fill, style }) => {
    const viewBoxValues = "0 0 122.88 85.57";
    
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBoxValues} style={style} >
          <path fill={fill} d="M3.8,0,62.48,47.85,118.65,0ZM0,80.52,41.8,38.61,0,4.53v76ZM46.41,42.37,3.31,85.57h115.9L78,42.37,64.45,53.94h0a3,3,0,0,1-3.79.05L46.41,42.37Zm36.12-3.84,40.35,42.33V4.16L82.53,38.53Z"/>
      </svg>
    );
}

export default EmailIconSVG;
