import React from 'react';
import MaterialIcon from 'material-icons-react';

import LocationIconSVG from '../../common/svgComponents/LocationIconSVG';

const MapMarker = props => {

    return (
        <div id='infoMainContainer' style={{ pointerEvents: 'none' }}>
            <div id='addressInfoContainer'>
                <ul>
                    <li>
                        <span id='editAddressIcon'></span>
                        <span id='addressName'>{props.formattedSelectedAddress}</span>
                        <span id='gpsIcon' style={{ pointerEvents: 'all' }}>
                            <MaterialIcon icon='gps_fixed' size={20} />
                        </span>
                    </li>
                </ul>
            </div>
            <div id='markerContainer'>
                <LocationIconSVG width={85} height={85} fill={props.selectedTheme.type === 'DARK' ? props.selectedTheme.secondaryColor : props.selectedTheme.primaryColor} />
            </div>
        </div>
    );
};

export default MapMarker;