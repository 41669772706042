import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Modal, Container } from 'reactstrap';
import MaterialIcon from 'material-icons-react';
import i18n from '../../../i18next';

import ModalStep from '../../common/ModalStep';
import PromotionCard from './PromotionCard';
import DiscountsContentPWa from '../../../pwa-app/components/order/menu/DiscountsContentPwa';

import { loadAllAvailableDiscounts } from '../../../actions/index';
import { ARABIC, FRONT_END_URL } from "../../../utils/Constants";
import LoyaltyIconSvg from '../../../pwa-app/components/common/svgComponents/LoyaltyIconSVG';

class AvailableDiscountsScreen extends Component {

    componentDidMount() {
        this.applyTheme();
        const { restaurantId } = this.props.selectedRestaurant;
        this.props.loadAllAvailableDiscounts(restaurantId);
    }

    renderModalHeader() {
        const { headerFirstCol, subHeading } = styles;
        const isArabic = this.props.language === ARABIC;
        const { openTarget, customerThemes } = this.props;
        const { primaryFontColor, fontFamilyType, fontBold, fontItalic, uppercase } = customerThemes.selectedTheme;
        const svgFillColor = customerThemes.selectedTheme.type === 'DARK' ? customerThemes.selectedTheme.secondaryColor : customerThemes.selectedTheme.primaryColor;
        const renderHeaderIcon = openTarget === 'discounts' ?
            <span className="discounts-header" >
                <MaterialIcon icon="star_border" size={30} />
            </span> : <LoyaltyIconSvg width={27} height={27} fill={svgFillColor} style={isArabic ? { marginLeft: 10, transform: 'scaleX(-1)' } : { marginRight: 14 }} />;

        const headerStyle = {
            color: primaryFontColor,
            fontFamily: fontFamilyType,
            fontWeight: fontBold === 'bold' ? fontBold : 300,
            fontStyle: fontItalic,
            textTransform: uppercase
        }
        return (
            <React.Fragment>
                <Col sm={12} style={headerFirstCol}>
                    {renderHeaderIcon}
                    <span style={headerStyle}>{this.props.openTarget === 'discounts' ? i18n.t('screens:discountsScreen.heading') : i18n.t('screens:discountsScreen.headingTwo')}</span>
                </Col>
                <Col sm={12}>
                    <div style={subHeading}>{i18n.t('screens:discountsScreen.subHeading')}</div>
                </Col>
                <div
                    id="customCloseBtn"
                    className='available-discounts'
                    style={isArabic ? { marginTop: 5, position: 'absolute', top: 0, left: 5 } : { marginTop: 5, position: 'absolute', top: 0, right: 5 }} onClick={() => this.props.toggle()}></div>
            </React.Fragment>
        );
    }

    renderModalBody() {
        const { mobileView, desctopView } = styles;
        const { primaryFontColor } = this.props.customerThemes.selectedTheme;
        let filteredDiscounts, copyOfAvailableDiscounts, singleDiscount;
        const screenWidth = window.outerWidth < 992 ? mobileView : desctopView;

        const { openTarget, availableDiscounts, loyalty } = this.props;
        if (openTarget === 'discounts') {
            copyOfAvailableDiscounts = availableDiscounts.slice(0);

            filteredDiscounts = copyOfAvailableDiscounts.filter(function (discount) {
                return discount.withPromoCodes === false;
            });
        } else {
            filteredDiscounts = [loyalty];
        }

        singleDiscount = filteredDiscounts.length === 1 ? 'discounts-container-single' : 'discounts-container';

        if (window.outerWidth < 568) {
            return (
                <DiscountsContentPWa
                    renderColumn={null}
                    singleDiscount={singleDiscount}
                    filteredDiscounts={filteredDiscounts}
                    styles={styles}
                    selectedTheme={this.props.customerThemes.selectedTheme}
                />
            );
        } else {
            if (filteredDiscounts.length > 0) {
                return (
                    <Container style={desctopView} className={singleDiscount}>
                        {
                            filteredDiscounts.map((item, i) => {

                                let image = item.imagePath === null ? null : `${FRONT_END_URL}/${item.imagePath}`;

                                return (
                                    <PromotionCard
                                        key={`promoCard${i}`}
                                        promotion={item}
                                        promotionImage={image}
                                        styles={styles}
                                        selectedTheme={this.props.customerThemes.selectedTheme}
                                    />
                                );
                            })
                        }
                    </Container>
                );
            } else {
                return (
                    <Container style={desctopView} className="discounts-container">
                        <p style={{ color: primaryFontColor }}>{i18n.t('screens:discountsScreen.noDiscounts')}</p>
                    </Container>
                );
            }
        }
    }

    renderModalFooter() { // Returns null so that no footer is rendered
        return null;
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { mainTextColor, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;

        customizedStyles.subHeading.color = mainTextColor;
        customizedStyles.promotionNameStyle.fontFamily = fontFamilyType;
        customizedStyles.promotionNameStyle.fontWeight = fontBold === 'bold' ? fontBold : 300;
        customizedStyles.promotionNameStyle.fontStyle = fontItalic;
        customizedStyles.promotionNameStyle.textTransform = uppercase;

        styles = customizedStyles;
        return styles;
    }

    render() {
        const { discountsModal } = styles;

        return (
            <Modal size="lg" className="discounts" isOpen={this.props.isOpen} onToggle={this.props.toggle} toggle={this.props.toggle} style={discountsModal}>
                <ModalStep
                    isOpen={this.props.isOpen} onToggle={this.props.toggle}
                    renderHeader={this.renderModalHeader()}
                    renderBody={this.renderModalBody()}
                    renderModalFooter={this.renderModalFooter()}
                    styles={styles}
                    customerThemes={this.props.customerThemes}
                />
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        availableDiscounts: state.availableDiscounts,
        loyalty: state.loyalty.loyalty,
        selectedRestaurant: state.selectedRestaurant,
        customerThemes: state.customerThemes,
        language: state.storeLocalStorage.language
    }
}
export default connect(mapStateToProps, { loadAllAvailableDiscounts })(AvailableDiscountsScreen);

let styles = {
    discountsModal: {
        // height: 'calc(100% - 16px)'
    },
    modalStyle: {
    },
    modalBody: {

    },
    modalFooter: {
        display: 'none'
    },
    starIcon: {
        color: 'green !important'
    },
    discountImage: {
        // height: 170,
        objectFit: 'cover',
        // width: 225,
        borderRadius: 0
    },
    card: {
        width: 225,
        // margin: 7,
        // marginLeft: 18
        margin: '7px 12px'
    },
    mobileView: {
        padding: 0,
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        // width: 'calc(100% + 8px)',
        justifyContent: 'center',
        maxHeight: 'calc(70vh - 85px)',
        overflowY: 'overlay'
    },
    desctopView: {
        padding: 0,
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        // width: 'calc(100% + 8px)',
        // maxHeight: 'calc(100vh - 85px)',
        overflowY: 'overlay'
    },
    headerFirstCol: {
        padding: 0
    },
    subHeading: {
        justifyContent: 'flex-start',
        fontSize: 12,
        color: '#d3d4d3',
        paddingLeft: 24
    },
    description: {
        fontSize: 14
    },
    promotionNameStyle: {}
}