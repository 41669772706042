import React, { Component } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { Row, Col, Button, InputGroup, Input, InputGroupAddon } from 'reactstrap';
import { reSendPinCode } from '../../../../actions';
import i18n from '../../../../i18next';

import phoneIconSrc from '../../../../images/icons/icon-phone-grey.png';
import { ARABIC, FRONT_END_URL } from '../../../../utils/Constants';

 class SMSConfirmationCommon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            code: null,
            submitBtnDisabled: true,
            customerEmail: null,
            isCaptchaActive: false,
            captchaCode: ''
        };

        this.changeState = this.changeState.bind(this);
        this.toggleCaptcha = this.toggleCaptcha.bind(this);
    }

    componentDidUpdate() {
        if((this.props.customer.phoneNumberErrMsg && this.state.isCaptchaActive) || (this.props.customer.isResendSuccessMesage && this.state.isCaptchaActive)) {
            this.toggleCaptcha()
        }
    }

    reloadCaptcha() {
        this.setState({
            captchaCode: ''
        }, () => this.props.loadCaptcha())
    }

    changeState({ target }) {
        const appliedPromotions = this.props.shoppingCart ? this.props.shoppingCart.appliedPromotions : null;
        let isAppliedPromotionWithPromoCode = false;
        if(appliedPromotions && appliedPromotions.length) {
            appliedPromotions.map((promotion, index) => {
                if(promotion.promoCode) {
                    isAppliedPromotionWithPromoCode = true;
                }
            })
        }

        if(target.name === 'customerEmail') {
            this.setState({
                [target.name]: target.value
            })
        } else if (target.name === 'captchaCode') {
            this.setState({
                [target.name]: target.value
            })
        } else if(target.name === 'code' && this.props.customer.isForgottenEmailConfirmation) {
            this.setState({
                [target.name]: target.value
            }, () => {
                if (this.state.code.length === 4) {
                    this.setState({
                        submitBtnDisabled: false
                    })
                } else {
                    this.setState({
                        submitBtnDisabled: true
                    });
                }
            })
        
        } else {
            this.setState({
                [target.name]: target.value
            },
                () => {
                    if (this.state.code.length === 4) {
                        this.setState({
                            submitBtnDisabled: false
                        }, () => {
                            this.submitCode(this.state);
                            if (this.props.startOrder.currentPagePath.includes('/menu-screen') && !isAppliedPromotionWithPromoCode ) {
                                this.props.getOldestActiveLoyalty();
                            }
                        });
                    } else {
                        this.setState({
                            submitBtnDisabled: true
                        });
                    }
                });
        }

        if (this.props.customer.phoneNumberErrMsg || this.props.customer.isResendSuccessMesage) {
            this.props.resetErrMsg()
        }
    }


    toggleCaptcha() {
        this.setState({
            isCaptchaActive: !this.state.isCaptchaActive
        })
    }

    submitCode() {
        const countryCode = null;
        const customerEmail = this.props.customer.isForgottenEmailConfirmation ? this.state.customerEmail : '';

        if (!this.state.submitBtnDisabled) {
            return this.props.verifyCustomerPhone(this.props.customer.phone, this.state.code, countryCode, this.props.isPWA, customerEmail);
        }
    }

    resendPinCodeToggle() {
        this.toggleCaptcha()
        this.props.resetErrMsg()
    }

    renderContent() {
        const { subHeading, inputGroup, input, numberStyle, secondCol, thirdCol, phoneIconStyle, phoneIconStyleRTL, phoneNumberHeadingCol, marginAuto, submitBtn, submitBtnDisabled } = this.props.styles;
        const { primaryColor, primaryFontColor, fontFamilyType, fontBold, fontItalic, uppercase, mainTextColor, inactiveColor, buttonsFont } = this.props.customerThemes.selectedTheme;
        const { customer, brand } = this.props;
        const { captchaCode } = this.state
        const isArabic = this.props.language === ARABIC;
        const isMobileView = window.innerWidth <= 865 ? true : false
        
        const customerPhone = this.props.customer.phone ? (this.props.customer.phone.includes('%2B')) ? this.props.customer.phone.replace('%2B', '+') : this.props.customer.phone : null;
        const heading = customer.isLoginConfirmation ? i18n.t('screens:smsConfirmationScreen.emailHeading') : i18n.t('screens:smsConfirmationScreen.heading');
        const subHeadingText = customer.isLoginConfirmation ? i18n.t('screens:smsConfirmationScreen.emailSubHeadingOne') : i18n.t('screens:smsConfirmationScreen.subHeadingOne');
        const confirmationTypeText = customer.isLoginConfirmation ? i18n.t('screens:smsConfirmationScreen.email') : i18n.t('screens:smsConfirmationScreen.phoneNumber');
        const inputPlaceholder = customer.isLoginConfirmation ? i18n.t('screens:smsConfirmationScreen.enterEmailCode') : i18n.t('screens:smsConfirmationScreen.enterSMSCode');
        const currentConfirmationType = customer.isLoginConfirmation ? customer.email : customerPhone;
        
        const fontWeight = fontBold === 'bold' ? fontBold : 300;
        const selectSubtmitButtonStyle = captchaCode === '' ? submitBtnDisabled : submitBtn;
        let activeInactiveBtn = captchaCode === '' ? { backgroundColor: inactiveColor, color: buttonsFont } : { backgroundColor: primaryColor, color: buttonsFont };
        const hoverOrNoHover = captchaCode === '' ? '' : 'hoverEffect';

        const submitBtnFontStyle = {
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        const titleStyle = {
            margin: 'auto',
            textAlign: 'center',
            fontSize: 22,
            color: primaryFontColor,
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        return (
            <div className='modal-body-custom'>
                <Col sm={12} className='modal-custom-body-title' style={phoneNumberHeadingCol}>
                    <p className='title-style' style={titleStyle}>{heading}</p>
                </Col>
                <Col xs={12} className='modal-custom-body-inner-content' style={{ flexDirection: 'column', justifyContent: 'center', flex: 1 }}>
                    <Col xs={12} md={10} style={secondCol}>
                        <p style={subHeading}> {subHeadingText}</p>
                        <Col xs={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
                            <p style={{ color: primaryFontColor, textAlign: 'center' }}>{confirmationTypeText}:</p>
                            <p style={Object.assign({}, numberStyle, this.props.isArabic ? { color: primaryColor, marginRight: '5px'} : { color: primaryColor, marginLeft: '5px'})}>{currentConfirmationType}</p>
                        </Col>
                    </Col>
                    <Col xs={12} md={9} style={thirdCol}>
                        <InputGroup style={{...inputGroup, marginBottom: '3px'}}>
                            <img src={phoneIconSrc} width="30" height="auto" style={isArabic ? phoneIconStyleRTL : phoneIconStyle} />
                            <Input id="smsCode" type="text" name="code" style={input} placeholder={inputPlaceholder}
                                state={this.state.code}
                                onChange={this.changeState}
                                className='addressInputs' />
                        </InputGroup>
                        <div
                            hidden={!customer.isResendSuccessMesage || customer.isForgottenEmailConfirmation}
                            style={isArabic ? { color: 'green', textAlign: 'right', paddingRight: '10px' } : { color: 'green', textAlign: 'left', paddingLeft: '10px' }}
                        >{customer.isLoginConfirmation ? i18n.t('screens:smsConfirmationScreen.resendEmailSuccessMessage') : i18n.t('screens:smsConfirmationScreen.resendPhoneSuccessMessage')}</div>
                        <div
                            hidden={!customer.phoneNumberErrMsg || customer.isForgottenEmailConfirmation}
                            style={isArabic ? { color: 'red', textAlign: 'right', paddingRight: '10px' } : { color: 'red', textAlign: 'left', paddingLeft: '10px' }}
                        >{customer.phoneNumberErrMsg}</div>
                    </Col>
                    {
                        customer.isForgottenEmailConfirmation ?
                            <Col xs={12} md={9} style={{ margin: '7px auto 0px'}}>
                                <InputGroup style={{...inputGroup, marginBottom: '5px'}}>
                                    <Input id="yourEmail" type="text" name="customerEmail" placeholder="Email"
                                        state={this.state.customerEmail}
                                        value={this.state.customerEmail}
                                        onChange={this.changeState}
                                        style={input} 
                                        className='addressInputs'
                                        maxLength={64} />
                                </InputGroup>
                                <div
                                    hidden={!customer.isResendSuccessMesage}
                                    style={isArabic ? { color: 'green', textAlign: 'right', paddingRight: '10px' } : { color: 'green', textAlign: 'left', paddingLeft: '10px' }}
                                >{i18n.t('screens:smsConfirmationScreen.resendPhoneSuccessMessage')}</div>
                                <div
                                hidden={!customer.phoneNumberErrMsg}
                                style={isArabic ? { color: 'red', textAlign: 'right', paddingRight: '10px' } : { color: 'red', textAlign: 'left', paddingLeft: '10px' }}
                                >{customer.phoneNumberErrMsg}</div>
                            </Col> : null
                    }
                    {
                    !this.state.isCaptchaActive ? 
                        <Col xs={12} md={9} style={{display: 'flex', justifyContent: 'flex-start', margin: '0 auto 30px'}}>
                            <div onClick={!customer.isLoginConfirmation ? () => this.resendPinCodeToggle() : () => this.props.reSendPinCode(customer.id, customer.isLoginConfirmation, brand.id, captchaCode )}  style={{color: primaryColor, paddingLeft: 10, cursor: 'pointer'}}>{i18n.t('screens:smsConfirmationScreen.resendCode')}</div>
                        </Col> : null
                    }
                    { customer.captcha ?
                        <Col xs={12} md={9} hidden={customer.isLoginConfirmation ||  !this.state.isCaptchaActive} style={{margin: '0 auto'}}>
                            <p style={{...subHeading, margin: '10px 0 20px', color: mainTextColor}}>{i18n.t('screens:smsConfirmationScreen.resendSMSMessage')}</p>
                            <Col xs={10} md={8} style={marginAuto}>
                                <img src={`${FRONT_END_URL}/${this.props.customer.captcha.path}`} style={{ width: '100%', height: '50px', marginBottom: '10px' }} />
                                <InputGroup style={inputGroup}>
                                        <Input id="captchaCode" type="text" name="captchaCode" placeholder="Enter Code"
                                            state={captchaCode}
                                            value={captchaCode}
                                            onChange={this.changeState}
                                            style={isMobileView ? {...input, paddingLeft: '15px'} : input}
                                            className='addressInputs' />
                                        <InputGroupAddon addonType='append'>
                                            <Button
                                                className={hoverOrNoHover}
                                                disabled={!captchaCode}
                                                onClick={() => this.props.reSendPinCode(customer.id, customer.isLoginConfirmation, brand.id, captchaCode, () => this.reloadCaptcha())}
                                                style={Object.assign({}, selectSubtmitButtonStyle, activeInactiveBtn, submitBtnFontStyle)}
                                            >
                                                {i18n.t('screens:smsConfirmationScreen.resendSMS')}
                                            </Button>
                                        </InputGroupAddon>
                                </InputGroup> 
                            </Col>
                        </Col> : null
                    }
                </Col>
                {this.renderModalFooter()}
            </div>
        );
    }

    renderModalFooter() {
        const { submitBtn, submitBtnDisabled, modalCustomSubmitBody } = this.props.styles;
        const { primaryColor, inactiveColor, buttonsFont, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;

        let regex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
        let isEmailInValidFormat = this.state.customerEmail ? this.state.customerEmail.match(regex) : false;
        
        const isSubmitBtnDisabled = this.props.customer.isForgottenEmailConfirmation ? !this.state.submitBtnDisabled && isEmailInValidFormat ? false : true : this.state.submitBtnDisabled;
        
        const selectSubtmitButtonStyle = isSubmitBtnDisabled ? submitBtnDisabled : submitBtn;
        let activeInactiveBtn = isSubmitBtnDisabled ? { backgroundColor: inactiveColor, color: buttonsFont } : { backgroundColor: primaryColor, color: buttonsFont };
        const hoverOrNoHover = isSubmitBtnDisabled ? '' : 'hoverEffect';

        const fontWeight = fontBold === 'bold' ? fontBold : 300;
        const submitBtnFontStyle = {
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        return (
            <Row className='modal-custom-body-footer' style={modalCustomSubmitBody}>
                <Col xs={12} sm={10} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Col xs={12} sm={6} className="submitNumberBtn">
                        <Button className={hoverOrNoHover} style={Object.assign({}, selectSubtmitButtonStyle, activeInactiveBtn, submitBtnFontStyle)}
                            onClick={() => this.submitCode()}>{i18n.t('screens:smsConfirmationScreen.submitCode')}</Button>
                    </Col>
                </Col>
            </Row>
        );
    }

    render() {
       
        return (
            <React.Fragment>
                {this.renderContent()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps, {reSendPinCode})(SMSConfirmationCommon);