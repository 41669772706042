import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import MaterialIcon from 'material-icons-react';
import i18n from "../../../i18next";

import ModalStep from '../../common/ModalStep';
import ConfirmAddressContent from './ConfirmAddressContent';

import { INPUT_FIELDS_BG_COLOR } from '../../../utils/StylesConstants';
import { getLoggedInUserFirstName } from '../../../utils/CommonUtils';
import { loadRestaurantsInDeliveryArea, saveCustomerDeliveryAddress, restaurantChainService, saveSelectedRestaurantData, updateCustomerDeliveryAddresses } from '../../../actions/index';
import { updateIconsAccordingToTheme } from "../../../utils/ThemesSelector";
import UserIconSVG from '../../../pwa-app/components/common/svgComponents/UserIconSVG';
import { ARABIC } from "../../../utils/Constants";

class ConfirmAddressScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            buildingNumber: '',
            fullAddress: '',
            street: '',
            town: '',
            zipCode: '',
            disabledNext: true
        };

        this.goBack = this.goBack.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
    }

    componentDidMount() {
        this.applyTheme();

        if (this.props.userAddress && this.props.userAddress.fullAddress && this.props.userAddress.street !== null && this.props.userAddress.street !== undefined) {

            this.setState({
                ...this.state,
                fullAddress: this.props.userAddress.fullAddress,
                street: this.props.userAddress.street.includes('Unname') ? '' : this.props.userAddress.street,
                town: this.props.userAddress.city,
                buildingNumber: this.props.userAddress.streetNumber ? this.props.userAddress.streetNumber : '',
                zipCode: this.props.userAddress.zipCode ? this.props.userAddress.zipCode : ''
            }, () => {
                if (this.validateData()) {
                    this.setState({ ...this.state, disabledNext: false });
                } else {
                    this.setState({ ...this.state, disabledNext: true })
                }
            });
        }
    }

    updateAddress() {

        const addressId = this.props.userAddress.id;
        let updatedAddress = {};

        if (this.state.street !== "") {
            updatedAddress['street'] = this.state.street;
            updatedAddress['fullAddress'] = this.state.street;
        }

        if (this.state.buildingNumber !== "") {
            updatedAddress['streetNumber'] = this.state.buildingNumber;
            updatedAddress['fullAddress'] = updatedAddress['fullAddress'] + ' ' + this.state.buildingNumber;
        }

        if (this.state.town !== "") {
            updatedAddress['city'] = this.state.town;
            updatedAddress['fullAddress'] = updatedAddress['fullAddress'] + ', ' + this.state.town;
        }

        if (this.state.zipCode !== "") {
            updatedAddress['zipCode'] = this.state.zipCode;
            updatedAddress['fullAddress'] = updatedAddress['fullAddress'] + ', ' + this.state.zipCode;
        }

        this.props.updateCustomerDeliveryAddresses(addressId, updatedAddress, () => {
            this.props.toggle();
        });
    }

    getUpdatedFullAddress(newAddress) {
        let fullAddress = `${newAddress.streetNumber} ${newAddress.street}, ${newAddress.city}, ${newAddress.zipCode}`;

        return fullAddress;
    }

    validateData() {
        let validData = true;

        // If no data for address or any missing part of the address and street name is Unnamed Road -> form is not valid
        if (!this.state.fullAddress || !this.state.town || !this.state.zipCode || this.state.zipCode.length > 31 || !this.state.buildingNumber || ( !this.state.street === undefined || !this.state.street === null || this.state.street.includes('Unname'))) {
            validData = false;
            this.state.disabledNext = true;
            return validData;
        }

        if (this.state.fullAddress === '' || !this.state.town === '' || !this.state.zipCode === ''
            || !this.state.buildingNumber === '') {
            this.state.disabledNext = true;
            return validData = false;
        }

        validData = true;
        this.state.disabledNext = false;

        return validData;
    }

    goForward() {
        const { fullAddress, town, zipCode, buildingNumber, street } = this.state;

        let validData = this.validateData();

        if (validData && this.props.selectedOrderType.value == 'Delivery') {
            let newAddress = { ...this.props.userAddress };
            newAddress.streetNumber = buildingNumber;
            newAddress.zipCode = zipCode;
            newAddress.street = street;
            newAddress.city = town;
            // Detect changes inside address fields and get the updated fullAddress from user updated address fields
            newAddress.fullAddress = this.getUpdatedFullAddress(newAddress);

            this.props.saveCustomerDeliveryAddress(newAddress);

            this.props.restaurantChainService(newAddress, (restaurant) => {
                //in case restaurant object is array there are restaurants in given delivery area, but they are offline
                if (Array.isArray(restaurant)) {
                    this.props.loadRestaurantsInDeliveryArea(restaurant, () => {
                        this.props.history.push({
                            pathname: '/start-order/restaurant-selection',
                            state: { isRedirectedFromDelivery: true }
                        });
                    });
                } else {
                    this.props.saveSelectedRestaurantData(restaurant, () => {

                        this.props.history.push('/start-order/order-time');
                    });
                }
            });

        }

        if (validData && this.props.selectedOrderType.value == 'Collection') {

            let newAddress = { ...this.props.userAddress };
            newAddress.fullAddress = fullAddress;
            // Detect changes inside address fields and get the updated fullAddress from user updated address fields
            // newAddress.fullAddress = this.getUpdatedFullAddress(newAddress);

            newAddress.apartment = buildingNumber;
            newAddress.zipCode = zipCode;
            newAddress.city = town;

            this.props.saveCustomerDeliveryAddress(newAddress);

            this.props.history.push('/start-order/restaurant-selection');
        }

    }

    goBack() {
        this.props.history.goBack();
    }

    handleAddressChange(newAddress) {
        let updatedAddress = JSON.parse(JSON.stringify(newAddress));

        this.setState({
            ...this.state,
            buildingNumber: updatedAddress.buildingNumber,
            fullAddress: updatedAddress.fullAddress,
            street: updatedAddress.street,
            town: updatedAddress.town,
            zipCode: updatedAddress.zipCode
        }, () => {
            // Check if the data in all fields is valid
            if (!this.validateData()) {
                this.setState({ ...this.state, disabledNext: true });
            } else {
                this.setState({ ...this.state, disabledNext: false });
            }
        });
    }

    renderModalHeader() {
        const { headerColumn, centredItem, modalHeaderStyle } = styles;
        const customerLoggedIn = this.props.customer !== undefined && this.props.customer !== null && this.props.customer.id !== undefined
            && this.props.customer.id !== undefined && this.props.customer.id !== null && this.props.customer.id !== -1 && this.props.customer.email !== undefined
            && this.props.customer.email !== null && this.props.customer.email !== "null" && this.props.customer.email !== 'undefined';

        if (this.props.isProfileEdit) {
            return (
                <Fragment>
                    <div className='start-order-header-container' >
                        <div className='editAddressText'>
                            <p style={modalHeaderStyle}>{i18n.t('screens:confirmAddressScreen.editAddress')}</p>
                        </div>
                        <div style={{ width: 90, textAlign: 'right' }}>
                            <Button className='start-order-custom-close-button' onClick={() => { this.props.toggle() }}>
                                <MaterialIcon icon="close" color="#000" size={18} />
                            </Button>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <div className='start-order-header-container'>
                        <Button className="start-order-custom-back-button" onClick={() => this.goBack()}>
                            <span className="backButtonIcon"><MaterialIcon icon="navigate_before" color="#000" size={32} /></span>
                            <span style={{ fontWeight: 300 }}>{i18n.t('common:buttons.backBtn')}</span>
                        </Button>
                        {(customerLoggedIn) ? this.renderCustomer() : ''}
                        <div style={{ width: 90, textAlign: 'right' }}>
                            <Button className='start-order-custom-close-button' onClick={() => { this.props.toggle() }}>
                                <MaterialIcon icon="close" color="#000" size={18} />
                            </Button>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }

    renderCustomer() {
        const { imageStyle, imageStyleRTL, profileNameStyle, profileNameStyleRTL, centredItem } = styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        // const iconImage = updateIconsAccordingToTheme(type, 'user-secondary');
        const isArabic = this.props.language === ARABIC;

        const userFirstName = getLoggedInUserFirstName(this.props.customer.name);

        let customerNameClass = '';
        if (window.outerWidth <= 500) {
            customerNameClass = 'customer-long-text-back-button';
        }

        return (
            <div style={{ margin: '0 auto', fontSize: 20, textAlign: 'center' }} className='customer-name-mobile-style'>
                <UserIconSVG width={23} height={23} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? imageStyleRTL : imageStyle} />
                {/* <img src={iconImage} width="40" height="auto" style={imageStyle} /> */}
                <span style={isArabic ? profileNameStyleRTL : profileNameStyle} className={customerNameClass}>{userFirstName}</span>
            </div>
        );
    }

    renderNextButton() {
        const { buttonsFont, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        const fontWeight = fontBold === 'bold' ? fontBold : 300;
        const nextButtonTextStyle = {
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        let nextButtonStyle = '';

        const streetInvalid = !(this.state.street !== '' && !this.state.street.toLowerCase().includes('unname'));

        if (this.state.disabledNext || streetInvalid) {
            nextButtonStyle = 'disabledNextButton';
        } else {
            nextButtonStyle = 'nextButton';
        }

        return (
            <Col sm={{ size: 12, offset: 1 }} md={12} style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
                <Button className={nextButtonStyle} style={{}} onClick={this.goForward.bind(this)}>
                    <span style={nextButtonTextStyle}>{i18n.t('common:buttons.nextBtn')}</span>
                    <span className="btnNextIcon">
                        <MaterialIcon icon="navigate_next" color={buttonsFont} size={35} />
                    </span>
                </Button>
            </Col>
        )
    }

    renderConfirmButton() {
        const { fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        const fontWeight = fontBold === 'bold' ? fontBold : 300;
        const saveButtonTextStyle = {
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }
        let confirmButtonStyle = '';
        if (this.state.buildingNumber == '' || this.state.buildingNumber == '' || this.state.street == '' || this.state.town == '' || this.state.zipCode == '') {
            confirmButtonStyle = 'disabledNextButton saveBtn';
        } else {
            confirmButtonStyle = 'nextButton saveBtn';
        }

        return (
            <Col sm={{ size: 12, offset: 1 }} md={12} style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
                <Button className={confirmButtonStyle} style={{}} onClick={() => this.updateAddress()}>
                    <span style={{ padding: 0 }}>{i18n.t('common:buttons.saveBtn')}</span>
                </Button>
            </Col>
        );
    }

    renderModalBody() {
        const { screenMessage } = styles;
        const { type } = this.props.customerThemes.selectedTheme;
        const iconImage = updateIconsAccordingToTheme(type, 'check');
        const { primaryFontColor, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        const fontWeight = fontBold === 'bold' ? fontBold : 300;

        let modalBodyContainerStyle = {
            padding: '0 16px',
            position: 'absolute',
            width: '100%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        };

        const titleFontStyle = {
            fontFamily: fontFamilyType,
            fontWeight,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        return (
            <React.Fragment>
                {this.renderModalHeader()}
                <div className='modal-body-custom' style={{ padding: 0, margin: 0 }}>
                    <Col sm={12} className='modal-custom-body-title' style={{ height: 57, maxHeight: 57, minHeight: 57 }}>
                        {(this.props.isProfileEdit) ? '' : <p className='start-order-body-header-title startOrderScreenTitle' style={Object.assign({}, screenMessage, titleFontStyle, { margin: 'auto', textAlign: 'center', fontSize: 22, color: primaryFontColor })}>{i18n.t('screens:confirmAddressScreen.confirmAddress')}</p>}
                    </Col>
                    <Col xs={12} className='modal-custom-body-inner-content confirm-address-screen'>
                        <ConfirmAddressContent
                            updateAddressInParent={this.handleAddressChange}
                        />
                    </Col>
                    <Row className='modal-custom-body-footer confirm-address-screen' style={{ display: 'flex', justifyContent: 'center' }}>
                        {(this.props.isProfileEdit) ? this.renderConfirmButton() : this.renderNextButton()}
                    </Row>
                </div>
            </React.Fragment>
        );
    }

    renderModalFooter() {
        return null;
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { startOrderFontSize, primaryFontColor, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        customizedStyles.screenMessage.fontSize = startOrderFontSize;
        customizedStyles.profileNameStyle.color = primaryFontColor;
        customizedStyles.profileNameStyleRTL.color = primaryFontColor;
        customizedStyles.modalHeaderStyle.color = primaryFontColor;
        customizedStyles.modalHeaderStyle.fontFamily = fontFamilyType;
        customizedStyles.modalHeaderStyle.fontWeight = fontBold === 'bold' ? fontBold : 300;
        customizedStyles.modalHeaderStyle.fontStyle = fontItalic;
        customizedStyles.modalHeaderStyle.textTransform = uppercase;
        styles = customizedStyles;
        return styles;
    }

    render() {

        return (
            <AvForm fluid className="start-order">
                <ModalStep
                    isOpen={this.props.isOpen}
                    onToggle={this.props.toggle}
                    // renderHeader={this.renderModalHeader()}
                    renderBody={this.renderModalBody()}
                    renderFooter={this.renderModalFooter()}
                    styles={styles}
                    customerThemes={this.props.customerThemes}
                />
            </AvForm>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        customer: state.customer,
        userAddress: state.customer.selectedDeliveryAddress,
        selectedOrderType: state.orderTypes.selectedOrderType,
        customerThemes: state.customerThemes,
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps, { loadRestaurantsInDeliveryArea, saveCustomerDeliveryAddress, restaurantChainService, saveSelectedRestaurantData, updateCustomerDeliveryAddresses })(ConfirmAddressScreen);

let styles = {
    modalBody: {
        textAlign: 'center',
        padding: 0,
        minHeight: '480px',
        backgroundColor: '#ffffff'
    },
    screenMessage: {
        textAlign: 'center',
        width: '100%',
        marginBottom: 0
    },
    inputFieldStyle: {
        height: 55,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: INPUT_FIELDS_BG_COLOR,
        marginBottom: 10
    },
    navigateNextIcon: {
        position: 'absolute',
        top: 3
    },
    modalHeaderStyle: {
        textAlign: 'center',
        fontSize: 22,
        marginBottom: 0,
        marginLeft: 70
    },
    inputFieldContainer: {
        position: 'relative'
    },
    greenTickIconStyle: {
        width: 30,
        height: 'auto',
        position: 'absolute',
        top: 13,
        right: 23,
        backgroundColor: '#f0f0f0',
        padding: 5
    },
    headerColumn: {
        paddingLeft: 0,
        width: 72
    },
    login: {
        fontSize: 22,
        marginLeft: 12,
        marginRight: 12
    },
    heading: {
        fontSize: 22,
        margin: 'auto',
        textAlign: 'center'
    },
    imageStyle: {
        marginRight: 16,
    },
    imageStyleRTL: {
        marginLeft: 16,
    },
    centredItem: {
        margin: '0 auto',
        fontSize: 20,
        textAlign: 'center',
        paddingRight: 55
    },
    profileNameStyle: {
        marginLeft: -10,
        verticalAlign: 'middle',
        fontSize: 20
    },
    profileNameStyleRTL: {
        marginRight: -10,
        verticalAlign: 'middle',
        fontSize: 20
    }
};