import React from 'react';
import { CardBody } from 'reactstrap';

import ItemCardContent from './ItemCardContent';
import ItemCardImage from './ItemCardImage';

const ItemCardThumbnail = props => {

    return (
        <CardBody style={{ display: 'flex', padding: 0 }}>
            <div style={{ padding: props.isArabic ? '12px 30px 0px 18px' : '12px 18px 0px 30px', width: 'calc(100% - 125px)' }}>
                <ItemCardContent {...props} />
            </div>
            <ItemCardImage {...props} />
        </CardBody>
    );
}

export default ItemCardThumbnail;