
import React, { Component } from 'react';
import { Table } from 'reactstrap';
import i18n from '../../../i18next';

import { getPriceWithCurrencySymbol } from '../../../utils/CommonUtils';
import { ARABIC } from "../../../utils/Constants";

class ServiceFeeScreen extends Component {

    componentDidMount() {
        this.applyTheme();
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const {
            primaryFontColor,
            mainTextColor,
            menuItemsBackground,
            buttonsFont,
            fontFamilyType,
            fontBold,
            fontItalic,
            uppercase
        } = this.props.customerThemes.selectedTheme;

        customizedStyles.mainContainer.backgroundColor = menuItemsBackground;
        customizedStyles.headerStyle.color = primaryFontColor;
        customizedStyles.buttonsStyle.color = buttonsFont;
        customizedStyles.bodyContainer.color = mainTextColor;
        customizedStyles.buttonsStyle.fontFamily = fontFamilyType;
        customizedStyles.buttonsStyle.fontWeight = fontBold === 'bold' ? this.props.customerThemes.selectedTheme.fontBold : 300;
        customizedStyles.buttonsStyle.fontStyle = fontItalic;
        customizedStyles.buttonsStyle.textTransform = uppercase;

        styles = customizedStyles;
        return styles;
    }

    renderServiceFeeTable() {
        console.log(this.props)
        const isArabic = this.props.isArabic === ARABIC;
        const tabletOrDesktopView = window.innerWidth >= 940;
        const colWidth = tabletOrDesktopView ? 'col-10' : 'col-12';
        let { menuItemsBackground, tableHeadersBackground, primaryFontColor, mainTextColor, uppercase } = this.props.customerThemes.selectedTheme;
        const serviceFee = getPriceWithCurrencySymbol(Number(this.props.serviceFee).toFixed(2), this.props.currencySymbol, isArabic);
        const serviceFeeMinAmount = getPriceWithCurrencySymbol(Number(this.props.minAmount).toFixed(2), this.props.currencySymbol, isArabic);
        const serviceFeeMaxAmount = getPriceWithCurrencySymbol(Number(this.props.maxAmount).toFixed(2), this.props.currencySymbol, isArabic);
        const percent = this.props.percentAmount;
        const isPercent = this.props.percent;

        return (
            <div style={{ display: 'flex', border: 'none', borderStyle: 'hidden' }}>
                <Table style={{ marginBottom: 0, border: 'none', borderCollapse: 'collapse', borderStyle: 'hidden' }}>
                    <thead style={{ backgroundColor: menuItemsBackground, border: 'none' }}>
                        <tr className="d-table-row" style={{ background: menuItemsBackground, marginTop: 10, padding: '0px 6px', color: primaryFontColor, border: 'none' }}>
                            <th className={colWidth} style={{ border: 'revert-layer', borderColor: 'transparent', display: 'flex', background: menuItemsBackground, textTransform: uppercase }}>{i18n.t('screens:shoppingCart.menuuServiceFee')}</th>                       
                                <div
                                    id="customCloseBtn"
                                    className='available-discounts'
                                    style={{ marginTop: 5, position: 'absolute', top: 0, right: 5 }} onClick={() => this.props.onToggle()}>
                                </div>
                        </tr> 
                    </thead>
                    
                    <tbody style={{ backgroundColor: menuItemsBackground, border: 'none' }}>
                        { isPercent ? 
                        <tr className="d-table-row" style={{ border: 'none'}}>
                            <td className={colWidth} style={{ fontSize: 14, color: mainTextColor, border: 'none', maxWidth: '100px' }}>
                                <div style={{display: 'flex', border: 'none', }}>
                                    {i18n.t('screens:shoppingCart.serviceFeeMessagePartOne')}{serviceFeeMinAmount}{i18n.t('screens:shoppingCart.serviceFeeMessagePartTwo')}{serviceFeeMaxAmount}.
                                </div>
                            </td>
                        </tr> :
                        <tr className="d-table-row" style={{border: 'none'}}>
                            <td className={colWidth} style={{ fontSize: 14, color: mainTextColor, border: 'none' }}>
                                <div style={{display: 'flex', border: 'none'}}>
                                    {i18n.t('screens:shoppingCart.serviceFeeFixedAmountMessagePartOne')}{serviceFee}{i18n.t('screens:shoppingCart.serviceFeeFixedAmountMessagePartTwo')}.
                                </div>
                            </td>
                        </tr> 
                        }
                    </tbody> 

                    <thead style={{ border: 'none' }}>
                        <tr className="d-table-row" style={{ background: tableHeadersBackground, marginTop: 10, padding: '0px 6px', color: primaryFontColor, border: 'none' }}>
                            <th className={colWidth} style={{ border: 'none', background: menuItemsBackground, textTransform: uppercase }}>{i18n.t('screens:shoppingCart.deliveryFee')}</th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: menuItemsBackground, border: 'none' }}>
                        <tr className="d-table-row" style={{border: 'none'}}>
                            <td className={colWidth} style={{ fontSize: 14, color: mainTextColor, border: 'none' }}>
                                <div style={{ display: 'flex', border: 'none' }}>
                                    {i18n.t('screens:shoppingCart.deliveryFeeMessage')}
                                </div>
                            </td>
                        </tr>
                    </tbody>

                    <thead style={{ border: 'none' }}>
                        <tr className="d-table-row" style={{ background: tableHeadersBackground, marginTop: 10, padding: '0px 6px', color: primaryFontColor, border: 'none' }}>
                            <th className={colWidth} style={{ border: 'none', background: menuItemsBackground, textTransform: uppercase }}>{i18n.t('screens:shoppingCart.returnDeposit')}</th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: menuItemsBackground, border: 'none' }}>
                        <tr className="d-table-row" style={{border: 'none'}}>
                            <td className={colWidth} style={{ fontSize: 14, color: mainTextColor, border: 'none' }}>
                                <div style={{display: 'flex', border: 'none'}}>
                                    {i18n.t('screens:shoppingCart.returnDepositMessage')}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        )
    }


    render() {
        return (
            this.renderServiceFeeTable()
        )
    }
}

export default ServiceFeeScreen;

let styles = {
    headerStyle: {
        display: 'flex',
        padding: 20,
        justifyContent: 'center'
    },
    bodyContainer: {
        padding: 20
    },
    buttonsStyle: {
        borderRadius: 0,
        border: 'none',
        minWidth: 170,
        paddingTop: 10,
        paddingBottom: 10
    },
    mainContainer: {}
}