import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { updateCSSVariables } from '../../../utils/ThemesSelector';

import PhoneNumberPWA from './login/PhoneNumberPWA';
import SMSConfirmationPWA from './login/SMSConfirmationPWA';

import { submitLogin, verifyCustomerPhone, registerCustomer, openChangeOrderTimeScreen, resetPhoneNumberErrMsg, redirectToUserCredentialsPath, resetErrMsg, forgottenEmail, redirectToForgottenEmailPath, resetForgottenEmailConfirmation, resetLoginConfirmation, getCaptcha } from '../../../actions';
import UserCredentialsPWA from './login/UserCredentialsPWA';
import ProfileScreenPWA from "./ProfileScreenPWA";
import ForgottenEmailPWA from './login/ForgottenEmailPWA';


class ProfileContainer extends Component {

    componentDidMount() {
        this.applyTheme();
    }

    applyTheme() {
        updateCSSVariables(this.props.customerThemes.selectedTheme.colorsCss);
    }

    render() {
        return (
            <div>
                <Switch>
                    <Route
                        path='/brand/:id/profile/login/phone-number'
                        render={(props) => (
                            <PhoneNumberPWA {...props}
                                customerThemes={this.props.customerThemes}
                                customer={this.props.customer}
                                brand={this.props.brand}
                                submitLogin={this.props.submitLogin}
                                openChangeOrderTimeScreen={this.props.openChangeOrderTimeScreen}
                                resetPhoneNumberErrMsg={this.props.resetPhoneNumberErrMsg}
                                redirectToUserCredentialsPath={this.props.redirectToUserCredentialsPath}
                                resetErrMsg={this.props.resetErrMsg}
                                startOrder={this.props.startOrder}
                                redirectToForgottenEmailPath={this.props.redirectToForgottenEmailPath}
                                resetLoginConfirmation={this.props.resetLoginConfirmation}
                                resetForgottenEmailConfirmation={this.props.resetForgottenEmailConfirmation}
                            />
                        )}
                    />

                    <Route
                        path='/brand/:id/profile/login/sms-confirmation'
                        render={(props) => (
                            <SMSConfirmationPWA {...props}
                                customerThemes={this.props.customerThemes}
                                customer={this.props.customer}
                                submitCode={this.props.submitCode}
                                verifyCustomerPhone={this.props.verifyCustomerPhone}
                                openChangeOrderTimeScreen={this.props.openChangeOrderTimeScreen}
                                startOrder={this.props.startOrder}
                                brand={this.props.brand}
                                resetErrMsg={this.props.resetErrMsg}
                                loadCaptcha={this.props.getCaptcha}
                            />
                        )}
                    />
                    <Route
                        path='/brand/:id/profile/login/user-credentials'
                        render={(props) => (
                            <UserCredentialsPWA {...props}
                                customerThemes={this.props.customerThemes}
                                customer={this.props.customer}
                                registerCustomer={this.props.registerCustomer}
                                openChangeOrderTimeScreen={this.props.openChangeOrderTimeScreen}
                                resetErrMsg={this.props.resetErrMsg}
                                brand={this.props.brand}
                                loadCaptcha={this.props.getCaptcha}
                            />
                        )}
                    />
                    <Route
                        path='/brand/:id/profile/login/forgotten-email'
                        render={(props) => (
                            <ForgottenEmailPWA {...props}
                                customerThemes={this.props.customerThemes}
                                customer={this.props.customer}
                                brand={this.props.brand}
                                submitLogin={this.props.submitLogin}
                                openChangeOrderTimeScreen={this.props.openChangeOrderTimeScreen}
                                resetErrMsg={this.props.resetErrMsg}
                                forgottenEmail={this.props.forgottenEmail}
                                loadCaptcha={this.props.getCaptcha}
                            />
                        )}
                    />
                    <Route
                        path='/brand/:id/profile'
                        render={(props) => (
                            <ProfileScreenPWA {...props}
                                customerThemes={this.props.customerThemes}
                                customer={this.props.customer}
                                registerCustomer={this.props.registerCustomer}
                                openChangeOrderTimeScreen={this.props.openChangeOrderTimeScreen}
                            />
                        )}
                    />
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        brand: state.brand,
        customerThemes: state.customerThemes,
        customer: state.customer,
        submitCode: state.submitCode,
        startOrder: state.startOrder
    };
};

export default connect(mapStateToProps,
    { 
        submitLogin, 
        verifyCustomerPhone, 
        registerCustomer, 
        openChangeOrderTimeScreen, 
        resetPhoneNumberErrMsg, 
        redirectToUserCredentialsPath,
        resetErrMsg,
        forgottenEmail,
        redirectToForgottenEmailPath,
        resetLoginConfirmation,
        resetForgottenEmailConfirmation,
        getCaptcha
    })(ProfileContainer);