import React from 'react';
import { Col } from 'reactstrap';
import { isMobile } from 'mobile-device-detect';

import ItemCardContainer from '../../../common/deck/ItemCardContainer';

const ComoPromotionsList = props => {

    if (!props.assets) return null;

    return props.assets.map((asset, index) => {

        if (asset.status !== 'Active') return null;

        const getCardClassName = (promo, selectedPromotions) => {

            let className = 'promotion-card';
            if (isMobile) {
                className += '-device';
            }

            selectedPromotions.map(selectedPromotion => {
                if (selectedPromotion && promo.key === selectedPromotion.key) {

                    className = 'promotion-card-selected';
                }
            });

            return className;
        };

        return (
            <Col
                key={`promoColumn${index}`}
                xs={12} sm={12} md={12} lg={6} xl={6}
                style={{ padding: props.isArabic ? '15px 0 0 15px' : '15px 15px 0 0' }}
                onClick={() => props.selectPromotion(asset)}>
                <ItemCardContainer
                    {...props}
                    cardContentItemIndex={index}
                    cardId={asset.key}
                    cardStyle={
                        {
                            height: 125,
                            border: 'none',
                            backgroundColor: props.customerThemes.selectedTheme.menuItemsBackground,
                            borderRadius: 0
                        }
                    }
                    cardContentItem={asset}
                    isBadgeAllowed={false}
                    cardClassName={getCardClassName(asset, props.selectedItems)}
                />

            </Col>
        );
    });
}

export default ComoPromotionsList;