import React, { Component } from 'react';
import Axios from 'axios';
import i18n from '../../../../i18next';
import { Col } from 'reactstrap';
import { SERVER_URL, FRONT_END_URL } from '../../../../utils/Constants';
import { getAllUrlParams, getQueryInfoFromUrl } from '../../../../utils/CommonUtils';
import { parentLocalStorage, sendMessageToParentSite } from '../../../../utils/CrossOriginUtils';


class TbkChileForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tbkUser: null,
            integrationUrl: null
        }
    }

    componentDidMount = () => {
        const isIntegration = getQueryInfoFromUrl().brandId;

        this.props.validateCardInput(true);

        if (isIntegration) {
            setTimeout(() => {      
                parentLocalStorage('get', 'parentUrl')
                .then(parentUrlData => {
                    this.setState({
                        integrationUrl: parentUrlData
                    })
                });
            }, 2000);
        }
    };

    componentWillMount = () => {
        const isIntegration = getQueryInfoFromUrl().brandId;

        if (isIntegration) {
            sendMessageToParentSite('getParentUrl');
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.payment.isCardValid !== this.props.payment.isCardValid) {
            this.props.validateCardInput(true);
        }

        if (this.props.paymentProvider.tbkUser) {
            this.props.placeOrder(false, this.props.paymentProvider.tbkUser);
            this.props.clearTbkData();
        }

        const isIntegration = getQueryInfoFromUrl().brandId;

        if (this.props.paymentProvider.confirmEnrollmentErrMsg) {
            this.props.errorProcessingPayment(this.props.paymentProvider.confirmEnrollmentErrMsg, null, this.props.paymentProvider.providerType.id, isIntegration);
            this.props.clearConfirmEnrollmentErrMsg();
        }
    }

    componentWillUnmount = () => {
        this.props.validateCardInput(false);
    };

    checkCardData = (e) => {
        // Prevents the form from refreshing the page
        e.preventDefault();
        // Starts the processing window
        this.props.processingPayment();
        this.doAuthorize();
    }
    
    doAuthorize = async () => {
        this.props.processingPayment();
        
        const { customer, selectedRestaurant, shoppingCart, customerThemes, orderTypes, pwaAppRunning } = this.props;
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total : null;
        const isIntegration = getQueryInfoFromUrl().brandId;
        const navigationType = this.props.storeLocalStorage.navigationType;
        const brandId = this.props.brand.id;
        const restaurantId = selectedRestaurant.restaurantId;
        const restaurantTypeId = selectedRestaurant.type ? selectedRestaurant.type.id : null;
        const selectedOrderTypeId = orderTypes.selectedOrderType.id;
        const selectedThemeType = customerThemes.selectedTheme.type;
        let selectedThemePrimaryColor = customerThemes.selectedTheme.primaryColor;

        if (selectedThemePrimaryColor && selectedThemePrimaryColor.startsWith('#')) {
            selectedThemePrimaryColor = selectedThemePrimaryColor.slice(1);
        }
        
        let responseUrl;

        if (this.props.pwaAppRunning) {
            responseUrl = `${FRONT_END_URL}/tbk-confirm-enrollment`;
        } else if (isIntegration) {
            responseUrl = this.state.integrationUrl;
        } else {
            responseUrl = `${FRONT_END_URL}/tbk-confirm-enrollment`;

            // For local testing
            // responseUrl = `http://localhost:3001/tbk-confirm-enrollment`;
        }

            let paymentIntentRequest = {
                customerId: customer.id,
                restaurantId: selectedRestaurant.restaurantId,
                amount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : shoppingCart.total,
                currency: selectedRestaurant.currency,
                tbkAuthorizeRequest: {
                    responseUrl: responseUrl
                }
            }

            const header = { headers: { 'Authorization': `TOKEN${customer.token}` } };
            
            await Axios.post(`${SERVER_URL}/payment/authorize`, paymentIntentRequest, header)
                .then(response => {
                    if (response.data.tbkAuthorizationDTO.enrolled) { // registered
                        let tbkPlaceOrderRequest = {
                            tbkUser: response.data.tbkAuthorizationDTO.tbkUser
                        }

                        this.props.placeOrder(false, tbkPlaceOrderRequest);
                        this.props.clearTbkData();
                    } else if (response.data.tbkAuthorizationDTO.enrolled === false) { // unregistered, redirect to Tbk page
                        
                        const dataForTbk = {
                            brandId: brandId,
                            navigationType: navigationType,
                            restaurantId: restaurantId,
                            restaurantTypeId: restaurantTypeId,
                            selectedOrderTypeId: selectedOrderTypeId,
                            selectedThemeType: selectedThemeType,
                            selectedThemePrimaryColor: selectedThemePrimaryColor,
                            pwaAppRunning: pwaAppRunning,
                            isIntegration: isIntegration
                        }
                        const stringifyData = JSON.stringify(dataForTbk);
                        parentLocalStorage('set', 'dataForTbk', stringifyData);
                        this.tbkRedirect(response.data.tbkAuthorizationDTO.url_webpay, response.data.tbkAuthorizationDTO.token, restaurantId);
                    }
                })
                .catch (error => {
                    this.props.errorProcessingPayment(error, 'Authorize Failure', this.props.paymentProvider.providerType.id);
                    this.props.sendEmailReport(selectedRestaurant.restaurantId, error, 'Tbk authorize error');
                });
    }

    tbkRedirect = (redirectUrl, token, restaurantId) => {
        if (!this.props.pwaAppRunning) {
            window.location.href = `${redirectUrl}?TBK_TOKEN=${token}`;
        } else {
            let browserRef = window.cordova.InAppBrowser.open(`${redirectUrl}?TBK_TOKEN=${token}`, '_blank', 'location=no,toolbar=no'); // iOS only works with target = _blank
            browserRef.addEventListener('loadstart', (event) => this.completePwaOrder(event, browserRef, restaurantId));
        }
    }

    completePwaOrder = (event, browserRef, restaurantId) => {
       if (event) {
        if ((event.url).includes('tbk-confirm-enrollment?TBK_TOKEN')) {
            const queryParams = getAllUrlParams(event.url);
            const tbkTokenParam = queryParams.TBK_TOKEN;

            this.props.sendTbkToken(tbkTokenParam, null, null, restaurantId);
            browserRef.close();
            browserRef.removeEventListener('loadstart');
            }
        }
    }

    render() { 
        const { primaryFontColor } = this.props.customerThemes.selectedTheme;
        const { cardsInfoMessage } = this.props.styles;
        const isMobileView = window.innerWidth <= 865;

        return (
            <Col>
            <form
                id='tbkForm'
                onSubmit={(e) => this.checkCardData(e)}
                hidden={true}
            />
            <p
                hidden={(this.props.customer.savedPaymentCards && this.props.customer.savedPaymentCards.length) || this.props.payment.selectedPaymentType.id !== 1}
                style={!this.props.pwaAppRunning && isMobileView ? {...cardsInfoMessage, color: primaryFontColor, position: 'none', left: 'unset'} :
                {...cardsInfoMessage, color: primaryFontColor}}>*{i18n.t('common:alerts.paymentProvidersWarning')}</p>
            </Col>
        )
    }
}

export default TbkChileForm;