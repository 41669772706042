import React, { Component } from 'react';
import Axios from 'axios';
import { Col } from 'reactstrap';

import i18n from '../../../../i18next';
import { FRONT_END_URL, SERVER_URL } from '../../../../utils/Constants';
import { getQueryInfoFromUrl, getAllUrlParams } from '../../../../utils/CommonUtils';

class WorldPayFormUS extends Component {
    componentDidMount = () => {
        this.props.validateCardInput(true);
        this.completeOrder();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.payment.isCardValid !== this.props.payment.isCardValid) {
            this.props.validateCardInput(true);
        }

        // need to save verificationCode first and after that to redirect to world pay url
        if ((prevProps.paymentProvider.worldPayData.verificationCode !== this.props.paymentProvider.worldPayData.verificationCode)
        &&  this.props.paymentProvider.worldPayData.verificationCode) {

            setTimeout(() => this.worldPayRedirect(this.props.paymentProvider.worldPayData.redirectUrl), 2000);
        }
    }

    async submitForm(e) {
        e.preventDefault();
        this.props.processingPayment();

        const { customer, selectedRestaurant, shoppingCart } = this.props;
        const { navigationType, brandId } = this.props.storeLocalStorage;
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total : null;

        const isIntegration = getQueryInfoFromUrl().brandId;
        let redirectUrl = '';

        if (isIntegration) {
            redirectUrl = `${FRONT_END_URL}/?brandId=${brandId}&navigationType=${navigationType}#/checkout/`;
        } else if (this.props.pwaAppRunning) {
            redirectUrl = 'http://localhost:8100/';   // this is just fake url bcs world pay want some redirect url
        } else {
            redirectUrl = `${FRONT_END_URL}/#/checkout`;
        }

        let paymentIntentRequest = {
            customerId: customer.id,
            restaurantId: selectedRestaurant.restaurantId,
            amount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : shoppingCart.total,
            currency: selectedRestaurant.currency,
            worldPayUSAuthorizeRequest: {
                redirectUrl
            }
        };

        const header = { headers: { 'Authorization': `TOKEN${customer.token}` } };

        await Axios.post(`${SERVER_URL}/payment/authorize`, paymentIntentRequest, header)
            .then(response => {
                this.props.saveWorldPayData(response.data.worldPayUSAuthrizationDTO);
            })
            .catch(error => {
                this.props.errorProcessingPayment(error, 'AuthorizeFailure', this.props.paymentProvider.providerType.id);
                this.props.sendEmailReport(selectedRestaurant.restaurantId, error, 'World Pay authorize error');
            });
    }

    worldPayRedirect = (redirectUrl) => {
        if (!this.props.pwaAppRunning) {
            window.location.href = redirectUrl;
        } else {
            let browserRef = window.cordova.InAppBrowser.open(redirectUrl, '_blank', 'location=no,toolbar=no'); // iOS only works with target = _blank
            browserRef.addEventListener('loadstart', (event) => this.completeOrder(event, browserRef));
            browserRef.addEventListener('exit', (event) => this.completeOrder(event, browserRef));
        }

    }

    completeOrder = (event, browserRef) => {
        if (new URLSearchParams(window.location.hash).get('ExpressResponseCode')) {       // Desktop and mobile view flow
            const responseCode = parseInt(new URLSearchParams(window.location.hash).get('ExpressResponseCode'));
            const validationCode = new URLSearchParams(window.location.hash).get('ValidationCode');
            const authorizationCode = this.props.paymentProvider.worldPayData.verificationCode;

            if (responseCode === 0 && validationCode === authorizationCode) {
                const paymentProviderData = {
                    transactionId: new URLSearchParams(window.location.hash).get('TransactionID'),
                    amount: new URLSearchParams(window.location.hash).get('ApprovedAmount'),
                    lastDigits: new URLSearchParams(window.location.hash).get('LastFour')
                }

                this.props.placeOrder(false, paymentProviderData);
                this.props.clearWorldPayData();
            } else {
                this.props.errorProcessingPayment({}, '', this.props.paymentProvider.providerType.id);
                this.props.clearWorldPayData();
            }
        } else if (event) {         // Apps flow
            if ((event.url).indexOf('ExpressResponseCode=') !== -1 || (event.url).indexOf('HostedPaymentStatus=') !== -1) {
                browserRef.close();
                browserRef.removeEventListener('loadstart', this.completeOrder);
                let allUrlParams = getAllUrlParams(event.url);
                const responseCode = parseInt(allUrlParams.ExpressResponseCode);
                const validationCode = allUrlParams.ValidationCode;
                const authorizationCode = this.props.paymentProvider.worldPayData.verificationCode;
                const hostedPaymentStatus = allUrlParams.HostedPaymentStatus;
                if (responseCode === 0 && validationCode === authorizationCode && hostedPaymentStatus === 'Complete') {
                    const paymentProviderData = {
                        transactionId: allUrlParams.TransactionID,
                        amount: allUrlParams.ApprovedAmount,
                        lastDigits: allUrlParams.LastFour
                    }
    
                    this.props.placeOrder(false, paymentProviderData);
                    this.props.clearWorldPayData();
                } else if (hostedPaymentStatus === 'Cancelled') {     // handle error when cancel transaction on APPS
                    this.props.errorProcessingPayment({}, i18n.t(`screens:shoppingCartActions.cancelledByUser`), this.props.paymentProvider.providerType.id);
                    this.props.clearWorldPayData();
                } else {
                    this.props.errorProcessingPayment({}, '', this.props.paymentProvider.providerType.id);
                    this.props.clearWorldPayData();
                }
            } else if (event.type === 'exit' && this.props.paymentProvider.worldPayData.verificationCode !== null) {     // handle error when click back button on APPS
                this.props.errorProcessingPayment({}, i18n.t(`screens:shoppingCartActions.cancelledByUser`), this.props.paymentProvider.providerType.id);
                this.props.clearWorldPayData();
            }
        } else if (this.props.paymentProvider.worldPayData.verificationCode) {   // handle error when click back button or cancel transaction on DESKTOP
            this.props.errorProcessingPayment({}, i18n.t(`screens:shoppingCartActions.cancelledByUser`), this.props.paymentProvider.providerType.id);
            this.props.clearWorldPayData();
        }
    }

    render() {
        const { primaryFontColor } = this.props.customerThemes.selectedTheme;
        const { cardsInfoMessage } = this.props.styles;
        const isMobileView = window.innerWidth <= 865;
        
        return (
            <Col>
            <form
                id='worldPayForm'
                onSubmit={(e) => this.submitForm(e)}
                hidden={true}
            />
            <p
                hidden={this.props.payment.selectedPaymentType.id !== 1}
                style={!this.props.pwaAppRunning && isMobileView ? {...cardsInfoMessage, color: primaryFontColor, position: 'none', left: 'unset'} :
                {...cardsInfoMessage, color: primaryFontColor}}>*{i18n.t('common:alerts.paymentProvidersWarning')}</p>
            </Col>
        )
    }
}
export default WorldPayFormUS;