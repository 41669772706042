import React from 'react';
import i18n from '../../../../i18next';

const NutriListItem = React.forwardRef((props, ref) => {
    const styles = {
        nutriCell: {
            backgroundColor: '#F4F4F4',
            padding: '10px 0',
            marginLeft: 2,
            marginBottom: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: props.exactHeight ? props.exactHeight : 'auto'
        },
        nutriLabel: {
            fontSize: 13,
            textAlign: 'center',
            marginBottom: 0,
            color: '#000',
            fontFamily: 'Roboto',
            fontWeight: 400
        },
        nutriValue: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            fontSize: 14,
            color: '#979797',
            fontWeight: 400,
            textAlign: 'center'
        }
    }

    const { nutriCell, nutriLabel, nutriValue } = styles;
    const measureUnit = props.itemLabel === 'cal' ? 'kcal' : 'g';
    let calculatedWidth = 'calc(100% / 5)';

    if (window.innerWidth < 992 || props.calledFrom === 'shoppingCart') {
        calculatedWidth = 'calc(100% / 3)';
    } else if (window.innerWidth < 1080) {
        calculatedWidth = 'calc(100% / 4)';
    }

    return (
        <li ref={ref} hidden={props.hide} style={{ listStyleType: 'none', width: calculatedWidth, minHeight: props.exactHeight ? props.exactHeight : 'auto' }}>
            <div style={nutriCell}>
                <label style={nutriLabel}>{i18n.t(`common:nutritions.${props.itemLabel}`)}</label>
                <div style={nutriValue}>{`${props.itemValue} ${measureUnit}`}</div>
            </div>
        </li>
    );
});

export default NutriListItem;