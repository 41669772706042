import { GET_CURRENT_THEME_SUCCESS, CLEAR_STATE, CLEAR_APP_STATE } from '../../actions/types';

const INITIAL_STATE = {
    themeLight: {
        id: 1,
        type: 'LIGHT',
        colorsCss: [
            {
                key: '--primary-color',
                value: '#62B522'
            },
            {
                key: '--secondary-hover-color',
                value: '#7bb559'
            },
            {
                key: '--secondary-color',
                value: '#62B522'
            },
            {
                key: '--button-outline',
                value: '#62B522'
            },
            {
                key: '--custom-scrollbar',
                value: '#62B522'
            },
            {
                key: '--inactive-color',
                value: '#9a9a9a'
            },
            {
                key: '--menu-items',
                value: '#fff'
            },
            {
                key: '--menu-background',
                value: '#F7F9F8'
            },
            {
                key: '--primary-font',
                value: '#000'
            },
            {
                key: '--placeholder-color',
                value: '#bcc1c7'
            },
            {
                key: '--inputs-font',
                value: '#495057'
            },
            {
                key: '--buttons-font',
                value: '#FFFFFF'
            },
            {
                key: '--badge-font',
                value: '#fff'
            },
            {
                key: '--chef-note',
                value: '#F0F0F0'
            }
        ],
        primaryColor: '#62B522',
        secondaryColor: '#62B522',
        mainTextColor: '#6e7676',
        inactiveColor: '#9a9a9a',
        buttonOutlineColor: '#62B522',
        checkoutScreenNames: '#6e7676',
        fontWeight: 300,
        startOrderFontSize: '1.25em',
        menuScreenFontSize: '1em',
        textDecoration: 'none',
        primaryHoverColor: '#62B522',
        secondaryHoverColor: '#7bb559',
        restInfoBackground: '#fff',
        menuBackground: '#F7F9F8',
        menuItemsBackground: '#fff',
        chefNoteBackground: '#F0F0F0',
        primaryFontColor: '#000',
        inputsPlaceholderColor: '#bcc1c7',
        inputsFontColor: '#495057',
        menuuLogoColor: 'lightgray',
        buttonsFont: '#FFFFFF',
        upsellBackground: '#B8C2C3',
        upsellPrimaryFont: '#212529',
        upsellSecondaryFont: 'rgb(110, 118, 118)',
        tableHeadersBackground: 'lightgray',
        tableHeadersFont: '#000',
        badgeFontColor: '#fff',
        fontFamilyType: 'Roboto',
        fontBold: 'normal',
        fontItalic: 'normal',
        uppercase: 'none'
    },
    themeDark: {
        id: 749352,
        type: 'DARK',
        colorsCss: [
            {
                key: '--primary-color',
                value: '#363636'
            },
            {
                key: '--secondary-hover-color',
                value: '#363636'
            },
            {
                key: '--secondary-color',
                value: '#979797'
            },
            {
                key: '--button-outline',
                value: '#B6B8B7'
            },
            {
                key: '--custom-scrollbar',
                value: '#979797'
            },
            {
                key: '--inactive-color',
                value: '#DCDDDD'
            },
            {
                key: '--menu-items',
                value: '#fff'
            },
            {
                key: '--menu-background',
                value: '#F7F9F8'
            },
            {
                key: '--primary-font',
                value: '#000'
            },
            {
                key: '--placeholder-color',
                value: '#bcc1c7'
            },
            {
                key: '--inputs-font',
                value: '#495057'
            },
            {
                key: '--buttons-font',
                value: '#FFFFFF'
            },
            {
                key: '--badge-font',
                value: '#fff'
            },
            {
                key: '--chef-note',
                value: '#F5F5F5'
            }
        ],
        primaryColor: '#363636',
        secondaryColor: '#979797',
        mainTextColor: '#6e7676',
        inactiveColor: '#DCDDDD',
        buttonOutlineColor: '#B6B8B7',
        checkoutScreenNames: '#DEDEDE',
        checkoutScreenNames: '#6e7676',
        startOrderFontSize: '1.5em',
        menuScreenFontSize: '1.05em',
        textDecoration: 'underline',
        secondaryHoverColor: '#979797',
        restInfoBackground: '#fff',
        menuBackground: '#F7F9F8',
        menuItemsBackground: '#fff',
        chefNoteBackground: '#F5F5F5',
        primaryFontColor: '#000',
        inputsPlaceholderColor: '#bcc1c7',
        inputsFontColor: '#495057',
        menuuLogoColor: 'lightgray',
        buttonsFont: '#FFFFFF',
        upsellBackground: '#B8C2C3',
        upsellPrimaryFont: '#212529',
        upsellSecondaryFont: 'rgb(110, 118, 118)',
        tableHeadersBackground: 'lightgray',
        tableHeadersFont: '#000',
        badgeFontColor: '#fff',
        fontFamilyType: 'Roboto',
        fontBold: 'normal',
        fontItalic: 'normal',
        uppercase: 'none'
    },
    selectedTheme: {
        id: -1,
        type: null
    },
    constantStyles: {
        loadingContainer: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            textAlign: 'center',
            verticalAlign: 'middle',
            background: '#F8F8F8',
            zIndex: 100000000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        loadingContainerTransparent: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            textAlign: 'center',
            verticalAlign: 'middle',
            zIndex: 100000000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CURRENT_THEME_SUCCESS:
            if (action.payload.type === "CUSTOM") {
                return {
                    ...state,
                    selectedTheme: {
                        id: action.payload.id,
                        type: action.payload.type,
                        colorsCss: [
                            {
                                key: '--primary-color',
                                value: action.payload.primaryColor
                            },
                            {
                                key: '--secondary-hover-color',
                                value: action.payload.secondaryHover
                            },
                            {
                                key: '--secondary-color',
                                value: action.payload.secondaryColor
                            },
                            {
                                key: '--button-outline',
                                value: action.payload.buttonOutline
                            },
                            {
                                key: '--custom-scrollbar',
                                value: action.payload.customScrollbar
                            },
                            {
                                key: '--inactive-color',
                                value: action.payload.inactiveColor
                            },
                            {
                                key: '--menu-items',
                                value: action.payload.menuItemsBackground ? action.payload.menuItemsBackground : '#fff'
                            },
                            {
                                key: '--menu-background',
                                value: action.payload.menuBackground ? action.payload.menuBackground : '#F7F9F8'
                            },
                            {
                                key: '--primary-font',
                                value: action.payload.primaryFontColor ? action.payload.primaryFontColor : '#000'
                            },
                            {
                                key: '--placeholder-color',
                                value: action.payload.inputsPlaceholderColor ? action.payload.inputsPlaceholderColor : '#bcc1c7'
                            },
                            {
                                key: '--inputs-font',
                                value: action.payload.inputsFontColor ? action.payload.inputsFontColor : '#495057'
                            },
                            {
                                key: '--buttons-font',
                                value: action.payload.buttonsFont ? action.payload.buttonsFont : '#FFFFFF'
                            },
                            {
                                key: '--badge-font',
                                value: action.payload.badgeFontColor ? action.payload.badgeFontColor : '#fff'
                            },
                            {
                                key: '--chef-note',
                                value: action.payload.chefNoteBackground ? action.payload.chefNoteBackground : '#F0F0F0'
                            }
                        ],
                        primaryColor: action.payload.primaryColor,
                        secondaryColor: action.payload.secondaryColor,
                        mainTextColor: action.payload.mainTextColor ? action.payload.mainTextColor : '#6e7676',
                        inactiveColor: action.payload.inactiveColor,
                        buttonOutlineColor: action.payload.buttonOutline,
                        checkoutScreenNames: '#DEDEDE',
                        checkoutScreenNames: '#6e7676',
                        startOrderFontSize: '1.5em',
                        menuScreenFontSize: '1.05em',
                        secondaryHoverColor: action.payload.secondaryHover,
                        restInfoBackground: action.payload.restInfoBackground ? action.payload.restInfoBackground : '#fff',
                        menuBackground: action.payload.menuBackground ? action.payload.menuBackground : '#F7F9F8',
                        menuItemsBackground: action.payload.menuItemsBackground ? action.payload.menuItemsBackground : '#fff',
                        chefNoteBackground: action.payload.chefNoteBackground ? action.payload.chefNoteBackground : '#F5F5F5',
                        primaryFontColor: action.payload.primaryFontColor ? action.payload.primaryFontColor : '#000',
                        inputsPlaceholderColor: action.payload.inputsPlaceholderColor ? action.payload.inputsPlaceholderColor : '#bcc1c7',
                        inputsFontColor: action.payload.inputsFontColor ? action.payload.inputsFontColor : '#495057',
                        backGroundImageFilePath: action.payload.backGroundImageFilePath,
                        imageBackground: action.payload.imageBackground,
                        menuuLogoColor: action.payload.menuuLogoColor ? action.payload.menuuLogoColor : 'lightgray',
                        buttonsFont: action.payload.buttonsFont ? action.payload.buttonsFont : '#FFFFFF',
                        upsellBackground: action.payload.upsellBackground ? action.payload.upsellBackground : '#B8C2C3',
                        upsellPrimaryFont: action.payload.upsellPrimaryFont ? action.payload.upsellPrimaryFont : '#212529',
                        upsellSecondaryFont: action.payload.upsellSecondaryFont ? action.payload.upsellSecondaryFont : 'rgb(110, 118, 118)',
                        tableHeadersBackground: action.payload.tableHeadersBackground ? action.payload.tableHeadersBackground : 'lightgray',
                        tableHeadersFont: action.payload.tableHeadersFont ? action.payload.tableHeadersFont : '#000',
                        badgeFontColor: action.payload.badgeFontColor ? action.payload.badgeFontColor : '#fff',
                        fontFamilyType: action.payload.fontFamily ? action.payload.fontFamily : 'Roboto', 
                        fontBold: action.payload.fontWeight ? 'bold' : 'normal',
                        fontItalic: action.payload.fontStyle ? 'italic' : 'normal',
                        uppercase: action.payload.fontVariant ? 'uppercase' : 'none'
                    }
                }
            } else {
                return {
                    ...state,
                    selectedTheme: action.payload
                }
            }
        case CLEAR_STATE:
            if (action.fromPwa) {
                return state;
            } else {
                return INITIAL_STATE;
            }
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}