import { SAVE_PREVIEW_MODE_VALUE } from "../types";

export const checkIsPreviewMode = (queryParams, callback) => {
    return (dispatch, getState) => {
        
        const restaurantId = getState().selectedRestaurant.restaurantId;
        const brandId = getState().brand.id;

        const urlData = {
            previewMode: (Object.keys(queryParams).length > 0 && queryParams.previewMode !== undefined) ? queryParams.previewMode : false,
            restaurantId: (Object.keys(queryParams).length > 0 && queryParams.restaurantId !== undefined) ? queryParams.restaurantId : (restaurantId)? restaurantId : null,
            brandId: (Object.keys(queryParams).length > 0 && queryParams.brandId !== undefined) ? queryParams.brandId : (brandId)? brandId : null
        };

        dispatch({ type: SAVE_PREVIEW_MODE_VALUE, payload: urlData });

        callback(queryParams.restaurantId);
    }
};