import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, ListGroup, ListGroupItem } from 'reactstrap';
import { GridLoader } from 'react-spinners';
import i18n from '../../i18next';
import { isMobileOnly } from 'mobile-device-detect';

import { COLLECTION_ID, DELIVERY_ID, CA, US, ARABIC } from '../../utils/Constants';
import {
    transformProductPrice,
    transformProductsComingFromServer,
    calculateDiscount, getPriceWithCurrencySymbol
} from '../../utils/CommonUtils';
import { MAIN_GREEN_COLOR } from '../../utils/StylesConstants';
import ModalStep from '../common/ModalStep';

class OrderDetailsScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.toggleOrderDetails = this.toggleOrderDetails.bind(this);
    }

    componentDidMount() {
        this.applyTheme();
    }

    toggleOrderDetails() {
        this.applyTheme();
        this.props.toggle(this.props.selectedOrder);
    }

    subtotalSum(orderProducts) {
        let shoppingCartItems;
        if (!orderProducts) {
            shoppingCartItems = this.props.shoppingCart.orderProducts;
        } else {
            shoppingCartItems = orderProducts;
        }

        let subtotalSum = 0;
        let temporarySum = 0;

        shoppingCartItems.map(product => {
            if (product.length > 1) {
                product = product[0];
            }

            temporarySum = product.price * product.quantity;
            subtotalSum += temporarySum;
        });

        return subtotalSum;
    }

    renderModalBody() {
        const { mainContainer, orderProductsListGroup } = styles;

        const { primaryFontColor } = this.props.customerThemes.selectedTheme;

        const order = this.props.selectedOrder;
        const orderProducts = order.orderProducts;

        return (
            <div style={{ maxHeight: isMobileOnly ? 300 : 400, overflowY: 'auto', color: primaryFontColor }} className="orderedProductDetailsContainer">
                <Col sm={12} md={12} style={{ ...mainContainer, padding: 0 }}>
                    <ListGroup flush style={orderProductsListGroup}>
                        {this.renderOrderProducts(orderProducts)}
                    </ListGroup>
                </Col>
            </div>
        )
    }

    renderOrderProducts(products) {
        const { productNameCol, productNameColRTL, productQty, productQtyRTL, productQuestions, mealDealName, priceStyle, priceStyleRTL, mealDealProductName, mealDealQuestions } = styles;

        const orderProducts = transformProductsComingFromServer(products);
        const currency = this.props.currency;
        const isArabic = this.props.language === ARABIC;

        return orderProducts.map(product => {
            const productInstruction = (product.length > 1) ? '' : (product.instruction !== undefined && product.instruction !== null) ? product.instruction.notes : '';

            if (product.length > 1) {  //MEAL DEAL
                let mealDeal = product.slice(0, 1);
                let mealDealProducts = product.slice(1);;
                const paddingLeftValue = (mealDeal[0].quantity > 1) ? 28 : 0;
                const mealDealTotalPrice = transformProductPrice(mealDeal[0].price);
                const mealDealTotalPriceWithCurrency = getPriceWithCurrencySymbol(mealDealTotalPrice, currency, isArabic);


                return (
                    <ListGroupItem className="orderHistoryListGroupItem">

                        <Col sm={12} style={isArabic ? productNameColRTL : productNameCol} className='orderProductInfo'>
                            <span style={isArabic ? productQtyRTL : productQty}>{(mealDeal[0].quantity > 1) ? mealDeal[0].quantity + ' x ' : ''}</span>
                            <p style={mealDealName}>{mealDeal[0].name}<span style={isArabic ? priceStyleRTL : priceStyle} >{mealDealTotalPriceWithCurrency}</span></p>
                        </Col>
                        {
                            mealDealProducts.map((mealDealProduct) => {
                                const productPrice = mealDealProduct.totalPrice;
                                const isPriceHidden = (productPrice == 0);
                                const mealDealProductPrice = transformProductPrice(mealDealProduct.price);
                                const mealDealProductPriceWithCurrency = getPriceWithCurrencySymbol(mealDealProductPrice, currency, isArabic);

                                return (
                                    <Col sm={12}
                                        style={isArabic ? { float: 'left', paddingLeft: 0, paddingRight: paddingLeftValue } : { float: 'right', paddingRight: 0, paddingLeft: paddingLeftValue }} className='orderProductInfo'>
                                        <p style={mealDealProductName}>{mealDealProduct.name}
                                            <span style={isArabic ? priceStyleRTL : priceStyle}
                                                hidden={isPriceHidden}>{mealDealProductPriceWithCurrency}</span>
                                        </p>
                                        <p style={mealDealQuestions}>{this.renderProductQuestions(mealDealProduct)}</p>
                                        <p style={mealDealQuestions}>{(mealDealProduct.instruction !== null) ?
                                            <i>"{mealDealProduct.instruction.notes}"</i> : ''}</p>
                                    </Col>
                                )
                            })
                        }

                    </ListGroupItem>
                )

            } else {
                const productPrice = (product.quantity > 1) ? product.price * product.quantity : product.price;
                const isPriceHidden = (productPrice == 0) ? true : false;
                const marginLeftValue = (product.quantity > 1) ? 28 : 0;

                return (
                    <ListGroupItem className="orderHistoryListGroupItem">
                        <Col sm={12} style={isArabic ? productNameColRTL : productNameCol} className='orderProductInfo'>
                            <span style={isArabic ? productQtyRTL : productQty}>{(product.quantity > 1) ? product.quantity + ' x ' : ''}</span>
                            <p style={{ marginBottom: 5 }}>
                                {product.name} {product.sizeName !== null ? product.sizeName : ''}
                                <span style={isArabic ? priceStyleRTL : priceStyle}
                                    hidden={isPriceHidden}>
                                    {this.renderProductPrice(product)}
                                </span>
                            </p>
                            <p style={Object.assign({}, productQuestions, isArabic ? { marginRight: marginLeftValue } : { marginLeft: marginLeftValue })}>
                                {this.renderProductQuestions(product)}
                            </p>
                            <p style={Object.assign({}, productQuestions, isArabic ? { marginRight: marginLeftValue } : { marginLeft: marginLeftValue })}>{(product.instruction !== null) ?
                                <i>"{productInstruction}"</i> : ''}</p>
                        </Col>
                    </ListGroupItem>
                )
            }
        });
    }

    renderProductPrice(product) {
        const { primaryColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;
        const productPrice = (product.quantity > 1) ? product.price * product.quantity : product.price;
        const currency = this.props.currency;
        const transformedProductPrice = transformProductPrice(productPrice);
        const transformedProductPriceWithCurrency = getPriceWithCurrencySymbol(transformedProductPrice, currency, isArabic);

        if (product.promotionFreeProduct) {
            return (
                <span style={{ color: primaryColor }}>{i18n.t('screens:customerOrderDetailsScreen.freeProduct')}</span>
            )
        } else {
            return (
                <span>{transformedProductPriceWithCurrency}</span>
            )
        }
    }

    renderProductQuestions(product) {
        const { orderQuestions } = product;
        let modifiedAnswers = [];

        if (orderQuestions !== undefined && orderQuestions !== null) {
            orderQuestions.map(orderQuestion => {
                const { removedAnswers, orderAnswers, posDefault } = orderQuestion;
                let answersArr = posDefault ? removedAnswers : orderAnswers;

                answersArr.map(orderAnswer => {
                    let answerName = orderAnswer.name, answerQuantity = orderAnswer.quantity, modifiedAnswerName = '', extraOrNo = '';

                    if (posDefault) {
                        extraOrNo = i18n.t('screens:customerOrderDetailsScreen.no');
                    } else {
                        extraOrNo = i18n.t('screens:customerOrderDetailsScreen.extra');
                    }

                    modifiedAnswerName = orderAnswer.quantity > 1 ? `${extraOrNo} ${answerName}(${answerQuantity})` : `${extraOrNo} ${answerName}`;

                    modifiedAnswers.push(modifiedAnswerName);
                });
            });
        }

        return modifiedAnswers.join(', ');
    }

    renderTransparentBackgroundLoaderContainer() {
        const { loadingContainerTransparent, loadingIndicator } = styles;

        if (this.props.loading.transparentLoader) {
            return (
                <div style={loadingContainerTransparent}>
                    <div style={loadingIndicator} className='text-center loadingIndicator'>
                        <GridLoader color={MAIN_GREEN_COLOR} size={20} />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    renderModalHeader() {
        const { screenTitleContainer, screenTitleContainerRTL, screenTitle, orderDateStyle, closeIconStyle, closeButtonStyle } = styles;
        const isArabic = this.props.language === ARABIC;

        const { mainTextColor } = this.props.customerThemes.selectedTheme;

        const order = this.props.selectedOrder;
        const orderDateText = (order.orderType.id == DELIVERY_ID) ? i18n.t('screens:customerOrderDetailsScreen.delivered') : (order.orderType.id == COLLECTION_ID) ? i18n.t('screens:customerOrderDetailsScreen.pickedUp') : '';
        const orderDate = (order.acceptedTime !== null) ? order.acceptedTime.slice(0, 10) : order.wantedTime.slice(0, 10);
        const isMobileView = (window.outerWidth <= 575) ? true : false;

        if (isMobileView) {
            return (
                <React.Fragment>
                    <div id="customCloseBtn" style={isArabic ? { marginTop: 5, position: 'absolute', top: 0, left: 5 } : { marginTop: 5, position: 'absolute', top: 0, right: 5 }} onClick={this.toggleOrderDetails} />

                    { isArabic ?
                        <Col xs={12} sm={12} md={12} style={screenTitleContainerRTL}>
                            <p style={{ textAlign: 'right', color: mainTextColor, fontSize: 16, marginBottom: 0 }}>{orderDate + ' ' + orderDateText}</p>
                            <p style={screenTitle}>{i18n.t('screens:customerOrderDetailsScreen.screenTitle')}</p>
                        </Col> :
                        <Col xs={12} sm={12} md={12} style={screenTitleContainer}>
                            <p style={screenTitle}>{i18n.t('screens:customerOrderDetailsScreen.screenTitle')}</p>
                            <p style={{ textAlign: 'left', color: mainTextColor, fontSize: 16, marginBottom: 0 }}>{orderDateText + ' ' + orderDate}</p>
                        </Col>
                    }
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div id="customCloseBtn" style={isArabic ? { marginTop: 5, position: 'absolute', top: 0, left: 5 } : { marginTop: 5, position: 'absolute', top: 0, right: 5 }} onClick={this.toggleOrderDetails} />

                    {isArabic ?
                        <Col xs={12} sm={12} md={12} style={screenTitleContainerRTL}>
                            <p style={Object.assign({}, screenTitle, {display: 'flex'})}>
                                <span style={{marginLeft: '5px'}}>{i18n.t('screens:customerOrderDetailsScreen.screenTitle')}</span>
                                <span style={Object.assign({}, orderDateStyle, {marginLeft: '5px'})}>{' - ' + orderDateText + ' '}</span>
                                <span style={orderDateStyle}>{orderDate}</span>
                            </p>
                        </Col> :
                         <Col xs={12} sm={12} md={12} style={screenTitleContainer}>
                            <p style={screenTitle}>
                                {i18n.t('screens:customerOrderDetailsScreen.screenTitle')}
                                <span style={orderDateStyle}>{` - ${orderDateText}  ${orderDate}`}</span>
                            </p>
                     </Col>
                    }
                </React.Fragment>
            );
        }

    }

    renderModalFooter() {
        const { totalPrice, totalPriceRTL, orderDetails, orderDetailsRTL } = styles;
        const isArabic = this.props.language === ARABIC;
        const currency = this.props.currency;
        const order = this.props.selectedOrder;
        const orderedProducts = this.props.selectedOrder.orderProducts;
        const subtotalSum = this.subtotalSum(orderedProducts);
        const subtotalPrice = transformProductPrice(subtotalSum);
        const subtotalPriceWithCurrency = getPriceWithCurrencySymbol(subtotalPrice, currency, isArabic);
        const isDeliveryFeeHidden = (order.orderType.id == DELIVERY_ID) ? false : true;
        const deliveryFee = order.deliveryCharge;
        const deliveryFeePrice = transformProductPrice(deliveryFee);
        const deliveryFeePriceWithCurrency = getPriceWithCurrencySymbol(deliveryFeePrice, currency, isArabic);
        const totalTips = Number(order.tip);
        const totalTipsPrice = transformProductPrice(totalTips);
        const totalTipsPriceWithCurrency = getPriceWithCurrencySymbol(totalTipsPrice, currency, isArabic);
        const isTipsHidden = (totalTips == 0) ? true : false;
        const discount = Math.abs(calculateDiscount(order));
        const discountPrice = transformProductPrice(discount);
        const discountPriceWithCurrency = getPriceWithCurrencySymbol(discountPrice, currency, isArabic);
        const isDiscountHidden = (discount == 0) ? true : false;
        const vat = order.totalTax;
        const vatPrice = transformProductPrice(vat);
        const vatPriceWithCurrency = getPriceWithCurrencySymbol(vatPrice, currency, isArabic);
        const isVatHidden = (vat == 0) ? true : false;
        const total = order.total;
        const totPrice = transformProductPrice(total);
        const totalPriceWithCurrency = getPriceWithCurrencySymbol(totPrice, currency, isArabic);
        const deposits = getPriceWithCurrencySymbol(transformProductPrice(order.orderDeposit), currency, isArabic);

        return (
            <Fragment>
                { isArabic ?
                <div style={{ paddingLeft: 12 }}>
                    <p style={orderDetailsRTL}>{subtotalPriceWithCurrency} :{i18n.t('screens:shoppingCart.subtotal')}</p>
                    <p style={orderDetailsRTL} hidden={isDeliveryFeeHidden}>{deliveryFeePriceWithCurrency} :{i18n.t('screens:shoppingCart.deliveryFee')}</p>
                    <p style={orderDetailsRTL} hidden={isTipsHidden}>{totalTipsPriceWithCurrency} :{i18n.t('screens:shoppingCart.tips')}</p>
                    <p style={orderDetailsRTL} hidden={isDiscountHidden} >{discountPriceWithCurrency} - :{i18n.t('screens:shoppingCart.discount')}</p>
                    {
                        this.props.selectedRestaurant.address.country === CA || this.props.selectedRestaurant.address.country === US ? this.renderTaxes(order.orderTaxes, orderDetails, currency, isVatHidden)
                            : <p style={orderDetailsRTL} hidden={isVatHidden} >{vatPriceWithCurrency}: {i18n.t('screens:shoppingCart.vat')}</p>
                    }
                    {
                        order.orderDeposit ?
                            <p style={orderDetailsRTL}>{deposits} :{i18n.t('screens:shoppingCart.deposit')}: </p> : null
                    }
                        <p style={totalPriceRTL}>{totalPriceWithCurrency} :{i18n.t('screens:shoppingCart.total')}</p>
                </div> :
                <div style={{ paddingRight: 12 }}>
                <p style={orderDetails}>{i18n.t('screens:shoppingCart.subtotal')}: {subtotalPriceWithCurrency}</p>
                <p style={orderDetails} hidden={isDeliveryFeeHidden}>{i18n.t('screens:shoppingCart.deliveryFee')}: {deliveryFeePriceWithCurrency}</p>
                <p style={orderDetails} hidden={isTipsHidden}>{i18n.t('screens:shoppingCart.tips')}: {totalTipsPriceWithCurrency}</p>
                <p style={orderDetails} hidden={isDiscountHidden} >{i18n.t('screens:shoppingCart.discount')}: - {discountPriceWithCurrency}</p>
                {
                    this.props.selectedRestaurant.address.country === CA || this.props.selectedRestaurant.address.country === US ? this.renderTaxes(order.orderTaxes, orderDetails, currency, isVatHidden)
                    : <p style={orderDetails} hidden={isVatHidden} >{i18n.t('screens:shoppingCart.vat')}: {vatPriceWithCurrency}</p>
                }
                {
                    order.orderDeposit ? 
                        <p style={orderDetails}>{i18n.t('screens:shoppingCart.deposit')}: {deposits}</p> : null
                }
                    <p style={totalPrice}>{i18n.t('screens:shoppingCart.total')}: {totalPriceWithCurrency}</p>
                </div>
                }
            </Fragment>
        );
    }

    renderTaxes(taxes, styles, currency, isVatHidden) {
        const isArabic = this.props.language === ARABIC;
        return taxes.map(tax => {
            return <p style={styles} hidden={isVatHidden}>{tax.taxName ? `${tax.taxName} ${tax.taxPercent}%` : `Tax ${tax.taxPercent}%`}: {getPriceWithCurrencySymbol(tax.totalTax.toFixed(2), currency, isArabic)}</p>
        });
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { primaryColor, fontWeight, mainTextColor, primaryFontColor, menuItemsBackground } = this.props.customerThemes.selectedTheme;
        customizedStyles.screenTitle.color = primaryColor;
        customizedStyles.screenTitle.fontWeight = fontWeight;
        customizedStyles.orderDateStyle.fontWeight = 'normal';
        customizedStyles.orderDateStyle.color = mainTextColor;
        customizedStyles.orderDetails.color = mainTextColor;
        customizedStyles.orderDetailsRTL.color = mainTextColor;
        customizedStyles.totalPrice.color = primaryFontColor;
        customizedStyles.totalPriceRTL.color = primaryFontColor;
        customizedStyles.mainContainer.backgroundColor = menuItemsBackground;
        customizedStyles.productQuestions.color = mainTextColor;
        customizedStyles.mealDealQuestions.color = mainTextColor;
        styles = customizedStyles;

        return styles;
    }

    render() {
        return (
            <div className="orderDetailsModal">
                <ModalStep
                    onToggle={this.toggleOrderDetails}
                    renderHeader={this.renderModalHeader()}
                    renderBody={this.renderModalBody()}
                    renderFooter={this.renderModalFooter()}
                    styles={styles}
                    customerThemes={this.props.customerThemes}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps)(OrderDetailsScreen);

let styles = {
    mainContainer: {
        backgroundColor: '#fff',
        margin: 'auto'
    },
    closeButtonStyle: {
        border: '1px solid #656565',
        borderRadius: '50%',
        height: 25,
        width: 25,
        position: 'absolute',
        top: 33,
        right: 33
    },
    closeIconStyle: {
        position: 'absolute',
        top: 1,
        left: 8
    },
    screenTitleContainer: {
        textAlign: 'left',
        color: '#62b400'
    },
    screenTitleContainerRTL: {
        textAlign: 'right',
        color: '#62b400'
    },
    screenTitle: {
        marginBottom: 2,
        fontSize: 18
    },
    orderDateStyle: {
        textAlign: 'center',
        color: 'grey',
    },
    orderProductsListGroup: {
        marginBottom: 10, 
        border: 'none' 
    },
    priceContainer: { 
        flexDirection: 'column', 
        float: 'right' 
    },
    totalPrice: { 
        textAlign: 'right', 
        float: 'right', 
        fontSize: 16 
    },
    totalPriceRTL: { 
        textAlign: 'left', 
        float: 'left', 
        fontSize: 16 
    },
    orderDetails: { 
        textAlign: 'right', 
        fontSize: 14, 
        marginBottom: 2, 
        color: '#B4B4B4' 
    },
    orderDetailsRTL: { 
        textAlign: 'left', 
        fontSize: 14, 
        marginBottom: 2, 
        color: '#B4B4B4' 
    },
    totalPriceInfoText: { 
        color: 'grey', 
        fontSize: 12 
    },
    productThumbnailCol: { 
        float: 'left', 
        textAlign: 'left', 
        paddingRight: 0, 
        paddingLeft: 0, 
        marginLeft: 0, 
        height: '100%' 
    },
    productThumbnailImg: { 
        width: '125px', 
        height: '100%', 
        objectFit: 'cover' 
    },
    productNameCol: { 
        float: 'right', 
        paddingRight: 0, 
        paddingLeft: 0 
    },
    productNameColRTL: { 
        float: 'left', 
        paddingRight: 0, 
        paddingLeft: 0 
    },
    productQty: { 
        float: 'left', 
        marginRight: 6 
    },
    productQtyRTL: { 
        float: 'right', 
        marginLeft: 6 
    },
    productQuestions: { 
        color: 'grey', 
        fontSize: 12, 
        marginBottom: 2 
    },
    mealDealName: { 
        marginBottom: 10, 
        fontSize: 18 
    },
    mealDealProductName: { 
        marginBottom: 6, 
        fontSize: 14
    },
    mealDealQuestions: { 
        marginBottom: 10, 
        marginTop: -5, 
        color: 'grey', 
        fontSize: 12 
    },
    priceStyle: { 
        float: 'right', 
        fontSize: 14, 
        marginRight: -12 
    },
    priceStyleRTL: { 
        float: 'left', 
        fontSize: 14, 
        marginRight: -12 
    }
}