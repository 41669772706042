import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import i18n from '../../../../i18next';

const ComoPointShop = props => {

    const {
        type,
        primaryColor,
        secondaryColor,
        fontFamilyType,
        fontBold,
        fontItalic,
        uppercase,
        checkoutScreenNames,
        fontWeight
    } = props.customerThemes.selectedTheme;

    const convertBalanceToMonetaryValue = balance => {

        return balance / 100;
    };

    const pointsBalance = props.statePointsBalance && props.statePointsBalance.balance.monetary ? convertBalanceToMonetaryValue(props.statePointsBalance.balance.monetary) : '';

    return (
        <Fragment>
            <Col style={props.isArabic ? {
                color: type === "LIGHT" ? checkoutScreenNames : secondaryColor,
                fontSize: 18,
                fontFamily: fontFamilyType,
                fontWeight: fontBold === 'bold' ? fontBold : 300,
                fontStyle: fontItalic,
                textTransform: uppercase,
                marginBottom: 4,
                paddingRight: 30
            } :
                {
                    color: type === "LIGHT" ? checkoutScreenNames : secondaryColor,
                    fontSize: 18,
                    fontFamily: fontFamilyType,
                    fontWeight: fontBold === 'bold' ? fontBold : 300,
                    fontStyle: fontItalic,
                    textTransform: uppercase,
                    marginBottom: 4,
                    paddingLeft: 30
                }}>
                {i18n.t('screens:como.comoPointShop')}
            </Col>
            <Col sm={12} md={12} lg={6} style={props.isArabic ? { paddingRight: 0 } : { paddingLeft: 0 }}>
                <div
                    hidden={!pointsBalance}
                    style={
                        {
                            display: 'flex',
                            justifyContent: 'flex-start',
                            color: primaryColor,
                            fontWeight: fontWeight
                        }
                    }>
                    <p>{i18n.t('screens:como.pointsBalance')}:</p>
                    <p>{pointsBalance}</p>
                </div>
            </Col>
            <Row style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                padding: props.isArabic ? '0 10px 0 0' : '0 0 0 10px'
            }}>
                {props.children}
            </Row>
        </Fragment>
    );
};

export default ComoPointShop;