import React, { Component, Fragment } from 'react';
import i18n from '../../../../i18next';

import NutriListItem from './NutriListItem';
import { calcQuesLvlNutris } from '../../../../utils/ProductWizardUtils';
import NutritionsCollapse from './NutritionsCollapse';

class NutriList extends Component {
    constructor() {
        super()

        this.nutriRefs = [];
        /** These hadcoded values make it possible to use a strict rendering order */
        this.nutritions = ['cal', 'total_fat', 'fat_saturated', 'protein', 'carbs', 'sugars', 'fibre', 'salt', 'sodium'];
        this.nutritions.forEach(nutri => {
            let ref = React.createRef();
            this.nutriRefs.push(ref)
        });
        this.state = {
            highestEl: 0,
            prevWidth: 0,
            prevHeight: 0
        }
    }

    styles = {
        ulStyles: {
            paddingLeft: 0,
            marginBottom: 0,
            display: 'flex',
            flexFlow: 'row wrap'
        },
        cartPadding: {
            paddingLeft: 15,
            paddingRight: 15
        }
    }

    componentDidMount() {
        this.calcNutritionBoxHeight();
    }

    componentDidUpdate() {
        if (window.innerWidth !== this.state.prevWidth || window.innerHeight !== this.state.prevHeight) {
            this.calcNutritionBoxHeight();
        }
    }

    calcNutritionBoxHeight() {
        /** Triggers second render so that refs values get updated
         * and gets the highest height of the ref elements to
         * later set it as height to all other list items
         */
        let highestEl = 0;
        this.nutriRefs.map(nutriRef => {
            if (nutriRef.current.clientHeight > highestEl) {
                highestEl = nutriRef.current.clientHeight;
            }

            return highestEl;
        });

        this.setState({
            ...this.state,
            highestEl: Math.ceil(highestEl),
            prevHeight: window.innerHeight,
            prevWidth: window.innerWidth
        });
    }

    /**
     * take all selected answers in considration
     * and sum up the different nutri values
     * exclude ingredients (posDefault's) unless removed
     */
    calcNutritions(nutri, product) {
        // const { product } = this.props;
        let totalValue = 0;
        /**Add product answers nutrition values */
        if (product.mealDeal) {
            product.questions.map(mdQuestion => {
                if (mdQuestion.selectedProduct) {
                    totalValue = calcQuesLvlNutris(mdQuestion.selectedProduct.subQuestions, nutri, totalValue);
                    if (mdQuestion.selectedProduct.nutrients) {
                        mdQuestion.selectedProduct.nutrients.map(nutrient => {
                            if (nutrient.nutrition.nutritionCode === nutri) {
                                totalValue += nutrient.value;
                            }

                            return totalValue;
                        });
                    }
                }

                return totalValue;
            });

        } else {
            totalValue = calcQuesLvlNutris(product.questions, nutri, totalValue);
            /**Add product nutrition values */
            if (product.nutrients) {
                product.nutrients.map(nutrient => {
                    // if (nutri.nutrition.id === nutrient.nutrition.id) {
                    if (nutri === nutrient.nutrition.nutritionCode) {
                        return totalValue += nutrient.value;
                    }

                    return totalValue;
                });
            }
        }
        /**Common trick for filling up the memmory so that calculations don't go like 0.01999999998 */
        totalValue = Number(Math.round(totalValue + 'e2') + 'e-2');

        if (totalValue < 0) {
            totalValue = 0;
        }

        if (product.quantity) {
            totalValue *= product.quantity;
        }
        
        return totalValue;
    }

    calcNutritionsTotal(nutri) {
        let totalValue = 0;
        this.props.shoppingCart.orderProducts.map(product => {
            return totalValue += this.calcNutritions(nutri, product);
        });

        /**Common trick for filling up the memmory so that calculations don't go like 0.01999999998 */
        totalValue = Number(Math.round(totalValue + 'e2') + 'e-2');

        return totalValue;
    }

    renderListItems() {

        return this.nutritions.map((nutri, i) => {
            const itemValue = this.props.calledFrom === 'productWizard' ? this.calcNutritions(nutri, this.props.product) : this.calcNutritionsTotal(nutri);

            return (
                <NutriListItem
                    ref={this.nutriRefs[i]}
                    key={`nutrition_${i}`}
                    hide={itemValue <= 0}
                    exactHeight={this.state.highestEl}
                    itemLabel={nutri}
                    itemValue={itemValue}
                    theme={this.props.calledFrom === 'productWizard' ? this.props.selectedTheme : this.props.customerThemes.selectedTheme}
                    calledFrom={this.props.calledFrom}
                />
            );
        });
    }

    renderForWizard() {
        return (
            <Fragment>
                <ul style={this.styles.ulStyles}>
                    {
                        this.renderListItems()
                    }
                </ul>
            </Fragment>
        );
    }

    renderForCart() {
        return (
            <NutritionsCollapse {...this.props}>
                <ul style={{ ...this.styles.ulStyles, ...this.styles.cartPadding }}>
                    {
                        this.renderListItems()
                    }
                </ul>
                <p style={{ ... { marginBottom: 0, fontSize: 10, color: this.props.customerThemes.selectedTheme.mainTextColor }, ...this.styles.cartPadding }}>{i18n.t('common:nutritions.nutriMsg')}</p>
            </NutritionsCollapse>
        );
    }

    render() {

        return (
            <Fragment>
                {
                    this.props.calledFrom === 'productWizard' ? this.renderForWizard() : this.renderForCart()
                }
            </Fragment>
        );
    }
}

export default NutriList;