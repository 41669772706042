import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Button, Col, Label, Alert, InputGroup, InputGroupAddon, InputGroupText, Input, } from "reactstrap";
import { AvField, AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import i18n from "../../i18next";

import { convertStringToBoolean } from "../../utils/CommonUtils";
import { updateCustomer } from "../../actions/index";
import DeactivateConfirmationModal from "../common/DeactivateConfirmationModal";
import { clearMessageAlert, showMessageAlert, resetMessageAlert } from "../../actions";
import { ARABIC } from "../../utils/Constants";

class DetailsScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null,
            name: '',
            phone: '',
            formattedPhone: '',
            countryCode: '',
            email: '',
            active: true,
            isDeactivateConfirmationModalOpen: false,
            stateAlreadyCopiedFromProps: false,
            isSpecialSymbols: false
        };

        this.changeState = this.changeState.bind(this);
        this.toggleConfirmDeactivateScreen = this.toggleConfirmDeactivateScreen.bind(this);
    }

    toggleConfirmDeactivateScreen(isActive) {
        this.setState({
            active: isActive,
            isDeactivateConfirmationModalOpen: !this.state.isDeactivateConfirmationModalOpen
        });
    }

    componentWillMount() {
        this.props.resetMessageAlert();
    }

    changeState({ target }) {
        
        if (target.name === 'name' && target.value !== null && target.value !== "" && target.value !== undefined){
            let regex = /[!a-zA-ZÀ-úА-яЁё\u0620-\u064Aa0-9_.-\s]+/g;
            let nameValue = target.value;
            let isNameInValidFormat = nameValue.match(regex);
            let isSpecialSymbols = nameValue.split(regex).join("");
            const verifiedNameInput = isNameInValidFormat && isNameInValidFormat !== null ? isNameInValidFormat.join("") : '';

            if(isSpecialSymbols) {
                this.setState({
                    name: verifiedNameInput.trimStart(),
                    isSpecialSymbols: true
                })
            } else {
                this.setState({
                    name: verifiedNameInput.trimStart(),
                    isSpecialSymbols: false
                });
            }
        } else if(target.name === 'active') {
            this.setState({
                active: !this.state.active
            });
        } else {
            this.setState({
                [target.name]: target.value
            });
        }
    }

    copyStateFromProps() {

        if (this.props.customer.id !== undefined && this.props.customer.id !== null &&
            (!this.state.stateAlreadyCopiedFromProps || this.state.id !== this.props.customer.id)) {
            this.setState({
                id: this.props.customer.id,
                name: this.props.customer.name,
                phone: this.props.customer.phone,
                email: this.props.customer.email,
                stateAlreadyCopiedFromProps: true
            });
        }
    } 

    saveProfile() {

        const customerId = this.state.id;
        const customer = {
            name: this.state.name,
            phone: this.state.phone,
            email: this.state.email,
            active: convertStringToBoolean(this.state.active)
        };

        this.props.updateCustomer(customerId, customer);
    }

    renderSuccessAlert() {
        const successAlert = this.props.alertMessage.showSuccessAlert;

        if (successAlert) {
            setTimeout(() => this.props.clearMessageAlert('success'), 1500);
        }

        const dismiss = this.props.clearMessageAlert.bind(this, 'success');

        if (successAlert) {
            return (
                <div className='alertContainer'>
                    <Alert className='success-alert' color='success' toggle={dismiss} style={{ borderRadius: 0 }}>
                        {i18n.t('screens:profileScreen.customerDetailsUpdSuccess')}
                    </Alert>
                </div>
            );
        } else {
            return null;
        }
    }

    renderErrorAlert() {
        const errorAlert = this.props.alertMessage.showErrorAlert;

        const dismiss = this.props.clearMessageAlert.bind(this, 'error');

        if (errorAlert) {
            return (
                <div className='alertContainer'>
                    <Alert className='error-alert' color='danger' toggle={dismiss} style={{ borderRadius: 0 }}>
                        {i18n.t('screens:profileScreen.customerDetailsUpdFailure')}
                    </Alert>
                </div>
            );
        } else {
            return null;
        }
    }

    renderDeactivateAccount() {
        const { checkboxLabelStyle, checkboxInput } = this.props.styles;
        const isArabic = this.props.language === ARABIC;

        return (
            <InputGroup style={{padding: '10px 0', width: '140px'}}>
                <Label check style={checkboxLabelStyle} className='profileCheckbox-label'>
                    {i18n.t('screens:profileDetailsScreen.deleteAccount')}
                    <Input type="checkbox" id="active" name="active"
                        checked={!this.state.active}
                        onChange={this.changeState}
                        style={checkboxInput} />
                    <span className='profileCheckbox-custom' style={isArabic ? { left: '0' } : { right: '0' }}></span>
                </Label>
            </InputGroup>
        );
    }

    render() {
        let { mainContainer, saveButtonStyle, formStyle, formFieldsStyle, formLabelStyle } = this.props.styles;
        const { chefNoteBackground, buttonsFont, mainTextColor, primaryColor, inactiveColor } = this.props.customerThemes.selectedTheme;
        const { isRedirectFromComo } = this.props.como;
        const isMobileView = window.innerWidth <= 768;
        let regex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
        let isEmailInValidFormat = this.state.email ? this.state.email.match(regex) : null;
        const isDisabled = (this.state.name !== '' && this.state.name !== null) && (this.state.email !== '' && this.state.email !== null) && (this.state.phone !== '' && this.state.phone !== null) && isEmailInValidFormat ? false : true;
        let submitBtnClassName, activeInactiveBtn;

        if (!isDisabled) {
            activeInactiveBtn = { backgroundColor: primaryColor, color: buttonsFont };
        } else {
            activeInactiveBtn = { backgroundColor: inactiveColor, color: buttonsFont };
        }

        formFieldsStyle = { ...formFieldsStyle, backgroundColor: chefNoteBackground, marginBottom: '5px' };
        saveButtonStyle = { ...saveButtonStyle, color: buttonsFont };
        formLabelStyle = { ...formLabelStyle, color: mainTextColor, paddingLeft: '0'};

        this.copyStateFromProps();

        return (
            <Fragment>
                <Col sm={5} md={5} style={isMobileView ? { ...mainContainer, margin: '0 5px 20px' } : mainContainer} className="sideContent">
                    <div className='text-center alert-container'>
                        {this.renderSuccessAlert()}
                        {this.renderErrorAlert()}
                    </div>
                    <div style={formStyle}>
                        <InputGroup>
                            <Label xs={12} for="name" style={formLabelStyle}>{i18n.t('screens:profileDetailsScreen.customerName')}</Label>
                            <Input id="name" type="text" name="name"
                                state={this.state.name}
                                value={this.state.name}
                                onChange={this.changeState}
                                style={formFieldsStyle}
                                className='addressInputs' />
                                {
                                    this.state.isSpecialSymbols ? 
                                        <div style={{width: '100%', color: 'red'}}>{i18n.t('common:alerts.allowedCharacters')}</div> : null
                                }
                        </InputGroup>
                        <InputGroup>
                            <Label xs={12} for="email" style={formLabelStyle}>{i18n.t('screens:profileDetailsScreen.customerEmail')}</Label>
                            <Input type="email" name="email" id="email"
                                value={this.state.email}
                                onChange={this.changeState}
                                errorMessage={i18n.t('common:validations.errorMsg')}
                                style={formFieldsStyle}
                                className='addressInputs' />
                        </InputGroup>
                        <InputGroup className="inputNumNoArrows">
                            <Label xs={12} for="phone" style={formLabelStyle}>{i18n.t('screens:profileDetailsScreen.customerPhone')}</Label>
                            <Input name="phone" id="phone"
                                value={this.state.phone}
                                onChange={this.changeState}
                                errorMessage={i18n.t('common:validations.errorMsg')}
                                style={formFieldsStyle}
                                className='addressInputs' />
                        </InputGroup>
                        { !isRedirectFromComo ?
                            this.renderDeactivateAccount() : null
                        }
                        <Button 
                            className={submitBtnClassName} 
                            style={Object.assign({}, saveButtonStyle, activeInactiveBtn)}
                            onClick={() => (this.state.active === 'true' || this.state.active === true) ? this.saveProfile(true) : this.toggleConfirmDeactivateScreen(false)}
                            disabled={isDisabled}
                            >
                            {i18n.t('common:buttons.saveBtn')}
                        </Button>
                    </div>
                </Col>
                {this.state.isDeactivateConfirmationModalOpen && <DeactivateConfirmationModal
                    isOpen={this.state.isDeactivateConfirmationModalOpen}
                    onToggle={this.toggleConfirmDeactivateScreen}
                    onConfirm={(callback) => this.saveProfile(callback)}
                    user={this.state}
                    customerThemes={this.props.customerThemes}

                />}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        alertMessage: state.alertMessage,
        customerThemes: state.customerThemes,
        como: state.como,
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps, { updateCustomer, showMessageAlert, clearMessageAlert, resetMessageAlert })(DetailsScreen);

let styles = {
    deactivationInfoText: { fontSize: 12, color: 'grey', padding: 5, margin: 0 }
};