import {
    GET_MEMBERSHIP_DETAILS,
    RESET_COMO_ERROR_RESPONSE,
    RESET_COMO_ERRORS_ONLY,
    SET_TRANSACTIONID_AND_OPEN_TIME,
    GET_BENEFITS_RESPONSE,
    SAVE_PAYMENT_RESPONSE,
    UPDATE_MEMBER_POINT_BALANCE,
    CANCEL_PAYMENT_REIMBURSE_POINTS,
    TRIGGER_USER_REGISTRATION,
    VOID_PURCHASE,
    GET_MEMBERSHIP_DETAILS_IN_PROGRESS,
    CLEAR_APP_STATE,
    CLEAR_STATE,
    IS_REDIRECT_FROM_COMO,
    IS_RESTARTED_ORDER_FROM_COMO_USER,
    COMO_TEMPORARY_TOKEN
} from '../../../actions/types';

const INITIAL_STATE = {
    status: null,
    membership: null,
    memberNotes: null,
    errors: null,
    userRegistrationTriggered: false,
    getMembershipDetailsInProgress: false,
    userSavedData: {
        transactionId: null,
        openTime: null,
        getBenefitsResponse: null,
        paymentResponse: null
    },
    isRedirectFromComo: false,
    isRestartedOrderFromComoUser: false,
    temporaryToken: null
};

export default (state = INITIAL_STATE, action) => {

    const { type, payload } = action;

    switch (type) {
        case GET_MEMBERSHIP_DETAILS:
            return {
                ...state,
                status: payload.status,
                membership: payload.membership,
                memberNotes: payload.memberNotes,
                errors: payload.errors
            };
        case SET_TRANSACTIONID_AND_OPEN_TIME:
            return {
                ...state,
                userSavedData: {
                    ...state.userSavedData,
                    transactionId: payload.transactionId,
                    openTime: payload.openTime
                }
            };
        case GET_BENEFITS_RESPONSE:
            return {
                ...state,
                userSavedData: {
                    ...state.userSavedData,
                    getBenefitsResponse: payload
                }
            };
        case SAVE_PAYMENT_RESPONSE:
            return {
                ...state,
                userSavedData: {
                    ...state.userSavedData,
                    paymentResponse: payload
                }
            };
        case UPDATE_MEMBER_POINT_BALANCE:
            return {
                ...state,
                membership: {
                    ...state.membership,
                    creditBalance: {
                        ...state.membership.creditBalance,
                        balance: payload
                    }
                }
            };
        case CANCEL_PAYMENT_REIMBURSE_POINTS:
            return {
                ...state,
                membership: {
                    ...state.membership,
                    creditBalance: {
                        ...state.membership.creditBalance,
                        balance: payload.balance
                    }
                },
                userSavedData: {
                    ...state.userSavedData,
                    paymentResponse: null
                }
            };
        case TRIGGER_USER_REGISTRATION:
            return {
                ...state,
                userRegistrationTriggered: !state.userRegistrationTriggered
            };
        case VOID_PURCHASE:
            return {
                ...state,
                userSavedData: {
                    ...state.userSavedData,
                    getBenefitsResponse: null,
                    openTime: null,
                    transactionId: null
                }
            };
        case GET_MEMBERSHIP_DETAILS_IN_PROGRESS:
            return {
                ...state,
                getMembershipDetailsInProgress: payload
            };
        case RESET_COMO_ERRORS_ONLY:
            return {
                ...state,
                errors: [],
                status: null
            };
        case RESET_COMO_ERROR_RESPONSE:
        case CLEAR_STATE:
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        case IS_REDIRECT_FROM_COMO:
            return {
                ...state,
                isRedirectFromComo: payload
            }
        case IS_RESTARTED_ORDER_FROM_COMO_USER:
            return {
                ...state,
                isRestartedOrderFromComoUser: payload
                }
        case COMO_TEMPORARY_TOKEN:
            return {
                ...state,
                temporaryToken: payload
                }
        default:
            return state;
    }
};