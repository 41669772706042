import React from 'react';
import i18n from '../../../i18next';
import { getPriceWithCurrencySymbol, transformProductPrice } from '../../../utils/CommonUtils';

const MinMaxOrderAmount = ({ selectedRestaurant, style, isCheckout, shoppingCart, isArabic }) => {

    const currency = selectedRestaurant.currency;
    const minimumOrderAmountPrice = transformProductPrice(selectedRestaurant.minimumOrderAmount);
    const minOrderAmountPriceWithCurrency = getPriceWithCurrencySymbol(minimumOrderAmountPrice, currency);
    const minimumOrderAmountWithPromotion = getPriceWithCurrencySymbol(transformProductPrice(1), currency, isArabic);
    const maximumOrderAmountPrice = transformProductPrice(selectedRestaurant.maximumOrderAmount);
    const maxOrderAmountPriceWithCurrency = getPriceWithCurrencySymbol(maximumOrderAmountPrice, currency, isArabic);
    const minOrderAmountHidden = selectedRestaurant.minimumOrderAmount === undefined || selectedRestaurant.minimumOrderAmount === null || selectedRestaurant.minimumOrderAmount === 0;
    const maxOrderAmountHidden = selectedRestaurant.maximumOrderAmount === undefined || selectedRestaurant.maximumOrderAmount === null || selectedRestaurant.mmaximumOrderAmount === 0;
    const discount = shoppingCart.discount;
    const total = shoppingCart.total;

    const isDiscountMoreThanTotal = discount > 0 && total === 0;
    const hideOrderAmountMsg = isDiscountMoreThanTotal ? true : (minOrderAmountHidden && maxOrderAmountHidden);

    const minOrMaxOrderAmount = !maxOrderAmountHidden && minOrderAmountHidden ? i18n.t('common:wizard.maxQuantity') : (maxOrderAmountHidden && !minOrderAmountHidden ? i18n.t('common:wizard.minQuantity') : '');
    const displayMinAmount = minOrderAmountHidden ? '' : `${minOrderAmountPriceWithCurrency}`;
    const displayMaxAmount = maxOrderAmountHidden ? '' : `${maxOrderAmountPriceWithCurrency}`;
    const dashInBetweenAmounts = minOrderAmountHidden || maxOrderAmountHidden ? '' : ' - ';

    const mobileBtnStyle = isCheckout ? {} : { fontSize: 12, margin: 0 };

    return (
        <p hidden={hideOrderAmountMsg} style={Object.assign({}, style, mobileBtnStyle)}>
            {`*${minOrMaxOrderAmount} ${i18n.t('screens:shoppingCart.minimumOrderAmount')} ${shoppingCart.appliedPromotions.length ? minimumOrderAmountWithPromotion : displayMinAmount}${dashInBetweenAmounts}${displayMaxAmount}`}
        </p>
    );
}

export default MinMaxOrderAmount;