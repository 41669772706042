import axios from '../../utils/AxiosUtils';

import { 
    APPLY_GIFTCARD_SUCCESS, 
    CLEAR_GIFT_CARDS_STATE, 
    LOADING_NO_BACKGROUND, 
    CLEAR_GIFT_CARDS_TOTAL_DISCOUNT,
    SHOW_ERROR_GIFT_CARDS_MESSAGE
 } from '../types';

export const applyGiftCard = (appliedGiftCards, currentGiftCard, callback) => {
    return (dispatch, getState) => {
        
        dispatch({ type: LOADING_NO_BACKGROUND, payload: true });

        const restaurantId = getState().selectedRestaurant.restaurantId;
        const total = getState().shoppingCart.total;
        const url = `/gift-card-provider/${restaurantId}/apply`;
       
        let updatedGiftCard = {};

        if(currentGiftCard) {
            updatedGiftCard = {
                total: total,
                giftCards: [...appliedGiftCards, currentGiftCard]
            }
        } else {
            updatedGiftCard = {
                total: total,
                giftCards: appliedGiftCards
            }
        }

        axios(getState).post(url, updatedGiftCard)
            .then(response => applyGiftCardSuccess(response, dispatch, callback))
            .catch(err => applyGiftCardError(err.response.data.userMessage, dispatch));
    };
};

const applyGiftCardSuccess = (response, dispatch, callback) => {
    dispatch( {type: APPLY_GIFTCARD_SUCCESS, payload: response.data} );
    dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
    dispatch({ type: SHOW_ERROR_GIFT_CARDS_MESSAGE, payload: false });

    if (callback) {
        callback();
    }
};

const applyGiftCardError = (errorMessage, dispatch) => {
    dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
    dispatch({ type: SHOW_ERROR_GIFT_CARDS_MESSAGE, payload: errorMessage });
};

export const clearGiftCardsState = () => {
    return (dispatch) => {

        dispatch({ type: CLEAR_GIFT_CARDS_STATE });
    };
};

export const clearGifCardsTotalDiscount = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_GIFT_CARDS_TOTAL_DISCOUNT });
    };
};

export const clearGiftCardsErrorMessage = () => {
    return (dispatch) => {
        dispatch({ type: SHOW_ERROR_GIFT_CARDS_MESSAGE, payload: null });
    };
};