import React, { Fragment } from 'react';
import i18n from '../../../../i18next';

import ConfirmationModal from '../../../common/ConfirmationModal';

const ComoModalHandler = props => {

    const getModalProps = () => {

        const { isPromoPartnerModalOpen, isUserLoggedInPartner } = props.promotionPartners;
        const { userRegistrationTriggered, errors, status } = props.como;

        const userNotRegistered = status === 'error' && errors[0].code === '4001012';
        const wrongApiKey = errors && errors[0] && errors[0].code === '4030000';

        let attributes = {
            ...props,
            isOpen: isPromoPartnerModalOpen,
            onToggle: () => props.togglePromoPartnerModal(),
            customerThemes: props.customerThemes
        };

        const textData = {
            informationMessage: i18n.t('screens:como.noAccountInfoMsg'),
            confirmationMessage: i18n.t('screens:como.noAccountWarning')
        };

        if (userRegistrationTriggered) {
            textData.informationMessage = i18n.t('screens:como.linkSent');
            textData.confirmationMessage = i18n.t('screens:como.linkSentWarning');
        }

        if (wrongApiKey) {

            return attributes = {
                ...attributes,
                hideCancelBtn: true,
                yesBtnText: 'OK',
                onConfirm: () => props.resetErrorsOnly(),
                message: {
                    informationMessage: i18n.t('common:alerts.errMsgHeading'),
                    confirmationMessage: i18n.t('screens:como.wrongApiKeyMsg')
                }
            };
        }

        if (!isUserLoggedInPartner && !userRegistrationTriggered && !userNotRegistered) {

            attributes = {
                ...attributes,
                hideCancelBtn: false,
                yesBtnText: i18n.t('screens:como.signIn'),
                onCancel: () => props.setUserAnswer('NO'),
                onConfirm: () => props.getComoMemberDetails(true),
                message: {
                    informationMessage: i18n.t('screens:como.signInComo'),
                    confirmationMessage: i18n.t('screens:como.cartMadeFinal')
                },
                dontCloseOnSuccess: true
            };
        }
        // } else if (!isUserLoggedInPartner && userNotRegistered && !userRegistrationTriggered) {

        //     attributes = {
        //         ...attributes,
        //         hideCancelBtn: userRegistrationTriggered,
        //         yesBtnText: userRegistrationTriggered ? 'OK' : '',
        //         onCancel: () => props.setUserAnswer('NO'),
        //         onConfirm: () => props.registerToComo(),
        //         message: textData
        //     };
        // }

        return attributes;
    }

    return (
        <Fragment>

            {
                props.promotionPartners.isPromoPartnerModalOpen &&
                <ConfirmationModal {...getModalProps()} />
            }

        </Fragment>
    );
};

export default ComoModalHandler;