import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import '../../styles/commonModal.css';

const root = document.getElementById('root');

class CommonModal extends Component {
    constructor(props) {
        super(props);

        this.modalOverlay = document.createElement('div');
        let body = document.getElementsByTagName("BODY")[0];
        body.style.overflow = 'hidden';

        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        // The portal element is inserted in the DOM tree after
        // the Modal's children are mounted, meaning that children
        // will be mounted on a detached DOM node. If a child
        // component requires to be attached to the DOM tree
        // immediately when mounted, for example to measure a
        // DOM node, or uses 'autoFocus' in a descendant, add
        // state to Modal and only render the children when Modal
        // is inserted in the DOM tree.
        root.appendChild(this.modalOverlay);
        this.modalOverlay.setAttribute('class', 'commonModal');
        this.modalOverlay.addEventListener('click', (e) => this.closeModal(e), false);
    }

    componentWillUnmount() {
        root.removeChild(this.modalOverlay);
        this.modalOverlay.removeEventListener('click', (e) => this.closeModal(e), false);
        // Dont allow scrolling befora cart modal close
        if (!this.props.isCartModalOpen) {
            let body = document.getElementsByTagName("BODY")[0];
            body.style.overflowY = 'auto';
        }
    }

    closeModal(e) {
        if (!document.getElementsByClassName(this.props.className)[0].contains(e.target)) {
            // Clicked outside the box
            this.props.toggle();
        }
    }

    renderDialog() {
        if (this.props.isOpen) {
            return (
                <div 
                    className={`modalDialog ${this.props.dialogName}`}
                    // style={{ height: this.state.operationSystem.includes('Safari') ? window.outerHeight : 'auto'}}
                    >{this.props.children}</div>
            );
        }
    }

    render() {
        return ReactDOM.createPortal(
            this.renderDialog(),
            this.modalOverlay,
        );
    }
}

export default CommonModal;