import React from 'react';
import {
    Col,
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    Button
} from 'reactstrap';
import i18n from '../../../../i18next';

import {
    getPriceWithCurrencySymbol,
    negativeDecimalsValidation
} from '../../../../utils/CommonUtils'

const ComoPayment = props => {

    const { state, restaurant, como } = props;
    const { inputStyle, applyBtnStyle } = styles;
    const {
        primaryColor,
        fontWeight,
        chefNoteBackground,
        buttonsFont,
        inactiveColor
    } = props.customerThemes.selectedTheme;

    const convertBalanceToMonetaryValue = balance => {
        return balance / 100;
    };

    const getPromosPriceOnly = () => {
        let total = 0;
        props.promotionPartners.promoPartnerAppliedPromos.map(promo => {
            total += promo.totalDiscount;
        });

        return total;
    }

    const creditBalanceMonetary = state.creditBalance.balance ? state.creditBalance.balance.monetary : 0;

    const creditBalance = state.creditBalance && creditBalanceMonetary ? convertBalanceToMonetaryValue(creditBalanceMonetary) : 0;
    const creditsWithCurr = getPriceWithCurrencySymbol(creditBalance, restaurant.currency, props.isArabic);

    let isPaymenDisabled = state.usedCreditBalance === null || state.usedCreditBalance === undefined;
    const subTotalWithoutDiscounts = props.shoppingCart.subtotal - getPromosPriceOnly();

    if (state.usedCreditBalance > subTotalWithoutDiscounts || state.usedCreditBalance > creditBalanceMonetary / 100) {
        isPaymenDisabled = true;
    }

    const creditBalanceValue = como.userSavedData.paymentResponse ? props.correctRounding(Math.abs(como.userSavedData.paymentResponse.payments[0].amount / 100), 2) === 0 ? null : state.usedCreditBalance : state.usedCreditBalance;


    return (
        <Col sm={12} md={12} lg={12}>
            <FormGroup hidden={!(creditBalance != null)}>
                <Label style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    color: primaryColor,
                    fontWeight: fontWeight,
                    marginBottom: 0
                }}>{i18n.t('screens:como.creditBalance')}: {creditsWithCurr}</Label>
                <InputGroup>
                    <Input
                        type='number'
                        name='usedCreditBalance'
                        value={creditBalanceValue}
                        onChange={props.changeState}
                        min={0} max={creditBalanceMonetary / 100}
                        onKeyPress={(e) => negativeDecimalsValidation(e)}
                        style={
                            {
                                ...inputStyle,
                                backgroundColor: chefNoteBackground
                            }
                        }
                    />
                    <InputGroupAddon addonType='append'>
                        <Button
                            disabled={isPaymenDisabled}
                            onClick={() => props.handleBalancePayment()}
                            style={
                                {
                                    ...applyBtnStyle,
                                    fontStyle: buttonsFont,
                                    backgroundColor: isPaymenDisabled ? inactiveColor : primaryColor,
                                    width: '100%',
                                    height: '100%',
                                    border: 'none'
                                }
                            }
                        >
                            {i18n.t('screens:como.usePointsBtn')}
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </FormGroup>
        </Col>
    );
}

export default ComoPayment;

const styles = {
    inputStyle: {
        borderRadius: 0
    },
    applyBtnStyle: {
        borderRadius: 0
    }
};