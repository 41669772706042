import React, {Component} from 'react';
import i18n from "../../../../i18next";

import ApplicationStep from '../../common/ApplicationStep';
import SMSConfirmationCommon from './SMSConfirmationCommon';

export default class SMSConfirmationPWA extends Component {

    componentWillMount() {
        this.applyTheme();
        this.props.loadCaptcha();
    }

    componentWillUnmount() {
        this.props.resetErrMsg()
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const {primaryColor, fontWeight, startOrderFontSize, mainTextColor, chefNoteBackground} = this.props.customerThemes.selectedTheme;

        customizedStyles.heading.fontSize = startOrderFontSize;
        customizedStyles.subHeading.color = mainTextColor;
        customizedStyles.input.fontWeight = fontWeight;
        customizedStyles.input.backgroundColor = chefNoteBackground;
        customizedStyles.submitBtn.backgroundColor = primaryColor;
        customizedStyles.numberStyle.color = primaryColor;
        styles = customizedStyles;
        return styles;
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        return (
            <ApplicationStep
                hideBackButton={false}
                transparentHeader={false}
                text={i18n.t('screens:smsConfirmationScreen.confirmation')}
                goBack={this.goBack.bind(this)}
                content={
                    <SMSConfirmationCommon
                        styles={styles}
                        customerThemes={this.props.customerThemes}
                        customer={this.props.customer}
                        verifyCustomerPhone={this.props.verifyCustomerPhone}
                        isPWA={true}
                        openChangeOrderTimeScreen={this.props.openChangeOrderTimeScreen}
                        startOrder={this.props.startOrder}
                        brand={this.props.brand}
                        resetErrMsg={this.props.resetErrMsg}
                        loadCaptcha={this.props.loadCaptcha}
                    />
                }
            />
        )
    }
}

let styles = {
    modalBody: {
        textAlign: 'center',
        padding: 0,
        minHeight: '480px',
        backgroundColor: '#ffffff'
    },
    modalFooter: {},
    login: {
        fontSize: 22,
        marginLeft: 30,
        marginRight: 12
    },
    loginTextStyle: {
        marginLeft: -10,
        verticalAlign: 'middle'
    },
    heading: {
        fontSize: 22,
        margin: 'auto',
        textAlign: 'center'
    },
    subHeading: {
        fontSize: 14,
        color: '#939393',
        marginBottom: 4,
        textAlign: 'center'
    },
    inputGroup: {
        margin: 'auto',
        height: 50,
        marginBottom: 10
    },
    input: {
        height: 50,
        borderRadius: 0,
        backgroundColor: '#e9ecef',
        textAlign: 'center',
        paddingLeft: 35
    },
    numberStyle: {
        color: '#61B522',
        // fontWeight: 700
    },
    submitBtn: {
        height: 50,
        borderRadius: 0,
        width: '100%',
        marginBottom: 38,
        backgroundColor: '#61B522',
        border: 'none'
    },
    submitBtnDisabled: {
        height: 50,
        borderRadius: 0,
        width: '100%',
        marginBottom: 38,
        backgroundColor: '#a8a8a8',
        border: 'none'
    },
    headerColumn: {
        paddingLeft: 0,
        width: 72
    },
    secondCol: {
        margin: 'auto'
    },
    thirdCol: {
        margin: 'auto'
    },
    phoneIconStyle: {
        position: 'absolute',
        padding: '8px 5px',
        marginLeft: 5,
        zIndex: 1
    },
    phoneIconStyleRTL: {
        position: 'absolute',
        padding: '8px 5px',
        marginRight: 5,
        zIndex: 1
    },
    imageStyle: {
        paddingRight: 18,
        marginTop: 3
    },
    centredItem: {
        margin: '0 auto',
        fontSize: 20,
        textAlign: 'center',
        paddingRight: 55
    },
    modalCustomSubmitBody: {
        display: 'flex',
        justifyContent: 'center',
        margin: '50px 0 0 0'
    },
    phoneNumberHeadingCol: { 
        height: 57, 
        maxHeight: 57, 
        minHeight: 57 
    },
    marginAuto: {
        margin: 'auto',
        position: 'relative',
        bottom: 20,
        top: 0
    }
}