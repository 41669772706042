import React from 'react';
// {/* <style type="text/css">
// 	.st0{fill:#FFFFFF;}
// 	.st1{fill:#37A72E;}
// 	.st2{fill:url(#Combined-Shape_4_);}
// </style> */}

// <?xml version="1.0" encoding="utf-8"?>
// <!-- Generator: Adobe Illustrator 22.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
const MirSVG = ({ width, height }) => {
	return (

		<svg width={width} height={height} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 		viewBox="0 0 780 500" enableBackground="new 0 0 780 500" space="preserve">

			<path class="st0" fill='#FFFFFF' d="M40,0h700c22.1,0,40,17.9,40,40v420c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40C0,17.9,17.9,0,40,0z"/>
			<g id="Page-1">
				<g id="Artboard" transform="translate(-91.000000, -154.000000)">
					<g id="Group" transform="translate(91.000000, 154.000000)">
						<path id="Combined-Shape" fill='#37A72E' d="M544.1,240.5v108h60v-64h68c28.6-0.2,52.9-18.5,62.1-44H544.1z"/>
							<linearGradient id="Combined-Shape_4_" gradientUnits="userSpaceOnUse" x1="362.4047" y1="275.4307" x2="363.4047" y2="275.4307" gradientTransform="matrix(201.7633 0 0 -79 -72583.8438 21950.0254)">
								<stop  offset="0" stopColor="#00A0E5"/>
								<stop  offset="1" stopColor="#0077C3"/>
							</linearGradient>
						<path id="Combined-Shape_1_" fill='url(#Combined-Shape_4_)' d="M536.1,151.5c3.5,44.1,45.3,79,96.3,79c0.2,0,104.3,0,104.3,0
							c0.8-4,1.2-8.2,1.2-12.5c0-36.6-29.5-66.2-66-66.5L536.1,151.5z"/>
						<path id="Combined-Shape_2_" fill='#37A72E' d="M447.3,229.4l0-0.1L447.3,229.4c0.7-1.2,1.8-1.9,3.2-1.9c2,0,3.5,1.6,3.6,3.5l0,0
							v116.5h60v-196h-60c-7.6,0.3-16.2,5.8-19.4,12.7L387,266.6c-0.1,0.4-0.3,0.8-0.5,1.2l0,0l0,0c-0.7,1-1.9,1.7-3.3,1.7
							c-2.2,0-4-1.8-4-4v-114h-60v196h60v0c7.5-0.4,15.9-5.9,19.1-12.7l49-105.1C447.2,229.6,447.3,229.5,447.3,229.4L447.3,229.4z"/>
						<path id="Combined-Shape_3_" fill='#37A72E' d="M223.3,232.8l-35.1,114.7H145L110,232.7c-0.3-1.8-1.9-3.2-3.9-3.2
							c-2.2,0-3.9,1.8-3.9,3.9c0,0,0,0,0,0l0,114h-60v-196h51.5H109c11,0,22.6,8.6,25.8,19.1l29.2,95.5c1.5,4.8,3.8,4.7,5.3,0
							l29.2-95.5c3.2-10.6,14.8-19.1,25.8-19.1h15.3h51.5v196h-60v-114c0,0,0,0,0-0.1c0-2.2-1.8-3.9-3.9-3.9
							C225.2,229.5,223.6,230.9,223.3,232.8L223.3,232.8z"/>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default MirSVG;