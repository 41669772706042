import { sendEmailReport } from '../../actions/index';

export const initiateGooglePayOption = (paymentProvider, orderData, placeOrderCallback) => {

    const paymentProviderName = paymentProvider.providerType.value.toLowerCase();
    const publicKey = paymentProvider.stripePremenuData.publicKey;
    const amount = orderData.totalAmount;
    const currencyCode = orderData.restaurant.currency.toUpperCase();
    const countryCode = orderData.brand.countryCode.toUpperCase();

    let request = {
        gateway: paymentProviderName,
        merchantId: publicKey,
        amount: amount,
        countryCode: countryCode,
        currencyCode: currencyCode,
        gpMerchantName: orderData.restaurant.name,
        // gpMerchantId: '12345678901234567890', // uncomment if needed
        // purpose: 'TEST', // uncomment if needed
    }

    window.cordova.plugins.ApplePayGooglePay.makePaymentRequest(request, result => {
        // in success callback, raw response as encoded JSON is returned. Pass it to your payment processor as is.
        try {
            let decryptedResult = result.replaceAll(' ', '').replaceAll('\\n', '').replaceAll('\\', '').replaceAll('"{', '{').replaceAll('}"', '}');
            let parsedResult = JSON.parse(decryptedResult);
            const stripeToken = parsedResult.paymentMethodData.tokenizationData.token.id;
            placeOrderCallback(stripeToken);
        } catch (error) {
            sendEmailReport(orderData.restaurant.restaurantId, error, 'Google Pay parse response error');
        }
    },
        error => {
            // in error callback, error message is returned.
            // it will be "Payment cancelled" if used pressed Cancel button.
        }
    )
};

export const deviceSupportGooglePay = (callback) => {
    window.cordova.plugins.ApplePayGooglePay.canMakePayments(result => {
        callback(result);
    })
};