import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane, Row } from 'reactstrap';
import { ARABIC } from '../../../utils/Constants';

class AnswerGroupsTabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 1,
            defaultGroup: {
                id: -1,
                title: 'Others'
            }
        }
    }

    componentDidMount() {
        const { currentQuestion } = this.props;

        if (currentQuestion.answerGroups.length) {
            this.setState({
                ...this.state,
                activeTab: this.findFirstGroupWithAnswers(currentQuestion.answerGroups, currentQuestion)
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentQuestion } = this.props;

        if (currentQuestion.id !== prevProps.currentQuestion.id && currentQuestion.answerGroups.length) {
            this.setState({
                ...this.state,
                activeTab: this.findFirstGroupWithAnswers(currentQuestion.answerGroups, currentQuestion)
            });
        }
    }

    toggleGroup(groupId) {
        if (this.state.activeTab !== groupId) {
            this.setState({
                ...this.state,
                activeTab: groupId
            });
        }
    }

    findFirstGroupWithAnswers(answerGroups, currentQuestion) {
        let groupId = {};
        let i = 0;
        while (i < answerGroups.length) {
            let group = answerGroups[i];
            let groupFound = false;

            currentQuestion.answers.map(answer => {
                if (answer.answerGroup) {
                    if (answer.answerGroup.id === group.id) {
                        groupFound = true;
                        groupId = group.id;
                    }
                }
            });

            if (groupFound) {
                break;
            }
            i++;
        }

        return groupId;
    }

    checkForAnswersWithoutGroup(currentQuestion) {
        let answerWithoutGroupFound = false, i = 0;
        while (!answerWithoutGroupFound) {
            if (!currentQuestion.answers[i]) {
                break;
            }
            if (!currentQuestion.answers[i].answerGroup) {
                return answerWithoutGroupFound = true;
            }
            i++;
        }

        return answerWithoutGroupFound;
    }

    render() {
        const { currentQuestion } = this.props;
        const isArabic = this.props.language === ARABIC;
        let answerGroupsCopy = currentQuestion.answerGroups.slice();
        if (this.checkForAnswersWithoutGroup(currentQuestion)) {
            answerGroupsCopy.push(this.state.defaultGroup);
        }

        answerGroupsCopy = answerGroupsCopy.filter(group => {
            let groupWithoutAnswers = true;
            currentQuestion.answers.map(answer => {
                if (answer.answerGroup) {
                    if (answer.answerGroup.id === group.id || group.id === -1) {
                        groupWithoutAnswers = false;
                    }
                }
            });

            if (!groupWithoutAnswers) {
                return group;
            }
        });

        let tabContentStyle = {
            display: 'flex',
            flex: 1,
            // marginBottom: 111
        }, tabPaneStyle = { width: '100%' };

        return (
            <Fragment>
                <Nav tabs style={isArabic ? { maxHeight: 'max-content', paddingRight: 0 } : { maxHeight: 'max-content'}}>
                    <NavItem className='productWizardNavItem'>
                        {
                            answerGroupsCopy.map((group, groupIndex) => {
                                return (
                                    <NavLink id={group.id} key={`navlinkTab${groupIndex}`}
                                        className={this.state.activeTab === group.id ? 'active' : ''}
                                        onClick={() => this.toggleGroup(group.id)}
                                    >
                                        {group.title}
                                    </NavLink>
                                );
                            })
                        }
                    </NavItem>
                </Nav>
                {
                    answerGroupsCopy.map((group, groupIndex) => {

                        if (this.state.activeTab === group.id) {

                            return (
                                <TabContent
                                    activeTab={this.state.activeTab}
                                    key={`renderInTabs${groupIndex}`}
                                    style={tabContentStyle}>
                                    <TabPane tabId={group.id} style={tabPaneStyle}>
                                        <Row style={{ margin: 0 }}>
                                            {this.props.renderInList(group)}
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            );
                        }
                    })
                }
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        language: state.storeLocalStorage.language
    }
}

export default connect(mapStateToProps)(AnswerGroupsTabs);