import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import i18n from '../../../../i18next';
import { Col, Form, Input, FormGroup, FormFeedback } from "reactstrap";
import { isIOS, isMacOs, isMobileOnly, isSafari } from 'mobile-device-detect';

import { placeOrder } from '../../../../actions';
import { SERVER_URL, MONERIS_LINK, FRONT_END_URL, ARABIC } from '../../../../utils/Constants';
import { getQueryInfoFromUrl, getAllUrlParams } from '../../../../utils/CommonUtils';

class MonerisForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSaveCardAllowed: false,
            dataKey: '',
            street: '',
            number: '',
            postCode: '',
            streetError: false,
            numberError: false,
            postCodeError: false
        }
    }

    componentDidMount = () => {
        this.props.validateCardInput(true);
        window.addEventListener('message', this.respMsg, false);
        this.completeOrder();
    }

    componentWillUnmount = () => {
        window.removeEventListener('message', this.respMsg, false);
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.payment.isCardValid !== this.props.payment.isCardValid) {
            //Moneris does not allow us to know if card field is filled or not. 
            //Instead they return error codes which we have already handled for this we set the isCardValid property to always be True
            //and therefore the place order button will always be active
            this.props.validateCardInput(true);
        }
    }

    changeState = ({ target }) => {
        if (target.name === 'isSaveCardAllowed') {
            this.setState({
                isSaveCardAllowed: !this.state.isSaveCardAllowed
            });
        } else {
            this.setState({
                [target.name]: target.value,
                [`${target.name}Error`]: target.value ? false : true
            });
        }
    }

    respMsg = (e) => {
        let response = {};
        try {
            response = JSON.parse(e.data);
        } catch (catchError) {
            // console.log(catchError)
        }
        // const isBillingAddressEntered = this.state.street !== '' && this.state.number !== '' && this.state.postCode !== '';    // if street and number are required

        if (response.responseCode) {
            if (response.responseCode[0] === '001') {
                this.setState({ dataKey: response.dataKey }, () => this.doPlaceOrder());
            } else {
                this.props.errorProcessingPayment(response.responseCode, '', this.props.paymentProvider.providerType.id);
            }
        }
    }

    doPlaceOrder = async () => {
        const { customer, selectedRestaurant, shoppingCart } = this.props;
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total : null;
        let paymentIntentRequest = {
            customerId: customer.id,
            restaurantId: selectedRestaurant.restaurantId,
            amount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : shoppingCart.total,
            currency: selectedRestaurant.currency,
            app: this.props.pwaAppRunning,
            monerisAuthorizeRequest: {
                dataKey: this.state.dataKey,
                redirectUrl: this.getMenuuRedirectUrl(),
                challengeWindowSize: '05',
                userAgent: window.navigator.userAgent,
                browserLang: window.navigator.language,
                windowHeight: window.innerHeight,
                windowWidth: window.innerWidth,
                newCard: Object.entries(this.props.payment.selectedPaymentCard).length ? false : this.state.isSaveCardAllowed,
                street: Object.entries(this.props.payment.selectedPaymentCard).length ? this.props.payment.selectedPaymentCard.street : this.state.street,
                streetNumber: Object.entries(this.props.payment.selectedPaymentCard).length ? this.props.payment.selectedPaymentCard.streetNumber : this.state.number,
                zipCode:  Object.entries(this.props.payment.selectedPaymentCard).length ? this.props.payment.selectedPaymentCard.zipCode : this.state.postCode
            }
        }

        const header = { headers: { 'Authorization': `TOKEN${customer.token}` } };

        await Axios.post(`${SERVER_URL}/payment/authorize`, paymentIntentRequest, header)
            .then(response => {
                if (!response.data.monerisResponse.challangeUrl) {   // normal flow
                    const providerData = {
                        dataKey: response.data.monerisResponse.dataKey,
                        orderId: response.data.monerisResponse.orderId,
                        cavv: response.data.monerisResponse.cavv,
                        txnNumber: response.data.monerisResponse.txnNumber,
                        newCard: Object.entries(this.props.payment.selectedPaymentCard).length ? false : this.state.isSaveCardAllowed,
                        amount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : shoppingCart.total,
                        street: response.data.monerisResponse.street,
                        streetNumber: response.data.monerisResponse.streetNumber,
                        zipCode: response.data.monerisResponse.zipCode
                    };

                    this.props.placeOrder(false, providerData);
                } else {    //3d secure flow                                
                    this.secureRedirect(response.data.monerisResponse);
                }
            })
            .catch(error => {
                this.props.errorProcessingPayment(error, 'Authorize Failure', this.props.paymentProvider.providerType.id);
                this.props.sendEmailReport(selectedRestaurant.restaurantId, error, 'paymentIntentFailure');
            });
    }

    getMenuuRedirectUrl = () => {
        const { pwaAppRunning } = this.props;
        const { navigationType, brandId } = this.props.storeLocalStorage;
        const isIntegration = getQueryInfoFromUrl().brandId;
        let redirectUrl = '';

        if (isIntegration) {
            redirectUrl = `${FRONT_END_URL}/?brandId=${brandId}&navigationType=${navigationType}#/checkout/`;
        } else if (pwaAppRunning) {
            redirectUrl = 'http://localhost:8100/';   // this is just fake url bcs moneris want some redirect url
        } else {
            redirectUrl = `${FRONT_END_URL}/#/checkout`;
        }

        return redirectUrl;
    }

    secureRedirect = (response) => {
        let challangeUrl = response.challangeUrl;
        let challangeData = response.challangeData;
        let monerisForm = `<form id="monerisForm" method="POST" action="${challangeUrl}"><input type="hidden" name="creq" value="${challangeData}"></form>`;

        this.props.saveMonerisData(response);

        setTimeout(() => {
            if (this.props.pwaAppRunning) {
                const pageContent = `<html><body>${monerisForm}<script type="text/javascript">document.getElementById("monerisForm").submit();</script></body></html>`;
                const pageContentUrl = 'data:text/html;base64,' + btoa(pageContent);
                let browserRef = window.cordova.InAppBrowser.open(pageContentUrl, '_blank', 'location=no');
                browserRef.addEventListener('loadstart', (event) => this.completeOrder(event, browserRef));
            } else {
                document.body.insertAdjacentHTML('afterbegin', monerisForm);
                document.getElementById('monerisForm').submit();
            }
        }, 2000)
    }

    checkCardData = (e) => {
        // Prevents the form from refreshing the page
        e.preventDefault();
        // Starts the processing window
        this.props.processingPayment();
        if (!Object.entries(this.props.payment.selectedPaymentCard).length) {
            var monFrameRef = document.getElementById('monerisFrame').contentWindow;
            monFrameRef.postMessage('tokenize', MONERIS_LINK);
        } else {
            this.setState({ dataKey: this.props.payment.selectedPaymentCard.paymentCardId }, () => this.doPlaceOrder());
        }
    }

    completeOrder = (event, browserRef) => {
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total : null;
        if (new URLSearchParams(window.location.search).get('success') && this.props.paymentProvider.monerisData) {    // desktop flow
            const isSuccess = new URLSearchParams(window.location.search).get('success');

            if (isSuccess === 'true') {
                const providerData = {
                    dataKey: this.props.paymentProvider.monerisData.dataKey,
                    orderId: this.props.paymentProvider.monerisData.orderId,
                    cavv: new URLSearchParams(window.location.search).get('cavv'),
                    newCard: new URLSearchParams(window.location.search).get('isNewCard'),
                    amount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : this.props.shoppingCart.total,
                    street: this.props.paymentProvider.monerisData.street,
                    streetNumber: this.props.paymentProvider.monerisData.streetNumber,
                    zipCode: this.props.paymentProvider.monerisData.zipCode
                };

                this.props.placeOrder(false, providerData);
                this.props.clearMonerisData();
            } else {
                this.props.errorProcessingPayment([], '', this.props.paymentProvider.providerType.id);
                this.props.clearMonerisData();
            }
        } else if (event) {                                                    // apps flow
            if ((event.url).indexOf('success=') !== -1 && this.props.paymentProvider.monerisData) {
                browserRef.close();
                browserRef.removeEventListener('loadstart', this.completeOrder);
                let allUrlParams = getAllUrlParams(event.url);
                const isSuccess = allUrlParams.success;

                if (isSuccess === 'true') {
                    const providerData = {
                        dataKey: this.props.paymentProvider.monerisData.dataKey,
                        orderId: this.props.paymentProvider.monerisData.orderId,
                        cavv: allUrlParams.cavv,
                        newCard: allUrlParams.isNewCard,
                        amount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : this.props.shoppingCart.total,
                        street: this.props.paymentProvider.monerisData.street,
                        streetNumber: this.props.paymentProvider.monerisData.streetNumber,
                        zipCode: this.props.paymentProvider.monerisData.zipCode
                    }

                    this.props.placeOrder(false, providerData);
                    this.props.clearMonerisData();
                } else {
                    this.props.errorProcessingPayment([], '', this.props.paymentProvider.providerType.id);
                    this.props.clearMonerisData();
                }
            }
        } else if (this.props.paymentProvider.monerisData) {    // handle error when click back button or cancel transaction
            this.props.errorProcessingPayment([], '', this.props.paymentProvider.providerType.id);
            this.props.clearMonerisData();
        }
    }

    renderMonerisForm = () => {
        let { inputsFontColor, chefNoteBackground } = this.props.customerThemes.selectedTheme;
        let cardFieldsBackground = chefNoteBackground.replace('#', '');
        inputsFontColor = inputsFontColor.replace('#', '');
        const profileId = this.props.paymentProvider.monerisPremenuData.profileId;
        const isPwa = this.props.pwaAppRunning || isMobileOnly;
        let source = `${MONERIS_LINK}?id=${profileId}&pmmsg=true&css_body=background:${cardFieldsBackground};display:flex;align-items:center&css_textbox=background-color:${cardFieldsBackground};border:none;outline:none;color:${inputsFontColor};font-size:16px;padding:0px&css_textbox_pan=width:${isPwa ? 60 : 60}%;&enable_exp=1&css_textbox_exp=width:${isPwa ? 25 : 30}%;&enable_cvd=1&css_textbox_cvd=width:${isPwa ? 15 : 10}%;&display_labels=2${isPwa ? '&pan_label=Card Number&exp_label=MM/YY' : '&pan_label=Card Number&exp_label=Expiry Date MM/YY'}`

        return (
            <div style={{ display: 'flex' }}>
                <div className="checkout" id="card-element" style={{ backgroundColor: chefNoteBackground, height: 46, width: '100%', border: '1px solid lightgrey', padding: '12px 12px 12px' }}>
                    <iframe id='monerisFrame' src={source} frameborder='0' width="100%" height="23px"></iframe>
                </div>
            </div>
        )
    }

    renderBillingAddress = () => {
        const { menuItemsBackground, inputsFontColor, chefNoteBackground } = this.props.customerThemes.selectedTheme;
        const { billingInputsStyle } = styles;
        const isMobileView = window.innerWidth <= 565;
        const isIOSApp = isIOS && this.props.pwaAppRunning ? true : false;

        return (
            <div style={{ display: 'flex', marginTop: 15, flexDirection: isMobileView ? 'column' : 'row' }}>
                <FormGroup style={{ width: isMobileView ? '100%' : '90%' }}>
                    <Input
                        className={isSafari || isIOSApp ? 'monerisStreet' : null}
                        type='text'
                        name='street'
                        placeholder={i18n.t('screens:checkoutScreen.cardBillingAddress')}
                        value={this.state.street}
                        // invalid={this.state.streetError}
                        onChange={this.changeState}
                        style={{ ...billingInputsStyle, backgroundColor: chefNoteBackground, color: inputsFontColor }}
                        required={false}/>
                    {/* <FormFeedback>{i18n.t('common:validations.errorMsg')}</FormFeedback> */}
                </FormGroup>
                <div style={{width: isMobileView ? '100%' : '40%', display: 'flex'}}>
                    <FormGroup style={!isMobileView ? { marginLeft: 10 } : null}>
                        <Input
                            className={isSafari || isIOSApp ? 'monerisNumber' : null}
                            type='text'
                            name='number'
                            placeholder={i18n.t('screens:checkoutScreen.streetNumber')}
                            value={this.state.number}
                            // invalid={this.state.numberError}
                            onChange={this.changeState}
                            style={{ ...billingInputsStyle, backgroundColor: chefNoteBackground, color: inputsFontColor }} />
                        {/* <FormFeedback>{i18n.t('common:validations.errorMsg')}</FormFeedback> */}
                    </FormGroup>
                    <FormGroup style={{ marginLeft: 10 }}>
                        <Input
                            className={isSafari || isIOSApp ? 'monerisPostCode' : null}
                            type='text'
                            name='postCode'
                            placeholder={i18n.t('screens:checkoutScreen.zipOrPostalCode')}
                            value={this.state.postCode}
                            // invalid={this.state.postCodeError}
                            onChange={this.changeState}
                            style={{ ...billingInputsStyle, backgroundColor: chefNoteBackground, color: inputsFontColor }} />
                        {/* <FormFeedback>{i18n.t('common:validations.errorMsg')}</FormFeedback> */}
                    </FormGroup>
                </div>
            </div>
        )
    }

    renderSaveCard = () => {
        let { primaryFontColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        return (
            <label
                htmlFor='isSaveCardAllowed'
                className='marketingCheckbox-label unselectable'
                style={{
                    minWidth: '90%',
                    margin: isArabic ? '5px 22px auto auto' : '5px auto auto 22px',
                    color: primaryFontColor,
                    fontSize: 16,
                    lineHeight: '33px',
                    cursor: this.props.payment.selectedPaymentCard.paymentCardId ? 'default' : 'pointer'
                }}>
                <span style={{ marginLeft: 10 }}>{i18n.t('screens:checkoutScreen.saveCardMsg')}</span>
                <input
                    type='checkbox' name='isSaveCardAllowed' id='isSaveCardAllowed' style={{ marginTop: 5 }}
                    checked={this.state.isSaveCardAllowed}
                    onChange={this.changeState} disabled={this.props.payment.selectedPaymentCard.paymentCardId} />
                <span className='marketingCheckbox-custom'></span>
            </label>
        )
    }

    render = () => {
        const { monerisElementStyle } = styles;
        let { primaryFontColor } = this.props.customerThemes.selectedTheme;

        return (
            <Col xs={12} sm={12} md={8} style={monerisElementStyle} hidden={this.props.paymentWithCard}>
                <Form
                    id='monerisForm'
                    onSubmit={(e) => this.checkCardData(e)}
                    hidden={this.props.payment.selectedPaymentType.id !== 1}
                >
                    {this.renderMonerisForm()}
                    {/* not needed for now */}
                    {this.renderBillingAddress()}
                    {this.renderSaveCard()}
                    <label style={{color: primaryFontColor}}>{i18n.t('screens:checkoutScreen.cardDetailsInfo')}</label>
                </Form>
            </Col>
        )
    }
}

export default connect(null, { placeOrder })(MonerisForm);

let styles = {

    monerisElementStyle: {
        margin: 'auto',
        paddingBottom: 10
    },

    billingInputsStyle: {
        borderRadius: 0,
        height: 46,
        padding: 12
    }
}


