import {CLEAR_APP_STATE, CLEAR_STATE, SAVE_PREVIEW_MODE_VALUE} from "../../actions/types";

const INITIAL_STATE = {
    previewMode: false,
    restaurantId: null
};

export default (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case SAVE_PREVIEW_MODE_VALUE:
            return {...state, previewMode: action.payload.previewMode, restaurantId: action.payload.restaurantId}
        case CLEAR_STATE:
            return INITIAL_STATE;
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
