import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Button, Row } from 'reactstrap';
import { saveFacebookUserData, submitLogin, startOrderScreenPath, activateLoader, stopLoader } from '../../../actions';
import FacebookLogoSVG from '../../../pwa-app/components/common/svgComponents/FacebookLogoSVG';
import i18n from '../../../i18next';
import { ARABIC, FACEBOOK_APP_ID, GENERIC_API_KEY } from '../../../utils/Constants';
import { encryptData, hashKey } from '../../../utils/CommonUtils';

class FacebookSignInAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
        userData: null
    };

    this.facebookSignIn = this.facebookSignIn.bind(this);
    this.facebookLogin = this.facebookLogin.bind(this);
    this.fetchFacebookUserData = this.fetchFacebookUserData.bind(this);
  }

  componentDidMount() {
    if (!window.FB) {
      this.props.activateLoader();
      this.initFacebookSdk().then(() => {
        this.props.stopLoader();
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.newSocialLoginInfo !== prevProps.newSocialLoginInfo && this.props.newSocialLoginInfo) {
      if (this.state.userData && this.props.newSocialLoginInfo.providerName === 'facebook') {
        this.props.saveFacebookUserData(this.state.userData);      	
        this.props.stopLoader();

        if (this.props.isCheckoutLogin) {
          const path = '/menu-screen/social-response';
          this.props.startOrderScreenPath(path);
        } else {
          this.props.history.push('/start-order/social-response');
        }
      }
    }
  }

  initFacebookSdk = async () => {
    return new Promise((resolve, reject) => {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: 'v20.0'
        });
        resolve();
        window.FB.AppEvents.logPageView();
      };

      (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    });
  }

  facebookLogin() {
    window.FB.login((response) => {
      if (response.authResponse) {
        this.fetchFacebookUserData(response.authResponse.accessToken);
      }
    }, { scope: 'public_profile,email' });
  }

  facebookSignIn() {
    window.FB.getLoginStatus((response) => {
      if (response.status === 'connected' && response.authResponse) {
        this.fetchFacebookUserData(response.authResponse.accessToken);
      } else {
        this.facebookLogin();
      }
    });
  }
  
  fetchFacebookUserData(accessToken) {
    window.FB.api('/me', { fields: 'name,email,picture', access_token: accessToken }, (response) => {
      if (response) {
        let userData = {};
        userData.socialProvider = "facebook";
        userData.jwtToken = accessToken;

        if (response.id) {
          userData.accountId = response.id;
        }        
        if (response.name) {
          userData.name = response.name;
        }
        if (response.email) {
          userData.email = response.email;
        }
        if (response.picture.data.url) {
          userData.picture = response.picture.data.url;
        }

        const year = new Date().getFullYear();
        const brandId = this.props.brand.id;
        const key = hashKey(`${GENERIC_API_KEY}${brandId}${year}`);
  
        const encrData = encryptData(userData, key);
        if (encrData) {
          this.setState({
              userData: encrData
          });
        }

        let isOrderDone = false;
        if (this.props.isOrderDone) {
          isOrderDone = true;      	
        }

        let isPWA = false;
        if (this.props.isPWA) {
          isPWA = true;
        }
  
        this.props.activateLoader();
        this.props.submitLogin(undefined, isOrderDone, isPWA, undefined, undefined, encrData, this.props.isCheckoutLogin);
      }
    });
  }

  render() {
    const { loginButtonStyle, loginImageIcon, loginImageIconRTL } = styles;
    const isArabic = this.props.language === ARABIC;
    
    return (
      <Button className='loginButton loginBtnHover' color="outline-success" style={loginButtonStyle} onClick={this.facebookSignIn}>
          <FacebookLogoSVG width={24} height={24} fill={'#3B579D'} style={isArabic ? loginImageIconRTL : loginImageIcon} />
          <span>{i18n.t('screens:loginTypesScreen.continueFacebook')}</span>
      </Button>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newSocialLoginInfo: state.customer.newSocialLoginInfo,
    brand: state.brand,
    customerThemes: state.customerThemes,
    language: state.storeLocalStorage.language
  };
};

export default withRouter(connect(mapStateToProps, { 
  saveFacebookUserData,
  submitLogin,
  startOrderScreenPath,
  activateLoader,
  stopLoader
})(FacebookSignInAuth));

let styles = {
    loginButtonStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        maxWidth: 400,
        fontWeight: 400,
        position: 'relative',
        marginBottom: '14px'
    },
    loginImageIcon: {
        marginRight: 12
    },
    loginImageIconRTL: {
        marginLeft: 12
    }
}
