import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18next';
import { Button, Col } from 'reactstrap';
import MaterialIcon from 'material-icons-react';

import PromotionPartnerAdapter from '../../../components/order/promotions/PromotionPartnerAdapter';
import ShoppingCart from '../../../components/order/shoppingCart/ShoppingCart'
import { togglePromotionsModal, toggleShoppingCartModal, resetShowComoError } from '../../../actions/index';
import { ARABIC } from '../../../utils/Constants';

class PromotionsScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comoInformation: null,
            rejectedRegistration: false
        }

        this.goBack = this.goBack.bind(this);
        this.sendInfoToShoppingCart = this.sendInfoToShoppingCart.bind(this);
        this.redirectNotVerifiedUser = this.redirectNotVerifiedUser.bind(this);
    }

    componentWillUnmount =() => {
        this.props.resetShowComoError();
    }

    rejectedRegistrationOnClick = () => {
        this.setState({
            rejectedRegistration: true
        })
    }

    goBack() {
        const isMobileView = window.innerWidth <= 865;
        if (isMobileView) {
            this.props.toggleShoppingCartModal();
        }
        this.props.togglePromotionsModal();
    }

    redirectNotVerifiedUser() {
        this.props.history.push('/checkout');
        this.props.togglePromotionsModal();
    }

    sendInfoToShoppingCart(comoInformation) {
        this.setState({
            comoInformation 
        })
    }

    renderPromotionsHeader() {
        const isMobileView = window.innerWidth <= 865;
        const { primaryFontColor, fontFamilyType } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;
        const isLoggedInPromotionPartner = this.props.promotionPartners.isUserLoggedInPartner;

        return (
            <div id="headerText" style={{ minWidth: '100%', padding: '7px 0', display: 'flex', justifyContent: 'center' }}>
                { !isMobileView ?
                    <div id="restAndBrandName" style={{ color: primaryFontColor, fontFamily: fontFamilyType }}>
                        <h3 style={{ marginBottom: '0px', fontSize: '24px' }}>{i18n.t('screens:como.mainSectionHeader')}</h3>
                        <div
                            id="customCloseBtn"
                            className='available-discounts'
                            style={isArabic ? { position: 'absolute', top: 4, left: isLoggedInPromotionPartner ? 30 : 5 } : { position: 'absolute', top: 4, right: isLoggedInPromotionPartner ? 30 : 5 }}
                            onClick={() => this.goBack()}>
                        </div>
                    </div> :
                    <Col xs={12} sm={12} md={12} style={{ color: primaryFontColor, fontFamily: fontFamilyType, display: 'flex', alignItems: 'center' }}>
                        <Col xs={2} sm={2} md={2} style={isArabic ? { padding: '0px', position: 'absolute', top: 0, right: 7, zIndex: 10 } : { padding: '0px', position: 'absolute', top: 0, left: 7, zIndex: 10 }}>
                            <Button className="backButton" style={{ color: primaryFontColor, padding: '0px' }} onClick={() => this.goBack()}>
                                <span className="backButtonIcon" style={{ fontWeight: 300, bottom: 2 }}>
                                    <MaterialIcon icon="navigate_before" color="#000" size={32} />
                                </span>
                                <span className="backButtonText" style={{ color: primaryFontColor, position: 'relative', top: 1 }}>{i18n.t('common:buttons.backBtn')}</span>
                            </Button>
                        </Col>
                        <Col xs={10} sm={10} md={10} style={{ margin: '0 auto' }}>
                            <h3 style={{ marginBottom: '0px', color: primaryFontColor, display: 'flex', justifyContent: 'center', fontSize: '24px' }}>{i18n.t('screens:como.mainSectionHeader')}</h3>
                        </Col>
                    </Col>
                }
            </div >
        )
    }

    renderPromotionsBody() {

        const isLoggedInPromotionPartner = this.props.promotionPartners.isUserLoggedInPartner;
        const membershipStatus = this.props.como.membership ? this.props.como.membership.status : null;

        return (
            <div style={{ marginBottom:  this.state.rejectedRegistration ? 30 : 0}}>
                <PromotionPartnerAdapter 
                    sendInfoToShoppingCart={this.sendInfoToShoppingCart}
                    rejectedRegistrationOnClick={this.rejectedRegistrationOnClick}
                    rejectedRegistration={this.state.rejectedRegistration}
                    redirectNotVerifiedUser={this.redirectNotVerifiedUser}
                />
                { this.state.rejectedRegistration || (isLoggedInPromotionPartner && membershipStatus === 'Active') ?
                    <ShoppingCart
                        history={this.props.history}
                        isCheckoutScreen={false}
                        isPromotionsModalOpen={this.props.isPromotionsModalOpen}
                        comoInformation={this.state.comoInformation}
                    /> : null
                }
            </div>
        )
    }

    render() {
        const { menuBackground } = this.props.customerThemes.selectedTheme;
        const isMobileView = window.innerWidth <= 865;
        return (
            <div className='promotionsModal' style={isMobileView ? { overflowY: 'auto', overflowX: 'hidden', backgroundColor: menuBackground, height: '100vh' } : { overflowY: 'auto', overflowX: 'hidden', backgroundColor: menuBackground, maxHeight: '90vh', minWidth: '500px' }}>
                {this.renderPromotionsHeader()}
                {this.renderPromotionsBody()}
            </div>
        )
    }
}

const mapStateToProps = state => {

    return {
        brand: state.brand,
        promotionPartners: state.promotionPartners,
        customerThemes: state.customerThemes,
        payment: state.payment,
        customer: state.customer,
        shoppingCart: state.shoppingCart,
        pwaAppRunning: state.pwaAppRunning,
        upsellProducts: state.upsellProducts.upsellProducts,
        upsellMessage: state.upsellProducts.upsellMessage,
        products: state.restaurantProducts,
        categories: state.restaurantCategories,
        promotionPartners: state.promotionPartners,
        como: state.como,
        isPromotionsModalOpen: state.commonReducer.isPromotionsModalOpen,
        isShoppingCartModalOpen: state.commonReducer.isShoppingCartModalOpen,
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps, {
    togglePromotionsModal,
    toggleShoppingCartModal,
    resetShowComoError
})(PromotionsScreen);