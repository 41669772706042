import React, { Component } from 'react';
import { Col, Input } from 'reactstrap';
import i18n from '../../i18next';
import { isMobile } from 'mobile-device-detect';

import SearchSVG from '../../pwa-app/components/common/svgComponents/SearchSVG';
import { convertColorToRgba } from '../../utils/CommonUtils';

class ProductSearchBar extends Component {
    constructor() {
        super();

        this.state = {
            productName: '',
            matchingProducts: [],
            borderStyle: 'none',
            iOSDetected: false,
            isLoadedFromIntegration: false
        }

        this.autoSearch = this.autoSearch.bind(this);
        this.searchOnFocus = this.searchOnFocus.bind(this);
        this.searchOnBlur = this.searchOnBlur.bind(this);
    }

    styles = {
        mainCol: {
            display: 'flex',
            maxWidth: 1600,
            margin: 'auto',
            marginTop: 5,
            pointerEvents: 'none',
            padding: 0
        },
        mainColDesktopLargeImageDesign: {
            display: 'flex',
            justifyContent: 'flex-start',
            maxWidth: 'calc(76% - 10px)',
            marginTop: 5,
            pointerEvents: 'none',
            padding: 0
        },
        mainColDesktop: {
            display: 'flex',
            justifyContent: 'flex-start',
            maxWidth: '79%',
            marginTop: 5,
            pointerEvents: 'none',
            padding: 0
        },
        secondaryCol: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 5px 0 12px',
            pointerEvents: 'auto'
        },
        secondaryColRTL: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 12px 0 5px',
            pointerEvents: 'auto'
        },
        inputMain: {
            borderRadius: 0,
            paddingLeft: 38,
            minHeight: 38,
            maxHeight: 38
        },
        inputMainRTL: {
            borderRadius: 0,
            paddingRight: 38,
            minHeight: 38,
            maxHeight: 38
        },
        searchIcon: {
            position: 'absolute',
            top: 8,
            left: 18
        },
        searchIconRTL: {
            position: 'absolute',
            top: 8,
            right: 18,
            transform: 'scaleX(-1)'
        }
    }

    componentDidMount() {
        const { menuBackground } = this.props.customerThemes.selectedTheme;
        const iOSDetected = window.navigator.userAgent.match(/iPhone; CPU iPhone OS/) || window.navigator.userAgent.match(/CPU iPhone/) || window.navigator.userAgent.match(/iPad;/);
        let isLoadedFromIntegration = false;
        if (new URLSearchParams(window.location.search).get('brandId')) {
            isLoadedFromIntegration = true;
        }

        window.addEventListener('beforeunload', () => isMobile && this.props.toggleSearchProductFocus(false));

        this.setState({
            ...this.state,
            borderStyle: `1px solid ${menuBackground}`,
            iOSDetected,
            isLoadedFromIntegration
        })
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', () => isMobile && this.props.toggleSearchProductFocus(false));
        isMobile && this.props.toggleSearchProductFocus(false);
    }

    autoSearch({ target }) {
        const productName = target.value.toLowerCase();
        const productNameLength = productName.length;
        let matchingProducts = [];

        if (productNameLength >= 3) {
            // filter the products for everyone that has a match with the user input
            matchingProducts = this.props.products.filter((product) => {

                if (product.name.toLowerCase().includes(productName)) {
                    return product;
                } else if (product.description && product.description.toLowerCase().includes(productName)) {
                    return product;
                }
            });
        }

        this.setState({
            ...this.state,
            productName: target.value,
            matchingProducts
            // Send data to parent component to hide the menuu and categories
        }, () => this.props.sendDataToParent(productNameLength >= 3, matchingProducts));
    }

    searchOnFocus() {
        const { primaryColor } = this.props.customerThemes.selectedTheme;
        const { iOSDetected, isLoadedFromIntegration } = this.state;

        this.setState({
            ...this.state,
            borderStyle: `1px solid ${primaryColor}`
        })

        isMobile && this.props.toggleSearchProductFocus(true);

        if (iOSDetected && isLoadedFromIntegration && isMobile) {
            const menuNavigationContainer = document.getElementById('menuNavigationContainer');
            menuNavigationContainer.classList.add('stickyProductBarTop');
        }
    }

    searchOnBlur() {
        const { menuBackground } = this.props.customerThemes.selectedTheme;
        const { iOSDetected, isLoadedFromIntegration } = this.state;
        const menuNavigationContainer = document.getElementById('menuNavigationContainer');

        this.setState({
            ...this.state,
            borderStyle: `1px solid ${menuBackground}`
        })

        setTimeout(() => {
            isMobile && this.props.toggleSearchProductFocus(false);

            if (iOSDetected && isLoadedFromIntegration && isMobile) {
                this.handleIsNavBarInViewPort(menuNavigationContainer);
            }
        }, 500);
    }

    handleIsNavBarInViewPort(menuNavigationContainer) {
        const compareBoundingDiv = document.getElementById('compareBoundingDiv');
        let positionOfElementAboveMenuNavigationContainer = compareBoundingDiv.getBoundingClientRect().top;
        let inInViewPort = positionOfElementAboveMenuNavigationContainer >= 0;

        if (inInViewPort) {
            menuNavigationContainer.classList.remove('stickyProductBarTop');
        }
    }

    render() {
        let { mainCol, secondaryCol, secondaryColRTL, inputMain, inputMainRTL, searchIcon, searchIconRTL,  mainColDesktop, mainColDesktopLargeImageDesign } = this.styles;
        const { primaryColor, menuItemsBackground, primaryFontColor } = this.props.customerThemes.selectedTheme;
        const convertedColorToRgba = convertColorToRgba(menuItemsBackground, 0.6)


        let extraStyles = this.state.productName.length >= 3 ? { position: 'relative', top: -5 } : {};
        let inputSecondary = this.state.productName.length >= 3 ? { border: this.state.borderStyle } : { border: this.state.borderStyle };

        // const numberOfColumns = isMobile && window.innerWidth <= 865 ? 12 : 8;

        if (isMobile && window.innerWidth <= 865) {

            mainCol = {
                ...mainCol,
                padding: this.state.productName.length >= 3 ? 0 : '5px 0 0 0',
                marginTop: 0
            };
            secondaryCol = {
                ...secondaryCol,
                padding: 0
            };
            secondaryColRTL = {
                ...secondaryCol,
                padding: 0
            };
            searchIcon = {
                ...searchIcon,
                left: 10
            };
            searchIconRTL = {
                ...searchIcon,
                right: 10
            };
        }

        return (
            <Col sm={12} className={!isMobile && !window.innerWidth <= 865 && this.props.enableLargeImageDesign ? 'productSearchBarCol' : null} style={!isMobile && !window.innerWidth <= 865 ? this.props.enableLargeImageDesign ? mainColDesktopLargeImageDesign : mainColDesktop : mainCol}>
                <Col sm={12} md={10} style={ this.props.isArabic ? { ...secondaryColRTL, ...extraStyles } : { ...secondaryCol, ...extraStyles }}>
            
                    <SearchSVG width={25} height={25} fill={primaryColor} style={this.props.isArabic ? Object.assign({},searchIconRTL, {transform: 'scaleX(-1)'})  : searchIcon} />

                    <Input
                        id='productSearch'
                        type='text'
                        name='productName'
                        autocomplete="off"
                        placeholder={i18n.t('screens:menusAndProductsScreen.searchProductInput')}
                        onChange={this.autoSearch}
                        onFocus={() => this.searchOnFocus()}
                        onBlur={() => this.searchOnBlur()}
                        value={this.state.productName}
                        style={this.props.isArabic ? { ...inputMainRTL, ...inputSecondary, ...{ background: convertedColorToRgba, color: primaryFontColor }} : { ...inputMain, ...inputSecondary, ...{ background: convertedColorToRgba, color: primaryFontColor }} }
                    />
                </Col>
            </Col>
        );
    }
}

export default ProductSearchBar;