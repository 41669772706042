import React from 'react';

const HamburgerButtonSVG = ({ width, height, color, stroke, strokeWidth, svgClicked }) => {
    // Viewbox values are the ones that move the svg in its plane
    const viewBoxValues = '0 0 24 24';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBoxValues}>
            {/* <path d="M0 0h24v24H0z" fill="none"/> */}
            <path fill={color} width={width} stroke={stroke} strokeWidth={strokeWidth} d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
        </svg>
    );
};

export default HamburgerButtonSVG;