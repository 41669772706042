import _ from "lodash";
import { calculateProductPrice } from './ProductWizardUtils';

export const transformGetProduct = (product) => {

    let transformedProduct = {
        id: (product.productId) ? product.productId : (product.id) ? product.id : null,
        name: (product.name) ? product.name : null,
        priority: (product.priority) ? product.priority : null,
        description: (product.description) ? product.description : null,
        mealDeal: (product.mealDeal) ? product.mealDeal : false,
        promotionFreeProduct: (product.promotionFreeProduct) ? product.promotionFreeProduct : false, //This is a default value.
        posId: (product.posId) ? product.posId : null,
        minimumQuantity: (product.minimumQuantity) ? product.minimumQuantity : null,
        maximumQuantity: (product.maximumQuantity) ? product.maximumQuantity : null,
        price: (product.price) ? product.price : 0,
        tax: (product.tax) ? product.tax : null,
        secondaryTax: product.secondaryTax,
        instruction: (product.instruction) ? {
            notes: product.instruction.notes
        } : null,
        questions: (product.questions) ? getTransformedQuestions(product.questions) : [],
        currentStep: 1, //not a request property. This is a default value for the product wizard.
        enableNext: false,  //not a request property. This is a default value for the product wizard.
        allergens: (product.allergens) ? product.allergens : [],
        dietaryInfo: (product.dietaryInfo) ? product.dietaryInfo : [],
        quantity: (product.quantity) ? product.quantity : null,
        freeToppingsCount: (product.freeToppingsCount) ? product.freeToppingsCount : 0,
        imagePath: (product.imagePath) ? product.imagePath : null,
        thumbnailPath: (product.thumbnailPath) ? product.thumbnailPath : null,
        deposits: product.deposits ? product.deposits : 0,
        nutrients: product.nutrients ? product.nutrients : []
    }

    return transformedProduct;
}

export const getTransformedQuestions = (unformattedQuestions) => {
    let formattedQuestions = [];
    unformattedQuestions.map((unformattedQuestion, index) => {

        let formattedQuestion = {
            id: unformattedQuestion.id,
            name: unformattedQuestion.name,
            priority: unformattedQuestion.priority,
            index: index,
            posId: unformattedQuestion.posId,
            posDefault: unformattedQuestion.posDefault,
            description: unformattedQuestion.description,
            minimumQuantity: unformattedQuestion.minimumQuantity,
            maximumQuantity: unformattedQuestion.maximumQuantity,
            multiIngredients: unformattedQuestion.multiIngredients,
            freeToppingsCount: unformattedQuestion.freeToppingsCount,
            orderByPriority: unformattedQuestion.orderByPriority,
            dependsOnQuestion: (unformattedQuestion.dependsOnQuestion) ? unformattedQuestion.dependsOnQuestion : [],
            dependsOnAnswers: (unformattedQuestion.dependsOnAnswers) ? unformattedQuestion.dependsOnAnswers : [],
            answerGroups: unformattedQuestion.answerGroups,
            answers: (unformattedQuestion.answers) ? getTransformedAnswers(unformattedQuestion.answers) : [],
            selectedAnswers: (unformattedQuestion.selectedAnswers) ? getTransformedAnswers(unformattedQuestion.selectedAnswers) : [{ id: -1, name: '' }], //not a request property
            activeTab: 1 //not a request property
        }

        formattedQuestions.push(formattedQuestion);
    });

    return formattedQuestions;
}

export const getTransformedAnswers = (unformattedAnswers) => {
    let formattedAnswers = [];

    unformattedAnswers.map(unformattedAnswer => {
        let formattedAnswer = {
            id: unformattedAnswer.id,
            name: unformattedAnswer.name,
            priority: unformattedAnswer.priority,
            posId: unformattedAnswer.posId,
            description: unformattedAnswer.description,
            price: unformattedAnswer.price,
            modifyPrice: unformattedAnswer.modifyPrice,
            originalProductPrice: unformattedAnswer.originalProductPrice,
            tax: unformattedAnswer.tax,
            secondaryTax: unformattedAnswer.secondaryTax,
            mealDealProduct: unformattedAnswer.mealDealProduct,
            answerGroup: unformattedAnswer.answerGroup,
            subQuestions: (unformattedAnswer.subQuestions) ? getTransformedQuestions(unformattedAnswer.subQuestions) : [],
            parentProductId: unformattedAnswer.parentProductId,
            allergens: unformattedAnswer.allergens,
            dietaryInfo: unformattedAnswer.dietaryInfo,
            freeToppingsCount: (unformattedAnswer.freeToppingsCount) ? unformattedAnswer.freeToppingsCount : 0,
            maximumQuantity: (unformattedAnswer.maximumQuantity) ? unformattedAnswer.maximumQuantity : 0,
            default: unformattedAnswer.default
        }

        formattedAnswers.push(formattedAnswer);
    });

    return formattedAnswers;
}

export const getPromotionProducts = (promotionProducts, orderedProducts) => { // applyPromotion response handling
    let transformedOrderProducts = orderedProducts.slice(0);
    let promotionProductsCopy = [...promotionProducts].filter(product => !product.mealDealProduct && product); // remove meal deal products from the array
    let foundInState = false;

    orderedProducts.map(orderProduct => {
        if (orderProduct.promotionFreeProduct) {
            return foundInState = true;
        }
    });

    if (promotionProductsCopy.length >= orderedProducts.length) {
        promotionProductsCopy.map((promoProduct, i) => {

            if (promoProduct.promotionFreeProduct && foundInState === false) {
                transformedOrderProducts.splice(i, 0, transformGetProduct(promoProduct));
                if (promoProduct.sizeName) {
                    transformedOrderProducts[i].sizeName = promoProduct.sizeName;
                }
            } else if (promoProduct.promotionFreeProduct && foundInState) {
                let updatedProduct = orderedProducts[i];

                if (promoProduct.productId !== updatedProduct.id) {
                    updatedProduct = transformGetProduct(promoProduct);
                }

                if (promoProduct.sizeName) {
                    updatedProduct.sizeName = promoProduct.sizeName;
                }

                if (promoProduct.quantity !== updatedProduct.quantity) {
                    updatedProduct.quantity = promoProduct.quantity;
                }

                transformedOrderProducts.splice(i, 1, updatedProduct);//transformGetProduct(promoProduct));
            }
        });
    } else if (promotionProductsCopy.length < orderedProducts.length) {
        transformedOrderProducts = transformedOrderProducts.filter((product) => {
            if (!product.promotionFreeProduct) {
                return product
            }
        });
        promotionProductsCopy.map((promoProduct, i) => {
            if (promoProduct.promotionFreeProduct) {
                transformedOrderProducts.splice(i, 0, transformGetProduct(promoProduct))
            }
        })
    }

    return transformedOrderProducts;
}

export const getAppliedPromotions = (appliedPromotions) => {
    let promotions = [];

    appliedPromotions.map(promotion => {
        let updatedPromotion = {
            promotion: {
                id: promotion.promotionId
            },
            promoCode: promotion.promoCode,
            totalDiscount: promotion.totalDiscount,
            name: promotion.promotionName
        }

        promotions.push(updatedPromotion);
    });

    return promotions;
}

export const transformShoppingCartProducts = (shoppingCartProducts, allowToppingSubstitution) => {
    const orderedProducts = [];
    let transformedOrderProducts = [];

    shoppingCartProducts.map((product, index) => {
        if (product.mealDeal) {
            orderedProducts.push(transformMealDealProducts(product, allowToppingSubstitution));
        } else {
            orderedProducts.push(transformRegularProducts(product, allowToppingSubstitution));
        }
    });

    for (let i = 0; i < orderedProducts.length; i++) {
        for (let k = 0; k < orderedProducts[i].length; k++) {
            transformedOrderProducts.push(orderedProducts[i][k])
        }
    }

    return transformedOrderProducts;
}

export const transformRegularProducts = (product, allowToppingSubstitution) => {
    let productCopy = { ...product };

    const { id, name, description, instruction, price, quantity, tax, secondaryTax, questions, promotionFreeProduct } = productCopy;
    let transformedQuestions = [];
    questions.map(question => {
        let transformedAnswers = [];
        if (question.selectedAnswers) {
            question.selectedAnswers.map(answer => {
                let transformedAnswer = {
                    answer: { id: answer.id },
                    name: answer.name,
                    posId: answer.posId,
                    price: answer.price,
                    quantity: answer.count ? answer.count : 1
                }

                transformedAnswers.push(transformedAnswer);
            });
        }

        let transformedQuestion = {
            name: question.name,
            priority: question.priority,
            question: { id: question.id },
            orderAnswers: transformedAnswers
        }

        transformedQuestions.push(transformedQuestion);
    });

    let transformedProduct = {
        name,
        description,
        instruction,
        price: calculateProductPrice(allowToppingSubstitution, price, 1, product),  //QTY is always one because price * qty is calculated in the backend
        quantity,
        tax,
        secondaryTax,
        product: { id },
        orderQuestions: transformedQuestions
    };

    if (promotionFreeProduct) {
        transformedProduct.promotionFreeProduct = promotionFreeProduct;
    }

    return [transformedProduct]; // for some reason it should be returned as an ARRAY
}

export const transformMealDealProducts = (product, allowToppingSubstitution) => {
    let promotionProduct = product;
    let filteredProductsInPromotion = [];

    let filteredPromotionObject = _.pick(product, ['id', 'mealDeal', 'mealDealProduct', 'quantity', 'name', 'price', 'tax', 'secondaryTax', 'notes', 'index', 'productIndex']);
    // Get the data for the promotion and format it for server

    let resultPromotionObject = {
        product: {
            id: filteredPromotionObject.id
        },
        mealDeal: filteredPromotionObject.mealDeal,
        mealDealProduct: false,
        quantity: filteredPromotionObject.quantity,
        name: filteredPromotionObject.name,
        price: calculateProductPrice(allowToppingSubstitution, filteredPromotionObject.price, 1, product), //QTY is always one because price * qty is calculated in the backend
        tax: filteredPromotionObject.tax,
        secondaryTax: filteredPromotionObject.secondaryTax,
        instruction: (filteredPromotionObject.instruction) ? {
            notes: filteredPromotionObject.instruction.notes
        } : null,
        orderQuestions: [],
    };

    filteredProductsInPromotion.push(resultPromotionObject);

    // Prepare the data for the Products in the promotion
    promotionProduct.questions.map((question) => {

        let filteredQuestion = _.pick(question, ['id', 'mealDeal', 'mealDealProduct', 'quantity', 'name', 'price', 'tax', 'secondaryTax', 'notes', 'selectedProduct']);
        let filteredOrderQuestions = [];
        if (filteredQuestion.selectedProduct.subQuestions) {
            filteredQuestion.selectedProduct.subQuestions.map((subQuestion) => {

                let filteredSubQuestion = _.pick(subQuestion, ['id', 'name', 'selectedAnswers']);

                let filteredOrderAnswers = [];
                if (filteredSubQuestion.selectedAnswers) {

                    filteredSubQuestion.selectedAnswers.map((answer) => {
                        if (answer.id != -1) {
                            let filteredAnswer = {
                                answer: {
                                    id: answer.id
                                },
                                quantity: (answer.count) ? answer.count : 1,
                                name: answer.name,
                                price: answer.price,
                                posId: answer.posId,
                            };

                            filteredOrderAnswers.push(filteredAnswer);
                        }
                    });

                    let resultSubQuestion = {
                        question: {
                            id: filteredSubQuestion.id
                        },
                        name: filteredSubQuestion.name,
                        orderAnswers: filteredOrderAnswers.filter(orderAnswer => orderAnswer)
                    };

                    filteredOrderQuestions.push(resultSubQuestion);
                }

            });
        }

        let resultProductFromPromotion = {
            product: {
                id: filteredQuestion.selectedProduct.id
            },
            mealDeal: true,
            mealDealProduct: filteredQuestion.selectedProduct.mealDealProduct,
            quantity: 1,
            name: filteredQuestion.selectedProduct.name,
            price: filteredQuestion.selectedProduct.price,
            originalProductPrice: filteredQuestion.selectedProduct.originalProductPrice,
            tax: filteredQuestion.selectedProduct.tax,
            secondaryTax: filteredQuestion.selectedProduct.secondaryTax,
            instruction: (filteredQuestion.selectedProduct.instruction) ? {
                notes: filteredQuestion.selectedProduct.instruction.notes
            } : null,
            parentProductId: filteredQuestion.selectedProduct.parentProductId,
            orderQuestions: filteredOrderQuestions.filter(orderQuestion => orderQuestion),
            questionId: question.id
        };

        filteredProductsInPromotion.push(resultProductFromPromotion);
    });
    return filteredProductsInPromotion;
}

export const transformProductsFromOrderHistory = (product, selectedRestaurantData) => {
    // const generalWizardProduct = !product.mealDeal && !checkIfProductIsPizza(product);
    let productCopy = { ...product };
    // console.log("producCOPY ", productCopy, ' product ', product);
    productCopy.questions = transformProductQuestions(product.combineQuestions, product.mealDeal);
    // console.log("producCOPY 2", productCopy);
    delete productCopy.combineQuestions;

    return productCopy;
};

const transformProductQuestions = (combineQuestions, mealDeal) => {
    const combineQuestionsCopy = [...combineQuestions];
    const transformedQuestions = [];

    combineQuestionsCopy.map(combineQuestion => {
        let combineQuestionCopy = { ...combineQuestion };
        if (mealDeal) {
            combineQuestionCopy.selectedProduct = transformProductSubQuestions(combineQuestionCopy.selectedAnswers[0], combineQuestionCopy.answers);
            delete combineQuestionCopy.selectedAnswers;
        } else {
            combineQuestionCopy.selectedAnswers = combineQuestion.selectedAnswers;
        }

        transformedQuestions.push(combineQuestionCopy);
    });

    return transformedQuestions;
}

const transformProductSubQuestions = (selectedProduct, originalProductsArr) => {
    const selectedProductCopy = { ...selectedProduct };
    let originalAnswerProduct = {};
    originalProductsArr.map(originalProduct => {
        if (originalProduct.id === selectedProductCopy.id) {
            originalAnswerProduct = { ...originalProduct };
        }
    });

    if (originalAnswerProduct.subQuestions) {
        selectedProductCopy.subQuestions = addSelectedAnswers(originalAnswerProduct.subQuestions, selectedProductCopy.combinedSubQuestions);
    }

    if (selectedProductCopy.combinedSubQuestions) {
        delete selectedProductCopy.combinedSubQuestions;
    }

    return selectedProductCopy;
}

const addSelectedAnswers = (subQuestions, combinedSubQuestions) => {
    if (combinedSubQuestions.length) {
        subQuestions.map(subQuestion => {
            combinedSubQuestions.map(combinedQuestion => {
                if (subQuestion.id === combinedQuestion.id) {
                    subQuestion.selectedAnswers = combinedQuestion.selectedAnswers ? combinedQuestion.selectedAnswers : [];
                }
            });
        });
    } else {
        subQuestions.map(subQuestion => {
            if (subQuestion.posDefault) {
                subQuestion.selectedAnswers = subQuestion.answers;
            }
        });
    }

    return subQuestions;
}