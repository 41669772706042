import {CLEAR_APP_STATE, SAVE_MAP_ZOOM} from '../../../actions/types';

const INITIAL_STATE = 15;

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SAVE_MAP_ZOOM:
            return action.payload;
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
