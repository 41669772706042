import axios from '../../../utils/AxiosUtils';
import i18n from '../../../i18next';

import {
    COLLECTION_ID,
    DELIVERY_ID
} from '../../../utils/Constants';
import {
    GET_CUSTOMER_SAVED_ADDRESSES_SUCCESS,
    GET_CUSTOMER_SAVED_RESTAURANTS_SUCCESS,
    LOAD_RESTAURANTS_IN_ZONE_SUCCESS,
    RESET_CUSTOMER_SAVED_ADDRESSES_SUCCESS,
    RESET_CUSTOMER_SAVED_RESTAURANTS_SUCCESS,
    LOG_OUT_CUSTOMER,
    SELECT_ORDER_TYPE,
    SAVE_SELECTED_RESTAURANT,
    SAVE_CUSTOMER_DELIVERY_ADDRESS,
    SAVE_RESTAURANT_WORKING_TIME,
    GET_BRAND_DATA_FROM_RESTAURANT_SUCCESS,
    SAVE_SELECTED_RESTAURANT_PREVIEW_MODE,
    GET_ORDER_TYPES_SUCCESS,
    SAVE_ORDER_TIME_DETAILS,
    SHOW_ERROR_DIALOG,
    LOADING_NO_BACKGROUND,
    SAVE_RESTAURANT_SERVICE_FEE_PREVIEW_MODE,
    SAVE_ORDER_TIME_TAX_FLAG_PREVIEW_MODE,
    SAVE_SELECTED_RESTAURANT_TOPPING_SUBSTITUTION_FLAG_PREVIEW_MODE,
    OPEN_SENDING_ORDER_MODAL,
    CLEAR_STATE,
    GET_PROMOTION_PARTNER
} from '../../types';
import { push } from "connected-react-router";

export const loadRestaurantsInZone = (brandId, queryParams, callback) => {
    return (dispatch, getState) => {

        dispatch({ type: LOADING_NO_BACKGROUND, payload: true });
        const { skipLocationStep } = getState().brand;
        let queryLatitude = queryParams.latitude ? `latitude=${queryParams.latitude}` : '';
        let queryLongitude = queryParams.longitude ? `&longitude=${queryParams.longitude}` : '';
        let queryAddress = queryParams.address ? `&address=${queryParams.address}` : '';
        let queryOrderType = queryParams.orderType.value === 'Curbside' ? `&orderType=${queryParams.orderType.value}` : '';
        const params = skipLocationStep ? `distance?${queryOrderType.substr(1)}` : `distance?${queryLatitude}${queryLongitude}${queryAddress}${queryOrderType}`;

        const url = `/restaurant-chain/${brandId}/restaurants/${params}`;

        axios(getState).get(url)
            .then(response => loadRestaurantsInZoneSuccess(dispatch, response, callback))
            .catch((_) => {

                dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
                dispatch({ type: SHOW_ERROR_DIALOG, payload: true });
            });
    };
};

const loadRestaurantsInZoneSuccess = (dispatch, response, callback) => {

    dispatch({ type: LOAD_RESTAURANTS_IN_ZONE_SUCCESS, payload: response.data });
    callback();
};

export const loadCustomerSavedDeliveryAddresses = () => {
    return (dispatch, getState) => {

        doLoadCustomerSavedDeliveryAddresses(dispatch, getState);
    };
};

export const doLoadCustomerSavedDeliveryAddresses = (dispatch, getState, isCustomerLoggedIn) => {

    const customerId = getState().customer.id;
    const restaurantChainId = getState().brand.id;

    const url = `/customer/${customerId}/restaurants/delivery?restaurantChainId=${restaurantChainId}`;

    axios(getState).get(url)
        .then(response => loadCustomerSavedDeliveryAddressesSuccess(dispatch, response, isCustomerLoggedIn))
        .catch((error) => {
            console.log(error);
            dispatch({ type: SHOW_ERROR_DIALOG, payload: true });
        });
}

const loadCustomerSavedDeliveryAddressesSuccess = (dispatch, response, isCustomerLoggedIn) => {

    dispatch({ type: GET_CUSTOMER_SAVED_ADDRESSES_SUCCESS, payload: response.data });

    dispatch({ type: SELECT_ORDER_TYPE, payload: { value: 'Delivery', id: DELIVERY_ID } });

    if (isCustomerLoggedIn) {
        if (response.data.length > 0) {
            dispatch(push(`/start-order/saved-addresses`));
        } else {
            dispatch(push(`/start-order/user-address`));
        }
    }
};

export const resetCustomerSavedDeliveryAddresses = (callback) => {
    return (dispatch) => {

        dispatch({ type: RESET_CUSTOMER_SAVED_ADDRESSES_SUCCESS });
        callback();
    }
};

export const loadCustomerSavedRestaurants = () => {
    return (dispatch, getState) => {

        doLoadCustomerSavedRestaurants(dispatch, getState);
    };
};

export const doLoadCustomerSavedRestaurants = (dispatch, getState, isCustomerLoggedIn) => {

    let customerId = getState().customer.id;
    const restaurantChainId = getState().brand.id;
    const { skipLocationStep } = getState().brand;

    const url = `/customer/${customerId}/restaurants/collection?restaurantChainId=${restaurantChainId}`;

    axios(getState).get(url)
        .then(response => loadCustomerSavedRestaurantsSuccess(dispatch, response, isCustomerLoggedIn, skipLocationStep))
        .catch(_ => resetCustomerSavedRestaurants(() => loadCustomerSavedRestaurants()));
};

export const loadCustomerSavedRestaurantsSuccess = (dispatch, response, isCustomerLoggedIn, skipLocationStep) => {

    dispatch({ type: GET_CUSTOMER_SAVED_RESTAURANTS_SUCCESS, payload: response.data });
    dispatch({ type: SELECT_ORDER_TYPE, payload: { value: 'Collection', id: COLLECTION_ID } });

    if (isCustomerLoggedIn) {
        if (response.data.length > 0) {
            dispatch(push(`/start-order/saved-addresses`));
        } else {
            if (skipLocationStep) {
                dispatch(push('/start-order/restaurant-selection'));
            } else {
                dispatch(push(`/start-order/user-address`));
            }
        }
    }
};

export const resetCustomerSavedRestaurants = (callback) => {
    return (dispatch) => {

        dispatch({ type: RESET_CUSTOMER_SAVED_RESTAURANTS_SUCCESS });

        if (callback) {
            callback();
        }
    }
};

export const loadOrderTypeData = (orderType, calledFromPwa) => {
    return (dispatch, getState) => {

        const selectedOrderType = orderType.value.toLowerCase().replace(' ', '');
        const customerId = getState().customer.id;
        const { skipLocationStep } = getState().brand;

        if (customerId && !calledFromPwa) {
            switch (selectedOrderType) {
                case 'delivery':
                    doLoadCustomerSavedDeliveryAddresses(dispatch, getState, true);
                    break;
                case 'collection':
                    doLoadCustomerSavedRestaurants(dispatch, getState, true);
                    break;
                case 'dinein':
                    dispatch(push(`/start-order/user-address`));
                    break;
                case 'curbside':
                    dispatch({ type: SELECT_ORDER_TYPE, payload: orderType });
                    dispatch(push(`/start-order/car-details`));
                    break;
                default:
            }
        } else if (!calledFromPwa) {
            dispatch({ type: SELECT_ORDER_TYPE, payload: orderType });
            if (skipLocationStep && selectedOrderType === 'collection') {
                dispatch(push('/start-order/restaurant-selection'))
            } else if (selectedOrderType === 'curbside') {
                dispatch(push(`/start-order/car-details`));
            } else {
                dispatch(push(`/start-order/user-address`));
            }
        } else if (calledFromPwa) {

            dispatch({ type: SELECT_ORDER_TYPE, payload: orderType });

            if (orderType.value === 'Delivery' && calledFromPwa.calledFrom === 'MainScreen') {
                dispatch(push(`/brand/${calledFromPwa.brandId}/start-order/confirm-address`));
            } else if (orderType.value === 'Delivery' && calledFromPwa.calledFrom === 'ConfirmAddress') {
                //Commented because the url is already pushed from the ConfirmAddressPWA component
                // dispatch(push(`/brand/${calledFromPwa.brandId}/start-order/order-time`));
            } else if (orderType.value === 'Curbside') {
                dispatch(push(`/brand/${calledFromPwa.brandId}/start-order/car-details`));
            } else {
                dispatch(push(`/brand/${calledFromPwa.brandId}/start-order/restaurant-selection`));
            }
        }
    };
};

export const loadRestaurantsInDeliveryArea = (restaurant, callback) => {
    return (dispatch) => {

        dispatch({ type: LOAD_RESTAURANTS_IN_ZONE_SUCCESS, payload: restaurant });
        callback();
    };
};

export const saveSelectedRestaurantData = (restaurant, callback) => {
    return (dispatch) => {

        dispatch({ type: SAVE_SELECTED_RESTAURANT, payload: restaurant });
        callback();
    };
};

export const selectedOrderTypeData = (orderType) => {
    return (dispatch) => {

        dispatch({ type: SELECT_ORDER_TYPE, payload: orderType });
    };
};

export const getRestaurantByDeliveryAddress = (address) => {
    return (dispatch, getState) => {

        const restaurantChainId = getState().brand.id;
        const url = `/restaurant-chain/${restaurantChainId}/restaurant/delivery?longitude=${address.longitude}&latitude=${address.latitude}&zipCode=${address.zipCode}`;

        axios(getState).get(url)
            .then(response => getRestaurantByDeliveryAddressSuccess(dispatch, response))
            .catch(_ => {

                dispatch({ type: SHOW_ERROR_DIALOG, payload: true });
            });
    }
};

const getRestaurantByDeliveryAddressSuccess = (dispatch, response) => {

    dispatch({ type: SAVE_SELECTED_RESTAURANT, payload: response.data });
    dispatch(push(`/start-order/order-time`));
};

export const saveCustomerDeliveryAddress = (address) => {
    return (dispatch) => {

        dispatch({ type: SAVE_CUSTOMER_DELIVERY_ADDRESS, payload: address });
    }
};

export const selectRestaurantWorkingTime = (restaurantWorkingTime) => {
    return (dispatch) => {

        dispatch({ type: SAVE_RESTAURANT_WORKING_TIME, payload: restaurantWorkingTime });
    }
};

export const logOutCustomer = () => {
    return (dispatch) => {

        dispatch({ type: LOG_OUT_CUSTOMER });
    }
};

export const getRestaurantById = (restaurantId) => {
    return (dispatch, getState) => {

        if (restaurantId !== undefined && restaurantId !== null) {

            const url = `/restaurant/${restaurantId}`;

            axios(getState).get(url)
                .then(response => getRestaurantByIdSuccess(dispatch, response, getState))
                .catch(_ => { });
        }
    }
};

const getRestaurantByIdSuccess = (dispatch, response, getState) => {

    const restaurantData = response.data;


    const brandData = {
        id: restaurantData.chain.id,
        city: restaurantData.address.city,
        countryCode: restaurantData.address.country,
        name: restaurantData.chain.name,
        orderingPage: restaurantData.chain.orderingPage
    }

    const restaurant = {
        name: restaurantData.name,
        currency: restaurantData.currency,
        distance: '123m',
        restaurantId: restaurantData.restaurantId,
        address: restaurantData.address,
        displayAddress: restaurantData.displayAddress,
        posType: restaurantData.posSettings.type,
        restaurantTimeZone: restaurantData.timeZone
    }

    const orderTypes = [
        { id: COLLECTION_ID, value: "Collection" },
        { id: DELIVERY_ID, value: "Delivery" }
    ];

    const orderTypesData = {
        orderTypes: orderTypes,
        bannerImagePath: restaurantData.chain.bannerImagePath
    };

    const estimatedOrderTime = {
        asap: "asap",
        date: null,
        estimateOrderTime: 15,
        futureOrder: false,
        selectTime: null,
        time: null,
        wantedTime: null,
        showMenuuLogo: getState().estimateOrderTime.showMenuuLogo
    };

    dispatch({ type: SAVE_SELECTED_RESTAURANT_PREVIEW_MODE, payload: restaurant });
    dispatch({ type: SELECT_ORDER_TYPE, payload: { value: 'Collection', id: COLLECTION_ID } });
    dispatch({ type: GET_ORDER_TYPES_SUCCESS, payload: orderTypesData });
    dispatch({ type: GET_BRAND_DATA_FROM_RESTAURANT_SUCCESS, payload: brandData });
    dispatch({ type: SAVE_ORDER_TIME_DETAILS, payload: estimatedOrderTime });
    dispatch(getMissingPreviewData(restaurantData.id));
};

export const getMissingPreviewData = (restaurantId) => {
    return (dispatch, getState) => {

        const url = `/restaurant/${restaurantId}/preview-data`;

        axios(getState).get(url)
            .then(response => getMissingPreviewDataSuccess(dispatch, response))
            .catch(_ => { dispatch({ type: SHOW_ERROR_DIALOG, payload: true }) });

    }
};

const getMissingPreviewDataSuccess = (dispatch, response) => {

    dispatch({ type: SAVE_SELECTED_RESTAURANT_TOPPING_SUBSTITUTION_FLAG_PREVIEW_MODE, payload: response.data.allowToppingSubstitution });
    dispatch({ type: SAVE_RESTAURANT_SERVICE_FEE_PREVIEW_MODE, payload: response.data.simpleBilling });
    dispatch({ type: SAVE_ORDER_TIME_TAX_FLAG_PREVIEW_MODE, payload: response.data });
    dispatch({ type: GET_PROMOTION_PARTNER, payload: response.data.promotionPartner });
};

export const getRestaurantPauseStatus = () => {
    return (dispatch, getState) => {

        const restaurantId = getState().selectedRestaurant.restaurantId;
        const url = `/restaurant/${restaurantId}/check-paused`;

        axios(getState).get(url)
            .catch((error) => {

                if (error.response.data.code === 500) {
                    const brandId = getState().brand.id;
                    const isPwa = getState().temporaryOrderTime.currentPagePath.includes('brand');

                    const sendingOrderModalData = {
                        orderStatusMessage: i18n.t(`screens:shoppingCartActions.pausedRestaurant`),
                        customerInfoMessage: i18n.t(`screens:shoppingCartActions.orderingNotAvailable`),
                        orderSucceed: false
                    }

                    dispatch({ type: OPEN_SENDING_ORDER_MODAL, payload: sendingOrderModalData });

                    setTimeout(() => {
                        if (isPwa) {
                            dispatch({ type: CLEAR_STATE, fromPwa: isPwa });
                            dispatch(push(`/brand/${brandId}/start-order/main-screen`));
                        } else {
                            dispatch({ type: CLEAR_STATE });
                            dispatch(push(`/start-order`));
                        }
                    }, 5000);
                }
            });
    }
};