import { sendMessageToParentSite } from "./CrossOriginUtils";

export const pushDataToGoogleTagManager = (eventType, eventValue, isApp, rawData, products, categories, currency, promoCode) => {
    if (isApp) {
        let event = '', data = {}, ecommerceData = {}, formattedProducts = [];
        if (eventValue === 'purchase') {
            event = eventValue;

            rawData.orderProducts.map(orderedProduct => {
                if (!orderedProduct.mealDealProduct) {
                    let formattedProduct = {
                        id: orderedProduct.productId,
                        name: orderedProduct.name,
                        price: orderedProduct.price.toFixed(2),
                        quantity: orderedProduct.quantity
                    }
                    formattedProducts.push(formattedProduct);
                }
            });
            data = {
                currency: rawData.currency,
                items: formattedProducts,
                tax: rawData.totalTax,
                transaction_id: rawData.id,
                value: Number((rawData.total + (rawData.serviceFee ? rawData.serviceFee : 0)).toFixed(2))
            }
            ecommerceData = {
                currency: data.currency,
                value: data.value,
                tax: data.tax,
                transaction_id: data.transaction_id
            }

            if (rawData.deliveryCharge) {
                data.shipping = rawData.deliveryCharge.toFixed(2);
                ecommerceData.shipping = rawData.deliveryCharge.toFixed(2);
            }

            if (rawData.promoCode) {
                ecommerceData.coupon = rawData.promoCode;
            }

            firebaseAnalyticsApps('ecommerce_purchase', ecommerceData);
        } else if (eventValue === 'checkout') {
            event = 'begin_checkout';
            data = {
                currency: currency,
                value: rawData.total.toFixed(2)
            }
            if (rawData.promoCode) {
                data.coupon = rawData.promoCode;
            }
        } else if (eventValue === 'view-item') {
            event = 'view_item';
            data = {
                item_id: rawData.id
            }
        } else if (eventValue === 'addToCart') {
            event = 'add_to_cart';
            data = {
                item_id: rawData.id,
                item_name: rawData.name,
                item_category: rawData.category,
                quantity: rawData.quantity,
                price: rawData.price.toFixed(2),
                currency: rawData.currency
            }
        } else if (eventValue === 'view_item_list') { // selecting a category
            event = 'view_item_list';
            data = {
                item_category: rawData.category,
            };
        }
        if (event) {
            firebaseAnalyticsApps(event, data);
        }
        // return window.dataLayer.push(objectToSend);
    } else {
        let formattedProducts = [], objectToSend = {};
        objectToSend = { [`${eventType}`]: eventValue };
        if (eventValue === 'purchase') {
            rawData.orderProducts.map(orderedProduct => {
                if (!orderedProduct.mealDealProduct) {
                    let formattedProduct = {
                        id: orderedProduct.productId,
                        name: orderedProduct.name,
                        price: orderedProduct.price.toFixed(2),
                        quantity: orderedProduct.quantity
                    }
                    formattedProducts.push(formattedProduct);
                }
            });

            objectToSend.ecommerce = {
                purchase: {
                    actionField: {
                        id: rawData.id,
                        revenue: Number((rawData.total + (rawData.serviceFee ? rawData.serviceFee : 0)).toFixed(2)),
                        tax: rawData.totalTax
                    },
                    products: formattedProducts
                }
            }

            if (rawData.deliveryCharge) {
                objectToSend.ecommerce.purchase.actionField.shipping = rawData.deliveryCharge.toFixed(2);
            }

            if (promoCode) {
                objectToSend.ecommerce.purchase.actionField.coupon = promoCode;
            }
        } else if (eventValue === 'view-item') {
            objectToSend.ecommerce = {
                click: {
                    actionField: {},
                    products: [
                        {
                            id: rawData.id,
                            name: rawData.name,
                            price: rawData.price.toFixed(2),
                            category: rawData.category
                        }
                    ]
                }
            }
        } else if (eventValue === 'addToCart') {
            objectToSend.ecommerce = {
                currencyCode: rawData.currency,
                add: {
                    products: [
                        {
                            id: rawData.id,
                            name: rawData.name,
                            price: rawData.price.toFixed(2),
                            category: rawData.category,
                            quantity: rawData.quantity
                        }
                    ]
                }
            }
        } else if (eventValue === 'removeFromCart') {
            objectToSend.ecommerce = {
                remove: {
                    products: [
                        {
                            id: rawData.id,
                            name: rawData.name,
                            price: rawData.price.toFixed(2),
                            category: rawData.category,
                            quantity: rawData.quantity
                        }
                    ]
                }
            }
        } else if (eventValue === 'checkout') {
            rawData.orderProducts.map(orderedProduct => {
                if (!orderedProduct.mealDealProduct) {
                    let formattedProduct = {
                        id: orderedProduct.id,
                        name: orderedProduct.name,
                        price: orderedProduct.price.toFixed(2),
                        quantity: orderedProduct.quantity,
                        category: getProductCategory(orderedProduct.id, products, categories)
                    }
                    formattedProducts.push(formattedProduct);
                }
            });

            objectToSend.ecommerce = {
                checkout: {
                    actionField: {
                        step: 1
                    },
                    products: formattedProducts
                }
            }
        } else if (eventValue === 'promotionClick') {
            objectToSend.ecommerce = {
                promoClick: {
                    promotions: [
                        {
                            id: rawData.id,
                            name: rawData.name
                        }
                    ]
                }
            }
        } else if (eventValue === 'impressions') {
            rawData.map(product => {
                let formattedProduct = {
                    id: product.id,
                    name: product.name,
                    category: getProductCategory(product.id, rawData, categories),
                    price: product.price,
                    position: product.priority
                }

                formattedProducts.push(formattedProduct);
            });

            objectToSend.ecommerce = {
                currencyCode: currency,
                impressions: formattedProducts
            }
        }
        // console.log('ANALYTICS UTILS ', objectToSend);
        sendMessageToParentSite(objectToSend);
    }
};

export const getProductCategory = (productId, restaurantProducts, restaurantCategories) => {
    let productCategoryName = null;
    restaurantProducts.map(restProduct => {
        if (productId === restProduct.id) {
            productCategoryName = getCategoryName(restProduct.categoryId, restaurantCategories);
        } else {
            if (restProduct.productSizesIds)
                restProduct.productSizesIds.map(sizeId => {
                    if (productId === sizeId) {
                        productCategoryName = getCategoryName(restProduct.categoryId, restaurantCategories);
                    }
                });
        }
    });

    return productCategoryName;
}

const getCategoryName = (categoryId, restaurantCategories) => {
    let categoryName = null;
    restaurantCategories.map(restCategory => {
        if (categoryId === restCategory.id) {
            categoryName = restCategory.name;
        }
    });

    return categoryName;
}

export const firebaseAnalyticsApps = (event, data) => {
    window.FirebasePlugin.logEvent(event, data);
}