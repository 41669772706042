import {
    TOGGLE_PROMO_PARTNER_MODAL,
    GET_PROMOTION_PARTNER,
    USER_LOGGED_IN_PARTNER,
    SET_USER_ANSWER
} from '../../types';

export const togglePromoPartnerModal = () => {
    return (dispatch) => {

        dispatch({ type: TOGGLE_PROMO_PARTNER_MODAL });
    };
};

export const getPromotionPartner = (partner) => {
    return (dispatch) => {
        dispatch({ type: GET_PROMOTION_PARTNER, payload: partner });
    };
};

export const isUserLoggedInPartner = () => {
    return (dispatch, getState) => {
        // TODO find a better way to handle this when more partners are added
        let isLoggedIn = false;

        if (getState().como && getState().como.membership) {
            isLoggedIn = true;
        }

        dispatch({ type: USER_LOGGED_IN_PARTNER, payload: isLoggedIn });
    };
};

export const setUserAnswer = (userAnswer) => {
    return (dispatch) => {

        dispatch({ type: SET_USER_ANSWER, payload: userAnswer});
    };
};