import { 
    APPLY_GIFTCARD_SUCCESS, 
    CLEAR_GIFT_CARDS_STATE, 
    CLEAR_STATE, 
    CLEAR_GIFT_CARDS_TOTAL_DISCOUNT,
    SHOW_ERROR_GIFT_CARDS_MESSAGE
 } from "../../actions/types";

const INITIAL_STATE = {
   giftCards: null,
   giftCardsTotalDiscount: null,
   total: null,
   errorMessage: null
};

export default (state = INITIAL_STATE, action) => {
    
    switch(action.type) {
        case APPLY_GIFTCARD_SUCCESS:
            return { 
                ...state,
                giftCards: action.payload,
                giftCardsTotalDiscount: (state.giftCardsTotalDiscount === 0 
                                        || state.giftCardsTotalDiscount === null 
                                        || (state.giftCardsTotalDiscount !== action.payload.totalDiscount) && (action.payload.totalDiscount !== 0) ) 
                                    ? action.payload.totalDiscount : state.giftCardsTotalDiscount,
                total: (state.total === 0 || state.total === null) ? action.payload.total : state.total,
            };
        case CLEAR_GIFT_CARDS_STATE:
            return  { 
                ...state,
                giftCards: state.giftCards
            };
        case SHOW_ERROR_GIFT_CARDS_MESSAGE:
            return { ...state, errorMessage: action.payload};
        case CLEAR_GIFT_CARDS_TOTAL_DISCOUNT:
            return INITIAL_STATE;
        case CLEAR_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};