import axios from 'axios';

import { SERVER_URL } from './Constants';

const getAxiosInstance = (getState) => {

        const language = getState && getState().storeLocalStorage.language ? getState().storeLocalStorage.language : 'en';
        const token =  getState && getState().customer && getState().customer.token ? getState().customer.token : '';

        const axiosInstance = axios.create({
            baseURL: SERVER_URL,
            headers: {
                'Accept-Language': `LANGUAGE${language}`,
                ...(token && { 'Authorization': `TOKEN${token}` })
            }
        });
    
        return axiosInstance;
}

export default getAxiosInstance;
