import {
    SELECT_BRAND_ID_SUCCESS,
    GET_BRAND_DATA_SUCCESS,
    GET_BRAND_NAME,
    CLEAR_STATE,
    GET_BRAND_DATA_FROM_RESTAURANT_SUCCESS,
    SELECT_BRAND_ID_FAILED,
    SAVE_IN_STORE_BRAND_DATA,
    CLEAR_APP_STATE
} from '../../../actions/types';

const INITIAL_STATE = {
    id: null,
    city: '',
    countryCode: '',
    name: '',
    orderingPage: '',
    invalidBrandId: false,
    skipLocationStep: false
};

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case SELECT_BRAND_ID_SUCCESS:
            return {...state, id: payload};
        case GET_BRAND_DATA_SUCCESS:
            return {
                ...state,
                city: payload.city,
                countryCode: payload.countryCode,
                name: payload.restaurantChainName,
                orderingPage: payload.orderingPage,
                skipLocationStep: payload.skipLocationStep
            };
        case GET_BRAND_NAME:
            return {
                ...state,
                name: payload.restaurantChainName
            };
        case GET_BRAND_DATA_FROM_RESTAURANT_SUCCESS:
            return {
                ...state,
                id: payload.id,
                city: payload.city,
                countryCode: payload.countryCode,
                name: payload.name,
                orderingPage: payload.orderingPage
            };
        case SAVE_IN_STORE_BRAND_DATA:
            return {
                ...state,
                id: payload.id,
                name: payload.name,
                countryCode: payload.countryCode
            };
        case SELECT_BRAND_ID_FAILED:
            return {...state, invalidBrandId: payload};
        case CLEAR_STATE:
            if (action.fromPwa) {
                return state;
            } else {
                return INITIAL_STATE;
            }
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
