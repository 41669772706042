import {
    CLEAR_STATE,
    ADD_PRODUCT_TO_OPENED_PRODUCTS, CLEAR_APP_STATE
} from '../../actions/types';

const INITIAL_STATE = [];

export default (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case ADD_PRODUCT_TO_OPENED_PRODUCTS:
            return action.payload;
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}