import {SHOW_ERROR_DIALOG, CLEAR_ERROR_DIALOG, CLEAR_APP_STATE} from '../actions/types';

const INITIAL_STATE = false;

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        // DO NOT show the error dialog
        // case SHOW_ERROR_DIALOG:
        //     return action.payload;
        // case CLEAR_ERROR_DIALOG:
        //     return INITIAL_STATE;
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};