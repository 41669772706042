export const initiateAppDevice = (brandId, callback) => {
    let uuid = window.device.uuid;
    let platform = window.device.platform;

    window.FirebasePlugin.setAnalyticsCollectionEnabled(true);
    window.FirebasePlugin.getToken(function (token) {
        console.log("this is the token: ", token);
        callback(brandId, token, uuid, platform);
    }, function (error) {
    });

    window.FirebasePlugin.onMessageReceived(function (message) {
        if (message.messageType === "notification") {
            if (message.tap) {
                console.log("Tapped in ",message.tap);
            }
        } else if (message.messageType == "data") {
             message = {
                body: message.body,
                collapse_key: message.collapse_key,
                from: message.from,
                id: message.id,
                messageType: "notification",
                notification_foreground: message.notification_foreground,
                sent_time: message.sent_time,
                show_notification: message.show_notification,
                title: message.title,
                ttl: message.ttl
            }
        }
    }, function (error) {
        console.error('error',error);
    });
};

export const allowIOSNotifications = (callback) => {
    let platform = window.device.platform;
    if (platform === 'iOS') {
        window.FirebasePlugin.hasPermission(function (isEnabled) {//data => isEnabled
            if (!isEnabled) {//!data.isEnabled => !isEnabled
                window.FirebasePlugin.grantPermission(function (status) {
                    window.FirebasePlugin.getToken(function (token) {
                        // console.log("this is the token: ", token);
                    });
                    callback();
                }, function (error) {
                    callback();
                });
            } else {
                callback();
            }
        }, function (error) {
            callback();
        });
    } else {
        callback();
    }
};
