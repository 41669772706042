import {ALLOW_TIME_WARNING, DISABLE_TIME_WARNING, CLEAR_APP_STATE} from '../actions/types';

const INITIAL_STATE = {
    isTimeWarningAllowed: false
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case ALLOW_TIME_WARNING:
            return {
                ...state,
                isTimeWarningAllowed: true
            };
        case DISABLE_TIME_WARNING:
            return {
                ...state,
                isTimeWarningAllowed: false
            };
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};