import {CLEAR_APP_STATE, CLEAR_STATE, LOAD_MARKETING_PREFERENCES_SUCCESS} from "../../actions/types";

const INITIAL_STATE = {};

export default (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case LOAD_MARKETING_PREFERENCES_SUCCESS:
            return action.payload;
        case CLEAR_STATE:
            return INITIAL_STATE;
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
