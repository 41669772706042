import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import MaterialIcon from 'material-icons-react';
import i18n from '../../i18next';
import { isMobile } from 'mobile-device-detect';

import ModalStep from '../common/ModalStep';

import { submitLogin, resetPhoneNumberErrMsg, forgottenEmail, redirectToUserCredentialsPath, redirectToForgottenEmailPath, resetErrMsg, resetLoginConfirmation, resetForgottenEmailConfirmation, openStartOrderScreen } from '../../actions';
import PhoneNumberCommon from '../../pwa-app/components/profile/login/PhoneNumberCommon';
import UserIconSVG from '../../pwa-app/components/common/svgComponents/UserIconSVG';
import { ARABIC } from '../../utils/Constants';

class PhoneNumberScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            phone: null,
            isPhoneTyped: false,
            countryCodeCoppiedToPhoneInput: false,
            submitDisabled: true
        };

        this.goBack = this.goBack.bind(this);
        // this.changeState = this.changeState.bind(this);
    }

    goBack() {
        if (this.props.isCheckoutLogin) {
            const path = '/menu-screen';
            this.props.openStartOrderScreen(path);
        } else {
            this.props.history.goBack();
        }
    }

    componentWillMount() {
        this.applyTheme();
        
        if (this.props.customer.isLoginConfirmation) {
            this.props.resetLoginConfirmation();
        } else if (this.props.customer.isForgottenEmailConfirmation) {
            this.props.resetForgottenEmailConfirmation();
        }
    }

    componentWillUnmount() {
        this.props.resetErrMsg()
    }

    componentDidMount() {
        document.getElementsByClassName('modal-header')[0].remove();

        const isMobileView = window.outerWidth <= 800;
        if (isMobileView) {
            document.getElementsByClassName('modal-body')[0].style.minHeight = '480px';
        }
    }

    submitLogin() {
        if (this.state.isPhoneTyped) {
            return this.props.submitLogin(this.state.phone, this.props.isOrderDone);
        }
    }

    renderModalHeader() {
        const { headerColumn, imageStyle, imageStyleRTL, loginTextStyle, loginTextStyleRTL } = styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;

        let isHidden = false;
        const isArabic = this.props.language === ARABIC;

        if (this.props.hideBackButton) {
            isHidden = true;
        }

        // Render the close button for start order modal on this screen when opening it from menuScreen
        let closeButtonShowClass = '';
        if (this.props.isOrderDone) closeButtonShowClass = ' from-menu-screen';

        return (
            <Fragment>
                <div className='start-order-header-container'>
                    <Button id="phoneScreenBackBtn" className="start-order-custom-back-button" disabled={isHidden} onClick={() => this.goBack()} style={(!isHidden) ? headerColumn : { marginRight: 10, height: '0 !important' }}>
                        <span hidden={isHidden} className="backButtonIcon"><MaterialIcon icon="navigate_before" color="#000" size={32} /></span>
                        <span hidden={isHidden} style={{ fontWeight: 300 }}>{i18n.t('common:buttons.backBtn')}</span>
                    </Button>
                    <div style={{ margin: '0 auto', fontSize: 20, textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                        <UserIconSVG width={23} height={23} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? imageStyleRTL : imageStyle} />
                        <span style={isArabic ? loginTextStyleRTL : loginTextStyle}>{i18n.t('screens:phoneNumberScreen.login')}</span>
                    </div>
                    <div style={{ width: 90, textAlign: isArabic ? 'left' : 'right' }}>
                        <Button className={`start-order-custom-close-button ${closeButtonShowClass}`} onClick={() => { this.props.toggle() }}>
                            <MaterialIcon icon="close" color="#000" size={18} />
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    }

    renderModalBody() {
        
        return (
            <React.Fragment>
                {this.renderModalHeader()}
                <PhoneNumberCommon
                    {...this.props}
                    styles={styles}
                    customerThemes={this.props.customerThemes}
                    customer={this.props.customer}
                    brand={this.props.brand}
                    submitLogin={this.props.submitLogin}
                    isPWA={false}
                    isOrderDone={this.props.isOrderDone}
                    forgottenEmail={this.props.forgottenEmail}
                    redirectToUserCredentialsPath={this.props.redirectToUserCredentialsPath}
                    redirectToForgottenEmailPath={this.props.redirectToForgottenEmailPath}
                    resetErrMsg={this.props.resetErrMsg}
                />
            </React.Fragment>
        );
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { primaryColor, textDecoration, fontWeight, startOrderFontSize, mainTextColor, chefNoteBackground, primaryFontColor } = this.props.customerThemes.selectedTheme;
        customizedStyles.heading.fontSize = startOrderFontSize;
        customizedStyles.subHeading.color = mainTextColor;
        customizedStyles.input.fontWeight = fontWeight;
        customizedStyles.submitBtn.backgroundColor = primaryColor;
        customizedStyles.subMsg.color = mainTextColor;
        customizedStyles.termsAndConditions.color = primaryColor;
        customizedStyles.termsAndConditions.textDecoration = textDecoration;
        customizedStyles.input.backgroundColor = chefNoteBackground;
        customizedStyles.loginTextStyle.color = primaryFontColor;
        customizedStyles.loginTextStyleRTL.color = primaryFontColor;
        styles = customizedStyles;
        return styles;
    }

    render() {
        const startOrderStyles = isMobile ? { overflowY: 'auto', overflowX: 'hidden', height: window.innerHeight } : {}

        return (
            <AvForm className="start-order phoneNumberScreen" style={startOrderStyles}>
                <ModalStep
                    onToggle={this.props.toggle}
                    // renderHeader={this.renderModalHeader()}
                    renderBody={this.renderModalBody()}
                    // renderFooter={this.renderModalFooter()}
                    styles={styles}
                    customerThemes={this.props.customerThemes}
                />
            </AvForm>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        brand: state.brand,
        orderTypes: state.orderTypes,
        customer: state.customer,
        customerThemes: state.customerThemes,
        language: state.storeLocalStorage.language,
        startOrder: state.startOrder
    };
};

export default connect(mapStateToProps, { 
    submitLogin, 
    resetPhoneNumberErrMsg, 
    forgottenEmail, 
    redirectToUserCredentialsPath, 
    redirectToForgottenEmailPath, 
    resetErrMsg,
    resetLoginConfirmation,
    resetForgottenEmailConfirmation,
    openStartOrderScreen 
})(PhoneNumberScreen);


let styles = {
    modalBody: {
        textAlign: 'center',
        padding: 0,
        minHeight: '480px',
        backgroundColor: '#ffffff'
    },
    modalFooter: {},
    heading: {
        fontSize: 22,
        margin: 'auto',
        textAlign: 'center'
    },
    subHeading: {
        fontSize: 14,
        color: '#939393',
        paddingBottom: 12,
        margin: 'auto'
    },
    login: {
        fontSize: 22,
        marginLeft: 30,
        marginRight: 12
    },
    loginTextStyle: {
        marginLeft: -10,
        verticalAlign: 'middle'
    },
    loginTextStyleRTL: {
        marginRight: -10,
        verticalAlign: 'middle'
    },
    centredItem: {
        margin: '0 auto',
        fontSize: 20,
        textAlign: 'center',
        paddingRight: 55
    },
    inputGroup: {
        margin: 'auto',
        height: 50,
        marginBottom: 10
    },
    input: {
        height: 50,
        // borderRadius: 4,
        backgroundColor: '#EFEFEF',
        textAlign: 'center'
    },
    submitBtn: {
        marginBottom: 12,
        height: 50,
        borderRadius: 0,
        minWidth: '100%',
        backgroundColor: '#61B522',
        border: 'none'
    },
    submitBtnDisabled: {
        marginBottom: 12,
        height: 50,
        borderRadius: 0,
        minWidth: '100%',
        backgroundColor: '#a8a8a8',
        border: 'none'
    },
    subMsg: {
        fontSize: 14,
        color: '#9a9a9a',
        marginBottom: 5
    },
    termsAndConditions: {
        color: "#61B522",
        cursor: 'pointer'
    },
    headerColumn: {
        // paddingLeft: 0,
        // width: 72
    },
    footerColOne: {
        display: 'flex',
        justifyContent: 'center'
    },
    footerColTwo: {
        textAlign: 'center'
    },
    bodyColThree: {
        margin: 'auto'
    },
    phoneIconStyle: {
        position: 'absolute',
        padding: '8px 5px',
        marginLeft: 75,
        zIndex: 1
    },
    phoneIconStyleRTL: {
        position: 'absolute',
        padding: '8px 5px',
        marginRight: 70,
        zIndex: 1
    },
    checkIconStyle: {
        position: 'absolute',
        padding: 4,
        marginLeft: -30,
        zIndex: 1,
        marginTop: 10
    },
    checkIconStyleRTL: {
        position: 'absolute',
        padding: 4,
        marginRight: -30,
        zIndex: 1,
        marginTop: 10
    },
    imageStyle: {
        marginRight: 16
    },
    imageStyleRTL: {
        marginLeft: 16
    },
    modalCustomSubmitBody: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '50px'
    },
    customBodyInnerContent: {
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1
    }
}