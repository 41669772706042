import React, { Component } from 'react';

import { GOOGLE_API_KEY } from '../../../../utils/Constants';
import savedAddressMarker from '../../../../images/icons/pwa-saved-user-addresses.png';

class Map extends Component {
    constructor(props) {
        super(props);

        this.mapRef = React.createRef();
        this.initMap = this.initMap.bind(this)
    }

    componentDidMount() {
        if (!window.google || (window.google && window.google.accounts)) {
            const script = document.createElement('script');
            script.defer = 'defer';
            script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&callback=initMap&libraries=geometry&language=en`;

            document.body.appendChild(script);

            script.addEventListener('load', e => {
                window.initMap = this.initMap();
            });
        } else {
            window.initMap = this.initMap();
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.customerSavedAddresses !== prevProps.customerSavedAddresses) && this.props.pwaAppRunning) {
            if (!window.google || (window.google && window.google.accounts)) {
                const script = document.createElement('script');
                script.defer = 'defer';
                script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&callback=initMap&libraries=geometry&language=en`;
    
                document.body.appendChild(script);
    
                script.addEventListener('load', e => {
                    window.initMap = this.initMap();
                });
            } else {
                window.initMap = this.initMap();
            }
        }
    }

    initMap() {
        let map = null;

        if (this.props.useGeocoder && this.props.useGeocoder === 'DECLINED') {

            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ 'address': this.props.brandCity ? this.props.brandCity : this.props.brandCountry }, (results, status) => {
                if (status === window.google.maps.GeocoderStatus.OK) {

                    const positionFromGoogleApi = {
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng()
                    };

                    this.props.handleAddressData(results, positionFromGoogleApi);

                    map = new window.google.maps.Map(document.getElementById('pwaMainScreenMap'), {
                        ...this.props.options,
                        center: positionFromGoogleApi
                    });

                    // Add user saved addresses to map
                    this.setSavedAddressesMarkersOnMap(map);

                    this.addMapListeners(map);
                }
            });
        } else {

            map = new window.google.maps.Map(document.getElementById('pwaMainScreenMap'), this.props.options);

            if (!this.props.hasFullAddress) {
                this.props.handleMapChange(map);
            }

            // Add user saved addresses to map
            this.setSavedAddressesMarkersOnMap(map);
            this.addMapListeners(map);
        }
    }

    addMapListeners(map) {
        let control = document.getElementById('gpsIcon');
        // Listen for clicks on the gpsIcon
        control.addEventListener('click', () => this.props.centerMapOnUserPosition(map));
        // Listen for zooming
        window.google.maps.event.addListener(map, 'zoom_changed', () => this.props.handleMapChange(map));
        // Listen for dragging the map around
        window.google.maps.event.addListener(map, 'dragend', () => this.props.handleMapChange(map));
    }

    setSavedAddressesMarkersOnMap(map) {

        if (!this.props.customerSavedAddresses.length) return [];

        this.props.customerSavedAddresses.map(address => {

            let marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(address.latitude, address.longitude),
                map,
                icon: savedAddressMarker
            });
        });
    }

    render() {
        return (
            <div id={this.props.id} ref={this.mapRef} />
        );
    }
}

export default Map;