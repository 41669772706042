import {
    CLEAR_STATE,
    CLEAR_TEMPORARY_ESTIMATE_ORDER_TIME_SUCCESS, CLOSE_CHANGE_ORDER_TIME_SCREEN,
    GET_TEMPORARY_ESTIMATE_ORDER_TIME_SUCCESS, OPEN_CHANGE_ORDER_TIME_SCREEN,
    SAVE_AVAILABLE_ORDERED_PRODUCTS,
    LOAD_ORDER_PRODUCTS_SUCCESS,
    CLEAR_CURRENT_PATH_AFTER_USE,
    SAVE_CURRENT_PATH_TO_STORE, CLEAR_APP_STATE, TOGGLE_UNAVAILABLE_PRODUCT_SCREEN,
    SAVE_PROFILE_BACK_BUTTON_PATH
} from "../../actions/types";

const INITIAL_STATE = {
    estimateOrderTime: null,
    workingHoursByDate: [],
    asap: '',
    selectTime: '',
    date: '',
    time: '',
    futureOrder: null,
    wantedTime: '',
    allOrderedProducts: [],
    unAvailableOrderedProducts: [],

    availableOrderedProducts: [],
    
    changeOrderTimeScreenOpened: false,
    currentPagePath: '/checkout',
    screenBeforeRefresh: '',
    isUnavailableProductsScreenOpen: false,
    profileBackButtonPath: ''
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOAD_ORDER_PRODUCTS_SUCCESS:
            return {
                ...state, allOrderedProducts: action.payload
            };
        case GET_TEMPORARY_ESTIMATE_ORDER_TIME_SUCCESS:
            return {
                ...state,
                estimateOrderTime: action.payload.estimateOrderTime,
                workingHoursByDate: action.payload.workingHoursByDate,
                asap: action.payload.asap,
                selectTime: action.payload.selectTime,
                date: action.payload.data,
                time: action.payload.time,
                futureOrder: action.payload.futureOrder,
                wantedTime: action.payload.wantedTime,
                unAvailableOrderedProducts: action.payload.unAvailableOrderedProducts,
                timeSlotBased: action.payload.timeSlotBased,
                timeSlotsByDate: action.payload.timeSlotsByDate
            };
        case SAVE_AVAILABLE_ORDERED_PRODUCTS:
            return {
                ...state,
                availableOrderedProducts: action.payload.availableOrderedProducts,
                unAvailableOrderedProducts: action.payload.unAvailableOrderedProducts
            };
        case OPEN_CHANGE_ORDER_TIME_SCREEN:
            return {
                ...state,
                changeOrderTimeScreenOpened: true,
                currentPagePath: action.payload
            };
        case CLOSE_CHANGE_ORDER_TIME_SCREEN:
            return {
                ...state,
                changeOrderTimeScreenOpened: false,
                currentPagePath: action.payload
            };
        case SAVE_CURRENT_PATH_TO_STORE:
            return {
                ...state,
                screenBeforeRefresh: action.payload
            }
        case CLEAR_CURRENT_PATH_AFTER_USE:
            return {
                ...state,
                screenBeforeRefresh: ''
            }
        case TOGGLE_UNAVAILABLE_PRODUCT_SCREEN:
            return {
                ...state,
                isUnavailableProductsScreenOpen: !state.isUnavailableProductsScreenOpen
            } 
        case SAVE_PROFILE_BACK_BUTTON_PATH:
        return {
            ...state,
            profileBackButtonPath: action.payload
        };
        case CLEAR_TEMPORARY_ESTIMATE_ORDER_TIME_SUCCESS:
            return INITIAL_STATE;
        case CLEAR_STATE:
            return INITIAL_STATE;
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}