import React, { Component } from "react";
import { connect } from 'react-redux';
import '../../styles/RestaurantSelectionPwa.css';

import ApplicationStep from '../common/ApplicationStep';
import RestaurantSelectionScreen from '../../../components/order/start/RestaurantSelectionScreen';

import i18n from "../../../i18next";
import { updateCSSVariables } from "../../../utils/ThemesSelector";

import { openChangeOrderTimeScreen } from "../../../actions";
import { setLastOpenedPagePWA } from "../../../utils/CommonUtils";
import { CURBSIDE_ID, DELIVERY_ID } from "../../../utils/Constants";

class RestaurantSelectionPwa extends Component {

    constructor(props) {
        super(props);

        let isRedirectedFromDelivery = false;
        if (this.props.location && this.props.location.state && this.props.location.state.isRedirectedFromDelivery) {
            isRedirectedFromDelivery = true;
        }

        this.state = {
            isRedirectedFromDelivery
        };

        this.onBackKeyDown = this.onBackKeyDown.bind(this);
    }

    componentDidMount() {
        this.applyTheme();

        setLastOpenedPagePWA(this.props.openChangeOrderTimeScreen);
        document.addEventListener("backbutton", this.onBackKeyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener("backbutton", this.onBackKeyDown, false);
    }

    onBackKeyDown(e) {
        e.preventDefault();
        e.stopPropagation();
        this.goBack();
    }

    applyTheme() {
        updateCSSVariables(this.props.customerThemes.selectedTheme.colorsCss);
    }

    goBack() {
        let brandId = Number(this.props.brandId);
        if (this.props.selectedOrderType.id === CURBSIDE_ID) {
            this.props.history.push('/brand/' + brandId + '/start-order/car-details');
        } else if (this.props.selectedOrderType.id === DELIVERY_ID) {
            this.props.history.push('/brand/' + brandId + '/start-order/confirm-address');
        } else {
            this.props.history.push('/brand/' + brandId + '/start-order/main-screen');
        }
    }

    renderContent() {

        return (
            <React.Fragment>
                <RestaurantSelectionScreen renderInPwa={true} history={this.props.history} isRedirectedFromDelivery={this.state.isRedirectedFromDelivery} />
            </React.Fragment>
        );
    }

    render() {

        return (
            <React.Fragment>
                <ApplicationStep
                    header={true}
                    hideBackButton={false}
                    transparentHeader={false}
                    content={this.renderContent()}
                    goBack={this.goBack.bind(this)}
                    text={i18n.t('screens:restaurantSelectionScreen.header')}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        brandId: state.brand.id,
        customerThemes: state.customerThemes,
        selectedOrderType: state.orderTypes.selectedOrderType,
    };
};

export default connect(mapStateToProps, { openChangeOrderTimeScreen })(RestaurantSelectionPwa);