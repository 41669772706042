import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { updateCSSVariables } from '../../../utils/ThemesSelector';
import MenuScreenPwa from './menu/MenuScreenPwa';
import DiscountsPwa from './menu/DiscountsPwa';
import CheckoutScreenPwa from './checkout/CheckoutScreenPwa';
import ProductWizardContainerPwa from './menu/ProductWizardContainerPwa';
import TipScreenPwa from './menu/TipScreenPwa';
import { openChangeOrderTimeScreen, allowTimeWarningPopup } from "../../../actions";
import ShoppingCartScreenPwa from './menu/ShoppingCartScreenPwa';
import PromotionsScreenPwa from './menu/PromotionsScreenPwa';
import OrderConfirmationScreenPwa from './orderConfirmation/OrderConfirmationScreenPwa';

class OrderContainer extends Component {
	componentDidMount() {
		this.props.allowTimeWarningPopup(true);
		this.applyTheme();
	}

	componentWillUnmount() {
		this.props.allowTimeWarningPopup(false);
	}

	applyTheme() {
		updateCSSVariables(this.props.customerThemes.selectedTheme.colorsCss);
	}

	render() {
		return (
			<Fragment>
				<Switch>
					{/* Menu and checkout screen routes  */}
					<Route path='/brand/:id/order/menu'
						render={(props) => (
							<MenuScreenPwa {...props} />
						)} />
					<Route path='/brand/:id/order/product/:productId'
						render={(props) => (
							<ProductWizardContainerPwa {...props} />
						)} />
					<Route path='/brand/:id/order/discounts'
						render={(props) => (
							<DiscountsPwa
								availableDiscounts={this.props.availableDiscounts}
								selectedRestaurant={this.props.selectedRestaurant}
								brandId={this.props.brandId}
								openChangeOrderTimeScreen={this.props.openChangeOrderTimeScreen}
								{...props}
							/>
						)} />
					<Route path='/brand/:id/order/loyalty'
						render={(props) => (
							<DiscountsPwa
								availableDiscounts={[this.props.loyalty.loyalty]}
								selectedRestaurant={this.props.selectedRestaurant}
								brandId={this.props.brandId}
								openChangeOrderTimeScreen={this.props.openChangeOrderTimeScreen}
								{...props}
							/>
						)}
					/>
					<Route path='/brand/:id/order/checkout'
						render={(props) => (
							<CheckoutScreenPwa {...props} />
						)}
					/>
					<Route path='/brand/:id/order/tip'
						render={(props) => (
							<TipScreenPwa {...props} />
						)}
					/>
					<Route path='/brand/:id/order/cart'
						render={(props) => (
							<ShoppingCartScreenPwa {...props} />
						)}
					/>
					<Route path='/brand/:id/order/promotions'
						render={(props) => (
							<PromotionsScreenPwa {...props} />
						)}
					/>
					<Route path='/brand/:id/order/order-confirmation'
						render={(props) => (
							<OrderConfirmationScreenPwa {...props} />
						)}
					/>
				</Switch>
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		brandId: state.brand.id,
		availableDiscounts: state.availableDiscounts,
		selectedRestaurant: state.selectedRestaurant,
		customerThemes: state.customerThemes,
		loyalty: state.loyalty
	};
};

export default connect(mapStateToProps, { openChangeOrderTimeScreen, allowTimeWarningPopup })(OrderContainer);