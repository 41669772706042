import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18next';

import ApplicationStep from '../common/ApplicationStep';
import CarDetailsScreen from '../../../components/order/start/CarDetailsScreen';

class CarDetailsScreenPwa extends Component {

    constructor(props) {
        super(props);

        this.renderContent = this.renderContent.bind(this);
    }

    renderContent() {
        return (
            <CarDetailsScreen 
                isPwa={true}
                history={this.props.history}
            />
        )
    }

    goBack() {
        this.props.history.push('/brand/'+ this.props.brandId +'/start-order/main-screen');
    }

    render() {
        return (
            <Fragment>
                <ApplicationStep
                    header={true}
                    hideBackButton={false}
                    transparentHeader={false}
                    content={this.renderContent()}
                    goBack={this.goBack.bind(this)}
                    text={i18n.t('screens:carDetailsScreen.enterCarDetails')}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        brandId: state.brand.id,
    }
}

export default connect(mapStateToProps)(CarDetailsScreenPwa)