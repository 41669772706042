import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProgressBar } from 'react-step-progress-bar';
import { isTablet, isAndroid, isMobileOnly, isMobile } from 'mobile-device-detect';

import ImageContainer from './ImageContainer';
import WizardStep from './WizardStep';
import Header from './Header';
import Footer from './Footer';
import { preventBodyScrollOnSafari, handleIosElasticScroll } from '../../../utils/CommonUtils';
import { updateCSSVariables } from '../../../utils/ThemesSelector';
import { pushDataToGoogleTagManager, getProductCategory } from '../../../utils/AnalyticsUtils';
import {
    calculateProductPrice,
    wizardStepCalculation,
    areDepOnReqFulfilled,
    noRemainingQuestions,
    newDeepCopy,
    getProductQuestions,
    areAllItemsDefault
} from '../../../utils/ProductWizardUtils';
import { ARABIC } from '../../../utils/Constants';

class ProductWizard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            progressIndicatorPercentages: 0,
            selectedProduct: {
                questions: []
            },
            step: 0,
            innerStep: 0,
            useInnerStep: false,
            isMinMaxValid: false,
            mealDealNotesByStep: [],
            iOSDetected: navigator.appVersion.match(/iPhone; CPU iPhone OS/) || navigator.appVersion.match(/CPU iPhone/) ||
                navigator.appVersion.match(/iPad;/) ||
                (navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                    navigator.userAgent &&
                    navigator.userAgent.indexOf('CriOS') == -1 &&
                    navigator.userAgent.indexOf('FxiOS') == -1),
            prevScrollPostion: 0,
            isMobileDevice: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
        };

        this.navigateBackAndForth = this.navigateBackAndForth.bind(this);
        this.incDecProductQty = this.incDecProductQty.bind(this);
        this.changeProductState = this.changeProductState.bind(this);
        // this.areAllItemsDefault = this.areAllItemsDefault.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.changeChefsNotes = this.changeChefsNotes.bind(this);
        this.directStepNavigation = this.directStepNavigation.bind(this);
        this.setMinMaxValidation = this.setMinMaxValidation.bind(this);
        this.changeAnswerQty = this.changeAnswerQty.bind(this);
        this.isLastStepCalc = this.isLastStepCalc.bind(this);

        this.productWizardRef = React.createRef();
    }

    componentDidMount() {
        const { step } = this.state;
        let isLastStep = false, modifiedStep = step;
        let selectedProduct = newDeepCopy(this.props.selectedProduct);
        // console.log('WIZ selectedProduct ', selectedProduct)
        let progressIndicatorPercentages = this.calcProgressIndicator(selectedProduct, step)// (step + 1) / selectedProduct.questions.length * 100;

        let selectedProductModified = this.addChefsInstructionsProp(selectedProduct, false);
        selectedProductModified = this.handleFreeProductSizeSelection(selectedProductModified);
        // check if autoAdd product should be used
        if (!selectedProductModified.mealDeal) {
            // return the values for 'isLastStep', 'modifiedStep' and add 'selectedAnswers'
            // to the selectedProductModified.questions (to each question)
            // wizardStepCalculation params => questions, step, direction, 
            let autoAddDepOnValues = wizardStepCalculation(selectedProductModified.questions, step, null);
            selectedProductModified.questions = autoAddDepOnValues.questions;
            isLastStep = autoAddDepOnValues.isLastStep;
            modifiedStep = autoAddDepOnValues.step;

            if (!selectedProduct.productIndex && selectedProduct.productIndex !== 0 && autoAddDepOnValues.isLastStep
                && selectedProductModified.questions.length === 1 && autoAddDepOnValues.skipToNext) {
                this.addToCart(selectedProductModified);
            }
        }

        this.setState({
            ...this.state,
            selectedProduct: selectedProductModified,
            progressIndicatorPercentages: progressIndicatorPercentages,
            mealDealNotesByStep: this.addChefsInstructionsProp(selectedProduct, true),
            noOtherQuestionsLeft: isLastStep,
            step: modifiedStep
        });

        if (this.props.pwaAppRunning) { // TODO this is yet to be checked if working
            // Handle elastic scroll issue on ios devices
            let actionType = 'Disable Scroll';
            handleIosElasticScroll(actionType);
        }

        if (window.innerWidth < 650 && this.state.iOSDetected && !this.props.isLoadedFromIntegration && !this.props.pwaAppRunning) {
            preventBodyScrollOnSafari(true);
            // Setting the prev scroll position in state
            setTimeout(() => {
                this.setState({
                    ...this.state,
                    prevScrollPostion: window.pageYOffset
                });
            }, 100);
        }

        setTimeout(() => {
            const isSimpleProduct = this.state.selectedProduct.questions.length === 0 ? true : false;
            const wizardHeader = document.getElementById('wizardHeader');

            if (isMobile && !isSimpleProduct && wizardHeader) {
                wizardHeader.scrollIntoView({ behavior: "smooth" });
            }
        }, 500);
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedRestaurant, pwaAppRunning, products, categories } = this.props;
        const { step } = this.state;
        const { selectedProduct } = this.state;
        // console.log('PRODUCT DID UPDATE ');
        if (prevState.step !== step) {
            let progressIndicatorPercentages = this.calcProgressIndicator(selectedProduct, step);//(step + 1) / selectedProduct.questions.length * 100;
            this.setState({
                progressIndicatorPercentages: progressIndicatorPercentages
            });
        }
        //Changes selectedProduct when size questions are present
        if (prevProps.selectedProduct !== this.props.selectedProduct) {
            let productCopy = newDeepCopy(this.props.selectedProduct);
            let stateProductCopy = newDeepCopy(this.state.selectedProduct);
            productCopy.instruction = stateProductCopy.instruction;
            productCopy.quantity = stateProductCopy.quantity ? stateProductCopy.quantity : 1;

            if (stateProductCopy.productIndex || stateProductCopy.productIndex === 0) {
                productCopy.productIndex = stateProductCopy.productIndex;
            }

            if (stateProductCopy.promotionFreeProduct) {
                productCopy.promotionFreeProduct = stateProductCopy.promotionFreeProduct;
            }
            /**COMMENTED OUT BECAUSE IT MIGHT CAUSE THE NEGATIVE IDs PROBLEM */
            // console.log('PW stateCopy ', stateProductCopy, ' propsCopy ', productCopy); 
            // Find the size question which already has been selected (in order to get in this check)
            // then find the same sizeQuestion in the productCopy (coming from the server)
            // then replace the productCopy's sizeQuestion with 
            // // stateProductCopy.questions.map(stateQuestion => {
            // //     productCopy.questions.map((productQuestion, index) => {
            // //         if (stateQuestion.selectedAnswers && stateQuestion.id === -111 && productQuestion.id === -111) {
            // //             productQuestion.selectedAnswers = stateQuestion.selectedAnswers;
            // //         }
            // //     });
            // // });
            // Google Analytics
            const filteredProduct = {
                id: productCopy.id,
                name: productCopy.name,
                price: calculateProductPrice(selectedRestaurant.allowToppingSubstitution, productCopy.price, productCopy.quantity, productCopy),
                category: getProductCategory(productCopy.id, products, categories)
            };

            pushDataToGoogleTagManager('event', 'view-item', pwaAppRunning, filteredProduct);
            // console.log('productCopy ', productCopy, ' stateProductCopy ', stateProductCopy)
            this.setState({
                ...this.state,
                selectedProduct: productCopy
            });
        }

        if (this.state.iOSDetected && this.state.isMobileDevice) {
            let wizardHeight = this.props.pwaAppRunning ? window.outerHeight : window.innerHeight;
            let productWizardHeight = [{ key: '--product-wizard-height', value: `${wizardHeight}px` }];
            updateCSSVariables(productWizardHeight);
        } else if (this.state.iOSDetected && this.props.selectedProduct.imagePath && window.innerWidth <= 786) {
            // console.log('windowINNER WIDHT ', window.innerWidth);
            let wizardHeight = this.props.pwaAppRunning ? window.outerHeight : window.innerHeight;
            let productWizardHeight = [{ key: '--product-wizard-height', value: `${wizardHeight}px` }];
            updateCSSVariables(productWizardHeight);
        } else {
            let productWizardHeight = [{ key: '--product-wizard-height', value: `auto` }];
            updateCSSVariables(productWizardHeight);
        }
    }

    componentWillUnmount() {
        if (this.state.iOSDetected && !this.props.isLoadedFromIntegration && !this.props.pwaAppRunning) {
            // Handle elastic scroll issue on ios devices
            let actionType = 'Enable Scroll';
            handleIosElasticScroll(actionType);

            if (window.innerWidth < 650) {
                preventBodyScrollOnSafari(false, this.state.prevScrollPostion);
            }
        }
    }

    calcProgressIndicator(selectedProduct, step) {

        let progress = (step + 1) / selectedProduct.questions.length * 100;

        return progress;
    }

    incDecProductQty(incDecParam) {
        const { selectedProduct } = this.state;
        let productQty = selectedProduct.quantity ? selectedProduct.quantity : 1;
        productQty += incDecParam;
        if (!selectedProduct.promotionFreeProduct) {
            this.setState({
                selectedProduct: {
                    ...this.state.selectedProduct,
                    quantity: productQty
                }
            });
        }
    }

    changeProductState(eventTargetChecked, item, isMinMaxValid, callNextStep) {
        const { selectedProduct, step, innerStep } = this.state;
        let newInnerStep = innerStep, noOtherQuestionsLeft = false;

        if (item.mealDealProduct) {
            let useInnerStep = item.subQuestions.length && callNextStep ? true : false;
            let goToNextStep = !item.subQuestions.length && callNextStep ? 1 + step : step;

            if (selectedProduct.questions.length <= goToNextStep) {
                goToNextStep = step;
            }

            let questionsCopy = [...selectedProduct.questions];
            questionsCopy[this.state.step].selectedProduct = item;
            // Auto add logic - this part is only activated when selecting items
            if (useInnerStep) {
                // check if item should be preselected
                let itemCopy = { ...item }; // item is product at this point
                let autoAddValues = {};
                itemCopy.subQuestions.map(subQuestion => { // map all the questions and if all conditions are met preselect the answer
                    let shouldAutoAddBeUsed = this.shouldAutoAddBeUsed(subQuestion);
                    if (shouldAutoAddBeUsed) {
                        autoAddValues = this.autoAddCheckForQuestions(itemCopy.subQuestions, innerStep, false, false);
                    }
                });

                if (autoAddValues.questions) {
                    itemCopy.subQuestions = autoAddValues.questions;
                    questionsCopy[this.state.step].selectedProduct = itemCopy;
                    let i = 0;
                    while (i < itemCopy.subQuestions.length) {
                        const currQ = itemCopy.subQuestions[i];
                        const shouldSkip = this.autoAddPrevNextQuesSkip(currQ);
                        if (!shouldSkip) {
                            // set steps
                            newInnerStep = i;
                            break;
                        }
                        i++;
                        if (!itemCopy.subQuestions[i]) {//check next question
                            // must get back to main steps
                            goToNextStep++;
                            if (!questionsCopy[goToNextStep]) { // if it does not exist keep inner step values as they are
                                goToNextStep--;
                                noOtherQuestionsLeft = true;
                            } else {   // if exists set the default values
                                newInnerStep = 0;
                                useInnerStep = false;
                            }
                            break;
                        }
                    }
                }
            }

            this.setState({
                ...this.state,
                selectedProduct: {
                    ...this.state.selectedProduct,
                    questions: questionsCopy
                },
                noOtherQuestionsLeft: noOtherQuestionsLeft,
                step: goToNextStep,
                innerStep: newInnerStep,
                useInnerStep: useInnerStep,
                isMinMaxValid: isMinMaxValid
            });
        } else { // md answers go here and reg products
            // console.log('ANSWERS ')
            let noRemainingQs = false;
            let questionsCopy = selectedProduct.questions.slice();
            let selectedMDproduct = {}, copyOfSubQuesitons = {};
            const currQuestAnswers = selectedProduct.mealDeal ? questionsCopy[step].selectedProduct.subQuestions[innerStep] : questionsCopy[step];
            let allItemsDefault = areAllItemsDefault(currQuestAnswers);
            if (selectedProduct.mealDeal) {
                selectedMDproduct = { ...questionsCopy[step].selectedProduct };
                copyOfSubQuesitons = selectedMDproduct.subQuestions.slice();
                copyOfSubQuesitons = getProductQuestions({ questionsCopy: copyOfSubQuesitons, step: innerStep, eventTargetChecked, allItemsDefault, item });
                noRemainingQs = noRemainingQuestions(copyOfSubQuesitons, innerStep);

                selectedMDproduct.subQuestions = copyOfSubQuesitons;
                questionsCopy[step].selectedProduct = selectedMDproduct;

            } else {
                questionsCopy = getProductQuestions({ questionsCopy, step, eventTargetChecked, allItemsDefault, item });
                noRemainingQs = noRemainingQuestions(questionsCopy, step);
            }

            this.setState({
                ...this.state,
                selectedProduct: {
                    ...this.state.selectedProduct,
                    questions: questionsCopy
                },
                noOtherQuestionsLeft: noRemainingQs,
                isMinMaxValid: isMinMaxValid
            }, () => { // auto step forward when min/max === 1 and noRemainingQs ? lastStep : notLastStep
                if (callNextStep && !noRemainingQs) {
                    this.navigateBackAndForth(1);
                }
            });
        }
    }

    changeAnswerQty(selectedAnswer, addOrRem) {
        const { selectedProduct, step, innerStep } = this.state;
        let questionsCopy = [...selectedProduct.questions];
        let currQ = { ...questionsCopy[step] };
        let copyOfSelectedAnswers = [], subQuestionsCopy = [], currSubQ = {};

        if (selectedProduct.mealDeal) {
            subQuestionsCopy = [...currQ.selectedProduct.subQuestions];
            currSubQ = subQuestionsCopy[innerStep];
            copyOfSelectedAnswers = this.getSelectedAnswers(currSubQ.selectedAnswers, selectedAnswer, addOrRem);
            currSubQ.selectedAnswers = copyOfSelectedAnswers;
            subQuestionsCopy[innerStep] = currSubQ;
            questionsCopy[step] = currQ;
        } else {
            copyOfSelectedAnswers = this.getSelectedAnswers(currQ.selectedAnswers, selectedAnswer, addOrRem);
            currQ.selectedAnswers = copyOfSelectedAnswers;
            questionsCopy[step] = currQ;
        }

        this.setState({
            ...this.state,
            selectedProduct: {
                ...this.state.selectedProduct,
                questions: questionsCopy
            }
        }, this.setMinMaxValidation);
    }

    getSelectedAnswers(answers, selectedAnswer, addOrRem) {
        let copyOfSelectedAnswers = [...answers];

        let newQty = selectedAnswer.count + addOrRem;
        if (newQty === 0) {
            copyOfSelectedAnswers = copyOfSelectedAnswers.filter(answer => {
                if (answer.id !== selectedAnswer.id) {
                    return answer;
                }
            });
        } else {
            copyOfSelectedAnswers.map(answerCopy => {
                if (answerCopy.id === selectedAnswer.id) {
                    answerCopy.count = newQty;
                }
            });
        }

        return copyOfSelectedAnswers;
    }

    handleFreeProductSizeSelection(product) {
        let productCopy = { ...product };
        if (product.promotionFreeProduct) {
            let hasSizeQuestions = productCopy.questions.filter(question => question.id === -111)[0] ? true : false;
            if (hasSizeQuestions) {
                productCopy.questions.filter(question => question.id === -111)[0].answers.map(answer => {
                    if (+answer.posId === productCopy.id) {

                        productCopy.questions[0].selectedAnswers = [];
                        productCopy.questions[0].selectedAnswers.push({ ...answer });
                        productCopy.questions[0].answers = [];
                        productCopy.questions[0].answers.push({ ...answer });
                    }
                });
            }
        }

        return productCopy;
    }
    // Navigations
    navigateBackAndForth(direction) {// direction === 1 || -1
        const { selectedProduct } = this.state;

        let useInnerStep = this.state.useInnerStep;
        let step = this.state.step, innerStep = this.state.innerStep;
        let steps = this.handleDirectionCalc(direction, step, innerStep, useInnerStep);
        step = steps.step;
        innerStep = steps.innerStep;
        useInnerStep = steps.useInnerStep;
        // console.log('MAIN METHOD currentQuestion ', currentQuestion, ' product ', selectedProduct)
        let dependsOnSteps = null;
        if (!selectedProduct.mealDeal) {
            dependsOnSteps = wizardStepCalculation(selectedProduct.questions, step, direction);
            step = dependsOnSteps.step;
            innerStep = 0;
            useInnerStep = false;

            this.setState({
                ...this.state,
                selectedProduct: {
                    ...this.state.selectedProduct,
                    questions: dependsOnSteps.questions
                },
                step: step,
                innerStep: innerStep,
                useInnerStep: useInnerStep,
                noOtherQuestionsLeft: dependsOnSteps ? dependsOnSteps.isLastStep : false
            });
        } else {
            if (useInnerStep) {
                let currQs = selectedProduct.questions[step].selectedProduct.subQuestions;
                dependsOnSteps = this.handleDependsOnQuestions(currQs, innerStep, direction);
                if (!currQs[dependsOnSteps.step]) {
                    step++;
                    innerStep = 0;
                    useInnerStep = false;
                } else {
                    let reqsFulfilled = areDepOnReqFulfilled(currQs, currQs[dependsOnSteps.step]);
                    if (reqsFulfilled) {
                        innerStep = dependsOnSteps.step;
                    } else {
                        step++;
                        innerStep = 0;
                        useInnerStep = false;
                    }
                }
            }

            this.setState({
                ...this.state,
                step: step,
                innerStep: innerStep,
                useInnerStep: useInnerStep,
                noOtherQuestionsLeft: dependsOnSteps ? dependsOnSteps.noOtherQuestionsLeft : false
            });
        }
    }

    handleDirectionCalc(direction, step, innerStep, useInnerStep, dependsOn) {
        const { selectedProduct } = this.state;
        if (direction < 0) {
            if (!useInnerStep) {
                step += direction;
                if (selectedProduct.questions[step].selectedProduct && selectedProduct.questions[step].selectedProduct.subQuestions.length) { // check if prevStep selectedProduct
                    useInnerStep = true;
                    innerStep = selectedProduct.questions[step].selectedProduct.subQuestions.length - 1; // equals the last selected question
                }
            } else if (useInnerStep) {
                innerStep += direction;
                if (innerStep < 0) {
                    useInnerStep = dependsOn ? dependsOn : false;
                    innerStep = 0;
                }
            }
        } else if (direction > 0) {
            if (useInnerStep) {
                innerStep += direction;
                if (innerStep >= selectedProduct.questions[this.state.step].selectedProduct.subQuestions.length) {
                    step += direction;
                    innerStep = 0;
                    useInnerStep = false;
                    if (step >= selectedProduct.questions.length) {
                        step--;
                        useInnerStep = true;
                    }
                }
            } else if (!useInnerStep) {
                if (selectedProduct.questions[this.state.step].selectedProduct && selectedProduct.questions[this.state.step].selectedProduct.subQuestions.length) {
                    innerStep = 0; // when we go back to the beginning but we have already selected product
                    useInnerStep = true;
                } else {
                    step += direction;
                    if (step >= selectedProduct.questions.length) {
                        step--;
                    }
                }
            }
        }

        return { step: step, innerStep: innerStep, useInnerStep: useInnerStep };
    }

    directStepNavigation(questionIndex) { // This navigates only to direct questions i.e. mainSteps
        const isStepValid = this.validateStep();
        if (isStepValid.blockNextBtn) {
            this.setMinMaxValidation(isStepValid.allowValidationClick);
        } else {
            this.setState({
                ...this.state,
                step: questionIndex,
                innerStep: 0,
                useInnerStep: false
            });
        }
    }
    // DependsOn logic
    handleDependsOnQuestions(productQuestions, step, direction) {
        let currQs = [...productQuestions];
        let currQ = { ...currQs[step] };
        let dependsOnRequirementsFulfilled = areDepOnReqFulfilled(currQs, currQ);

        if (dependsOnRequirementsFulfilled) { // if this is false skip and go to next question
            let noOtherQuestionsLeft = false;
            // if step is not last
            if (step >= currQs.length) {
                noOtherQuestionsLeft = true;
            } else {
                // check if more questions without depends on
                noOtherQuestionsLeft = noRemainingQuestions(currQs, step);
            }
            // check if all of the remaining questions have their dependsOn fulfilled if true 'next - false' else 'addToCart - true'
            return { step: step, noOtherQuestionsLeft: noOtherQuestionsLeft };
        } else {
            // if false skip it/ go to next/prev question
            let i = step, nextStepQuestionFound = false, noOtherQuestionsLeft = false;
            while (!nextStepQuestionFound && !noOtherQuestionsLeft) {
                i += direction;

                if (!currQs[i]) { // when no next/prev questions exist
                    noOtherQuestionsLeft = true;
                } else {
                    nextStepQuestionFound = areDepOnReqFulfilled(currQs, currQs[i]);
                }
            }

            if (noOtherQuestionsLeft) {
                // let newStep = step - 1; // Uncomment this for reg products or BETTER find how to improve this !!!!
                // console.log("DEPON LOGIC 2 ", noOtherQuestionsLeft, ' step ', step, ' index ', i,  ' nextStepQuestionFound ', nextStepQuestionFound);
                return { step: step, noOtherQuestionsLeft: noOtherQuestionsLeft };
            } else {
                noOtherQuestionsLeft = noRemainingQuestions(currQs, i);
                return { step: i, noOtherQuestionsLeft: noOtherQuestionsLeft };
            }
        }
    }
    // Add products
    autoAddCheckForQuestions(questions, step, stepAlreadyIncreased, manuallyIncreased) {
        let questionsCopy = [...questions], lastStep = false, stepIncreased = stepAlreadyIncreased;
        let currStep = manuallyIncreased ? 1 + step : step;
        if (!questionsCopy[currStep]) return { questions: questionsCopy, step: step, lastStep: false };

        let question = { ...questionsCopy[currStep] };

        if (question.minimumQuantity === 1 && question.maximumQuantity === 1 && question.answers.length === 1) {
            question.selectedAnswers = question.answers;
            question.selectedAnswers[0].count = 1;
            questionsCopy[currStep] = question;
            if (questionsCopy.length - 1 === currStep && question.selectedAnswers[0].price === 0) { //next question is last
                lastStep = true;
                if (manuallyIncreased) {
                    currStep--;
                }
            } else if (questionsCopy.length - 1 !== currStep && question.selectedAnswers[0].price === 0 && !manuallyIncreased) { // next question is NOT last
                // skip step
                currStep++;
                stepIncreased = true;
            }
        }

        if (manuallyIncreased) {
            currStep = step;
        }

        return { questions: questionsCopy, step: currStep, lastStep: lastStep, stepIncreased: stepIncreased };
    }

    autoAddPrevNextQuesSkip(question) {
        // console.log('autoAddPrevNextQuesSkip ', question)
        if (!question.selectedAnswers) { // prevents consecutive autoadd questions to 
            return false;
        }

        if (question.minimumQuantity === 1 && question.maximumQuantity === 1 && question.answers.length === 1 && question.selectedAnswers[0].price === 0) {
            return true;
        }

        return false;
    }

    shouldAutoAddBeUsed(question) {
        if (question.minimumQuantity === 1 && question.maximumQuantity === 1 && question.answers.length === 1) {
            return true;
        }

        return false;
    }

    addToCart(autoAddProduct) {
        const { selectedRestaurant, products, categories, currency } = this.props;
        let selectedProductCopy = { ...this.state.selectedProduct };
        let itemToAdd = selectedProductCopy;
        if (autoAddProduct) {
            itemToAdd = autoAddProduct;
        }

        if (!itemToAdd.quantity) {
            itemToAdd.quantity = 1;
        }

        if (itemToAdd.mealDeal) {
            itemToAdd = this.addNotes(itemToAdd);
        }
        // adds selectedAnswers for the default(ingredient) question when nothing has been chosen during the selection process
        itemToAdd = this.addIngredientsToQuestions(itemToAdd);
        // Google Analytics
        let filteredProduct = {
            id: itemToAdd.id,
            currency: currency,
            name: itemToAdd.name,
            price: calculateProductPrice(selectedRestaurant.allowToppingSubstitution, itemToAdd.price, itemToAdd.quantity, itemToAdd),
            quantity: itemToAdd.quantity,
            category: getProductCategory(itemToAdd.id, products, categories)
        };

        pushDataToGoogleTagManager('event', 'addToCart', this.props.pwaAppRunning, filteredProduct);

        this.props.addProductToCart(itemToAdd);
        this.props.toggle();
    }

    addIngredientsToQuestions(itemToAdd) { // adds ingredient answers to the questions when nothing has been chosen during selection phase
        if (itemToAdd.mealDeal) {
            itemToAdd.questions.map(question => {
                if (question.selectedProduct.subQuestions) {
                    question.selectedProduct.subQuestions.map(subQuestion => {
                        if (subQuestion.posDefault && !subQuestion.selectedAnswers) {
                            subQuestion.selectedAnswers = subQuestion.answers;
                        }
                    });
                }
            });
        } else {
            itemToAdd.questions.map(question => {
                if (question.posDefault && !question.selectedAnswers) {
                    question.selectedAnswers = question.answers;
                }
            });
        }

        return itemToAdd;
    }
    // Chefs instrutions
    addChefsInstructionsProp(product, returnNotes) {
        let productCopy = { ...product };

        if (!productCopy.instruction) {
            productCopy.instruction = null;
        }

        let productStructureCopy = [];
        if (product.mealDeal && product.questions.length) {
            let i = 0, questionsLength = product.questions.length;
            while (i < questionsLength) {
                let instructionObject = { instruction: { notes: '' } };
                try {
                    instructionObject = { instruction: product.questions[i].selectedProduct.instruction ? product.questions[i].selectedProduct.instruction : { notes: '' } }
                } catch {
                    instructionObject = { instruction: { notes: '' } };
                }

                productStructureCopy.push(instructionObject);
                i++;
            }
        }

        if (returnNotes) {
            return productStructureCopy;
        }

        return productCopy;
    }

    changeChefsNotes({ target }) { // mealDealProduct's notes are done according to questions
        const { selectedProduct, step, mealDealNotesByStep } = this.state;
        let stepsCopy = [...mealDealNotesByStep];
        let instruction = { notes: target.value };

        if (selectedProduct.mealDeal) {
            let stepObject = { instruction: instruction };

            if (stepsCopy.length) {
                stepsCopy.splice(step, 1, stepObject);
            } else {
                stepsCopy.push(stepObject);
            }

            this.setState({
                ...this.state,
                mealDealNotesByStep: stepsCopy
            });
        } else {
            let selectedProductCopy = { ...selectedProduct };
            selectedProductCopy.instruction = instruction;

            this.setState({
                ...this.state,
                selectedProduct: {
                    ...this.state.selectedProduct,
                    instruction: instruction
                }
            });
        }
    }

    addNotes(selectedProductCopy) {
        let questionsCopy = [...selectedProductCopy.questions];

        questionsCopy.map((question, i) => {
            let currentQuestion = { ...question };
            let currProductCopy = currentQuestion.selectedProduct;
            currProductCopy.instruction = this.state.mealDealNotesByStep[i].instruction;
        });

        selectedProductCopy.questions = questionsCopy;

        return selectedProductCopy;
    }
    // Validations
    validateStep() {
        const { selectedProduct, step, innerStep, useInnerStep } = this.state;
        let returnObject = {
            blockNextBtn: true,
            allowValidationClick: false
        };
        if (selectedProduct.mealDeal && selectedProduct.questions.length) {
            if (selectedProduct.questions[step].selectedProduct) {
                returnObject.blockNextBtn = false;
            }
        } else {
            if (selectedProduct) {
                returnObject.blockNextBtn = false;
            }
        }

        // console.log('validateStep ', this.state)
        if (!selectedProduct.id) return <div />;
        const selectedItem = selectedProduct.mealDeal && selectedProduct.questions.length ? selectedProduct.questions[step].selectedProduct : selectedProduct; // selectedProduct to keep original data structure at least for now
        let currentQuestion = selectedProduct.questions[step];

        if (selectedItem && useInnerStep) { // mealDeal inner steps
            currentQuestion = selectedProduct.questions[step].selectedProduct.subQuestions[innerStep];
        }
        let secondCheckCond = selectedProduct.mealDeal ? useInnerStep : true;
        if (currentQuestion && secondCheckCond) {

            const minQty = currentQuestion.minimumQuantity, maxQty = currentQuestion.maximumQuantity;
            let selectedAnswersCount = currentQuestion.selectedAnswers ? currentQuestion.selectedAnswers.length : 0;
            if (currentQuestion.multiIngredients && currentQuestion.selectedAnswers && !currentQuestion.posDefault) {
                selectedAnswersCount = 0;
                currentQuestion.selectedAnswers.map(currAnswer => {
                    selectedAnswersCount += currAnswer.count;
                });
            } else if (currentQuestion.selectedAnswers && currentQuestion.posDefault) { //posDefault ? calc the diff
                selectedAnswersCount = currentQuestion.answers.length - currentQuestion.selectedAnswers.length;
                if (selectedAnswersCount === currentQuestion.answers.length) { //when all selectedAnswers are removed
                    selectedAnswersCount = 0;
                }
            }
            // console.log('min ', minQty, ' max ', maxQty, ' selectedAnswersCount ', selectedAnswersCount);
            if ((selectedAnswersCount < minQty && minQty) || (selectedAnswersCount > maxQty && maxQty)) {
                returnObject.blockNextBtn = true;
                returnObject.allowValidationClick = true;
            } else if (!maxQty && minQty && selectedAnswersCount < minQty) {
                returnObject.blockNextBtn = true;
                returnObject.allowValidationClick = true;
            }

            if (!maxQty && !minQty && this.isLastStepCalc()) {
                returnObject.blockNextBtn = false;
            }
        } else if (selectedProduct.mealDeal && selectedProduct.questions.length && !useInnerStep && !selectedProduct.questions[step].selectedProduct) {
            returnObject.allowValidationClick = true;
        }

        return returnObject;
    }

    setMinMaxValidation(isValid, callback) {
        this.setState({
            ...this.state,
            isMinMaxValid: isValid
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    isLastStepCalc() {
        const { step, innerStep, useInnerStep, selectedProduct } = this.state;
        let isLastStep = false;

        if (step === selectedProduct.questions.length - 1) { // last main question === true
            if (!selectedProduct.questions[step].selectedProduct) { // current question has NO selectedProduct
                let hasSubQuestions = false, i = 0;
                while (!hasSubQuestions) { // Loop all the question's answers and if one has subQuestions then isLastStep = false
                    if (!selectedProduct.questions[step].answers[i]) {
                        break;
                    }

                    if (selectedProduct.questions[step].answers[i].subQuestions) {
                        hasSubQuestions = true;
                        break;
                    }
                    i++;
                }
                // TODO rework for MD
                isLastStep = !hasSubQuestions;
            } else { // current question has selectedProduct
                if (!selectedProduct.questions[step].selectedProduct.subQuestions.length) { // selectedProduct has NO subQuestions
                    // console.log('LAST IF')
                    isLastStep = true;
                } else { // selectedProduct has subQuestions then check if user is on the last subQuestions
                    isLastStep = useInnerStep && innerStep === selectedProduct.questions[step].selectedProduct.subQuestions.length - 1;
                    if (this.state.noOtherQuestionsLeft) {
                        isLastStep = true;
                    }
                }
            }
        }

        if (this.state.noOtherQuestionsLeft && !this.props.selectedProduct.mealDeal) {

            isLastStep = this.state.noOtherQuestionsLeft;
        }

        if (!selectedProduct.questions.length) {
            isLastStep = true;
        }

        if (!selectedProduct.mealDeal) {
            // check 
        }
        // console.log('isLAST STEP  ', isLastStep)
        return isLastStep;
    }

    isBackBtnBlocked() {
        if (!this.state.useInnerStep && this.state.step === 0) {
            return true;
        }
        return false;
    }

    checkNoteIsDisable(selectedProduct, step) {
        if (selectedProduct.mealDeal) {
            if (selectedProduct.questions.length) {
                if (selectedProduct.questions[step].selectedProduct) {
                    return selectedProduct.questions[step].selectedProduct.disableNotes;
                } else {
                    return true;
                }
            } else {
                return selectedProduct.disableNotes;
            }
        } else {
            return selectedProduct.disableNotes;
        }
    }

    // Renders 
    renderBody(isLowResTabletPortraitMode) {
        const { selectedTheme, currency } = this.props;
        const { step, innerStep, selectedProduct, useInnerStep, iOSDetected } = this.state;
        let selectedItem = {}, contentItem = {}, itemsLength = 0;
        if (selectedProduct.mealDeal && selectedProduct.questions.length) {
            selectedItem = selectedProduct.questions[step].selectedProduct; // selectedProduct to keep original data structure at least for now
            contentItem = selectedProduct.questions[step];
            itemsLength = selectedProduct.questions.length - 1;

            if (selectedItem && useInnerStep) {
                contentItem = selectedProduct.questions[step].selectedProduct.subQuestions[innerStep];
                itemsLength = selectedProduct.questions[step].selectedProduct.subQuestions.length - 1;
            }
        } else {
            selectedItem = selectedProduct.questions[step];
            contentItem = selectedProduct.questions[step];
            itemsLength = selectedProduct.questions.length - 1;
        }

        return (
            <WizardStep
                step={step}
                innerStep={innerStep}
                iOSDetected={iOSDetected}
                product={selectedProduct}
                productMainQuestion={selectedProduct.questions[step]}
                currentQuestion={contentItem}
                useInnerStep={useInnerStep}
                questionsLength={itemsLength}
                selectedTheme={selectedTheme}
                currency={currency}
                restaurant={this.props.selectedRestaurant}
                isMinMaxValid={this.state.isMinMaxValid}
                pwaAppRunning={this.props.pwaAppRunning}
                isLowResTabletPortraitMode={isLowResTabletPortraitMode}

                setMinMaxValidation={this.setMinMaxValidation}
                changeAnswerQty={this.changeAnswerQty}
                changeProductState={this.changeProductState}
            // areAllItemsDefault={areAllItemsDefault}
            />
        );
    }

    render() {
        const { selectedTheme, currency } = this.props;
        const { selectedProduct, progressIndicatorPercentages, step, innerStep } = this.state;
        const { menuItemsBackground } = selectedTheme;
        const disableNotes = this.checkNoteIsDisable(selectedProduct, step)
        const isArabic = this.props.language === ARABIC;
        const enableLargeImageDesign = (this.props.restaurant ? this.props.restaurant.enableLargeImageDesign : false) || (this.props.estimateOrderTime ? this.props.estimateOrderTime.enableLargeImageDesign : false)
        const isImagePath = enableLargeImageDesign ? selectedProduct.rectImagePath : selectedProduct.imagePath;

        if (!selectedProduct.id) return <div />;

        // const disableNotes = this.checkNoteIsDisable(selectedProduct, step);

        let productWizard = {};
        let wizardContentContainer = {
            display: 'flex',
            flexDirection: 'column',
            width: !isImagePath ? '100%' : '55%'
        };

        if (this.state.iOSDetected) {
            productWizard = {
                alignSelf: 'center'
            };

            wizardContentContainer = {
                ...wizardContentContainer,
                minHeight: isImagePath ? 'auto' : '100%'
            }
        }

        if (!this.state.isMobileDevice) {
            productWizard = {
                ...productWizard,
                overflowY: 'hidden'
            };
            wizardContentContainer = {
                ...wizardContentContainer,
                overflowY: 'auto'
            };
        }
        let isLowResTabletPortraitMode = isTablet && window.innerWidth < window.innerHeight && window.innerWidth < 1119;
        if (isLowResTabletPortraitMode) { // Portrait mode
            productWizard = {
                ...productWizard,
                flexDirection: 'column',
                height: selectedProduct.imagePath ? '85vh' : '50vh',
                overflow: 'auto'
            };
            if (!isAndroid) {
                productWizard.overflowX = 'hidden';
            }
            // Products without images do not need maxWidth set otherwise look bad
            if (isImagePath) {
                productWizard = {
                    ...productWizard,
                    maxWidth: 500
                };
            }

            if (!selectedProduct.questions.length) {
                productWizard.height = 'auto';
            }

            wizardContentContainer = {
                ...wizardContentContainer,
                overflow: 'initial'
            };
        } else if (isTablet && isImagePath && selectedProduct.questions.length) {
            wizardContentContainer = {
                ...wizardContentContainer,
                width: 'calc(100% - 500px)'
            };
        }

        if (!this.state.iOSDetected && !isImagePath) {
            wizardContentContainer.height = '100%';
        }

        let pWizClassNames = 'productWizard';
        if (!isImagePath && selectedProduct.questions.length) {
            pWizClassNames += ' noImg';
        } else if (!selectedProduct.questions.length) {
            pWizClassNames += ' simpleProduct';
            if (!isImagePath) {
                pWizClassNames += ' noImg';
            }
        } else if (selectedProduct.mealDeal) {
            pWizClassNames += ' mealDeal';
        } else {
            pWizClassNames += ' regularProduct';
        }
        if (this.state.iOSDetected) {
            pWizClassNames += ' iOS';
        } else if (!this.state.iOSDetected && !this.props.isLoadedFromIntegration) {
            pWizClassNames += ' notIntegrated';
        }

        if (isTablet) {
            pWizClassNames += ' isTablet';
        } else if (isMobileOnly) {
            pWizClassNames += ' isMobile';
        } else {
            pWizClassNames += ' isDesktop';
        }

        if (isTablet && window.innerHeight < window.innerWidth && !this.props.pwaAppRunning) {
            pWizClassNames += ' landscape';
        }

        if (isImagePath) {
            pWizClassNames += ' hasImg';
        }

        if (enableLargeImageDesign) {
            pWizClassNames += ' largeImageDesign'
        }

        if (this.props.pwaAppRunning) {
            pWizClassNames += ' pWA'
        }

        // console.log('STATE render ', this.state.progressIndicatorPercentages)
        return (
            <div
                ref={this.productWizardRef}
                className={pWizClassNames}
                style={{ ...productWizard, backgroundColor: menuItemsBackground }}
            >
                {
                    !this.props.pwaAppRunning &&
                    <div id="customCloseBtn" style={isArabic ? { marginTop: 5, position: 'absolute', top: 0, left: 15 } : { marginTop: 5, position: 'absolute', top: 0, right: 15 }} onClick={this.props.toggle}></div>
                }

                {isImagePath &&
                    <ImageContainer
                        product={selectedProduct}
                        // isMobileDevice={this.state.isMobileDevice}
                        isLowResTabletPortraitMode={isLowResTabletPortraitMode}
                        wizardRef={this.productWizardRef}
                        enableLargeImageDesign={enableLargeImageDesign}
                    />}
                <div
                    id='wizardContentContainer'
                    className='wizardContentContainer'
                    style={wizardContentContainer}>
                    {selectedProduct.id &&
                        <Header
                            iOSDetected={this.state.iOSDetected}
                            product={selectedProduct}
                            name={selectedProduct.name}
                            description={selectedProduct.description}
                            selectedTheme={selectedTheme}
                            directStepNavigation={this.directStepNavigation}
                            orderTypes={this.props.orderTypes}
                        />}
                    <div>
                        <ProgressBar
                            filledBackground={selectedTheme.primaryColor}
                            percent={progressIndicatorPercentages}
                        />
                    </div>

                    {this.renderBody(isLowResTabletPortraitMode)}

                    <Footer
                        step={step}
                        innerStep={innerStep}
                        navigate={this.navigateBackAndForth}
                        incDecProductQty={this.incDecProductQty}
                        addToCart={this.addToCart}
                        selectedTheme={selectedTheme}
                        product={this.state.selectedProduct} // needed for price calculation display
                        restaurant={this.props.selectedRestaurant} // needed for topping substitution
                        selectedMenuId={this.props.selectedMenuId}
                        menus={this.props.menus}
                        categories={this.props.categories}
                        products={this.props.products}
                        productQty={selectedProduct.quantity ? selectedProduct.quantity : 1}
                        productPrice={selectedProduct.price}
                        currency={currency}
                        changeNotes={this.changeChefsNotes}
                        instructions={selectedProduct.mealDeal && selectedProduct.questions.length ? this.state.mealDealNotesByStep[step].instruction.notes : selectedProduct.instruction ? selectedProduct.instruction.notes : ''}
                        disableNotes={disableNotes}

                        isLowResTabletPortraitMode={isLowResTabletPortraitMode}

                        setMinMaxValidation={this.setMinMaxValidation}
                        blockBackBtn={this.isBackBtnBlocked()}
                        isLastStep={this.isLastStepCalc()}
                        allowValidationClick={this.validateStep().allowValidationClick}
                        blockNextBtn={this.validateStep().blockNextBtn}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProp = state => {
    return {
        language: state.storeLocalStorage.language,
        customerSavedRestaurants: state.customerSavedRestaurants,
        estimateOrderTime: state.estimateOrderTime,
        restaurant: state.selectedRestaurant
    }
}

export default connect(mapStateToProp)(ProductWizard);