import React from 'react';

const WarningSvg = ({ width, height, fill, style }) => {
    const viewBoxValues = "0 0 24 24";
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} style={style} viewBox={viewBoxValues}>
            {/*<path d="M0 0h24v24H0z"/>*/}
            <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" fill={fill}/>
        </svg>
    );
};

export default WarningSvg;

