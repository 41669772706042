import React, { Component } from 'react';
import { Col } from 'reactstrap';
import Axios from 'axios';
import i18n from '../../../../i18next';

import { ARABIC, SERVER_URL } from '../../../../utils/Constants';
import { shoppingCartCalculations } from '../../../../utils/ShoppingCartUtils';
import GenericMonoIconSVG from '../cardIcons/mono/GenericMonoSVG';
import AmexIconSVG from '../cardIcons/flat-rounded/AmexIconSVG';
import DinersIconSVG from '../cardIcons/flat-rounded/DinersSVG';
import VisaSVG from '../cardIcons/flat-rounded/VisaSVG';
import DiscoverSVG from '../cardIcons/flat-rounded/DiscoverSVG';
import JcbSVG from '../cardIcons/flat-rounded/JcbSVG';
import MaestroSVG from '../cardIcons/flat-rounded/MaestroSVG';
import MastercardSVG from '../cardIcons/flat-rounded/MastercardSVG';
import HippercardSVG from '../cardIcons/flat-rounded/HipercardSVG';
import EloSVG from '../cardIcons/flat-rounded/EloSVG';
import MirSVG from '../cardIcons/flat-rounded/MirSVG';
import GenericCardSVG from '../cardIcons/flat-rounded/GenericCardSVG';

class BraintreeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSaveCardAllowed: false,
            isCardFormHidden: true,
            isSavedCard: false,
            isCardFieldValid: true,
            cardType: null,
            hostedFieldsCalled: false
        }

        this.braintreeFormRef = React.createRef();
        this.changeState = this.changeState.bind(this);
    }

    componentDidMount() {
        const { selectedPaymentType, selectedPaymentCard } = this.props.payment;
        this.toggleCardFields(selectedPaymentType.id, selectedPaymentCard);
    }

    componentDidUpdate(prevProps) {
        const { selectedPaymentType, selectedPaymentCard } = this.props.payment;

        if (prevProps.payment.selectedPaymentType !== selectedPaymentType || prevProps.payment.selectedPaymentCard !== selectedPaymentCard) {
            this.toggleCardFields(selectedPaymentType.id, selectedPaymentCard);
        }

        if (selectedPaymentType.id === 1 && !this.state.hostedFieldsCalled) {
            setTimeout(() => this.callAuthorize(), 200);
            this.setState({ ...this.state, hostedFieldsCalled: true, isCardFormHidden: false });
        }
    }

    componentWillUnmount() {
        this.setState({ ...this.state, hostedFieldsCalled: false });
    }

    changeState() {
        this.setState({
            ...this.state,
            isSaveCardAllowed: !this.state.isSaveCardAllowed
        });
    }

    toggleCardFields(id, selectedPaymentCard) {
        this.setState({
            ...this.state,
            isCardFormHidden: id !== 1,
            isSavedCard: selectedPaymentCard.brand ? true : false
        });
    }

    async callAuthorize() {
        const { customer, selectedRestaurant, shoppingCart, paymentProvider, payment, estimateOrderTime } = this.props;
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total : null;
        let that = this, threeDSecure, providerID = paymentProvider.providerType.id, form = document.getElementById('braintreeForm');
        const { total } = shoppingCartCalculations(shoppingCart, selectedRestaurant, undefined, estimateOrderTime.taxIncludeInPrice);
        const currentTotal = shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : total;

        try {
            const header = { headers: { 'Authorization': `TOKEN${customer.token}` } };
            const response = await Axios.post(`${SERVER_URL}/payment/authorize`, { customerId: customer.id, restaurantId: selectedRestaurant.restaurantId, currency: selectedRestaurant.currency, amount: currentTotal }, header);

            window.braintree.client.create({
                authorization: response.data.brainTreeResponse.token
            }, (clientErr, clientInstance) => {
                if (clientErr) {
                    that.props.sendEmailReport(selectedRestaurant.restaurantId, clientErr, 'clientCreation', 'createHostedFields');
                    console.error(clientErr);
                    return;
                }
                // Checks if we're using previously saved cards
                let isSavedCard = that.returnIsSavedCard();
                if (!isSavedCard) {

                    window.braintree.threeDSecure.create({
                        version: 2, // Will use 3DS 2 whenever possible
                        client: clientInstance
                    }, function (threeDSecureErr, threeDSecureInstance) {
                        if (threeDSecureErr) {
                            // Handle error in 3D Secure component creation
                            that.props.sendEmailReport(selectedRestaurant.restaurantId, threeDSecureErr, 'threeDSecureCreation');
                            console.error(threeDSecureErr);
                            return;
                        }
                        // console.log('threeDSecureInstance ', threeDSecureInstance);
                        threeDSecure = threeDSecureInstance;
                    });
                }

                // This example shows Hosted Fields, but you can also use this
                // client instance to create additional components here, such as
                // PayPal or Data Collector.

                window.braintree.hostedFields.create({
                    client: clientInstance,
                    styles: {
                        'input': {
                            'font-size': '16px'
                        },
                        'input.invalid': {
                            'color': '#eb1c26'
                        },
                        'input.valid': {
                            // 'color': 'green'
                        }
                    },
                    fields: {
                        number: {
                            container: '#braintree__number',
                            placeholder: i18n.t('screens:checkoutScreen.cardNumber')
                        },
                        expirationDate: {
                            container: '#braintree__expiration',
                            placeholder: 'MM / YY'
                        },
                        cvv: {
                            container: '#braintree__cvc',
                            placeholder: i18n.t('screens:checkoutScreen.cvc')
                        },
                        // postalCode: {
                        //     selector: '#braintree__postal',
                        //     placeholder: 'ZIP'
                        // }
                    }
                },
                    function (hostedFieldsErr, hostedFieldsInstance) {
                        if (hostedFieldsErr) {
                            that.props.sendEmailReport(selectedRestaurant.restaurantId, hostedFieldsErr, 'createHostedFields');
                            console.error(hostedFieldsErr);
                            return;
                        }
                        // console.log('hostedFieldsInstance ', hostedFieldsInstance, ' isSavedCard ', that.returnIsSavedCard());
                        // TODO check why is saved card not working
                        if (!isSavedCard) {
                            hostedFieldsInstance.on('blur', function (event) {
                                // console.log('blur ', event);
                                that.validateCardField(event);
                            });

                            hostedFieldsInstance.on('focus', function (event) {
                                // console.log('focus ', event);
                                that.setCardFieldValidInvalid(true);
                            });

                            hostedFieldsInstance.on('cardTypeChange', function (event) {
                                // console.log('cardTypeChange', event.cards.length)
                                if (event.cards.length === 1) {
                                    that.setCardType(event.cards[0].type);
                                } else {
                                    that.setCardType(null);
                                }
                            })

                            hostedFieldsInstance.on('validityChange', function (event) {
                                // console.log('hostedFieldsInstance ', event);
                                var allValid = true;
                                var key;

                                for (key in event.fields) {
                                    if (event.fields[key].isValid === false) {
                                        allValid = false;
                                        break;
                                    }
                                }

                                if (allValid) {
                                    that.props.validateCardInput(true);
                                } else {
                                    that.props.validateCardInput(false);
                                }
                            });
                        }

                        form.addEventListener('submit', function (event) {
                            event.preventDefault();
                            that.props.processingPayment();
                            isSavedCard = that.returnIsSavedCard();
                            if (!isSavedCard) {

                                hostedFieldsInstance.tokenize(function (tokenizeErr, payload) {
                                    if (tokenizeErr) {
                                        // that.props.sendEmailReport(selectedRestaurant.restaurantId, tokenizeErr, 'tokenizeCard');
                                        console.error(tokenizeErr);
                                        that.props.errorProcessingPayment(tokenizeErr, 'tokenize', providerID);
                                        return;
                                    }
                                    
                                    let threeDSecureParameters = {
                                        amount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : shoppingCart.total,
                                        bin: payload.bin,
                                        nonce: payload.nonce,
                                        email: that.props.customer.email, // optional
                                        billingAddress: {                 // optional
                                            phoneNumber: that.props.customer.phone,
                                            countryCodeApfa2: that.props.customer.countryCode
                                        },
                                        onLookupComplete: function (data, next) { // TODO find out what do I need this data for
                                            // // use `data` here, then call `next()`
                                            // console.log('onLookupComplete ', data);
                                            next();
                                        }
                                    }

                                    threeDSecure.verifyCard(threeDSecureParameters, function (err, response) {
                                        // Handle err/response
                                        if (err) {
                                            that.props.sendEmailReport(selectedRestaurant.restaurantId, err, 'verifyCard');
                                            that.props.errorProcessingPayment(err, err.type, providerID);
                                            
                                            // console.log('ERROR verifyCard ', err);
                                            return;
                                        }

                                        let isVerified = false;
                                        switch (response.threeDSecureInfo.status) {
                                            case 'authenticate_successful':
                                                isVerified = true;
                                                break;
                                            case 'authenticate_attempt_successful':
                                                isVerified = true;
                                                break;
                                            default:
                                                break;
                                        }

                                        // send the nonce to your server.
                                        if (isVerified) {
                                            let providerData = {
                                                clientNonce: response.nonce,
                                                cardToken: null,
                                                saveCard: that.returnSaveCardValue(), // TODO add this from state 
                                                last4Digits: payload.details.lastFour,
                                                transactionId: null
                                            }

                                            that.props.placeOrder(false, providerData);
                                        }
                                        if (!response.liabilityShifted && !response.verificationDetails) {
                                            hostedFieldsInstance.clear('number');
                                            hostedFieldsInstance.clear('expirationDate');
                                            hostedFieldsInstance.clear('cvv');

                                            that.props.errorProcessingPayment(response.threeDSecureInfo.status, response.threeDSecureInfo.status, providerID);
                                            // that.props.resetSendingOrderModal();
                                        } else if (!response.liabilityShifted && response.verificationDetails) {
                                            hostedFieldsInstance.clear('number');
                                            hostedFieldsInstance.clear('expirationDate');
                                            hostedFieldsInstance.clear('cvv');

                                            that.props.sendEmailReport(selectedRestaurant.restaurantId, response.threeDSecureInfo.status, 'verifyCard');
                                            that.props.errorProcessingPayment(response.threeDSecureInfo.status, response.threeDSecureInfo.status, providerID);
                                        }
                                    });
                                });
                            } else {
                                const { cardToken, lastDigits } = that.returnSavedCardValues();

                                let providerData = {
                                    clientNonce: null,
                                    cardToken: cardToken,
                                    saveCard: false,
                                    last4Digits: lastDigits,
                                    transactionId: null
                                }

                                that.props.placeOrder(false, providerData);
                            }
                        }, false);
                    });

            });
        } catch (error) {
            that.props.sendEmailReport(selectedRestaurant.restaurantId, error, 'authorizationFailed');
        }
    }

    validateCardField(event) {
        let key, field, isAnyFalse;
        for (key in event.fields) {
            field = event.fields[key];
            if (!field.isEmpty && !field.isValid) {
                isAnyFalse = true;
                this.setCardFieldValidInvalid(field.isValid);
            } else {
                this.setCardFieldValidInvalid(field.isValid);
            }
        }
        if (isAnyFalse) {
            this.setCardFieldValidInvalid(false);
        }
    }

    returnSaveCardValue() {
        return this.state.isSaveCardAllowed;
    }

    returnIsSavedCard() {
        return this.state.isSavedCard;
    }

    returnSavedCardValues() {
        const { payment } = this.props;
        
        return {
            cardToken: payment.selectedPaymentCard.paymentCardId,
            lastDigits: payment.selectedPaymentCard.lastDigits
        };
    }

    setCardFieldValidInvalid(isValid) {
        this.setState({
            ...this.state,
            isCardFieldValid: isValid
        });
    }

    setCardType(type) {
        this.setState({
            ...this.state,
            cardType: type
        });
    }

    renderCardIcon() {
        const width = 32, height = 32;
        switch (this.state.cardType) {
            case "visa":
                return <VisaSVG width={width} height={height} />;
            case "diners-club":
                return <DinersIconSVG width={width} height={height} />;
            case "discover":
                return <DiscoverSVG width={width} height={height} />;
            case "jcb":
                return <JcbSVG width={width} height={height} />;
            case "maestro":
                return <MaestroSVG width={width} height={height} />;
            case "elo":
                return <EloSVG width={width} height={height} />;
            case "hiper":
            case "hipercard":
                return <HippercardSVG width={width} height={height} />;
            case 'american-express':
                return <AmexIconSVG width={width} height={height} />;
            case "master-card":
                return <MastercardSVG width={width} height={height} />;
            case "mir":
                return <MirSVG width={width} height={height} />;
            case "unionpay":
                return <GenericCardSVG width={width} height={height} />;
            default:
                return <GenericMonoIconSVG width={width} height={height} />;
        }
    }

    render() {
        const isMobile = window.innerWidth <= 865;
        const isArabic = this.props.language === ARABIC;
        
        return (
            <Col xs={12} sm={12} md={8} style={{ margin: 'auto', paddingBottom: 10, padding: isMobile ? 0 : 'auto' }}>
                <div 
                    className='checkout'
                    id='card-element'
                    hidden={this.state.isCardFormHidden}
                    style={{ border: `1px solid ${this.state.isCardFieldValid ? '#d3d3d3' : '#eb1c26'}` }}>
                    <form id='braintreeForm' style={{ display: 'flex', flexDirection: 'row', border: '1px solid transparent', boxShadow: '0 1px 3px 0 #e6ebf1', transition: 'box-shadow .15s ease', }}>
                        <div style={{ height: 46, padding: '7px 0 7px 6px', width: 42 }}>{this.renderCardIcon()}</div>
                        <div id='braintree__number' style={{ height: 46, padding: !isMobile ? '12px 0 10px 12px' : '12px 0 10px 6px', width: isMobile ? '50%' : '65%' }}></div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '35%' }}>
                            <div id='braintree__expiration' style={{ height: 46, padding: !isMobile ? '12px 0 10px 12px' : '12px 0 10px 6px' }}></div>
                            <div id='braintree__cvc' style={{ height: 46, padding: !isMobile ? '12px 0 10px 12px' : '12px 0 10px 6px' }}></div>
                            {/* <div id='braintree__postal' style={{ height: 46, padding: '12px 0 10px 12px' }}></div> */}
                        </div>
                    </form>
                </div>
                <input
                    hidden={this.state.isCardFormHidden} disabled={this.props.payment.selectedPaymentCard.lastDigits}
                    type='checkbox' name='isSaveCardAllowed' id='isSaveCardAllowed' style={{ marginTop: 5, marginLeft: isMobile ? 40 : 0 }}
                    onChange={this.changeState}
                />
                <label hidden={this.state.isCardFormHidden} htmlFor='isSaveCardAllowed' style={{ margin: isArabic ? '5px 10px auto auto' : '5px auto auto 10px' }}>{i18n.t('screens:checkoutScreen.saveCardMsg')}</label>
            </Col>
        );
    }
}

export default BraintreeForm;