import React from 'react';

const LocationIconSVG = ({ width, height, fill, style }) => {
	const viewBoxValues = "0 0 65.8 98.5"
	
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBoxValues} style={style}>
			<g>
				<path fill={fill} d="M56.2,9.6C50,3.4,41.7,0,32.9,0c0,0,0,0,0,0c-8.7,0-17,3.5-23.2,9.7C3.4,16,0,24.3,0,33c0,6.7,2,13.1,5.8,18.6
					l27.1,46.9L60,51.7c3.8-5.5,5.9-12,5.9-18.7C65.8,24.1,62.4,15.9,56.2,9.6z M52.8,31.7c0,10.9-8.9,19.8-19.8,19.8
					c-10.9,0-19.8-8.9-19.8-19.8c0-10.9,8.9-19.8,19.8-19.8C43.9,11.8,52.8,20.7,52.8,31.7z"/>
			</g>
		</svg>
	);
}

export default LocationIconSVG;