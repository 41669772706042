import React from 'react';
import i18n from '../../../i18next';
import { isMobile, isMobileOnly } from 'mobile-device-detect';

import ShoppingCartSVG from '../../../pwa-app/components/common/svgComponents/ShoppingCartSVG';

const CartItemsButton = (props) => {

    const totalItemsCount = (orderProducts) => {
        let totalItemsCount = 0;

        orderProducts.map(product => {
            totalItemsCount += product.quantity;
        });

        return totalItemsCount;
    }

    const { type, primaryColor, secondaryColor, primaryFontColor, menuItemsBackground } = props.customerThemes.selectedTheme;
    const iconImageColor = type === 'DARK' ? secondaryColor : primaryColor;
    const { cartItemsContent, cartItemsContentRTL, shoppingCartIconStyle, shoppingCartIconStyleRTL } = styles;
    const isMenuHidden = (props.menus.length > 1) ? false : true;

    const mobileAndAppsCartButtonWithMultipleMenusStyle = {
        width: '18%',
        height: '50px',
        backgroundColor: menuItemsBackground,
        paddingLeft: '15px',
        right: '2px',
        top: '10px'
    }
    
    return (
        // <div onClick={() => props.customer.token ? props.toggleShoppingCartActive() : props.openLoginScreen()}
        <div onClick={props.toggleCartModal}
            className='cartItemsButton' 
            style={(props.pwaAppRunning || isMobileOnly || isMobile) && !isMenuHidden ? mobileAndAppsCartButtonWithMultipleMenusStyle : null }>
            <div style={{ paddingTop: 4 }}>
                <ShoppingCartSVG width={33} height={33} fill={iconImageColor} style={props.isArabic ? shoppingCartIconStyleRTL : shoppingCartIconStyle} isCartItemsButton={true} />
            </div>
            <div id="cartItemsIcon" style={ props.isArabic ? { ...cartItemsContentRTL, color: menuItemsBackground, backgroundColor: primaryColor, border: `1px solid ${menuItemsBackground} ` } : { ...cartItemsContent, color: menuItemsBackground, backgroundColor: primaryColor, border: `1px solid ${menuItemsBackground}`}}>
                <div style={{fontSize: '14px', padding: '3px'}} id="cartItemsNumber">{(props.shoppingCart.orderProducts !== undefined && props.shoppingCart.orderProducts !== null) ? `${totalItemsCount(props.shoppingCart.orderProducts)}` : `0`}</div>
            </div>
        </div>
    )
}

export default CartItemsButton;

const styles = {
    loggedUserStyle: {
        padding: 0,
        display: 'flex',
        marginRight: 15
    },
    loggedUserStyleRTL: {
        padding: 0,
        display: 'flex',
        marginLeft: 15
    },
    cartItemsContent: {
        width: '27px',
        height: '27px', 
        position: 'absolute',
        top: '0',
        textAlign: 'center',
        fontSize: '1.0625rem',
        borderRadius: '50%',
        alignItems: 'center'
    },
    cartItemsContentRTL: { 
        width: '27px',
        height: '27px', 
        position: 'absolute',
        top: '0',
        textAlign: 'center',
        fontSize: '1.0625rem',
        borderRadius: '50%',
        alignItems: 'center'
    },
    shoppingCartIconStyle: {
        height: 'auto',
        position: 'relative',
        left: 12,
        top: 6
    },
    shoppingCartIconStyleRTL: {
        height: 'auto',
        position: 'relative',
        right: 12,
        top: 6,
        left: 0
    },
}