import axios from '../../utils/AxiosUtils';

import {
    COLLECTION_ID,
    COLLECTION_INDEX,
    DELIVERY_ID,
    DELIVERY_INDEX,
    INSTORE_INDEX,
    CURBSIDE_INDEX
} from '../../utils/Constants';
import {
    CLEAR_SELECTED_PAYMENT_CARD,
    GET_CUSTOMER_SAVED_PAYMENT_CARDS_SUCCESS,
    LOAD_PAYMENT_TYPES_SUCCESS,
    SELECT_PAYMENT_CARD,
    SELECT_PAYMENT_TYPE,
    CARD_INPUT_VALIDATION
} from '../types';

export const getCustomerPaymentCards = () => {
    return (dispatch, getState) => {

        const customerId = getState().customer.id;
        const restaurantId = getState().selectedRestaurant.restaurantId;

        const url = `/payment/${customerId}/cards?restaurantId=${restaurantId}`;

        axios(getState).get(url)
            .then(response => getCustomerPaymentCardsSuccess(dispatch, response));
    }
};

const getCustomerPaymentCardsSuccess = (dispatch, response) => {

    dispatch({ type: GET_CUSTOMER_SAVED_PAYMENT_CARDS_SUCCESS, payload: response.data });
};

export const loadPaymentTypes = () => {
    return (dispatch, getState) => {

        let typeIndex;
        const restaurantId = getState().selectedRestaurant.restaurantId;
        const selectedOrderTypeId = getState().orderTypes.selectedOrderType.id;

        if (selectedOrderTypeId === COLLECTION_ID) {
            typeIndex = COLLECTION_INDEX;
        } else if (selectedOrderTypeId === DELIVERY_ID) {
            typeIndex = DELIVERY_INDEX;
        } else if (selectedOrderTypeId === INSTORE_INDEX) {
            typeIndex = INSTORE_INDEX;
        } else if (selectedOrderTypeId === CURBSIDE_INDEX) {
            typeIndex = CURBSIDE_INDEX;
        }

        const url = `/restaurant/${restaurantId}/supported-tender-types?typeIndex=${typeIndex}`;

        axios(getState).get(url)
            .then(response => loadPaymentTypesSuccess(dispatch, response));
    }
};

const loadPaymentTypesSuccess = (dispatch, response) => {

    dispatch({ type: LOAD_PAYMENT_TYPES_SUCCESS, payload: response.data });
};

export const selectPaymentType = (paymentType, callback) => {
    return (dispatch) => {

        dispatch({ type: SELECT_PAYMENT_TYPE, payload: paymentType });

        if (callback) {
            callback();
        }
    };
};

export const selectCustomerPaymentCard = (paymentCard) => {
    return (dispatch) => {

        const data = {
            paymentCardDTO: paymentCard
        };

        dispatch({ type: SELECT_PAYMENT_CARD, payload: data });
    };
};

export const clearPaymentCard = () => {
    return (dispatch) => {

        dispatch({ type: CLEAR_SELECTED_PAYMENT_CARD });
    };
};

export const validateCardInput = (isCardValid) => {
    return (dispatch) => {

        dispatch({ type: CARD_INPUT_VALIDATION, payload: isCardValid });
    };
};