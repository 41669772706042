import {
    CHANGE_WANTED_TIME,
    CLEAR_APP_STATE,
    CLEAR_ORDER_WIZARD,
    CLEAR_STATE,
    GET_ESTIMATE_ORDER_TIME_SUCCESS, SAVE_ESTIMATED_ORDER_TIME,
    SAVE_ORDER_TIME_DETAILS,
    SAVE_ORDER_TIME_TAX_FLAG_PREVIEW_MODE,
    SAVE_IN_STORE_WORKING_DAY,
    SAVE_COOK_TIME
} from '../../../actions/types';

const INITIAL_STATE = {
    estimateOrderTime: null,
    taxIncludeInPrice: false,
    workingHoursByDate: [],
    asap: '',
    selectTime: '',
    date: '',
    time: null,
    futureOrder: null,
    wantedTime: '',
    timeSlotBased: false,
    timeSlotsByDate: null,
    cookTime: null,
    showMenuuLogo: false,
    enableLargeImageDesign: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHANGE_WANTED_TIME:
            return { ...state, wantedTime: action.payload };
        case GET_ESTIMATE_ORDER_TIME_SUCCESS:
            return action.payload;
        case SAVE_ORDER_TIME_DETAILS:
            return action.payload;
        case SAVE_ESTIMATED_ORDER_TIME:
            return { ...state, estimateOrderTime: action.payload };
        case SAVE_ORDER_TIME_TAX_FLAG_PREVIEW_MODE:
            return { ...state, taxIncludeInPrice: action.payload.taxIncludeInPrice, showMenuuLogo: action.payload.showMenuuLogo, enableLargeImageDesign: action.payload.enableLargeImageDesign };
        case SAVE_IN_STORE_WORKING_DAY:
            let workingHoursByDateCopy = [];
            workingHoursByDateCopy.push(action.payload.workingDay);
            return {
                ...state,
                workingHoursByDate: workingHoursByDateCopy,
                estimateOrderTime: action.payload.estimateOrderTime,
                taxIncludeInPrice: action.payload.taxIncludeInPrice,
                showMenuuLogo: action.payload.showMenuuLogo
            };
        case SAVE_COOK_TIME:
            return { ...state, cookTime: action.payload};
        case CLEAR_ORDER_WIZARD:
            if (action.fromPwa) {
                return state;
            } else {
                return INITIAL_STATE;
            }
        case CLEAR_STATE:
            if (action.fromPwa) {
                return state;
            } else {
                return INITIAL_STATE;
            }
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}