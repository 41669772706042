import { combineReducers } from 'redux';
import PwaAppRunning from './PwaAppRunning';
import LoadingReducer from './LoadingReducer';
import ConnectionStatusReducer from './ConnectionStatusReducer';
import ErrorMessageReducer from './ErrorMessageReducer';
import AlertMessageReducer from './AlertMessageReducer';
import OrderTypesReducer from './order/start/OrderTypesReducer';
import BrandReducer from './order/start/BrandReducer';
import RestaurantsReducer from './order/start/RestaurantsReducer';
import CustomerReducer from './login/CustomerReducer';
import CustomerSavedRestaurantsReducer from './order/start/CustomerSavedRestaurantsReducer';
import CustomerSavedAddressesReducer from './order/start/CustomerSavedAddressesReducer';
import SelectedRestaurantReducer from "./order/start/SelectedRestaurantReducer";
import OrderTimeReducer from './order/start/OrderTimeReducer';
import UserAddressDeliveryZoneReducer from './order/start/UserAddressDeliveryZoneReducer';
import MenusReducer from "./order/MenusReducer";
import CategoriesReducer from "./order/CategoriesReducer";
import ProductsReducer from "./order/ProductsReducer";
import ShoppingCartReducer from './order/ShoppingCartReducer';
import SelectedProductReducer from './product/SelectedProductReducer';
import StartOrderReducer from "./order/start/StartOrderReducer";
import PaymentsReducer from "./order/PaymentsReducer";
import SendingOrderModalReducer from "./order/SendingOrderModalReducer";
import TemporaryOrderTimeReducer from "./order/TemporaryOrderTimeReducer";
import AvailableDiscountsReducer from './order/discounts/AvailableDiscountsReducer';
import MenusPreviewReducer from "./order/MenusPreviewReducer";
import OrderHistoryReducer from "./profile/OrderHistoryReducer";
import MarketingPreferencesReducer from "./profile/MarketingPreferencesReducer";
import ThemesReducer from './themes/ThemesReducer';
import OpenedProductsReducer from './product/OpenedProductsReducer';
import MainScreenPwaMapReducer from "./order/start/MainScreenPwaMapReducer";
import VersionControlReducer from "./VersionControlReducer";
import LoyaltyReducer from './order/discounts/LoyaltyReducer';
import TimeWarningReducer from "./TimeWarningReducer";
import UpsellReducer from './order/UpsellReducer';
import MenuDescriptionReducer from './order/MenuDescriptionReducer';
import PaymentProvidersReducer from './order/start/PaymentProvidersReducer';
import StoreLocalStorage from './StoreLocalStorageReducer';
import CommonReducer from './CommonReducer';
import ComoReducer from './promotions/como/ComoReducer';
import PromotionPartnersReducer from './promotions/PromotionPartnersReducer';
import GiftCardReducer from './giftCardProviders/GiftCardReducer';
import ProductsQtyReducer from './product/ProductsQtyReducer';

const rootReducer = combineReducers({
    pwaAppRunning: PwaAppRunning,
    loading: LoadingReducer,
    userOnline: ConnectionStatusReducer,
    errorMessage: ErrorMessageReducer,
    pwaMapZoomLevel: MainScreenPwaMapReducer,
    alertMessage: AlertMessageReducer,
    startOrder: StartOrderReducer,
    orderTypes: OrderTypesReducer,
    orderHistory: OrderHistoryReducer,
    brand: BrandReducer,
    restaurantsInZone: RestaurantsReducer,
    customer: CustomerReducer,
    customerSavedRestaurants: CustomerSavedRestaurantsReducer,
    customerSavedAddresses: CustomerSavedAddressesReducer,
    payment: PaymentsReducer,
    selectedRestaurant: SelectedRestaurantReducer,
    estimateOrderTime: OrderTimeReducer,
    temporaryOrderTime: TemporaryOrderTimeReducer,
    deliveryData: UserAddressDeliveryZoneReducer,
    restaurantMenus: MenusReducer,
    menuPreviewMode: MenusPreviewReducer,
    restaurantCategories: CategoriesReducer,
    restaurantProducts: ProductsReducer,
    shoppingCart: ShoppingCartReducer,
    selectedProduct: SelectedProductReducer,
    sendOrderModal: SendingOrderModalReducer,
    availableDiscounts: AvailableDiscountsReducer,
    marketingPreferences: MarketingPreferencesReducer,
    customerThemes: ThemesReducer,
    openedProducts: OpenedProductsReducer,
    checkVersion: VersionControlReducer,
    loyalty: LoyaltyReducer,
    timeWarningPopup: TimeWarningReducer,
    upsellProducts: UpsellReducer,
    menuDescription: MenuDescriptionReducer,
    paymentProvider: PaymentProvidersReducer,
    storeLocalStorage: StoreLocalStorage,
    commonReducer: CommonReducer,
    promotionPartners: PromotionPartnersReducer,
    como: ComoReducer,
    giftCards: GiftCardReducer,
    productsQty: ProductsQtyReducer
});

export default rootReducer;