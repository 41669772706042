import React from 'react';

const HomeSVG = ({ width, height, fill, style }) => {
	const viewBoxValues = "0 0 105.1 98.7";

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBoxValues} style={style}>
			<g>
				<path fill={fill} d="M90.4,59C78.3,46.4,66.1,33.7,54,21c-1.2-1.2-2-1.2-3.2,0C38.6,33.8,26.4,46.5,14.2,59.3c-1.4,1.4-2,3-2,5
					c0,10.1,0,20.3,0,30.4c0,2.7,1.3,4.1,4.1,4.1c6.8,0,13.6,0,20.5,0c2.8,0,4.3-1.5,4.3-4.3c0-8.1,0-16.2,0-24.2
					c0-2.5,1.4-3.9,3.9-3.9c5.1,0,10.3,0,15.4,0c2.7,0,4.1,1.4,4.1,4.1c0,8.1,0,16.1,0,24.2c0,2.7,1.5,4.2,4.2,4.2c6.7,0,13.4,0,20.1,0
					c2.6,0,4-1.4,4-4c0-5.1,0-10.3,0-15.4c0-4.9,0-9.8,0-14.7C92.7,62.4,92,60.6,90.4,59z"/>
				<path fill={fill} d="M103.7,52.6c-16.3-17.1-32.7-34.1-49-51.2c-1.7-1.8-2.9-1.8-4.6,0C40,11.9,29.9,22.5,19.7,33.1
					C13.5,39.6,7.2,46.1,1,52.7c-1.3,1.4-1.3,2.9,0,4.2c1.1,1.2,2.3,2.4,3.4,3.6c0.6,0.6,1.4,1.1,2.2,0.8c0.7-0.2,1.4-0.7,2-1.2
					c13.9-14.4,27.7-28.9,41.5-43.3c1.6-1.6,3.1-1.7,4.7,0c6.5,6.8,13.1,13.7,19.6,20.5c7.4,7.7,14.7,15.4,22.1,23.1
					c1.4,1.4,2.7,1.4,4.1,0c1.1-1.2,2.3-2.3,3.4-3.5c0.5-0.5,0.7-1.2,1.1-2C104.6,54,104.3,53.1,103.7,52.6z"/>
				<path fill={fill} d="M74.3,16.7c3,3.1,5.9,6.2,8.9,9.2c0.3,0.3,0.8,0.4,1.2,0.6c0.2-0.4,0.5-0.9,0.6-1.3c0-3.3,0-6.7,0-10
					c0-3.3,0-6.7,0-10c0-1.9-1-2.8-2.9-2.8c-2.1,0-4.2,0-6.3,0c-2,0-2.9,1-2.9,3c0,2.6,0,5.3,0,7.9C72.9,14.7,73.4,15.8,74.3,16.7z"/>
			</g>
		</svg>
	);
}

export default HomeSVG;