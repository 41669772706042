import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import '../../styles/DrawerContent.css';
import { logoutCustomer, openChangeOrderTimeScreen } from "../../../actions";
import { connect } from 'react-redux';
import i18n from '../../../i18next';

import { updateCSSVariables } from "../../../utils/ThemesSelector";
import { setLastOpenedPagePWA } from "../../../utils/CommonUtils";

import UserIconSecondarySVG from '../common/svgComponents/UserIconSecondarySVG';
import ClockSVG from '../common/svgComponents/ClockSVG';
import LocationIconSVG from '../common/svgComponents/LocationIconSVG';
import CreditCardSVG from '../common/svgComponents/CreditCardSVG';
import TrendingUpSVG from '../common/svgComponents/TrendingUpSVG';
import LogoutSVG from '../common/svgComponents/LogoutSVG';
import LoyaltyIconSvg from '../common/svgComponents/LoyaltyIconSVG';
import MenuuLogoSVG from '../common/svgComponents/MenuuLogoSVG';
import { ARABIC } from '../../../utils/Constants';

class DrawerContent extends Component {

    componentDidMount() {
        this.applyTheme();
    }

    applyTheme() {
        const { colorsCss, primaryColor } = this.props.customerThemes.selectedTheme;
        updateCSSVariables(colorsCss);
        let customizedStyles = JSON.parse(JSON.stringify(styles));

        customizedStyles.currentLink.color = primaryColor;
        styles = customizedStyles;
        return styles;
    }


    logoutCustomer() {
        const isPWA = true;
        this.props.logoutCustomer(isPWA);
    }


    renderSideMenuIcon(icon) {
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const isArabic = this.props.language === ARABIC;

        switch (icon) {
            case 'person':
                return <UserIconSecondarySVG width={25} height={25} fill={svgColor} style={isArabic ? {marginLeft: '3px'} : null}/>;
            case 'history':
                return <ClockSVG width={23} height={23} fill={svgColor} style={isArabic ? {marginLeft: '3px'} : null}/>;
            case 'location_on':
                return <LocationIconSVG width={24} height={24} fill={svgColor} style={isArabic ? {marginLeft: '3px'} : null}/>;
            case 'credit_card':
                return <CreditCardSVG width={24} height={24} fill={svgColor} style={isArabic ? {marginLeft: '3px'} : null}/>;
            case 'trending_up':
                return <TrendingUpSVG width={24} height={24} fill={svgColor} style={isArabic ? {transform: 'scaleX(-1)', marginLeft: '3px'} : null} />;
            case 'exit_to_app':
                return <LogoutSVG width={24} height={24} fill={svgColor} style={isArabic ? {transform: 'scaleX(-1)', marginLeft: '3px'} : null} />;
            case 'loyalty':
                return <LoyaltyIconSvg width={24} height={24} fill={svgColor} style={isArabic ? {transform: 'scaleX(-1)', marginLeft: '3px'} : null}/>;
            default:
                return;
        }
    }

    onClickLogin() {
        setLastOpenedPagePWA(this.props.openChangeOrderTimeScreen);
    }

    render() {
        const brandId = this.props.brand.id;
        const { currentLink } = styles;
        const { menuuLogoColor, menuItemsBackground, primaryFontColor } = this.props.customerThemes.selectedTheme;

        return (
            <div className='drawerContent' style={{backgroundColor: menuItemsBackground, color: primaryFontColor }}>
                <div className="inner">
                    {!this.props.customer.token ? (
                        <React.Fragment>
                            <Link className='drawerLink' to={`/brand/${brandId}/profile/login/phone-number`}
                                onClick={this.onClickLogin.bind(this)}>{this.renderSideMenuIcon("input")}{i18n.t('screens:profileScreen.login')}</Link>
                        </React.Fragment>
                    ) :
                        (
                            <React.Fragment>
                                <Link className='drawerLink'
                                    to={`/brand/${brandId}/profile/details`}
                                    onClick={this.props.toggleDrawer}
                                    style={(this.props.currentPath.endsWith('details')) ? currentLink : {}}
                                >{this.renderSideMenuIcon("person")}{i18n.t('screens:profileScreen.profileDetails')}</Link>
                                <Link className='drawerLink'
                                    to={`/brand/${brandId}/profile/order-history`}
                                    onClick={this.props.toggleDrawer}
                                    style={(this.props.currentPath.endsWith('order-history')) ? currentLink : {}}
                                >{this.renderSideMenuIcon("history")}{i18n.t('screens:profileScreen.orderHistory')}</Link>
                                <Link className='drawerLink'
                                    to={`/brand/${brandId}/profile/addresses`}
                                    onClick={this.props.toggleDrawer}
                                    style={(this.props.currentPath.endsWith('addresses')) ? currentLink : {}}
                                >{this.renderSideMenuIcon("location_on")}{i18n.t('screens:profileScreen.addresses')}</Link>
                                <Link className='drawerLink'
                                    to={`/brand/${brandId}/profile/cards`}
                                    onClick={this.props.toggleDrawer}
                                    style={(this.props.currentPath.endsWith('cards')) ? currentLink : {}}
                                >{this.renderSideMenuIcon("credit_card")}{i18n.t('screens:profileScreen.savedCards')}</Link>
                                {
                                    this.props.loyalty.loyalty.id ?
                                        <Link className='drawerLink'
                                            to={`/brand/${brandId}/profile/loyalty`}
                                            onClick={this.props.toggleDrawer}
                                            style={(this.props.currentPath.endsWith('loyalty')) ? currentLink : {}}
                                        >{this.renderSideMenuIcon("loyalty")}{i18n.t('screens:profileScreen.loyalty')}</Link> : null
                                }
                                <Link className='drawerLink'
                                    to={`/brand/${brandId}/profile/marketing`}
                                    onClick={this.props.toggleDrawer}
                                    style={(this.props.currentPath.endsWith('marketing')) ? currentLink : {}}
                                >{this.renderSideMenuIcon("trending_up")}{i18n.t('screens:profileScreen.marketingPrefrences')}</Link>
                                <Link to={`/brand/${brandId}/start-order/main-screen`} className='drawerLink' onClick={() => this.logoutCustomer()}
                                >{this.renderSideMenuIcon("exit_to_app")}{i18n.t('screens:profileScreen.logout')}</Link>
                            </React.Fragment>
                        )}
                </div>
                <hr />
                <br />
                <div className='drawerLink' onClick={() => { window.cordova.InAppBrowser.open('https://menuu.com/faq', '_blank', 'location=no') }}>
                    {this.renderSideMenuIcon("question_answer")}{i18n.t('screens:profileScreen.faq')}
                </div>
                <div className='drawerLink' onClick={() => { window.cordova.InAppBrowser.open('https://menuu.com/terms-and-policies', '_blank', 'location=no') }}>
                    {this.renderSideMenuIcon("info_outline")}{i18n.t('screens:profileScreen.terms')}    &amp; {i18n.t('screens:profileScreen.privacy')}
                </div>
                <MenuuLogoSVG width={120} height={47} fill={menuuLogoColor} style={{ cursor: 'pointer', position: 'absolute', bottom: 10, left: 0, width: '50%', margin: '0 25%' }} onClick={() => { window.open("https://menuu.com/?utm_source=menuu&utm_medium=link&utm_campaign=powered-by-menuu", '_blank') }} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        brand: state.brand,
        customer: state.customer,
        customerThemes: state.customerThemes,
        loyalty: state.loyalty,
        language: state.storeLocalStorage.language
    }
}

export default connect(mapStateToProps, { logoutCustomer, openChangeOrderTimeScreen })(DrawerContent);

let styles = {
    currentLink: {
        fontWeight: '900'
    }
}