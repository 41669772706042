import axios from '../../../utils/AxiosUtils';
import i18n from '../../../i18next';

import {
    LOADING_NO_BACKGROUND,
    GET_CURRENT_THEME_SUCCESS,
    SAVE_RESTAURANT_DATA_FOR_IN_STORE,
    SELECT_ORDER_TYPE,
    SAVE_IN_STORE_WORKING_DAY,
    SAVE_IN_STORE_BRAND_IMGS,
    SAVE_IN_STORE_BRAND_DATA,
    SAVE_IN_STORE_INFO,
    ERROR_RESPONSE,
    ERROR_RESPONSE_RESET,
    CLEAR_STATE,
    GET_LANGUAGE_SUCCESS,
    GET_SELECTED_LANGUAGE_SUCCESS,
    IS_DEFAULT_LANGUAGE
} from '../../types';
import { matchThemes } from '../../../utils/ThemesSelector';
import {
    getPaymentProvider,
    clearStoreLocalStorageState,
    getPromotionPartner
} from '../../index';
import { changeRTL } from '../../../utils/CommonUtils';
import { parentLocalStorage } from '../../../utils/CrossOriginUtils';

export const getRestaurantData = (restaurantId, typeId, callback) => {
    return (dispatch, getState) => {

        dispatch({ type: LOADING_NO_BACKGROUND, payload: true });
        const url = `/restaurant/${restaurantId}/dine-pay/info?typeId=${typeId}`;
        // resets errorResponse flag before reloading
        dispatch({ type: ERROR_RESPONSE_RESET });

        let isDefaultLanguage = getState().storeLocalStorage.isDefaultLanguage;
        let selectedLanguage = getState().storeLocalStorage.selectedLanguage;
        // These two lines below clear the whole app state
        // preventing caching restaurant/user data and menus
        dispatch({ type: CLEAR_STATE });
        parentLocalStorage('clear');
        dispatch(clearStoreLocalStorageState());

        axios(getState).get(url)
            .then(response => getRestaurantDataSuccess(response, dispatch, getState, callback, restaurantId, isDefaultLanguage, selectedLanguage))
            .catch(err => instoreError(err, dispatch, getState));
    }
}

const getRestaurantDataSuccess = (response, dispatch, getState, callback, restaurantId, isDefaultLanguage, selectedLanguage) => {

    const { customerThemes } = getState();
    // Theme Data
    let currentTheme, serverTheme;
    serverTheme = response.data.theme;
    currentTheme = matchThemes(serverTheme, customerThemes);

    if (isDefaultLanguage) {
        dispatch({ type: IS_DEFAULT_LANGUAGE, payload: true });
    }

    dispatch({ type: GET_CURRENT_THEME_SUCCESS, payload: currentTheme });
    // Restaurant Data
    dispatch({ type: SAVE_RESTAURANT_DATA_FOR_IN_STORE, payload: response.data });
    // OrderType Data
    dispatch({ type: SELECT_ORDER_TYPE, payload: { value: 'In Store', id: 5 } });
    dispatch({
        type: SAVE_IN_STORE_BRAND_IMGS,
        payload: {
            bannerImagePath: response.data.bannerImagePath,
            logoImagePath: response.data.logoImagePath,
            selectedOrderType: {
                value: 'In Store',
                id: 5
            }
        }
    });
    // Get promo partner
    dispatch(getPromotionPartner(response.data.promotionPartner));
    // Working day Data
    dispatch({ type: SAVE_IN_STORE_WORKING_DAY, payload: { workingDay: response.data.workingDay, estimateOrderTime: response.data.estimateOrderTime, taxIncludeInPrice: response.data.taxIncludedInPrice, showMenuuLogo: response.data.showMenuuLogo } });
    // Brand Data
    dispatch({ type: SAVE_IN_STORE_BRAND_DATA, payload: { id: response.data.restaurantChainId, name: response.data.restaurantChainName, countryCode: response.data.address.country } });
    dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
    // Payment providers Data
    dispatch(getPaymentProvider(restaurantId));
    // Changes the language and then dispatch to store triggering component rerender 

    dispatch({ type: GET_SELECTED_LANGUAGE_SUCCESS, payload: response.data.language })
    i18n.changeLanguage(isDefaultLanguage ? 'en' : response.data.language);
    changeRTL(isDefaultLanguage ? 'en' : response.data.language);
    dispatch({ type: GET_LANGUAGE_SUCCESS, payload: isDefaultLanguage ? 'en' : response.data.language });

    if (callback) {
        callback();
    }
};

const instoreError = (_, dispatch, getState) => {
    const { customerThemes } = getState();
    // Theme Data
    let currentTheme, serverTheme;
    serverTheme = { type: 'DARK' };
    currentTheme = matchThemes(serverTheme, customerThemes);
    dispatch({ type: ERROR_RESPONSE });
    dispatch({ type: GET_CURRENT_THEME_SUCCESS, payload: currentTheme });
    dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
}

export const saveInStoreInfo = (inStoreInfo) => {
    return (dispatch) => {
        dispatch({ type: SAVE_IN_STORE_INFO, payload: inStoreInfo });
    }
}

export const resetErrorResponseFlag = () => {
    return (dispatch) => {
        dispatch({ type: ERROR_RESPONSE_RESET });
    }
}