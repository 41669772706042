import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import MaterialIcon from 'material-icons-react';
import i18n from '../../i18next';
import { isMobile } from 'mobile-device-detect';
import ModalStep from '../common/ModalStep';
import { registerCustomer } from "../../actions/login/CustomerActions";
import { openStartOrderScreen, resetErrMsg } from '../../actions/index';
import { convertStringToBoolean } from "../../utils/CommonUtils";
import SocialUserCredentialsCommon from "../../pwa-app/components/profile/login/SocialUserCredentialsCommon";
import UserIconSVG from "../../pwa-app/components/common/svgComponents/UserIconSVG";

class SocialUserCredentialsScreen extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            socialProviderName: ''
        };

        this.goBack = this.goBack.bind(this);
        this.changeState = this.changeState.bind(this);
    }

    componentWillMount() {
        this.applyTheme();
    }

    componentWillUnmount() {
        this.props.resetErrMsg()
    }

    componentDidMount() {
        document.getElementsByClassName('modal-header')[0].remove();

        if (this.props.newSocialLoginInfo && this.props.newSocialLoginInfo.providerName) {
            this.setState({
                socialProviderName: this.props.newSocialLoginInfo.providerName
            });
        }
    }

    goBack() {
        this.props.history.push('/start-order/login-types');
    }

    changeState({ target }) {
        if (target.name == 'newsletter') {

            let targetValue = convertStringToBoolean(target.value);

            this.setState({
                [target.name]: !targetValue
            });
        } else {

            this.setState({
                [target.name]: target.value
            });
        }
    }

    renderModalHeader() {
        const { loginTextStyle, imageStyle } = styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        // const isHidden = (this.props.isCheckoutChangeTime) ? true : false;

        let isHidden = false;
        if (this.props.hideBackButton) {
            isHidden = true;
        }

        // Render the close button for start order modal on this screen when opening it from menuScreen
        let closeButtonShowClass = '';
        if (this.props.isOrderDone) closeButtonShowClass = ' from-menu-screen';

        return (
            <React.Fragment>
                <div className='start-order-header-container'>
                    <Button className="start-order-custom-back-button" onClick={() => this.goBack()}
                        style={{ opacity: (isHidden) ? 0 : 1 }}>
                        <span className="backButtonIcon"><MaterialIcon icon="navigate_before" color="#000"
                            size={32} /></span>
                        <span style={{fontWeight: 300}}>{i18n.t('common:buttons.backBtn')}</span>
                    </Button>
                    <div style={{ margin: '0 auto', fontSize: 20, textAlign: 'center' }}>
                        <UserIconSVG 
                            width={23} height={23}
                            fill={type === 'DARK' ? secondaryColor : primaryColor}
                            style={imageStyle} />
                        <span style={loginTextStyle}>{i18n.t('screens:loginTypesScreen.verify')}</span>
                    </div>
                    <div style={{ width: 90, textAlign: 'right', position: 'relative', bottom: 0 }}>
                        <Button className={`start-order-custom-close-button ${closeButtonShowClass}`} onClick={() => {
                            this.props.toggle()
                        }}>
                            <MaterialIcon icon="close" color="#000" size={18} />
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderModalBody() {
        const { socialProviderName } = this.state;

        return (
            <React.Fragment>
                {this.renderModalHeader()}
                { socialProviderName &&
                    <SocialUserCredentialsCommon
                        styles={styles}
                        customerThemes={this.props.customerThemes}
                        customer={this.props.customer}
                        registerCustomer={this.props.registerCustomer}
                        isPWA={false}
                        goBack={this.goBack.bind(this)}
                        brand={this.props.brand}
                        resetErrMsg={this.props.resetErrMsg}
                        socialProvider={socialProviderName}
                    />
                }
            </React.Fragment>
        );
    }


    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { primaryColor, textDecoration, startOrderFontSize, mainTextColor, primaryFontColor, chefNoteBackground } = this.props.customerThemes.selectedTheme;

        customizedStyles.heading.fontSize = startOrderFontSize;
        customizedStyles.submitBtn.backgroundColor = primaryColor;
        customizedStyles.privacyPolicy.color = primaryColor;
        customizedStyles.privacyPolicy.textDecoration = textDecoration;
        customizedStyles.subHeading.color = mainTextColor;
        customizedStyles.loginTextStyle.color = primaryFontColor;
        customizedStyles.input.backgroundColor = chefNoteBackground;
        customizedStyles.termsAndConditions.color = primaryColor;
        customizedStyles.termsAndConditions.textDecoration = textDecoration;
        styles = customizedStyles;
        return styles;
    }

    render() {
        const startOrderStyles = isMobile ? { overflowY: 'auto', overflowX: 'hidden', height: window.innerHeight } : {}

        return (
            <AvForm className="start-order" style={startOrderStyles}>
                <ModalStep
                    onToggle={this.props.toggle}
                    // renderHeader={this.renderModalHeader()}
                    renderBody={this.renderModalBody()}
                    // renderFooter={this.renderModalFooter()}
                    styles={styles}
                    customerThemes={this.props.customerThemes}
                />
            </AvForm>
        );
    }
}

const mapStateToProps = state => {
    return {
        customer: state.customer,
        customerThemes: state.customerThemes,
        brand: state.brand,
        newSocialLoginInfo: state.customer.newSocialLoginInfo
    };
};

export default connect(mapStateToProps, { registerCustomer, openStartOrderScreen, resetErrMsg })(SocialUserCredentialsScreen);

let styles = {
    modalBody: {
        textAlign: 'center',
        padding: 0,
        minHeight: '480px',
        backgroundColor: '#ffffff'
    },
    headerColumn: {
        paddingLeft: 0,
        width: 72
    },
    login: {
        marginLeft: -10,
        verticalAlign: 'middle'
    },
    loginTextStyle: {
        marginLeft: -10,
        verticalAlign: 'middle'
    },
    imageStyle: {
        marginRight: 16
    },
    heading: {
        fontSize: 22,
        margin: 'auto',
        textAlign: 'center'
    },
    subHeading: {
        fontSize: 14,
        marginBottom: 0,
        color: '#939393',
        paddingBottom: 12,
    },
    submitBtn: {
        display: 'flex',
        justifyContent: 'center',
        height: 50,
        borderRadius: 0,
        padding: 12,
        width: '100%',
        marginBottom: 38,
        border: 'none',
        backgroundColor: '#61B522'
    },
    submitBtnDisabled: {
        display: 'flex',
        justifyContent: 'center',
        height: 50,
        borderRadius: 0,
        padding: 12,
        width: '100%',
        marginBottom: 38,
        border: 'none',
        backgroundColor: '#a8a8a8'
    },
    marginAuto: {
        margin: 'auto',
        position: 'relative',
        bottom: 20,
        top: 0
    },
    inputGroup: {
        margin: 'auto',
        height: 50,
        marginBottom: 10
    },
    input: {
        height: 50,
        backgroundColor: "#e9ecef",
        textAlign: 'center',
        borderRadius: 0
    },
    inputGroupTickbox: {
        margin: 'auto',
        marginBottom: 12,
        backgroundColor: '#e9ecef',
        // borderRadius: 4
    },
    inputGroupText: {
        alignSelf: 'flex-start',
        padding: '18px 8px 6px 16px'
    },
    label: {
        padding: '12px 16px 5px 0',
        textAlign: 'left',
        whiteSpace: 'normal',
        fontSize: 14
    },
    privacyPolicy: {
        color: '#61B522',
        fontWeigth: 700,
        cursor: 'pointer'
    },
    centredItem: {
        margin: '0 auto',
        fontSize: 22,
        textAlign: 'center'
    },
    modalCustomSubmitBody: {
        marginTop: '5px',
        display: 'flex',
        justifyContent: 'center'
    },
    phoneIconStyle: {
        position: 'absolute',
        padding: '8px 5px',
        marginLeft: 75,
        zIndex: 1
    },
    phoneIconStyleRTL: {
        position: 'absolute',
        padding: '8px 5px',
        marginRight: 70,
        zIndex: 1
    },
    checkIconStyle: {
        position: 'absolute',
        padding: 4,
        marginLeft: -30,
        zIndex: 1,
        marginTop: 10
    },
    checkIconStyleRTL: {
        position: 'absolute',
        padding: 4,
        marginRight: -30,
        zIndex: 1,
        marginTop: 10
    },
    subMsg: {
        fontSize: 14,
        color: '#9a9a9a',
        marginBottom: 5
    },
    termsAndConditions: {
        color: "#61B522",
        cursor: 'pointer'
    }
}