import axios from '../../../utils/AxiosUtils';
import i18n from '../../../i18next';

import { matchThemes } from '../../../utils/ThemesSelector';
import { changeRTL } from '../../../utils/CommonUtils';
import {
    GET_ORDER_TYPES_SUCCESS,
    CLEAR_ORDER_TYPES,
    GET_BRAND_DATA_SUCCESS,
    GET_CURRENT_THEME_SUCCESS,
    SELECT_BRAND_ID_FAILED,
    LOADING_NO_BACKGROUND,
    GET_BRAND_NAME,
    GET_LANGUAGE_SUCCESS,
    GET_SELECTED_LANGUAGE_SUCCESS,
    IS_DEFAULT_LANGUAGE
} from '../../types';

export const getBrandData = (brandId) => {
    return (dispatch, getState) => {

        const url = `/restaurant-chain/${brandId}/order-types`;

        axios(getState).get(url)
            .then(response => dispatch({ type: GET_BRAND_NAME, payload: response.data }))
            .catch(error => console.log('Unable to fetch brand data', error));
    }
}

export const getOrderTypes = (brandId, callback) => {
    return (dispatch, getState) => {

        dispatch({ type: LOADING_NO_BACKGROUND, payload: true });

        const url = `/restaurant-chain/${brandId}/order-types`;

        axios(getState).get(url)
            .then(response => getOrderTypesSuccess(dispatch, response, getState, callback, brandId))
            .catch(error => getOrderTypesError(dispatch, error, callback, brandId));
    };
};

const getOrderTypesSuccess = (dispatch, response, getState, callback, brandId) => {
    dispatch({ type: LOADING_NO_BACKGROUND, payload: false });

    // First dispatch theme data to corresponding reducer to apply the correct colors
    let customerThemes, currentTheme, serverTheme, isDefaultLanguage, selectedLanguage;
    customerThemes = getState().customerThemes;
    serverTheme = response.data.theme;
    currentTheme = matchThemes(serverTheme, customerThemes);
    isDefaultLanguage = getState().storeLocalStorage.isDefaultLanguage;
    selectedLanguage = getState().storeLocalStorage.selectedLanguage;

    dispatch({ type: GET_CURRENT_THEME_SUCCESS, payload: currentTheme });
    dispatch({ type: GET_ORDER_TYPES_SUCCESS, payload: response.data });
    dispatch({ type: GET_BRAND_DATA_SUCCESS, payload: response.data });
    if (!isDefaultLanguage || response.data.language !== selectedLanguage) {
        dispatch({ type: GET_SELECTED_LANGUAGE_SUCCESS, payload: response.data.language })
        i18n.changeLanguage(response.data.language);
        changeRTL(response.data.language);
        dispatch({ type: GET_LANGUAGE_SUCCESS, payload: response.data.language });
    }

    setTimeout(() => {
        if (callback) {
            callback(brandId);
        }
    }, 350);
};

const getOrderTypesError = (dispatch, error, callback, brandId) => {
    dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
    dispatch({ type: CLEAR_ORDER_TYPES });
    dispatch({ type: SELECT_BRAND_ID_FAILED, payload: true });

    if (callback) {
        // console.log('ERROR callback');
        callback(brandId);
    }
};


export const clearOrderTypes = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_ORDER_TYPES });
    }
};

export const changeLanguage = (language) => {
    return (dispatch) => {
        if (language === 'en') {
            dispatch({ type: IS_DEFAULT_LANGUAGE, payload: true });
        } else {
            dispatch({ type: IS_DEFAULT_LANGUAGE, payload: false });
        }
        i18n.changeLanguage(language);
        changeRTL(language);
        dispatch({ type: GET_LANGUAGE_SUCCESS, payload: language });
    }
}