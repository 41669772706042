import React from 'react';

const CurbsideSVG = ({ width, height, fill, style, viewBoxNewValues }) => {
    const viewBoxValues = viewBoxNewValues ? viewBoxNewValues : "0 0 76.7 97.2";

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBoxValues} style={style}>
            <g>
                <path fill='transparent' d="M27.5,47.4l-14.6-3.2c-2.1-0.4-4,1.1-4,3.3v3.2c0,1.8,1.5,3.3,3.3,3.3h14.6c1.8,0,3.3-1.5,3.3-3.3
		C30.2,49.1,29.1,47.7,27.5,47.4z"/>
                <path fill='transparent' d="M83.1,31.3l-4.3-13.3c-0.8-2.4-3-4-5.5-4H55.4h0H30.6c-2.5,0-4.7,1.6-5.5,4l-4.3,13.3h34.6h3.5
		c-1.6-1.5-2.6-3.7-2.6-6.1c0-4.6,3.8-8.4,8.4-8.4c4.6,0,8.4,3.8,8.4,8.4c0,2.4-1,4.5-2.6,6.1H83.1z"/>
                <path fill='transparent' d="M73.8,50.7c0,1.8,1.5,3.3,3.3,3.3h14.6c1.8,0,3.3-1.5,3.3-3.3v-3.2c0-2.1-2-3.7-4-3.3l-14.6,3.2
		C74.9,47.7,73.8,49.1,73.8,50.7z"/>
                <path fill={fill} d="M132,33.4c0-0.8-0.2-1.6-0.7-2.2l-8.6-10.6c-0.9-1.7-2.8-2.9-4.9-2.9h-1.8c4.1-0.6,7.3-4.1,7.3-8.3
		c0-4.6-3.8-8.4-8.4-8.4c-4.6,0-8.4,3.8-8.4,8.4c0,4.3,3.2,7.8,7.3,8.3h-1.9c-1.3,0-2.4,0.4-3.3,1.1c0,0-0.1,0-0.1,0l-10.1,4.6
		l-10.7-1.5c-0.2,0-0.4,0.1-0.6,0.2l-3.2-9.9c-0.8-2.6-3.3-4.4-6-4.4H55.4h0h-29c-2.7,0-5.2,1.8-6,4.4l-3.8,11.7h-6.4
		c-1.3,0-2.4,0.8-2.9,1.9l-0.8,1.9c-0.7,1.7,0.2,3.6,1.9,4.2l2.1,2.9L4,40.5c-1.5,1.3-2.3,3.2-2.3,5.1V77c0,1.8,1.4,3.2,3.2,3.2
		h12.6c1.8,0,3.2-1.4,3.2-3.2v-6.3h34.7h27.8V77c0,1.8,1.4,3.2,3.2,3.2H99c1.8,0,3.2-1.4,3.2-3.2V45.6c0-2-0.8-3.8-2.3-5.1l-6.5-5.8
		L90,28.6l9.3,1.2l7.1-3.3l-0.3,4.8v11.2v1.4v32.3c0,2.7,1.2,4.3,3.7,4.3c2.8,0,4.3-1.4,4.3-4.3V49.3h1.1v26.9
		c0,2.7,1.2,4.3,3.7,4.3c2.8,0,4.3-1.4,4.3-4.3V43.9v-1.4V31.4l2.7,3.3v13.2c0,1.7,1.3,3,3,3s3-1.3,3-3V33.6
		C132,33.5,132,33.4,132,33.4z M26.8,54H12.2c-1.8,0-3.3-1.5-3.3-3.3v-3.2c0-2.1,2-3.7,4-3.3l14.6,3.2c1.5,0.3,2.6,1.7,2.6,3.3
		C30.2,52.5,28.7,54,26.8,54z M64.7,16.9c-4.6,0-8.4,3.8-8.4,8.4c0,2.4,1,4.5,2.6,6.1h-3.5H20.8l4.3-13.3c0.8-2.4,3-4,5.5-4h24.8h0
		h17.9c2.5,0,4.7,1.6,5.5,4l4.3,13.3H70.5c1.6-1.5,2.6-3.7,2.6-6.1C73.1,20.6,69.3,16.9,64.7,16.9z M91,44.3c2.1-0.4,4,1.1,4,3.3
		v3.2c0,1.8-1.5,3.3-3.3,3.3H77.1c-1.8,0-3.3-1.5-3.3-3.3c0-1.6,1.1-2.9,2.6-3.3L91,44.3z"/>
            </g>
        </svg>
    );
}

export default CurbsideSVG;