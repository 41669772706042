import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import MaterialIcon from 'material-icons-react';
import i18n from "../../../i18next";

import CarDetailsContent from './CarDetailsContent';

import { getCustomerCar, saveCarDetails } from '../../../actions/index';
import ModalStep from '../../common/ModalStep';
import { getLoggedInUserFirstName } from '../../../utils/CommonUtils';
import UserIconSVG from '../../../pwa-app/components/common/svgComponents/UserIconSVG';

class CarDetailsScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            makeBy: '',
            model: '',
            plate: '',
            color: '',
            disabledNext: true
        }

        this.goBack = this.goBack.bind(this);
        this.handleCarChange = this.handleCarChange.bind(this);
    }

    componentDidMount() {
        this.applyTheme();

        if (this.props.customer !== undefined && this.props.customer !== null && this.props.customer.id !== null && this.props.customer.id !== -1 &&
            this.props.customer.token !== null) {

            this.props.getCustomerCar(this.props.customer.id);
        }

        if (this.props.customer && this.props.customer.customerCar && this.props.customer.customerCar.makeBy) {
            this.setState({
                ...this.state,
                makeBy: this.props.customer.customerCar.makeBy,
                model: this.props.customer.customerCar.model,
                plate: this.props.customer.customerCar.plate,
                color: this.props.customer.customerCar.color,
            }, () => {
                // Check if the data in all fields is valid
                if (!this.validateData()) {
                    this.setState({ ...this.state, disabledNext: true });
                } else {
                    this.setState({ ...this.state, disabledNext: false });
                }
            })
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.customer.customerCar !== this.props.customer.customerCar) && this.props.customer.customerCar) {
            this.setState({
                ...this.state,
                makeBy: this.props.customer.customerCar.makeBy,
                model: this.props.customer.customerCar.model,
                plate: this.props.customer.customerCar.plate,
                color: this.props.customer.customerCar.color,
                disabledNext: false
            })
        }
    }

    goBack() {
        this.props.history.goBack();
    }

    handleCarChange(newCar) {
        let updatedCar = JSON.parse(JSON.stringify(newCar));

        this.setState({
            ...this.state,
            makeBy: updatedCar.makeBy,
            model: updatedCar.model,
            plate: updatedCar.plate,
            color: updatedCar.color,
        }, () => {
            // Check if the data in all fields is valid
            if (!this.validateData()) {
                this.setState({ ...this.state, disabledNext: true });
            } else {
                this.setState({ ...this.state, disabledNext: false });
            }
        });
    }

    validateData() {
        let validData = true;

        // If no data for car or any missing part of the car details -> form is not valid
        if (!this.state.makeBy || !this.state.model || !this.state.plate || !this.state.color) {
            validData = false;
            this.state.disabledNext = true;
            return validData;
        }

        validData = true;
        this.state.disabledNext = false;

        return validData;
    }

    goForward() {
        const carDetails = {
            makeBy: this.state.makeBy,
            model: this.state.model,
            plate: this.state.plate,
            color: this.state.color,
        }
        this.props.saveCarDetails(carDetails)
        if (this.props.isPwa) {
            this.props.history.push('/brand/' + this.props.brandId + '/start-order/restaurant-selection')
        } else {
            this.props.history.push('/start-order/restaurant-selection');
        }
    }

    renderCustomer() {
        const { imageStyle, imageStyleRTL, profileNameStyle, profileNameStyleRTL } = styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === 'bg' ? true : false;

        const userFirstName = getLoggedInUserFirstName(this.props.customer.name);

        let customerNameClass = '';
        if (window.outerWidth <= 500) {
            customerNameClass = 'customer-long-text-back-button';
        }

        return (
            <div style={{ margin: '0 auto', fontSize: 20, textAlign: 'center' }} className='customer-name-mobile-style'>
                <UserIconSVG width={23} height={23} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? imageStyleRTL : imageStyle} />
                {/* <img src={iconImage} width="40" height="auto" style={imageStyle} /> */}
                <span style={isArabic ? profileNameStyleRTL : profileNameStyle} className={customerNameClass}>{userFirstName}</span>
            </div>
        );
    }

    renderNextButton() {
        const { buttonsFont, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        const fontWeight = fontBold === 'bold' ? fontBold : 300;
        const nextButtonTextStyle = {
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        let nextButtonStyle = '';

        if (this.state.disabledNext) {
            nextButtonStyle = 'disabledNextButton';
        } else {
            nextButtonStyle = 'nextButton';
        }

        return (
            <Col sm={{ size: 12, offset: 1 }} md={12} style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
                <Button className={nextButtonStyle} onClick={this.goForward.bind(this)}>
                    <span style={nextButtonTextStyle}>{i18n.t('common:buttons.nextBtn')}</span>
                    <span hidden={this.props.isPwa} className="btnNextIcon">
                        <MaterialIcon icon="navigate_next" color={buttonsFont} size={35} />
                    </span>
                </Button>
            </Col>
        )
    }

    renderModalHeader() {
        const customerLoggedIn = this.props.customer !== undefined && this.props.customer !== null && this.props.customer.id !== undefined
            && this.props.customer.id !== undefined && this.props.customer.id !== null && this.props.customer.id !== -1 && this.props.customer.email !== undefined
            && this.props.customer.email !== null && this.props.customer.email !== "null" && this.props.customer.email !== 'undefined';

        return (
            <Fragment>
                <div className='start-order-header-container'>
                    <Button className="start-order-custom-back-button" onClick={() => this.goBack()}>
                        <span className="backButtonIcon"><MaterialIcon icon="navigate_before" color="#000" size={32} /></span>
                        <span style={{ fontWeight: 300 }}>{i18n.t('common:buttons.backBtn')}</span>
                    </Button>
                    {(customerLoggedIn) ? this.renderCustomer() : ''}
                    <div style={{ width: 90, textAlign: 'right' }}>
                        <Button className='start-order-custom-close-button' onClick={() => { this.props.toggle() }}>
                            <MaterialIcon icon="close" color="#000" size={18} />
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    }

    renderModalBody() {
        const { screenMessage } = styles;
        const { primaryFontColor, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        const fontWeight = fontBold === 'bold' ? fontBold : 300;

        const titleFontStyle = {
            fontFamily: fontFamilyType,
            fontWeight,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        return (
            <React.Fragment>
                {!this.props.isPwa && this.renderModalHeader()}
                <div className='modal-body-custom' style={{ padding: 0, margin: 0 }}>
                    <Col sm={12} className='modal-custom-body-title' style={{ height: 57, maxHeight: 57, minHeight: 57 }}>
                        <p className='start-order-body-header-title startOrderScreenTitle' style={Object.assign({}, screenMessage, titleFontStyle, { margin: 'auto', textAlign: 'center', fontSize: 22, color: primaryFontColor })}>{i18n.t('screens:carDetailsScreen.carDetails')}</p>
                    </Col>
                    <Col xs={12} className='modal-custom-body-inner-content confirm-address-screen'>
                        <CarDetailsContent
                            updateCarDetailsInParent={this.handleCarChange}
                            state={this.state}
                        />
                    </Col>
                    <Row className='modal-custom-body-footer confirm-address-screen' style={{ display: 'flex', justifyContent: 'center' }}>
                        {this.renderNextButton()}
                    </Row>
                </div>
            </React.Fragment>
        );
    }

    renderModalFooter() {
        return null
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { startOrderFontSize, primaryFontColor } = this.props.customerThemes.selectedTheme;
        customizedStyles.screenMessage.fontSize = startOrderFontSize;
        customizedStyles.profileNameStyle.color = primaryFontColor
        customizedStyles.profileNameStyleRTL.color = primaryFontColor
        styles = customizedStyles;
        return styles;
    }

    render() {
        return (
            <AvForm fluid className="start-order">
                <ModalStep
                    isOpen={this.props.isOpen}
                    onToggle={this.props.toggle}
                    renderBody={this.renderModalBody()}
                    renderFooter={this.renderModalFooter()}
                    styles={styles}
                    customerThemes={this.props.customerThemes}
                />
            </AvForm>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        customerThemes: state.customerThemes,
        customer: state.customer,
        brandId: state.brand.id,
        language: state.storeLocalStorage.language
    }
}

export default connect(mapStateToProps, { getCustomerCar, saveCarDetails })(CarDetailsScreen);

let styles = {
    screenMessage: {
        textAlign: 'center',
        width: '100%',
        marginBottom: 0
    },
    imageStyle: {
        marginRight: 16,
    },
    imageStyleRTL: {
        marginLeft: 16,
    },
    profileNameStyle: {
        marginLeft: -10,
        verticalAlign: 'middle',
        fontSize: 20
    },
    profileNameStyleRTL: {
        marginRight: -10,
        verticalAlign: 'middle',
        fontSize: 20
    },
}