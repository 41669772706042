import React from 'react';
import '../../../styles/BackButton.css';
import HamburgerButtonSVG from "../../common/svgComponents/HamburgerButtonSVG";
import {NAV_ICON_HEIGHT, NAV_ICON_WIDTH, NAV_ICON_COLOR, NAV_ICON_STROKE_OPTIONS} from "../../../../utils/Constants";

const HamburgerButton = (props) => {

    return (
        <HamburgerButtonSVG
            width={NAV_ICON_WIDTH}
            height={NAV_ICON_HEIGHT}
            color={props.isMainScreen ? '#000' : props.color}
            stroke={props.isMainScreen ? '' : (props.isShadowed ? NAV_ICON_STROKE_OPTIONS.stroke : '')}
            strokeWidth={props.isMainScreen ? '' : (props.isShadowed ? NAV_ICON_STROKE_OPTIONS.strokeWidth : '')}
            svgClicked={() => props.goBack()}
            isShadowed={props.isShadowed}
            // isMainScreen={props.isMainScreen}
        />
    );
}

export default HamburgerButton;
