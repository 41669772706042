import axios from '../utils/AxiosUtils';

import {
    CLEAR_APP_STATE,
    VERSION_CONTROL
} from './types';

const defaultCase = {
    status: "UP_TO_DATE",
    message: ""
};

export const checkVersionControl = (versionNumber, brandId, callback) => {
    return (dispatch, getState) => {

        const url = `/app/version-control?version=${versionNumber}&brandId=${brandId}`;

        axios(getState).get(url)
            .then(response => versionControlResponse(dispatch, response, true, callback))
            .catch(() => versionControlResponse(dispatch, defaultCase, false, callback));
    };
};

const versionControlResponse = (dispatch, response, isSuccess, callback) => {

    if (isSuccess) {
        dispatch({ type: VERSION_CONTROL, payload: response.data });
        callback();
    } else {
        dispatch({ type: VERSION_CONTROL, payload: response });
        callback();
    }
};

export const clearAppState = () => {
    return dispatch => {

        dispatch({ type: CLEAR_APP_STATE });
    };
};
