import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { isTablet, isIOS } from 'mobile-device-detect';

import RestaurantDetailsScreen from './RestaurantDetailsScreen';
import MenuScreen from './MenuScreen';
import { getAllUrlParams, getQueryInfoFromUrl } from "../../utils/CommonUtils";
import {
    checkIsPreviewMode,
    getRestaurantById,
    getOrderTypes,
    saveCurrentPathToStore,
    saveThemePreviewMode,
    loadAllAvailableDiscounts,
    allowTimeWarningPopup,
    getOldestActiveLoyalty,
    saveNavigationType
} from "../../actions/index";
import { updateCSSVariables, matchThemes } from '../../utils/ThemesSelector';
import { FRONT_END_URL } from '../../utils/Constants';

class MenuContainerScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // isTimeWarningAllowed: this.props.isTimeWarningAllowed

            iOSDetected: navigator.appVersion.match(/iPhone; CPU iPhone OS/) || navigator.appVersion.match(/CPU iPhone/) ||
                navigator.appVersion.match(/iPad;/) ||
                (navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                    navigator.userAgent &&
                    navigator.userAgent.indexOf('CriOS') == -1 &&
                    navigator.userAgent.indexOf('FxiOS') == -1),
        };
    }

    componentWillMount() {
        const url = window.location.href;
        !url.includes('previewMode') && this.applyTheme();
        const queryParams = getAllUrlParams(url);
        let parsedNavTypeValue = this.props.navigationType;
        // let parsedNavTypeValue = parseInt(localStorage.getItem('navigationType'));
        if (this.props.selectedRestaurant.restaurantId && parsedNavTypeValue === 1 && !url.includes('previewMode')) {
            this.props.saveCurrentPathToStore('/menu-screen');
        } else if ((!this.props.selectedRestaurant.restaurantId || parsedNavTypeValue === 0) && !url.includes('previewMode')) {
            this.props.history.push('/start-order');
            this.props.saveNavigationType(1);
            // localStorage.setItem('navigationType', 1);
        }

        const anotherQueryParams = getQueryInfoFromUrl();

        if (anotherQueryParams.customerThemeType) {
            const currentTheme = matchThemes(this.props.customerThemes.selectedTheme, this.props.customerThemes, anotherQueryParams.customerThemeType);
            this.props.saveThemePreviewMode(currentTheme);
        }
        if (url.includes('previewMode')) {
            this.props.checkIsPreviewMode(queryParams, (restaurantId) => {
                this.props.getRestaurantById(restaurantId);
                if (queryParams.brandId) {
                    this.props.getOrderTypes(queryParams.brandId);
                }
            });
        }

        if (this.props.customerThemes.selectedTheme.colorsCss) {
            updateCSSVariables(this.props.customerThemes.selectedTheme.colorsCss);
        }
        // Loading the promotions/loyalty before other screens are rendered
        const { restaurantId } = this.props.selectedRestaurant;
        this.props.loadAllAvailableDiscounts(restaurantId);
    }

    componentDidMount() {
        this.props.allowTimeWarningPopup(true);
    }

    componentDidUpdate(prevProps) {
        if (this.props.customerThemes.selectedTheme.colorsCss !== prevProps.customerThemes.selectedTheme.colorsCss && this.props.customerThemes.selectedTheme.colorsCss) {
            updateCSSVariables(this.props.customerThemes.selectedTheme.colorsCss);
            const url = window.location.href;
            url.includes('previewMode') && this.applyTheme();
        }
    }

    componentWillUnmount() {
        this.props.allowTimeWarningPopup(false);
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { menuBackground, backGroundImageFilePath } = this.props.customerThemes.selectedTheme;
        customizedStyles.imageBackground.backgroundImage = `url(${FRONT_END_URL}/${backGroundImageFilePath})`;
        customizedStyles.colorBackground.backgroundColor = menuBackground;
        styles = customizedStyles;
        return styles;
    }

    render() {
        const { imageBackground } = this.props.customerThemes.selectedTheme;

        return (
            <Fragment>
                <div style={{ ...imageBackground ? styles.imageBackground : styles.colorBackground, minHeight: isIOS && isTablet ? '100vh' : '100%' }}>
                    <RestaurantDetailsScreen history={this.props.history} />
                    <MenuScreen history={this.props.history} />
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        customer: state.customer,
        customerThemes: state.customerThemes,
        selectedRestaurant: state.selectedRestaurant,
        // isTimeWarningAllowed: state.timeWarningPopup
        navigationType: state.storeLocalStorage.navigationType
    };
};

export default connect(mapStateToProps, {
    checkIsPreviewMode,
    getRestaurantById,
    getOrderTypes,
    saveCurrentPathToStore,
    saveThemePreviewMode,
    loadAllAvailableDiscounts,
    allowTimeWarningPopup,
    getOldestActiveLoyalty,
    saveNavigationType
})(MenuContainerScreen);

let styles = {
    imageBackground: {
        backgroundRepeat: 'repeat',
        display: 'flow-root'
    },
    colorBackground: {
        display: 'flow-root'
    }
}
