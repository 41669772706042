import {CLEAR_SERVICE_MESSAGES, GET_SERVICE_MESSAGES} from "../../actions/types";

const INITIAL_STATE = {
    generalNote: '',
    serviceNote: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_SERVICE_MESSAGES:
            return {
                ...state,
                generalNote: action.payload.generalNote,
                serviceNote: action.payload.serviceNote
            };
        case CLEAR_SERVICE_MESSAGES:
            return INITIAL_STATE;
        default:
            return state;
    }
};