import axios from '../../utils/AxiosUtils';
import moment from "moment/moment";

import {
    COLLECTION_ID,
    IN_STORE_ID
} from '../../utils/Constants';
import {
    CLEAR_STATE,
    SAVE_RESTAURANT_CATEGORIES,
    SAVE_RESTAURANT_MENUS,
    SAVE_RESTAURANT_PRODUCTS,
    LOADING,
    SHOW_ERROR_DIALOG, SELECT_RESTAURANT_MENU_ID,
    SELECTED_MENU_ARRAY_INDEX
} from "../types";
import { pushDataToGoogleTagManager } from '../../utils/AnalyticsUtils';

export const getRestaurantAvailableMenus = () => {
    return (dispatch, getState) => {

        loadRestaurantAvailableMenus(dispatch, getState);
    }
};

export const loadRestaurantAvailableMenus = (dispatch, getState) => {

    dispatch({ type: LOADING, payload: true });

        let restaurant = getState().selectedRestaurant;
        let restaurantId = getState().selectedRestaurant.restaurantId;
        const previewMode = getState().menuPreviewMode.previewMode;
        let selectedOrderTypeId = getState().orderTypes.selectedOrderType.id;

    if (previewMode === true || previewMode === 'true') {
        restaurantId = getState().menuPreviewMode.restaurantId;
        selectedOrderTypeId = COLLECTION_ID;
    }

    const currentDateAndTime = moment().add(restaurant.restaurantTimeOffset, 'milliseconds').format('DD-MM-YYYY HH:mm:ss');
    let availableOnDate = (getState().estimateOrderTime.wantedTime )? getState().estimateOrderTime.wantedTime: currentDateAndTime;
    if (getState().estimateOrderTime.timeSlotBased) {
        let fromTime = moment(`${getState().estimateOrderTime.time.fromHour}:${getState().estimateOrderTime.time.fromMinute}:00`, 'HH:mm:ss').format('HH:mm:ss');
        availableOnDate = `${getState().estimateOrderTime.time.date} ${fromTime}`;
    }

    let typeIdQuery = '';
    if (selectedOrderTypeId === IN_STORE_ID) {
        typeIdQuery = `&dinePayTypeId=${new URLSearchParams(window.location.search).get('typeId')}`;
    }

    const url = `/restaurant/${restaurantId}/menu?availableOnDate=${availableOnDate}&previewMode=${previewMode}&orderTypeId=${selectedOrderTypeId}${typeIdQuery}`;

    axios(getState).get(url)
        .then(response => getRestaurantAvailableMenusSuccess(dispatch, response, getState))
        .catch(_ => {

            dispatch({ type: LOADING, payload: false });
            dispatch({ type: SHOW_ERROR_DIALOG, payload: true });
        });
};

const getRestaurantAvailableMenusSuccess = (dispatch, response, getState) => {

    let menus = response.data;
    let menuArray = [];
    let categoriesArray = [];
    let productsArray = [];
    

    for (let i = 0; i < menus.length; i++) {

        let menuData = {
            id: menus[i].id,
            name: menus[i].name,
            posId: menus[i].posId,
            priority: menus[i].priority,
            availableMenu: menus[i].availableMenu
        }

        menuArray.push(menuData);

        let categories = menus[i].categories;

        for (let j = 0; j < categories.length; j++) {

            const categoryData = {
                id: categories[j].id,
                menuId: menus[i].id,
                posId: categories[j].posId,
                name: categories[j].name,
                description: categories[j].description,
                minimumQuantity: categories[j].minimumQuantity,
                availableCategory: categories[j].availableCategory,
                priority: categories[j].priority,
                imageUrl: categories[j].imageUrl
            }

            categoriesArray.push(categoryData);

            let products = categories[j].products;

            for (let k = 0; k < products.length; k++) {

                const productData = {
                    id: products[k].id,
                    menuId: menus[i].id,
                    categoryId: categories[j].id,
                    posId: products[k].posId,
                    name: products[k].name,
                    description: products[k].description,
                    minimumQuantity: products[k].minimumQuantity,
                    availableProduct: products[k].availableProduct,
                    priority: products[k].priority,
                    productSizesIds: products[k].productSizesIds,
                    price: products[k].price,
                    thumbnailPath: products[k].thumbnailPath,
                    nutrients: products[k].nutrients,
                    imagePath: products[k].imagePath,
                    rectImagePath: products[k].rectImagePath,
                    questionsSize: products[k].questionsSize
                }

                productsArray.push(productData);
            }
        }
    }

    pushDataToGoogleTagManager('event', 'impressions', getState().pwaAppRunning, productsArray, undefined, categoriesArray, getState().selectedRestaurant.currency);
    dispatch({ type: SAVE_RESTAURANT_MENUS, payload: menuArray });
    dispatch({ type: SAVE_RESTAURANT_CATEGORIES, payload: categoriesArray });
    dispatch({ type: SAVE_RESTAURANT_PRODUCTS, payload: productsArray });
    dispatch({ type: LOADING, payload: false });
};

export const clearState = () => {
    return (dispatch, getState) => {

        const pwaAppRunning = getState().pwaAppRunning;

        dispatch({ type: CLEAR_STATE, fromPwa: pwaAppRunning });
    }
};

export const selectRestaurantMenu = (selectedMenuId) => {
    return (dispatch) => {

        dispatch({ type: SELECT_RESTAURANT_MENU_ID, payload: selectedMenuId });
    }
};

export const selectedMenuArrayIndex = (currentMenuArrayIndex) => {
    return (dispatch) => {
        
        dispatch({ type: SELECTED_MENU_ARRAY_INDEX, payload: currentMenuArrayIndex });
    }
};