import {
    CLEAR_APP_STATE,
    CLEAR_STATE,
    GET_CUSTOMER_SAVED_RESTAURANTS_SUCCESS,
    RESET_CUSTOMER_SAVED_RESTAURANTS_SUCCESS
} from '../../../actions/types';

const INITIAL_STATE = [];

export default (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case GET_CUSTOMER_SAVED_RESTAURANTS_SUCCESS:
            return action.payload;
        case RESET_CUSTOMER_SAVED_RESTAURANTS_SUCCESS:
            return INITIAL_STATE;
        case CLEAR_STATE:
            return INITIAL_STATE;
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}