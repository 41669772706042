import axios from '../../utils/AxiosUtils';

import {
    DISPATCH_PROMO_CODE,
    GET_ALL_LOYALTY_CODES_SUCCESS,
    GET_AUTO_APPLY_LOYALTY,
    GET_OLDEST_ACTIVE_LOYALTY
} from "../types";
import { parentLocalStorage } from '../../utils/CrossOriginUtils';

export const getLoyaltyForChain = (brandId) => {
    return (dispatch, getState) => {

        const customerId = getState().customer.id;
        const url = `/customer/${customerId}/loyalties?restaurantChainId=${brandId}`;

        axios(getState).get(url)
            .then(response => getLoyaltyForChainSuccess(response, dispatch))
            .catch(err => console.log('err ', err));
    };
};

const getLoyaltyForChainSuccess = (response, dispatch) => {
    dispatch({ type: GET_ALL_LOYALTY_CODES_SUCCESS, payload: response.data });
};

export const getOrUpdateAutoApplyLoyalty = (getOrUpdate) => {
    return (dispatch, getState) => {

        const customerId = getState().customer.id;
        const url = `/customer/${customerId}/loyalty-info`;

        if (getOrUpdate) {
            axios(getState).put(url, getOrUpdate)
                .then(res => {
                    dispatch({ type: GET_AUTO_APPLY_LOYALTY, payload: res.data });
                    parentLocalStorage('set', 'autoApplyLoyalty', res.data.autoApplyLoyalty);
                })
                .catch(err => console.log('err ', err));
        } else {
            axios(getState).get(url)
                .then(res => dispatch({ type: GET_AUTO_APPLY_LOYALTY, payload: res.data }))
                .catch(err => console.log('err ', err)) // TODO send email
        }
    };
};

export const getOldestActiveLoyalty = (restaurantID) => {
    return (dispatch, getState) => {

        if (getState().promotionPartners
            && getState().promotionPartners.promotionPartner
            && getState().promotionPartners.promotionPartner.id !== 1) {
            return null;
        }

        const { id } = getState().customer;
        const { restaurantId } = getState().selectedRestaurant;
        const { orderTypes } = getState();
        const url = `/customer/${id}/loyalty-code?restaurantId=${restaurantID ? restaurantID : restaurantId}&orderType=${orderTypes.selectedOrderType.value}`;

        axios(getState).get(url)
            .then(res => getOldestActiveLoyaltySuccess(res, dispatch))
            .catch(err => console.log('err ', err));
    };
};

const getOldestActiveLoyaltySuccess = (res, dispatch) => {
   
    dispatch({ type: GET_OLDEST_ACTIVE_LOYALTY, payload: res.data.code })
    dispatch({ type: DISPATCH_PROMO_CODE, payload: res.data.code })
}