import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { GridLoader } from 'react-spinners';

import StickyNavigationBar from './StickyNavigationBar';
import ProductsScreenPwa from './ProductsScreenPwa';
import {
	isArrayUndefinedOrNull,
	isItemUndefinedOrNull,
	renderFirstCategoryHiddenId
} from '../../../../utils/CommonUtils';
import { pushDataToGoogleTagManager } from '../../../../utils/AnalyticsUtils';
import { ARABIC, FRONT_END_URL } from '../../../../utils/Constants';

import MenuScreenAlerts from '../../../../components/order/menu/MenuScreenAlerts';
import CommonModal from '../../../../components/common/CommonModal';


export default class MenusAndProductsScreen extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			currency: '',
			selectedMenuId: -1,
			selectedCategories: [],
			selectedProductId: -1,
			selectedProductWizardOpened: false,
			stateAlreadyCopiedFromProps: false,
			itemOutOfCart: true,
			openMealDealWizard: false,
			mealDealInEditMode: false,
			loadScrollspyElements: false,
			selectedMenuArrayIndex: 0,
			matchingProducts: [],
			hiddenStickyMenu: false
		};

		this.handleScroll = this.handleScroll.bind(this);
	}

	componentDidMount() {
		this.applyTheme();
		let selectedMenuArrayIndex = this.props.currentMenuArrayIndex;

		if (selectedMenuArrayIndex) {
			this.setState({
				...this.state,
				selectedMenuArrayIndex: selectedMenuArrayIndex
			});
		}

		setTimeout(() => {
			this.setState({ loadScrollspyElements: true });
		}, 100);

		const categoryContainer = document.getElementById(`categoriesMainContainer`);

		window.addEventListener('scroll', _.debounce(() => {
			const currentElement = document.getElementsByClassName('is-current');

			if (currentElement && currentElement.length > 0) {
				const currentCategoryId = (currentElement[0] && currentElement[0].childNodes[0]) ? currentElement[0].childNodes[0].id : -1;
				const currentCategory = document.getElementById(currentCategoryId);

				if (currentCategory) {
					const currentCategoryOffsetLeft = currentCategory.offsetLeft;
					categoryContainer.scrollTo(currentCategoryOffsetLeft, 0);
				}
			}
		}, 100));

	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.selectedMenuId !== this.state.selectedMenuId) {
			for (let i = 0; i < this.props.menus.length; i++) {
				if (Number(this.state.selectedMenuId) === this.props.menus[i].id) {
					this.props.selectedMenuArrayIndex(i);
				}
			}
		}

		const menusFlag = isItemUndefinedOrNull(this.props.menus);
		const categoriesFlag = isArrayUndefinedOrNull(this.props.categories);
		const productsFlag = isArrayUndefinedOrNull(this.props.products);
		// const selectedMenuArrayIndexFlag = isItemUndefinedOrNull();
		// const selectedMenuIdFlag = isItemUndefinedOrNull();

		if (menusFlag && categoriesFlag && productsFlag && this.props.menus[this.state.selectedMenuArrayIndex]
			&& this.props.menus[this.state.selectedMenuArrayIndex].id && !this.state.stateAlreadyCopiedFromProps) {

			const selectedMenuId = this.props.menus[this.state.selectedMenuArrayIndex].id;
			const selectedCategories = this.getSelectedCategories(this.props.menus[this.state.selectedMenuArrayIndex].id);

			this.setState({
				...this.state,
				selectedMenuId: selectedMenuId,
				selectedCategories: selectedCategories,
				stateAlreadyCopiedFromProps: true
			});
		}

	}

	copyStateFromProps() {
		const menusFlag = isArrayUndefinedOrNull(this.props.menus);
		const categoriesFlag = isArrayUndefinedOrNull(this.props.categories);

		if (menusFlag && categoriesFlag && !this.state.stateAlreadyCopiedFromProps) {
			const { restaurant, menus, selectRestaurantMenu } = this.props;
			const { selectedMenuArrayIndex } = this.state;

			this.setState({
				...this.state,
				currency: restaurant.currency,
				selectedMenuId: menus[selectedMenuArrayIndex].id,
				selectedCategories: this.getSelectedCategories(menus[selectedMenuArrayIndex].id),
				stateAlreadyCopiedFromProps: true
			}, () => selectRestaurantMenu(menus[selectedMenuArrayIndex].id));
		}
	}

	componentWillReceiveProps(nextProps) {
		// If we change the restaurant get the updated menu props and update the state with the new props
		if (nextProps.menus.length !== 0 && this.props.menus.length !== 0) {
			if (nextProps.menus[0].id !== this.props.menus[0].id) {
				this.state.stateAlreadyCopiedFromProps = false;
			}
		}

		// If we change the restaurant get the updated categories props and update the state with the new props
		if (nextProps.categories && this.props.categories) {
			if (JSON.stringify(nextProps.categories) !== JSON.stringify(this.props.categories)) {
				this.state.stateAlreadyCopiedFromProps = false;
			}
		}
	}

	getSelectedCategories(selectedMenuId) {
		const { categories } = this.props;

		let selectedCategories = [];

		for (let i = 0; i < categories.length; i++) {
			if (categories[i].menuId == selectedMenuId) {
				selectedCategories.push(categories[i]);
			}
		}

		return selectedCategories;
	}

	changeState({ target }) {
		this.setState({
			...this.state,
			[target.name]: target.value,
			selectedCategories: this.getSelectedCategories(target.value)
		}, () => this.props.selectRestaurantMenu(target.value));

		setTimeout(() => { window.scrollTo(0, 0) }, 600);
		setTimeout(() => { this.setState({ loadScrollspyElements: true }) }, 100);
	}

	handleScroll(isSelectCategoryMobile, category, i) {
		pushDataToGoogleTagManager('event', 'view_item_list', this.props.isPwaApp, { category: category.name })

		const currentElement = document.getElementsByClassName('is-current');
		const isPwaApp = this.props.isPwaApp;
		const multipleMenus = this.props.menus.length > 1;

		if (currentElement && currentElement.length > 0) {
			if (isSelectCategoryMobile == true) {

				const categoryName = category.name;
				const element = document.getElementById(categoryName);

				if (element) {
					let elementOffsetTop = element.offsetTop + 285;
					// Set the offset height to higher value when we have multiple menus for the restaurant
					if (isPwaApp && multipleMenus) {
						elementOffsetTop = element.getBoundingClientRect().top + window.pageYOffset - 170;
					} else if (isPwaApp && !multipleMenus) {
						elementOffsetTop = element.getBoundingClientRect().top + window.pageYOffset - 120;
					}

					window.scroll({ top: elementOffsetTop, left: 0, behavior: 'smooth' });
				}
			} else {
				const categoryName = category.name;
				const element = document.getElementById(categoryName);

				if (element) {
					element.scrollIntoView({ block: "start", behavior: "smooth" });
				}
			}
		} else {
			const newElement = document.getElementById(category.name);

			if (newElement) {
				newElement.scrollIntoView({ block: "start", behavior: "smooth" });
			}
		}
	}

	sendDataToParent(hiddenStickyMenu, matchingProducts) {
		this.setState({
			...this.state,
			hiddenStickyMenu,
			matchingProducts
		});
	}

	// GridLoader and alerts
	renderLoaderContainer() {
		const { loadingIndicator } = styles;
		const { primaryColor, menuBackground } = this.props.customerThemes.selectedTheme;
		let { loadingContainer } = this.props.customerThemes.constantStyles;
		loadingContainer = { ...loadingContainer, backgroundColor: menuBackground }

		if (this.props.loading.backgroundLoader) {
			return (
				<div style={loadingContainer}>
					<div style={loadingIndicator} className='text-center loadingIndicator'>
						<GridLoader color={primaryColor} size={20} />
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	renderTransparentBackgroundLoaderContainer() {
		const { loadingIndicator } = styles;
		const { primaryColor } = this.props.customerThemes.selectedTheme;
		const { loadingContainerTransparent } = this.props.customerThemes.constantStyles;

		if (this.props.loading.transparentLoader || this.props.shoppingCart.promoCallsCount !== 0) {
			return (
				<div style={loadingContainerTransparent}>
					<div style={loadingIndicator} className='text-center loadingIndicator'>
						<GridLoader color={primaryColor} size={20} />
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	isProductMatchingSearch(productId) {
		const productFound = this.state.matchingProducts.filter(product => product.id === productId);

		return productFound.length;
	}

	noSearchedProducts(category) {
		return this.state.matchingProducts.filter(product => product.categoryId === category.id).length === 0;
	}

	applyTheme() {
		let customizedStyles = JSON.parse(JSON.stringify(styles));
		const { primaryColor, fontWeight, mainTextColor, menuBackground, backGroundImageFilePath, imageBackground } = this.props.customerThemes.selectedTheme;
		customizedStyles.productDescriptionStyle.color = mainTextColor;
		customizedStyles.productPriceStyle.color = primaryColor;
		customizedStyles.badge.background = primaryColor;
		customizedStyles.productPriceStyle.fontWeight = fontWeight;
		if (imageBackground) {
			customizedStyles.menuContainer.backgroundImage = `url(${FRONT_END_URL}/${backGroundImageFilePath})`;
		} else {
			customizedStyles.menuContainer.backgroundColor = menuBackground;
		}

		styles = customizedStyles;
		return styles;
	}

	render() {
		this.copyStateFromProps();

		return (
			<Fragment>
				<StickyNavigationBar
					menus={this.props.menus}
					categories={this.props.categories}
					selectRestaurantMenu={this.props.selectRestaurantMenu}
					hiddenStickyMenu={this.state.hiddenStickyMenu}
					alertMessage={this.props.alertMessage}
					changeState={this.changeState.bind(this)}
					selectedMenuId={this.state.selectedMenuId}
					loadScrollspyElements={this.state.loadScrollspyElements}
					handleScroll={this.handleScroll}
					selectedCategories={this.state.selectedCategories}
					isPwaApp={this.props.isPwaApp}
					styles={styles}
					customerThemes={this.props.customerThemes}
					sendDataToParent={this.sendDataToParent.bind(this)}
                    setMatchingProductsInParent={this.setMatchingProducts}
					isArabic={this.props.isArabic}
					renderShoppingCart={this.props.renderShoppingCart}
				/>
				<ProductsScreenPwa
					menus={this.props.menus}
					products={this.props.products}
					shoppingCart={this.props.shoppingCart}
					selectedCategories={this.state.selectedCategories}
					currency={this.state.currency}
					selectRestaurantMenu={this.props.selectRestaurantMenu}
					selectProduct={this.props.selectProduct}
					customerThemes={this.props.customerThemes}
					renderFirstCategoryHiddenId={renderFirstCategoryHiddenId}
					styles={styles}
					isPWA={true}
					generalNote={this.props.generalNote}
					numberOfMenus={this.props.menus.length}
					hiddenStickyMenu={this.state.hiddenStickyMenu}
					noSearchedProducts={this.noSearchedProducts.bind(this)}
					isProductMatchingSearch={this.isProductMatchingSearch.bind(this)}
					isArabic={this.props.isArabic}
					enableLargeImageDesign={this.props.enableLargeImageDesign}
					sendDataToParent={this.sendDataToParent.bind(this)}
					toggleSearchProductFocus={this.props.toggleSearchProductFocus}
				/>

				{this.renderLoaderContainer()}
				{this.renderTransparentBackgroundLoaderContainer()}
				<MenuScreenAlerts {...this.props} />
			</Fragment>

		);
	}
}

let styles = {
	menuContainer: {
		flexDirection: 'column',
		padding: 0
	},
	menuStyle: {
		WebkitAppearance: 'menulist-button',
		MozAppearance: 'none'
	},
	card: {
		height: 125,
		margin: 0,
		marginBottom: 30,
		border: 'none',
		backgroundColor: 'transparent',
		borderRadius: 0,
	},
	largeImageCard: {
		margin: '0 auto 30px',
		border: 'none',
		borderRadius: 0,
		backgroundColor: 'transparent'
	},
	cardOutlined: {
		height: 125,
		marginTop: 20,
		border: 'none',
		backgroundColor: 'rgba(255, 255, 255, 0.9)',
		borderRadius: 0
	},
	largeImageCardOutlined: {
		height: '500px',
		marginTop: 20,
		border: 'none',
		backgroundColor: 'rgba(255, 255, 255, 0.9)',
		borderRadius: 0
	},
	cardBody: {
		padding: "12px 30px 12px 30px",
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1
	},
	largeImageCardBody: {
		padding: "12px 30px",
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		padding: 0,
        margin: '0 10px'
	},
	productNameStyle: {
		marginBottom: 0,
		fontFamily: 'Roboto',
		fontWeight: 400,
		fontSize: '1.125rem',
		padding: 0
	},
	productNameStyleMobile: {
		marginBottom: 0,
		fontFamily: 'Roboto',
		fontWeight: 400,
		fontSize: '1.125rem',
		padding: 0,
		maxWidth: '98%'
	},
	productDescriptionStyle: {
		fontSize: '0.875rem',
		padding: 0,
		color: '#6e7676',
		marginBottom: 0
	},
	productPriceStyle: {
		fontSize: '0.875rem',
		padding: '5px 5px 5px 0',
		color: '#62b400',
		marginLeft: 0,
		marginBottom: 0
	},
	productPriceStyleRTL: {
		fontSize: '0.875rem',
		padding: '5px 0 5px 5px',
		color: '#62b400',
		marginLeft: 0,
		marginBottom: 0
	},
	categoryNameStyle: {
		padding: '5px 0px 0px 16px',
		color: '#000',
		fontFamily: 'Roboto',
		fontWeight: 400,
	},
	categoryNameStyleMobile: {
		padding: '5px 12px 32px',
		marginBottom: 0,
		marginTop: -16,
		color: '#000',
		fontFamily: 'Roboto',
		fontWeight: 400,
	},
	firstCategoryName: {
		padding: '26px 12px 32px',
		marginBottom: 0,
		marginTop: 0,
		color: '#000',
		fontFamily: 'Roboto',
		fontWeight: 400
	},
	menuBar: {
		padding: 0,
		height: 60,
		marginTop: -4,
		boxShadow: '1px 0px 24px 2px rgba(0,0,0,0.15)',
		backgroundColor: '#fff'
	},
	categoriesContainerMoreStyle: {
		float: 'left',
		height: 60,
		backgroundColor: '#fff'
	},
	badgeCol: {
		display: 'flex',
		justifyContent: 'flex-end',
		position: 'absolute',
		top: 10,
		left: 18,
		zIndex: 1
	},
	badgeColMobile: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignSelf: 'center',
		paddingRight: 0,
		paddingLeft: 5
	},
	badgeColMobileLargeImage: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        padding: 0,
        top: -10,
        left: 10
    },
    badgeColMobileLargeImageRTL: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        padding: 0,
        top: -10,
        right: 10
    },
	badge: {
		display: 'flex',
		justifyContent: 'center',
		paddingTop: 6,
		borderRadius: '50%',
		width: 26,
		height: 26,
		background: '#FA1106'
	},
	largeImageBadge: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 8,
        borderRadius: '50%',
        width: 30,
        height: 30,
        background: '#FA1106',
        fontSize: '14px'
    },
	listItemNoThumbnail: {
		borderLeftWidth: 0,
		borderRightWidth: 0,
		padding: 0
	},
	loadingContainer: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		textAlign: 'center',
		verticalAlign: 'middle',
		background: '#F8F8F8',
		zIndex: 100000000
	},
	loadingIndicator: {
		width: '72px',
		height: '72px',
	},
	loadingContainerTransparent: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		textAlign: 'center',
		verticalAlign: 'middle',
		zIndex: 100000000
	},
	imageStyle: {
		paddingRight: 18,
		marginTop: 0
	},
	categoryDescriptionMobile: {
		fontSize: 15,
		color: "#676767",
		padding: "10px 16px",
		paddingBottom: 0,
		fontStyle: "italic",
		display: "block",
		width: "100%",
		position: "relative",
		top: "-7px",
		paddingLeft: 12
	},
	profileNameStyle: {
		marginLeft: -10,
		verticalAlign: 'middle',
		fontSize: '1.0625rem'
	},
	shoppingCartIconStyle: {
		height: 'auto',
		width: 20,
		position: 'relative',
		left: 12,
		top: 6
	},
	generalNoteMobile: {
		color: 'rgb(103, 103, 103)',
		fontSize: 15,
		padding: '40px 16px 0 12px',
		marginBottom: -30,
		zIndex: 2,
		background: '#fff'
	},
	generalNoteMobileMultipleMenus: {
		color: 'rgb(103, 103, 103)',
		fontSize: 15,
		padding: '0px 16px 0 12px',
		marginBottom: -30,
		zIndex: 2,
		background: '#fff'
	}
};