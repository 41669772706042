import React from 'react';

const JcbSVG = ({ width, height }) => {
    return (
        <svg width={width} height={height} enableBackground="new 0 0 780 500" version="1.1" viewBox="0 0 780 500" space="preserve" xmlns="http://www.w3.org/2000/svg">
            <path d="M40,0h700c22.092,0,40,17.909,40,40v420c0,22.092-17.908,40-40,40H40c-22.091,0-40-17.908-40-40V40   C0,17.909,17.909,0,40,0z" fill="#0E4C96" />
            <path d="m632.24 361.27c0 41.615-33.729 75.36-75.36 75.36h-409.13v-297.88c0-41.626 33.733-75.37 75.365-75.37l409.12-1e-3 1e-3 297.89z" fill="#fff" />
            <linearGradient id="c" x1="83.977" x2="84.977" y1="645.5" y2="645.5" gradientTransform="matrix(132.87 0 0 -323.02 -10686 2.0876e5)" gradientUnits="userSpaceOnUse">
                <stop stopColor="#007B40" offset="0" /><stop stopColor="#55B330" offset="1" />
            </linearGradient>
            <path d="m498.86 256.54c11.684 0.253 23.437-0.516 35.076 0.4 11.787 2.199 14.629 20.043 4.156 25.888-7.141 
                3.851-15.633 1.433-23.379 2.113h-15.852l-1e-3 -28.401zm41.833-32.145c2.596 9.164-6.238 17.392-15.066 
                16.13h-26.767c0.185-8.642-0.368-18.021 0.271-26.208 10.725 0.301 21.549-0.616 32.21 0.479 4.581 1.151 
                8.414 4.917 9.352 9.599zm64.428-135.9c0.498 17.501 0.071 35.927 0.214 53.783-0.035 72.596 0.072 145.19-0.055 
                217.79-0.47 27.207-24.582 50.844-51.601 51.387-27.046 0.111-54.095 0.016-81.142 0.047v-109.75c29.47-0.154 
                58.959 0.307 88.417-0.232 13.667-0.859 28.632-9.875 29.27-24.914 1.61-15.103-12.632-25.551-26.152-27.201-5.198-0.135-5.044-1.516 
                0-2.117 12.892-2.787 23.02-16.133 19.226-29.499-3.236-14.058-18.772-19.499-31.697-19.472-26.351-0.18-52.709-0.026-79.062-0.077 
                0.172-20.489-0.354-41 0.286-61.474 2.087-26.716 26.806-48.747 53.447-48.27h78.849v-1e-3z" fill="url(#c)" />
            <linearGradient id="b" x1="83.984" x2="84.979" y1="645.5" y2="645.5" gradientTransform="matrix(133.43 0 0 -323.02 -11031 2.0876e5)" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1D2970" offset="0" /><stop stopColor="#006DBA" offset="1" />
            </linearGradient>
            <path d="m174.74 139.54c0.674-27.163 24.889-50.611 51.875-51.007 26.944-0.083 53.891-0.012 80.837-0.036-0.074 90.885 0.148 
                181.78-0.112 272.66-1.038 26.835-24.99 49.835-51.679 50.308-26.996 0.099-53.995 0.014-80.992 0.042v-113.45c26.223 
                6.194 53.722 8.832 80.473 4.721 15.993-2.574 33.488-10.424 38.902-27.014 3.986-14.191 
                1.742-29.126 2.334-43.691v-33.824h-46.297c-0.208 22.369 0.426 44.779-0.335 67.125-1.248 13.734-14.846 22.46-27.8 21.994-16.066 
                0.17-47.898-11.639-47.898-11.639-0.08-41.918 0.466-94.409 0.692-136.18z" fill="url(#b)" />
            <linearGradient id="a" x1="83.978" x2="84.977" y1="645.5" y2="645.5" gradientTransform="matrix(132.96 0 0 -323.03 -10842 2.0877e5)" gradientUnits="userSpaceOnUse">
                <stop stopColor="#6E2B2F" offset="0" />
                <stop stopColor="#E30138" offset="1" />
            </linearGradient>
            <path d="m324.72 211.89c-2.434 0.517-0.489-8.301-1.113-11.646 0.165-21.15-0.347-42.323 0.283-63.458 2.083-26.829 26.991-48.916 
                53.739-48.288h78.766c-0.073 90.884 0.147 181.78-0.111 272.66-1.039 26.834-24.992 49.833-51.681 50.308-26.997 0.1-53.997 
                0.015-80.997 0.043v-124.3c18.44 15.128 43.5 17.483 66.473 17.524 17.316-6e-3 34.534-2.674 51.35-6.67v-22.772c-18.953 
                9.446-41.232 15.446-62.243 10.019-14.655-3.65-25.294-17.812-25.056-32.937-1.699-15.728 7.524-32.335 22.981-37.011 
                19.189-6.008 40.107-1.413 58.096 6.397 3.854 2.019 7.765 4.521 6.222-1.921v-17.9c-30.084-7.156-62.101-9.792-92.329-2.004-8.749 2.469-17.271 6.212-24.38 11.958z" fill="url(#a)" />
        </svg>
    );
}

export default JcbSVG;