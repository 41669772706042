import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import i18n from '../../../i18next';

class MenuScreenAlerts extends Component {
    renderSuccessAlert() {
        const successAlert = this.props.alertMessage.showSuccessAlert;

        const dismiss = this.props.clearMessageAlert.bind(this, 'success');

        if (!successAlert) return null;

        setTimeout(() => this.props.clearMessageAlert('success'), 5000);
        return (
            <div className='alertContainer'>
                <Alert className='success-alert' color='success' toggle={dismiss} style={{ borderRadius: 0 }}>
                    {i18n.t('common:alerts.addedProductsSuccess')}
                </Alert>
            </div>
        );
    }

    renderErrorAlert() {
        const errorAlert = this.props.alertMessage.showErrorAlert;

        const dismiss = this.props.clearMessageAlert.bind(this, 'error');

        if (!errorAlert) return null;
        
        setTimeout(() => this.props.clearMessageAlert('error'), 3000);
        return (
            <div className='alertContainer'>
                <Alert className='error-alert' color='danger' toggle={dismiss}>
                    {i18n.t('common:alerts.custDetailsUpErr')}
                </Alert>
            </div>
        );
    }

    render() {
        const { mobileMsgStyle, desktopMsgStyle } = styles
        const isMobileView = (window.screen.width < 768) ? true : false;
        const errorMsgStyle = this.props.alertMessage.showErrorAlert || this.props.alertMessage.showSuccessAlert ? isMobileView ? mobileMsgStyle : desktopMsgStyle : {};

        return (
            <div style={errorMsgStyle}>
                {this.renderSuccessAlert()}
                {this.renderErrorAlert()}
            </div>
        )
    }
}

export default MenuScreenAlerts;

const styles = {
    mobileMsgStyle: {
        top: '50%',
        left: '3%',
        position: 'fixed',
        zIndex: 200000
    },

    desktopMsgStyle: {
        top: '60%',
        width: '100%',
        textAlign: 'center',
        position: 'fixed',
        zIndex: 2000000
    }
}