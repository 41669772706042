import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Modal } from 'reactstrap';
import moment from 'moment';

import TableSelectionScreen from './TableSelectionScreen';
import OrderTimeScreen from '../../order/start/OrderTimeScreen';
import MenuuLoader from '../../common/MenuuLoader';

import { getRestaurantData, saveInStoreInfo, resetErrorResponseFlag, saveStartOrderTime, changeLanguage } from '../../../actions';
import { updateCSSVariables } from '../../../utils/ThemesSelector';

class InStoreOrderContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            themeType: null,
            color: null,
            isOpen: true,
            isOrderTimeOpen: false,
            dinePayTypeId: null
        };

        this.toggle = this.toggle.bind(this);
        this.toggleLanguageButton = this.toggleLanguageButton.bind(this);
        this.toggleOrderTimeScreen = this.toggleOrderTimeScreen.bind(this);
    }

    componentDidMount() {

        let themeType = new URLSearchParams(window.location.search).get('theme');
        let color = new URLSearchParams(window.location.search).get('primaryColor');
        let restaurantId = new URLSearchParams(window.location.search).get('restaurantId');
        let typeId = new URLSearchParams(window.location.search).get('typeId');

        this.setState({
            ...this.state,
            themeType: themeType,
            color: color ? `#${color}` : null
        });

        this.props.getRestaurantData(restaurantId, typeId, () => {
            const currentTime = moment().add(this.props.selectedRestaurant.restaurantTimeOffset, 'milliseconds');
            this.props.saveStartOrderTime(currentTime);
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.customerThemes.selectedTheme.colorsCss !== prevProps.customerThemes.selectedTheme.colorsCss && this.props.customerThemes.selectedTheme.colorsCss) {
            this.applyTheme();
        }
    }

    componentWillUnmount() {
        this.props.resetErrorResponseFlag();
    }

    toggle() {
        this.setState({
            ...this.state,
            isOpen: !this.state.isOpen
        });
    }

    toggleLanguageButton(language) {
        this.props.changeLanguage(language)
    }

    toggleOrderTimeScreen(typeId) {
        this.setState({
            isOrderTimeOpen: !this.state.isOrderTimeOpen,
            dinePayTypeId: typeId
        })
    }

    applyTheme() {
        updateCSSVariables(this.props.customerThemes.selectedTheme.colorsCss);
    }

    render() {
        const { themeType, color } = this.state;
        let systemDefaultColor = this.props.customerThemes.themeDark.primaryColor;
        // Selects loader color for LIGHT/DARK themes cuz their colors are NOT coming from BACKEND
        if (themeType === 'LIGHT') {
            systemDefaultColor = this.props.customerThemes.themeLight.primaryColor;
        }

        const loaderColor = !color ? systemDefaultColor : color;

        return (
            <Container fluid style={{ background: 'red' }}>
                { !this.state.isOrderTimeOpen && <MenuuLoader loaderColor={loaderColor} /> }
                <Modal className="modalStep startOrder" isOpen={this.state.isOpen} size='lg' autoFocus={false}>
                    {
                        !this.state.isOrderTimeOpen ?
                            <TableSelectionScreen
                                {...this.props}
                                saveInStoreInfo={this.props.saveInStoreInfo}
                                toggle={this.toggle}
                                toggleLanguageButton={this.toggleLanguageButton}
                                toggleOrderTimeScreen={this.toggleOrderTimeScreen} /> :
                            <OrderTimeScreen
                                {...this.props}
                                dinePayTypeId={this.state.dinePayTypeId}
                                toggle={this.toggle} 
                                toggleOrderTimeScreen={this.toggleOrderTimeScreen}/>
                    }
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        customerThemes: state.customerThemes,
        selectedRestaurant: state.selectedRestaurant,
        brand: state.brand,
        orderTypes: state.orderTypes,
        loading: state.loading,
        logoImagePath: state.orderTypes.logoImagePath,
        pwaAppRunning: state.pwaAppRunning,
        estimateOrderTime: state.estimateOrderTime,
        storeLocalStorage: state.storeLocalStorage,
        language: state.storeLocalStorage.language,
        selectedLanguage: state.storeLocalStorage.selectedLanguage,
        isDefaultLanguage: state.storeLocalStorage.isDefaultLanguage
    }
}

export default connect(mapStateToProps, { getRestaurantData, saveInStoreInfo, resetErrorResponseFlag, saveStartOrderTime, changeLanguage })(InStoreOrderContainer);