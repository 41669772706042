import React from 'react';

const UserIconSVG = ({ width, height, fill, style }) => {
	const viewBoxValues = "0 0 94.6 94.6";
	
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBoxValues} style={style} >
			<g>
			{/* This is inner background */}
			{/* <path fill={fill} d="M76.3,18.3c-3.8-3.8-8.2-6.7-13-8.8c-5.1-2.1-10.4-3.2-16-3.2s-10.9,1.1-16,3.2c-4.9,2.1-9.3,5-13,8.8
				s-6.7,8.2-8.8,13c-2.1,5.1-3.2,10.4-3.2,16s1.1,10.9,3.2,16c1.7,4,4,7.6,6.8,10.9c1.4-9.9,7.5-18.3,15.9-23c1.2,1,2.5,1.9,4,2.7
				c3.4,1.8,7.3,2.8,11.1,2.8c3.9,0,7.7-1,11.1-2.8c1.4-0.8,2.8-1.7,4-2.7c8.4,4.7,14.5,13.1,15.9,23c2.8-3.3,5.1-6.9,6.8-10.9
				c2.1-5.1,3.2-10.4,3.2-16s-1.1-10.9-3.2-16C83,26.4,80.1,22,76.3,18.3z M55.5,48.4c-2.4,1.3-5.2,2.1-8.2,2.1s-5.7-0.7-8.2-2.1
				c-5.4-2.9-9.1-8.6-9.1-15.2c0-9.5,7.7-17.2,17.2-17.2s17.2,7.7,17.2,17.2C64.5,39.8,60.8,45.5,55.5,48.4z"/> */}
			<path fill={fill} d="M47.3,16c-9.5,0-17.2,7.7-17.2,17.2c0,6.6,3.7,12.3,9.1,15.2c2.4,1.3,5.2,2.1,8.2,2.1s5.7-0.7,8.2-2.1
				c5.4-2.9,9.1-8.6,9.1-15.2C64.5,23.7,56.8,16,47.3,16z"/>
			<path fill={fill} d="M90.9,28.9c-2.4-5.6-5.8-10.7-10.1-15c-4.3-4.3-9.4-7.8-15-10.1C59.9,1.2,53.7,0,47.3,0S34.7,1.2,28.9,3.7
				c-5.6,2.4-10.7,5.8-15,10.1c-4.3,4.3-7.8,9.4-10.1,15C1.3,34.7,0,40.9,0,47.3c0,6.4,1.3,12.6,3.7,18.4c2.4,5.6,5.8,10.7,10.1,15
				l0,0c0,0,0,0,0,0v0l0.1,0.1l0,0c4.3,4.3,9.3,7.7,14.9,10c5.8,2.5,12,3.7,18.4,3.7s12.6-1.3,18.4-3.7c5.6-2.3,10.6-5.7,14.9-10l0,0
				l0.2-0.2c0,0,0,0,0,0l0,0h0c4.3-4.3,7.7-9.4,10.1-15c2.5-5.8,3.7-12,3.7-18.4C94.6,40.9,93.3,34.7,90.9,28.9z M85.1,63.3
				c-1.7,4-4,7.6-6.8,10.9c-1.4-9.9-7.5-18.3-15.9-23c-1.2,1-2.5,1.9-4,2.7c-3.4,1.8-7.3,2.8-11.1,2.8c-3.9,0-7.7-1-11.1-2.8
				c-1.4-0.8-2.8-1.7-4-2.7c-8.4,4.7-14.5,13.1-15.9,23c-2.8-3.3-5.1-6.9-6.8-10.9c-2.1-5.1-3.2-10.4-3.2-16s1.1-10.9,3.2-16
				c2.1-4.9,5-9.3,8.8-13s8.2-6.7,13-8.8c5.1-2.1,10.4-3.2,16-3.2s10.9,1.1,16,3.2c4.9,2.1,9.3,5,13,8.8c3.8,3.8,6.7,8.2,8.8,13
				c2.1,5.1,3.2,10.4,3.2,16S87.2,58.2,85.1,63.3z"/>
		</g>
		</svg>
	);
}

export default UserIconSVG;