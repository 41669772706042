import React, { Component } from "react";
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import '../../styles/ConfirmAddressPwa.css';
import '../../styles/Common.css';

import ApplicationStep from '../common/ApplicationStep';
import ConfirmAddressContent from '../../../components/order/start/ConfirmAddressContent';
import { loadOrderTypeData, saveCustomerDeliveryAddress, restaurantChainService, saveSelectedRestaurantData, openChangeOrderTimeScreen, loadRestaurantsInDeliveryArea } from "../../../actions";

import i18n from "../../../i18next";
import { updateCSSVariables } from "../../../utils/ThemesSelector";
import { setLastOpenedPagePWA } from "../../../utils/CommonUtils";

class ConfirmAddressPwa extends Component {

    constructor(props) {
        super(props);

        this.state = {
            buildingNumber: '',
            fullAddress: '',
            street: '',
            town: '',
            zipCode: '',
            disabledNext: true
        };

        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.confirmAddress = this.confirmAddress.bind(this);
        this.onBackKeyDown = this.onBackKeyDown.bind(this);
    }

    componentDidMount() {
        this.applyTheme();

        if (this.props.userAddress.fullAddress) {

            this.setState({
                ...this.state,
                fullAddress: this.props.userAddress.fullAddress,
                street: this.props.userAddress.street,
                town: this.props.userAddress.city,
                buildingNumber: this.props.userAddress.streetNumber ? this.props.userAddress.streetNumber : '',
                zipCode: this.props.userAddress.zipCode ? this.props.userAddress.zipCode : ''
            }, () => {

                if (this.validateData()) {
                    this.setState({ ...this.state, disabledNext: false });
                }
            });
        }

        setLastOpenedPagePWA(this.props.openChangeOrderTimeScreen);
        // console.log('ConfirmAddressPwa DidMount');
        document.addEventListener("backbutton", this.onBackKeyDown, false);
    }

    componentWillUnmount() {
        // console.log('ConfirmAddressPwa WillUnmount');
        document.removeEventListener("backbutton", this.onBackKeyDown, false);
    }

    onBackKeyDown(e) {
        e.preventDefault();
        e.stopPropagation();
        // console.log('ConfirmAddress Backbutton');
        this.goBack();
    }

    applyTheme() {
        updateCSSVariables(this.props.customerThemes.selectedTheme.colorsCss);
    }

    goBack() {
        let brandId = Number(this.props.brandId);
        this.props.history.push('/brand/' + brandId + '/start-order/main-screen');
    }

    getUpdatedFullAddress(newAddress) {
        let fullAddress = `${newAddress.buildingNumber} ${newAddress.street}, ${newAddress.town}, ${newAddress.zipCode}`;

        return fullAddress;
    }

    handleAddressChange(newAddress) {
        let updatedAddress = JSON.parse(JSON.stringify(newAddress));

        this.setState({
            ...this.state,
            buildingNumber: updatedAddress.buildingNumber,
            fullAddress: this.getUpdatedFullAddress(newAddress),
            street: updatedAddress.street,
            town: updatedAddress.town,
            zipCode: updatedAddress.zipCode
        }, () => {

            // Check if the data in all fields is valid
            if (!this.validateData()) {
                this.setState({ ...this.state, disabledNext: true });
            } else {
                this.setState({ ...this.state, disabledNext: false });
            }

        });
    }

    confirmAddress() {
        // Select order type
        let confirmAddressOrderType = {
            id: 1,
            value: 'Delivery'
        };

        const calledFromPwa = {
            brandId: Number(this.props.brandId),
            calledFrom: 'ConfirmAddress'
        };

        this.props.loadOrderTypeData(confirmAddressOrderType, calledFromPwa);

        // Handle address reducer
        setTimeout(() => {
            let updatedAddress = JSON.parse(JSON.stringify(this.props.userAddress));

            updatedAddress.fullAddress = this.state.fullAddress;
            updatedAddress.streetNumber = this.state.buildingNumber;
            updatedAddress.zipCode = this.state.zipCode;
            updatedAddress.city = this.state.town;
            updatedAddress.street = this.state.street;

            this.props.saveCustomerDeliveryAddress(updatedAddress);

            this.props.restaurantChainService(updatedAddress, (restaurant) => {
                let brandId = Number(this.props.brandId);
                if (Array.isArray(restaurant)) {
                    this.props.loadRestaurantsInDeliveryArea(restaurant, () => {
                        this.props.history.push({
                            pathname: '/brand/' + brandId + '/start-order/restaurant-selection',
                            state: { isRedirectedFromDelivery: true }
                        });
                    });
                } else this.props.saveSelectedRestaurantData(restaurant, () => {
                    this.props.history.push('/brand/' + brandId + '/start-order/order-time');
                });
            });
        }, 180);

    }

    validateData() {
        let validData = true;

        if (!this.state.fullAddress || !this.state.town || !this.state.zipCode || this.state.zipCode.length > 31 || !this.state.buildingNumber) {
            validData = false;
            this.state.disabledNext = true;
            return validData;
        }

        if (this.state.fullAddress === '' || !this.state.town === '' || !this.state.zipCode === ''
            || !this.state.buildingNumber === '') {
            this.state.disabledNext = true;
            return validData = false;
        }

        validData = true;
        this.state.disabledNext = false;

        return validData;
    }

    renderContent() {
        const { fontFamilyType, fontBold, fontItalic, uppercase, buttonsFont } = this.props.customerThemes.selectedTheme;

        const nextBtnStyle = {
            fontFamily: fontFamilyType,
            fontWeight: fontBold === 'bold' ? fontBold : 300,
            fontStyle: fontItalic,
            textTransform: uppercase,
            color: buttonsFont
        }

        return (
            <React.Fragment>
                <div className='startOrderContentWrapper'>
                    <ConfirmAddressContent
                        updateAddressInParent={this.handleAddressChange}
                    />
                    <div className='confirmAddressButtonContainer'>
                        <Button className='nextButton btn btn-secondary' onClick={this.confirmAddress} disabled={!this.validateData()}>
                            <span style={nextBtnStyle}>{i18n.t('common:buttons.nextBtn')}</span>
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    render() {

        return (
            <React.Fragment>
                <ApplicationStep
                    header={true}
                    hideBackButton={false}
                    transparentHeader={false}
                    content={this.renderContent()}
                    goBack={this.goBack.bind(this)}
                    text={i18n.t('screens:confirmAddressScreen.confirmAddress')}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        orderTypes: state.orderTypes.orderTypes,
        brandId: state.brand.id,
        userAddress: state.customer.selectedDeliveryAddress,
        customer: state.customer,
        customerThemes: state.customerThemes
    };
};

export default connect(mapStateToProps, { loadOrderTypeData, saveCustomerDeliveryAddress, restaurantChainService, saveSelectedRestaurantData, openChangeOrderTimeScreen, loadRestaurantsInDeliveryArea })(ConfirmAddressPwa);