import React, { Component } from 'react';
import { Col } from 'reactstrap';
import Axios from 'axios';
import i18n from '../../../../i18next';

import { SERVER_URL, FRONT_END_URL } from '../../../../utils/Constants';
import { shoppingCartCalculations } from '../../../../utils/ShoppingCartUtils';

class MollieForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMollieAlreadyCalled: false,
            isSaveCardAllowed: false,
            isCardFormHidden: true,
            isSavedCard: false,
            isCardFieldValid: true,
            isCardPlaceholderHidden: false,
            isCvcPlaceholderHidden: false,
            isHolderPlaceholderHidden: false,
            areAllFieldsValid: {
                isCardValid: false,
                isDateValid: false,
                isCvcValid: false
            }
        }

        this.changeState = this.changeState.bind(this);
        this.placeOrder = this.placeOrder.bind(this);
    }

    componentDidMount() {
        const { selectedPaymentType, selectedPaymentCard } = this.props.payment;
        this.toggleCardFields(selectedPaymentType.id, selectedPaymentCard);
        // Checks and gets called when redirected from 3D secure window
        // if (window.location.search.includes('isRedirected')) {
        //     const molliePaymentData = {
        //         paymentId: this.props.paymentProvider.mollieTransactionId
        //     };
        //     console.log('DID MOUNT redirected ');
        //     this.props.placeOrder(false, molliePaymentData);
        // }
    }

    componentDidUpdate(prevProps) {
        const { profileId } = this.props.paymentProvider.molliePremenuData;
        const { selectedPaymentType, selectedPaymentCard } = this.props.payment;

        if (prevProps.payment.selectedPaymentType !== selectedPaymentType || prevProps.payment.selectedPaymentCard !== selectedPaymentCard) {
            this.toggleCardFields(selectedPaymentType.id, selectedPaymentCard);
        }

        if (window.Mollie && !this.state.isMollieAlreadyCalled) {
            this.createFormFields(profileId);
            this.setState({ ...this.state, isMollieAlreadyCalled: true });
        }
    }

    componentWillUnmount() {
        this.props.validateCardInput(false);
    }

    toggleCardFields(id, selectedPaymentCard) {
        this.setState({
            ...this.state,
            isCardFormHidden: id !== 1,
            isSavedCard: selectedPaymentCard.brand ? true : false
        });
    }

    createFormFields(profileId) {
        const mollie = window.Mollie(profileId, { locale: 'en_US', testmode: true });
        const form = document.getElementById('mollieForm');
        const options = {
            styles: {
                base: {
                    fontSize: '16px'
                }
            }
        }
        const holder = mollie.createComponent('cardHolder', options);
        const card = mollie.createComponent('cardNumber', options);
        const expiryDate = mollie.createComponent('expiryDate', options);
        const verificationCode = mollie.createComponent('verificationCode', options);

        holder.mount('#card-holder');
        card.mount('#card-number');
        expiryDate.mount('#expiry-date');
        verificationCode.mount('#verification-code');
        let that = this;

        holder.addEventListener('change', (event) => {
            that.cardElementsChange(event, 'Holder');
            that.setOverallValidity(event, 'Holder');
        });

        card.addEventListener('change', (event) => {
            that.cardElementsChange(event, 'Card');
            that.setOverallValidity(event, 'isCardValid');
        });

        card.addEventListener('blur', (event) => {
            that.setCardFieldValidInvalid(event);
        });

        expiryDate.addEventListener('blur', (event) => {
            that.setCardFieldValidInvalid(event);
        });

        expiryDate.addEventListener('change', (event) => {
            that.setOverallValidity(event, 'isDateValid');
        });

        verificationCode.addEventListener('change', (event) => {
            that.cardElementsChange(event, 'Cvc');
            that.setOverallValidity(event, 'isCvcValid');
        });

        verificationCode.addEventListener('blur', (event) => {
            that.setCardFieldValidInvalid(event);
        });

        // form.addEventListener('submit', (e) => this.placeOrder(e, mollie));
        form.addEventListener('submit', (e) => this.doPlaceOrder(e, mollie));
    }

    cardElementsChange(event, element) {
        if (event.error) {
            this.setState({
                ...this.state,
                [`is${element}PlaceholderHidden`]: event.dirty
            });
        } else {
            this.setState({
                ...this.state,
                [`is${element}PlaceholderHidden`]: event.dirty,
                isCardFieldValid: event.valid
            });
        }
    }

    setCardFieldValidInvalid(event) {
        this.setState({
            ...this.state,
            isCardFieldValid: event.valid
        });
    }

    setOverallValidity(event, element) {
        let areAllTrue = true;
        let areAllFieldsValid = { ...this.state.areAllFieldsValid };
        areAllFieldsValid[element] = event.valid;
        Object.values(areAllFieldsValid).map(fieldValue => {
            if (!fieldValue) {
                areAllTrue = false;
            }
        });

        this.setState({
            ...this.state,
            areAllFieldsValid: {
                ...this.state.areAllFieldsValid,
                [element]: event.valid
            }
        }, () => {
            if (areAllTrue) {
                this.props.validateCardInput(true);
                this.setCardFieldValidInvalid(event);
            } else {
                this.props.validateCardInput(false);
                this.setCardFieldValidInvalid(event);
            }
        });
    }

    changeState() {
        this.setState({
            ...this.state,
            isSaveCardAllowed: !this.state.isSaveCardAllowed
        });
    }

    // opens the new window for mollie checkout before making the api calls
    // needed for the iFrame integration. Safari does not support it otherwise.
    doPlaceOrder(e, mollie) {
        const windowRef = window.open();
        this.placeOrder(e, mollie, windowRef);
    }

    async placeOrder(e, mollie, windowRef) {
        e.preventDefault();
        const { customer, selectedRestaurant, shoppingCart, estimateOrderTime, pwaAppRunning, brand } = this.props;
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total : null;
        const { total } = shoppingCartCalculations(shoppingCart, selectedRestaurant, undefined, estimateOrderTime.taxIncludeInPrice);
        this.props.processingPayment();

        try {
            const { token, error } = await mollie.createToken();
            const header = { headers: { 'Authorization': `TOKEN${customer.token}` } };
            const reqObj = {
                customerId: customer.id,
                restaurantId: selectedRestaurant.restaurantId,
                currency: selectedRestaurant.currency.toUpperCase(),
                amount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : total,
                mollieAuthorizeRequest: {
                    cardToken: token,
                    redirectUrl: `${FRONT_END_URL}?isRedirected=true#/checkout`
                }
            };

            if (error) {
                // console.error(error);
                windowRef.close();
                this.props.errorProcessingPayment(error, 'error', 3);
            }

            // console.log('brand ', brand, ' pwaAppRunning ', pwaAppRunning, ' history ', this.props.history);
            await Axios.post(`${SERVER_URL}/payment/authorize`, reqObj, header)
                .then(response => {
                    this.props.dispatchTransactionId(response.data.mollieResponse.id, 3);
                    let that = this;

                    if (response.data.mollieResponse._links.checkout !== null) {
                        if (!this.props.pwaAppRunning) {
                            windowRef.location = response.data.mollieResponse._links.checkout.href;
                        } else {
                            let browserRef = window.cordova.InAppBrowser.open(response.data.mollieResponse._links.checkout.href, '_self', 'location=no,toolbar=no');
                            browserRef.addEventListener('loadstart', function (event) {

                                if ((event.url).endsWith('checkout')) {
                                    const molliePaymentData = {
                                        paymentId: that.props.paymentProvider.mollieTransactionId
                                    };
                                    that.props.placeOrder(false, molliePaymentData);
                                    browserRef.close();
                                }
                            });
                        }
                    } else {
                        const molliePaymentData = {
                            paymentId: response.data.mollieResponse.id
                        };
                        this.props.placeOrder(false, molliePaymentData);
                    }
                });
        } catch (error) {
            this.props.sendEmailReport(selectedRestaurant.restaurantId, error, 'CreateTokenFailure');
        }
    }

    render() {
        const isMobile = window.innerWidth <= 865;
        return (
            <Col xs={12} sm={12} md={8} style={{ margin: 'auto', paddingBottom: 10, padding: isMobile ? 0 : 'auto' }}>
                <div hidden={this.state.isCardFormHidden}>
                    <form
                        id='mollieForm'
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <div id="card-holder"
                            style={{ marginBottom: 10, height: 46, padding: '12px 0px 10px 12px', borderRadius: 0, border: `1px solid ${this.state.isCardFieldValid ? '#d3d3d3' : '#eb1c26'}`, boxShadow: '0 1px 3px 0 #e6ebf1', transition: 'box-shadow .15s ease' }}>
                            <span
                                hidden={this.state.isHolderPlaceholderHidden}
                                style={{ position: 'absolute', pointerEvents: 'none', color: '#aeaeae', fontWeight: 600 }}>{i18n.t('screens:checkoutScreen.cardHolder')}</span>
                        </div>

                        <div className='checkout'
                            id='card-element'
                            style={{ display: 'flex', border: `1px solid ${this.state.isCardFieldValid ? '#d3d3d3' : '#eb1c26'}`, boxShadow: '0 1px 3px 0 #e6ebf1', transition: 'box-shadow .15s ease' }}
                        >
                            <div id="card-number" style={{ height: 46, padding: !isMobile ? '12px 0 10px 12px' : '12px 0 10px 6px', width: isMobile ? '50%' : '65%' }}>
                                <span
                                    hidden={this.state.isCardPlaceholderHidden}
                                    style={{ position: 'absolute', pointerEvents: 'none', color: '#aeaeae', fontWeight: 600 }}>{i18n.t('screens:checkoutScreen.cardNumber')}</span>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', width: `${isMobile ? 50 : 35}%` }}>
                                <div id="expiry-date" style={{ height: 46, padding: !isMobile ? '12px 0 10px 12px' : '12px 0 10px 6px' }}></div>
                                <div id="verification-code" style={{ height: 46, padding: !isMobile ? '12px 0 10px 12px' : '12px 0 10px 6px' }}>
                                    <span
                                        hidden={this.state.isCvcPlaceholderHidden}
                                        style={{ position: 'absolute', pointerEvents: 'none', color: '#aeaeae', fontWeight: 600 }}>{i18n.t('screens:checkoutScreen.cvc')}</span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <input
                    hidden={this.state.isCardFormHidden} disabled={this.props.payment.selectedPaymentCard.lastDigits}
                    type='checkbox' name='isSaveCardAllowed' id='isSaveCardAllowed' style={{ marginTop: 5, marginLeft: isMobile ? 40 : 0 }}
                    onChange={this.changeState}
                />
                <label hidden={this.state.isCardFormHidden} htmlFor='isSaveCardAllowed' style={{ margin: '5px auto auto 10px' }}>Save card for later use</label> */}
            </Col>
        );
    }
}

export default MollieForm;