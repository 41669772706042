
import axios from '../../utils/AxiosUtils';
import { push } from "connected-react-router";

import {
    LOADING_NO_BACKGROUND,
    SELECTED_PRODUCT,
    SELECTED_PRODUCT_EDIT,
    SHOW_ERROR_DIALOG
} from '../types';

export const getSelectedProduct = (productId, callback, productQuantity, openedProduct, isAvailable) => {
    return (dispatch, getState) => {
        let selectedOrderTypeId = getState().orderTypes.selectedOrderType.id;

        // TODO find out why do we need these checks !!!
        if (openedProduct) {
            dispatch({ type: SELECTED_PRODUCT, payload: openedProduct });
            callback(openedProduct);
        } else if (!callback && !productQuantity && !openedProduct) {
            return dispatch(push(`/brand/${getState().brand.id}/offline`));
        } else {

            dispatch({ type: LOADING_NO_BACKGROUND, payload: true });

            const previewMode = getState().menuPreviewMode.previewMode;

            const url = `/product/${productId}?previewMode=${previewMode}&orderTypeId=${selectedOrderTypeId}`;

            axios(getState).get(url)
                .then(response => getSelectedProductSuccess(dispatch, response, callback, productQuantity, isAvailable))
                .catch(_ => {

                    dispatch({ type: SHOW_ERROR_DIALOG, payload: true });
                });
        }
    };
};

const getSelectedProductSuccess = (dispatch, response, callback, productQuantity, isAvailable) => {

    let responseData = { ...response.data };
    // The server response does NOT have product quantity so we pass it from the MenuScreen component
    if (!responseData.hasOwnProperty('quantity')) {
        responseData.quantity = productQuantity;
    }
    dispatch({ type: SELECTED_PRODUCT, payload: response.data }); // REAFACTORING payload
    
    // if (autoAddToCart && !responseData.questions.length && isAvailable) {
    //     autoAddToCart(responseData);
    // } else if (callback !== undefined) {
    //     callback(responseData);
    // }

    if (callback !== undefined) {

        callback(responseData);
    }

    dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
};

export const getSelectedProductForEdit = (product, callback, index) => {
    return (dispatch) => {

        let updatedProduct = { ...product };
        updatedProduct.productIndex = index;
        updatedProduct.productInEditMode = true;
        updatedProduct.addedToppingsPrice = product.addedToppingsPrice;

        dispatch({ type: SELECTED_PRODUCT_EDIT, payload: updatedProduct });

        setTimeout(() => callback(updatedProduct), 300);
    };
};

export const editProduct = (product, index, callback, originalProduct) => {
    return (dispatch) => {

        let updatedProduct = { ...product };
        updatedProduct.productIndex = index;
        if (originalProduct) {
            if (originalProduct.promotionFreeProduct) {
                updatedProduct.promotionFreeProduct = true;
                updatedProduct.instruction = originalProduct.instruction ? { ...originalProduct.instruction } : null;
                if (!updatedProduct.mealDeal) {
                    originalProduct.questions.map((question, index) => {
                        if (question.selectedAnswers) {
                            updatedProduct.questions[index].selectedAnswers = question.selectedAnswers;
                        }
                    });
                }
            }
        }

        dispatch({ type: SELECTED_PRODUCT_EDIT, payload: updatedProduct });
        callback(updatedProduct);
    };
};

export const getSelectedProductWithSelectedSize = (productSizePosId, productQuantity, callback) => {
    return (dispatch, getState) => {
        let selectedOrderTypeId = getState().orderTypes.selectedOrderType.id;

        const previewMode = getState().menuPreviewMode.previewMode;

        const url = `/product/${productSizePosId}?previewMode=${previewMode}&orderTypeId=${selectedOrderTypeId}`;

        axios(getState).get(url)
            .then(response => getSelectedProductWithSelectedSizeSuccess(dispatch, response, productQuantity, callback))
            .catch((error) => {
                console.log(error);
                dispatch({ type: SHOW_ERROR_DIALOG, payload: true });
            });
    };
};

const getSelectedProductWithSelectedSizeSuccess = (dispatch, response, productQuantity, callback) => {

    let responseData = { ...response.data };
    // The server response does NOT have product quantity so we pass it from the MenuScreen component
    if (!responseData.hasOwnProperty('quantity')) {
        responseData.quantity = productQuantity;
    }

    if (callback !== undefined) {

        dispatch({ type: SELECTED_PRODUCT, payload: responseData });
        callback(responseData);
    }
};