import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import Sticky from 'react-sticky-el';
import Scrollspy from 'react-scrollspy';

import {
	Col,
	ListGroup,
	ListGroupItem,
	Input,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from "reactstrap";
import { toggleSearchProductFocus } from '../../../../actions/index';
import { connect } from 'react-redux';
import { isMobile, isMobileOnly, isTablet } from 'mobile-device-detect';
class StickyNavigationBar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			shownCategories: [],
			moreCategories: []
		};
	}

	componentDidMount() {

		// Add listener on window resize to recalculate the showed categoryNames in the category menu
		setTimeout(() => {
			this.handleMoreButtonBehaviour();

			window.addEventListener('resize', _.debounce(() => this.handleMoreButtonBehaviour(), 10));
		}, 600);

	}

	componentWillReceiveProps(nextProps) {
		const componentReceivedNewSelectedCategories = nextProps.selectedCategories !== this.props.selectedCategories || this.state.shownCategories.length === 0;

		if (componentReceivedNewSelectedCategories) {
			let updatedShownCategories = _.cloneDeep(this.props.selectedCategories);
			updatedShownCategories.map((category, index) => {
				category.position = ++index;
			});

			this.setState({ ...this.state, shownCategories: _.cloneDeep(updatedShownCategories) });
		}

		// If menu is changed update the shownCategories and handle the more button behaviour
		if (JSON.stringify(nextProps.selectedCategories) !== JSON.stringify(this.props.selectedCategories)) {
			this.setState({ ...this.state, shownCategories: _.cloneDeep(nextProps.selectedCategories), moreCategories: [] }, () => {
				this.handleMoreButtonBehaviour();
			});
		}
	}

	handleMoreButtonBehaviour() {
		const isLandscapeView = (window.orientation == 90 || window.orientation == -90) ? true : false;
		const isMobilePortraitView = (window.innerWidth < 768) ? true : false;
		const isMobileLandscapeView = (isMobilePortraitView && isLandscapeView && window.innerHeight < 768) ? true : false;
		const isMobileView = isMobilePortraitView || isMobileLandscapeView;

		const menuContainer = document.querySelector("#categoriesMainContainer");
		let allNavCategories;
		if (menuContainer) {
			allNavCategories = menuContainer.querySelectorAll('ul.list-group');
		}

		const handleMoreButtonList = () => {
			if (!isMobileView) {
				// Get menu and nav width
				const menuWidth = menuContainer.offsetWidth;
				let navWidth = 0;

				allNavCategories.forEach((category) => {
					navWidth += category.children[0].getBoundingClientRect().width;
				});

				// Window size goes down -> if navWidth overflows menuWidth
				// add the last visible category to moreCategories(inside more button) and remove it from shownCategories
				if (navWidth > menuWidth) {
					// Copy the moreCategories and push the last category from shownCategories into moreCategories list -> order moreCategories by position
					let updatedMoreCategories = _.cloneDeep(this.state.moreCategories);

					let lastVisibleCategoryIndex = this.state.shownCategories.length - 1;
					const navLastVisibleCategory = this.state.shownCategories[lastVisibleCategoryIndex];

					updatedMoreCategories.push(_.cloneDeep(navLastVisibleCategory));
					updatedMoreCategories.sort((a, b) => { return a.priority - b.priority });

					// Remove the categories present in moreCategories list from shownCategories list
					let updatedShownCategories = _.cloneDeep(this.state.shownCategories);
					updatedShownCategories.map((category, index) => {
						updatedMoreCategories.map((moreCategory) => {
							if (category.id === moreCategory.id) {
								updatedShownCategories.splice(index, 1);
							}
						})
					});

					this.setState({ ...this.state, shownCategories: updatedShownCategories, moreCategories: updatedMoreCategories }, () => {
						// We need setTimeout so the dom is updated correctly next time the handleMoreButtonList functions is fired
						setTimeout(() => {
							handleMoreButtonList();
						}, 10);
					});

				} else { // Window size goes up
					const availableSpaceForAnotherCategory = menuWidth > (navWidth + 150);
					// If there is a place for another category -> remove it from moreCategories list and add it to shownCategories
					if (availableSpaceForAnotherCategory && this.state.moreCategories.length) {
						// Remove firstItem in moreCategories list
						let updatedMoreCategories = _.cloneDeep(this.state.moreCategories);
						const firstCategoryFromMoreCategories = _.cloneDeep(this.state.moreCategories[0]);

						updatedMoreCategories.map((category, index) => {
							if (category.id === firstCategoryFromMoreCategories.id) {
								updatedMoreCategories.splice(index, 1);
							}
						});

						// Add firstItem from moreCategories list to shownCategories
						let updatedShownCategories = _.cloneDeep(this.state.shownCategories);
						updatedShownCategories.push(firstCategoryFromMoreCategories);
						updatedShownCategories.sort((a, b) => { return a.priority - b.priority });

						this.setState({ ...this.state, shownCategories: updatedShownCategories, moreCategories: updatedMoreCategories });
					}

				}
			} else if (isMobileView) {
				// Empty the moreCategories list and add all categories back to shownCategories
				let updatedMoreCategories = [];

				let updatedShownCategories = _.cloneDeep(this.props.selectedCategories);
				updatedShownCategories.map((category, index) => {
					category.position = ++index;
				});

				this.setState({ ...this.state, shownCategories: updatedShownCategories, moreCategories: updatedMoreCategories });
			}

		};

		if (menuContainer && allNavCategories) {
			handleMoreButtonList();
		}
	}

	// This renders the main content section
	renderStickyContent() {
		const { menuBar, categoriesContainerMoreStyle, categoriesContainerMoreStyleRTL } = this.props.styles;
		const { menuItemsBackground } = this.props.customerThemes.selectedTheme;
		const { moreCategories } = this.state;

		const isHighResDisplay = (window.outerWidth >= 1600) ? 'fixedContainer' : '';
		const isMenuHidden = (this.props.menus.length > 1) ? false : true;
		const categoriesWithOneMenuStyle = (isMenuHidden) ? this.props.isArabic ? { paddingRight: 30 } : { paddingLeft: 30 } : {};
		const categoriesColWidth = (isMenuHidden) ? 11 : isTablet ? 8 : 9;
		const categoryContainerClasses = (isMenuHidden) ? 'categoryContainer categoryContainerWithHiddenMenu' : 'categoryContainer';
		const categoriesNames = this.getCategoryName();
		const noHiddenCategories = moreCategories.length > 0;
		const mobileOrAppCategoriesWidth = (this.props.isPwaApp || isMobile || isMobileOnly) && !isMenuHidden ? { maxWidth: '82%' } : null;
		

		const menuMobileStyle = isMobileOnly ? 'unset !important' : 'flex';
		const menuBarStyle = { display: menuMobileStyle, backgroundColor: menuItemsBackground };
		const menusContainerrStyle = {
			padding: isMobile ? isMobileOnly ? '12px 12px 0px 12px' : this.props.isArabic ? '12px 12px 12px 0px' : '12px 0px 12px 12px' : this.props.isArabic ? '12px 24px 12px 15px' : '12px 0px 12px 24px',
			maxWidth: (isMobileOnly) ? '100%' : 'auto'
		}

		const menuCategoriesStyle = {
			backgroundColor: menuItemsBackground,
			justifyContent: isMenuHidden && noHiddenCategories ? 'flex-start' : 'space-between'
		}

		let searchBarDependantStyles = {};
		let categoriesPadding = {};
		if (this.props.hiddenStickyMenu) {
			searchBarDependantStyles = {
				height: 62,
				backgroundColor: 'transparent',
				border: 'none'
			}
		}

		if ((this.props.isPwaApp || isMobile || isMobileOnly) && !isMenuHidden) {
			categoriesPadding = this.props.isArabic ? { paddingLeft: '0px' } : { paddingRight: '0px' }
		} else if ((this.props.isPwaApp || isMobile || isMobileOnly) && isMenuHidden) {
			categoriesPadding = this.props.isArabic ? { paddingLeft: '80px' } : { paddingRight: '80px' }
		} else {
			categoriesPadding = this.props.isArabic ? { paddingLeft: '50px' } : { paddingRight: '50px' }
		}

		return (
			<Sticky id="menuNavigationContainer" topOffset={this.props.isPwaApp ? -50 : 0} stickyStyle={this.props.isPwaApp ? { transform: 'translateY(50px)' } : { transform: 'translateY(0px)' }}
				style={{
					...{
						backgroundColor: menuItemsBackground,
						height: 62,
						border: '0.5px solid rgb(228, 228, 228)',
						paddingTop: 5,
						boxShadow: 'none',
						zIndex: 3,
						position: 'relative',
					}, ...searchBarDependantStyles
				}}>
				<Col hidden={this.props.hiddenStickyMenu} style={Object.assign({}, menuBar, menuBarStyle, categoriesPadding)} className={isHighResDisplay}>
					<Col xs={12} sm={2} md={2} className="menusContainer" style={menusContainerrStyle} hidden={isMenuHidden}>
						{this.renderMenus()}
					</Col>
					<Col xs={12} sm={9} md={categoriesColWidth} id="categoriesMainContainer" className={categoryContainerClasses} style={Object.assign({}, categoriesWithOneMenuStyle, this.props.isArabic ? categoriesContainerMoreStyleRTL : categoriesContainerMoreStyle, menuCategoriesStyle, mobileOrAppCategoriesWidth)}>
						{(categoriesNames.length > 0) ? this.renderCategoriesList(categoriesNames) : null}
					</Col>
					<Col sm={1} md={isTablet && !isMenuHidden ? 2 : 1} style={isMobileOnly || window.innerWidth < 768 ? { display: 'none' } : {}}>{(!isMobileOnly || window.innerWidth >= 768) ? this.renderMoreCategories() : null}</Col>
					{
						this.props.renderShoppingCart ?
						this.props.renderShoppingCart()
						: null
					}
				</Col>
				{/**TODO make a it a small width (something like the reactstrap product boxes in sm-s) 
				 * add centering styles - margins probabbly
				*/}
			</Sticky>
		);
	}

	// Render menus section
	renderMenus() {
		let { menuStyle } = this.props.styles;
		const { menuItemsBackground, primaryFontColor } = this.props.customerThemes.selectedTheme;

		menuStyle = { ...menuStyle, backgroundColor: menuItemsBackground, color: primaryFontColor }

		const { fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
		const fontStyles = {
			fontFamily: fontFamilyType,
			fontWeight: fontBold,
			fontStyle: fontItalic,
			textTransform: uppercase
		}

		return (
			<Input type="select" name="selectedMenuId" id="menu" value={this.props.selectedMenuId} onChange={this.props.changeState} style={{ ...menuStyle, ...fontStyles }}  >
				{
					this.props.menus.map((menu, i) => {
						return (
							<option key={`menus${i}`} value={menu.id}>
								{menu.name}
							</option>
						);
					})
				}
			</Input>
		);
	}

	// Render categories section
	getCategoryName() {
		let categoriesNames = [];

		for (let i = 0; i < this.props.categories.length; i++) {
			if (this.props.selectedMenuId == this.props.categories[i].menuId) {
				const categoryName = this.props.categories[i].name;
				categoriesNames.push(categoryName);
			}
		}

		return categoriesNames;
	}

	renderCategoriesList(categoriesNames) {
		const { shownCategories } = this.state;

		const isLandscapeView = (window.orientation == 90 || window.orientation == -90) ? true : false;

		const isMobilePortraitView = (window.screen.width < 768) ? true : false;
		const isMobileLandscapeView = (isMobilePortraitView && isLandscapeView && window.screen.height < 768) ? true : false;
		const isMobileView = isMobilePortraitView || isMobileLandscapeView;

		let offsetNumber;

		if (this.props.isPwaApp && this.props.menus.length > 1) {
			offsetNumber = -180;
		} else if ((isMobileView && this.props.menus.length > 1) || this.props.isPwaApp) {
			offsetNumber = -125;
		} else {
			offsetNumber = -80;
		}

		const { menuItemsBackground, primaryFontColor, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;

		const fontStyles = {
			fontFamily: fontFamilyType,
			fontWeight: fontBold === 'bold' ? fontBold : 300,
			fontStyle: fontItalic,
			textTransform: uppercase,
			backgroundColor: menuItemsBackground,
			color: primaryFontColor
		}

		return this.props.loadScrollspyElements &&
			<Scrollspy items={categoriesNames} currentClassName="is-current" offset={offsetNumber} style={{ marginBottom: 0 }}>
				{
					shownCategories.map((category, i) => {
						return (
							<ListGroup key={`category${i}`}>
								<ListGroupItem
									tag="li"
									id={`category${i}`}
									name={i}
									onClick={() => this.props.handleScroll(isMobileView, category, i)}
									className="menuCategory"
									style={fontStyles}>
									{category.name}
								</ListGroupItem>
							</ListGroup>
						)
					})
				}
			</Scrollspy>
	}

	renderMoreCategories() {
		const { moreCategories } = this.state;

		const noHiddenCategories = moreCategories.length > 0;

		const { fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
		const fontStyles = {
			fontFamily: fontFamilyType,
			fontWeight: fontBold === 'bold' ? fontBold : 300,
			fontStyle: fontItalic,
			textTransform: uppercase
		}

		if (noHiddenCategories && this.props.isArabic) {
			return (
				<ListGroup style={{paddingLeft: '25px'}}>
					<UncontrolledDropdown>
						<DropdownToggle nav className="moreCategoriesText" >
							<span style={{
								...fontStyles,
								fontSize: '1rem',
								display: 'flex',
								justifyContent: 'center'
							}}>More...</span>
						</DropdownToggle>
						<DropdownMenu left>
							{
								moreCategories.map((remainCategory, i) => {

									return (
										<DropdownItem key={`dropdownItem${i}`} style={{padding: '10px 25px 10px 15px'}} tag="li" onClick={() => this.props.handleScroll(false, remainCategory, i)} id={'#' + remainCategory.id} className="menuCategory">
											{remainCategory.name}
										</DropdownItem>
									)
								})
							}
						</DropdownMenu>
					</UncontrolledDropdown>
				</ListGroup>
			);
		} else if(noHiddenCategories) {
			return (
				<ListGroup>
					<UncontrolledDropdown>
						<DropdownToggle nav className="moreCategoriesText" >
							<span style={{
								...fontStyles,
								fontSize: '1rem',
								display: 'flex',
								justifyContent: 'center'
							}}>More...</span>
						</DropdownToggle>
						<DropdownMenu right>
							{
								moreCategories.map((remainCategory, i) => {

									return (
										<DropdownItem key={`dropdownItem${i}`} style={{ padding: '10px 15px 10px 25px' }} tag="li" onClick={() => this.props.handleScroll(false, remainCategory, i)} id={'#' + remainCategory.id} className="menuCategory">
											{remainCategory.name}
										</DropdownItem>
									)
								})
							}
						</DropdownMenu>
					</UncontrolledDropdown>
				</ListGroup>
			);
		}
	}

	render() {

		return (
			<Fragment>
				{this.renderStickyContent()}
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		customerThemes: state.customerThemes,
		products: state.restaurantProducts,
		menus: state.restaurantMenus.menus
	};
};

export default connect(mapStateToProps, { toggleSearchProductFocus })(StickyNavigationBar)