import React, { Component } from "react";
import { connect } from 'react-redux';

import { 
    getOrderTypes,
    loadCustomerFromLocalStorage,
    loadOrderTypeData,
    saveCustomerDeliveryAddress,
    saveStartOrderTime,
    loadCustomerSavedDeliveryAddresses,
    getSelectedProduct,
    openChangeOrderTimeScreen,
    addProductToCart } from "../../../../actions";
import { setLastOpenedPagePWA } from "../../../../utils/CommonUtils";
import ApplicationStep from '../../common/ApplicationStep';

import '../../../styles/ProductWizardContainerPwa.css';
import ProductWizard from '../../../../components/order/product/ProductWizard';

let transparentHeaderContainer = null;

class ProductWizardContainerPwa extends Component {

    constructor(props) {
        super(props);

        this.state = {
            product: null,
            selectedProductWizardOpened: false,
            generalProductWizardOpened: false,
            openMealDealWizard: false,
            mealDealInEditMode: false,
            isProductWizardOpen: false
        };

        this.onBackKeyDown = this.onBackKeyDown.bind(this);
    }

    componentDidMount() {
        // If product is in edit mode load the data for edit mode
        if (this.props.location.editProduct) {
            this.setState({
                ...this.state,
                product: this.props.location.editProduct.product,
                isProductWizardOpen: !this.state.isProductWizardOpen
            });
        } else { // Else product is in create mode -> get product data
            this.getProduct();
        }

        if (!window.location.href.includes('offline')) {
            setLastOpenedPagePWA(this.props.openChangeOrderTimeScreen);
        }

        // On elements loaded get the transparentHeaderContainer
        window.addEventListener('load', () => {
            transparentHeaderContainer = document.getElementById('headerContainerTransparent');
        });

        // console.log('ProductWizardContainerPwa DidMount');
        document.addEventListener("backbutton", this.onBackKeyDown, false);
    }

    componentWillUnmount() {
        // console.log('ProductWizardContainerPwa WillUnmount');
        document.removeEventListener("backbutton", this.onBackKeyDown, false);
    }

    onBackKeyDown(e) {
        e.preventDefault();
        e.stopPropagation();
        // console.log('ProductWizard Backbutton');
        this.goBack();
    }

    goBack() {
        this.props.history.push('/brand/' + this.props.brandId + '/order/menu');
    }

    getProduct() {
        let productId = this.props.match.params.productId;
        // if user offline search for matches in openedProducts else dispatch offline msg to user
        if (!this.props.userOnline) {
            let noMatchFound = true;

            this.props.openedProducts.map(openedProduct => {
                // console.log('openedProduct.id === productId ', openedProduct.id, ' productId', typeof productId);
                if (openedProduct.id === parseInt(productId)) {
                    noMatchFound = false;
                    // console.log('openedProduct.id === productId ', openedProduct.id === productId);
                    return this.props.getSelectedProduct(productId, (product) => {
                        if (product.mealDeal) {
                            this.setState({ ...this.state, product: JSON.parse(JSON.stringify(product)), openMealDealWizard: true, mealDealInEditMode: false });
                        } else {
                            this.setState({ ...this.state, product: JSON.parse(JSON.stringify(product)), selectedProductWizardOpened: true });
                        }
                    }, undefined, openedProduct);
                }
            });

            if (noMatchFound) {
                this.props.getSelectedProduct(productId, undefined, undefined, undefined);
            }
        } else {
            // Get the data for the selected product
            this.props.getSelectedProduct(productId, () => {
                this.setState({
                    ...this.state,
                    isProductWizardOpen: !this.state.isProductWizardOpen
                });
            });
        }
    }

    toggle() {
        this.setState({ 
            ...this.state,
            isProductWizardOpen: false,
            product: null
        }, () => this.props.history.goBack());
    }

    renderContent() {
        return (
            <React.Fragment>
                {this.state.isProductWizardOpen &&
                    <ProductWizard
                        toggle={this.toggle.bind(this)}
                        pwaAppRunning={true}
                        selectedProduct={this.state.product ? this.state.product : this.props.selectedProduct}
                        selectedRestaurant={this.props.selectedRestaurant}
                        selectedMenuId={this.props.selectedMenuId}
                        menus={this.props.menus}
                        categories={this.props.categories}
                        products={this.props.products}
                        selectedTheme={this.props.customerThemes.selectedTheme}
                        addProductToCart={this.props.addProductToCart}
                        currency={this.props.selectedRestaurant.currency}
                    />
                }
            </React.Fragment>
        );
    }

    render() {

        return (
            <React.Fragment>
                <ApplicationStep
                    header={true}
                    hideBackButton={false}
                    transparentHeader={true}
                    content={this.renderContent()}
                    goBack={this.goBack.bind(this)}
                    text={('')}
                    isHamburgerShadowed={true}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        orderTypes: state.orderTypes.orderTypes,
        customerSavedAddresses: state.customerSavedAddresses,
        menus: state.restaurantMenus.menus,
        categories: state.restaurantCategories,
        products: state.restaurantProducts,
        selectedMenuId: state.restaurantMenus.selectedMenuId,
        brandId: state.brand.id,
        brandCity: state.brand.city,
        userAddress: state.customer.selectedDeliveryAddress,
        customer: state.customer,
        customerThemes: state.customerThemes,
        userOnline: state.userOnline,
        openedProducts: state.openedProducts,
        selectedProduct: state.selectedProduct,
        selectedRestaurant: state.selectedRestaurant
    };
};

export default connect(mapStateToProps, {
    getOrderTypes,
    loadOrderTypeData,
    loadCustomerFromLocalStorage,
    saveCustomerDeliveryAddress,
    saveStartOrderTime,
    loadCustomerSavedDeliveryAddresses,
    getSelectedProduct,
    openChangeOrderTimeScreen,
    addProductToCart
})(ProductWizardContainerPwa);