import React, { Component } from 'react';
import { Row, Col, Button, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { connect } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";

import i18n from '../../../../i18next';
import { parseNumber, formatNumber, getCountryCallingCode } from 'libphonenumber-js';
import { isValidNumber, getNumberType } from 'libphonenumber-js/custom';
import metadata from 'libphonenumber-js/metadata.full.json';

import phoneIconSrc from '../../../../images/icons/icon-phone-grey.png';
import TickSVG from '../../common/svgComponents/TickSVG';
import { ARABIC, FRONT_END_URL, reCaptchaSiteKey } from '../../../../utils/Constants';
import { Link, NavLink } from 'react-router-dom';

class ForgottenEmailCommon extends Component {

    constructor(props) {
        super(props);

        this.state = {
            phone: null,
            isPhoneTyped: false,
            countryCodeCoppiedToPhoneInput: false,
            submitDisabled: true,
            countryCallingCode: null,
            captchaCode: ''
        };

        this.changeState = this.changeState.bind(this);
    }

    componentDidMount() {
        
        let countryCallingCode = this.props.brand.countryCode;

        if (countryCallingCode) {
            countryCallingCode = getCountryCallingCode(this.props.brand.countryCode);

            this.setState({
                countryCallingCode: countryCallingCode
            });
        }
    }

    componentDidUpdate(_, prevState) {
        if (prevState.phone !== this.state.phone && this.props.customer.phoneNumberErrMsg) {
            this.props.resetPhoneNumberErrMsg();
        }
    }

    reloadCaptcha() {
        this.setState({
            captchaCode: ''
        }, () => {
            this.props.loadCaptcha()
        })

    }


    changeState({ target }) {

        if(target.name === 'captchaCode') {
            this.setState({
                [target.name]: target.value
            })
        } else {

            this.setState({
                [target.name]: target.value
            },
                () => {

                    let phoneVariable = !this.state.phone && this.state.phone !== 0 ? '' : this.state.phone; // Needed because parseNumber demands a string
                    let phoneNumber = null;
                    let phoneNumbers = [parseNumber(`+${this.state.countryCallingCode}${phoneVariable}`), parseNumber(`${phoneVariable}`), parseNumber(`+${phoneVariable}`), parseNumber(`${phoneVariable}`, this.props.brand.countryCode)];

                    for (let i = 0; i < phoneNumbers.length; i++) {
                        let num = phoneNumbers[i];

                        if (Object.keys(num).length !== 0) {
                            const formatNumberToInternational = formatNumber(num, 'International');
                            const validatedNumber = isValidNumber(formatNumberToInternational, metadata);
                            const isMobileNumber = getNumberType(formatNumberToInternational, metadata);
                            if (validatedNumber && isMobileNumber.includes("MOBILE")) {
                                phoneNumber = num;
                                break;
                            }
                        }
                    }

                    if (!phoneNumber) { // Checks if phoneNumber is too long and exits the function
                        this.setState({
                            submitBtnDisabled: true,
                            isPhoneTyped: false
                        });
                        return;
                    } else {
                        this.setState({
                            submitBtnDisabled: false,
                            isPhoneTyped: true
                        })
                    }
                })
        }

        if (this.props.customer.phoneNumberErrMsg) {
            this.props.resetErrMsg()
        }
    }

    renderContent() {
        const { subHeading, inputGroup, input, bodyColThree, phoneIconStyle, phoneIconStyleRTL, checkIconStyle, checkIconStyleRTL, customBodyInnerContent, termsAndConditions, subMsg, marginAuto } = this.props.styles;
        const { type, primaryColor, secondaryColor, primaryFontColor, inputsFontColor, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        
        const { isPWA } = this.props;
        let isHidden = (this.state.isPhoneTyped) ? false : true;
        const isArabic = this.props.language === ARABIC;

        const fontWeight = fontBold === 'bold' ? fontBold : 300;
        const titleStyle = {
            margin: isPWA ? '20px auto 5px' : 'auto',
            textAlign: 'center',
            fontSize: 22,
            color: primaryFontColor,
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }
        const countryCallingCodeStyle = {
            width: 55, 
            maxWidth: 55, 
            borderRadius: 0, 
            borderRightWidth: 0, 
            textAlign: 'start', 
            paddingLeft: 16, 
            color: inputsFontColor 
        }
        const countryCallingCodeStyleRTL = {
            width: 55, 
            maxWidth: 55, 
            borderRadius: 0, 
            borderLeftWidth: 0, 
            textAlign: 'start', 
            paddingRight: 16, 
            color: inputsFontColor 
        }

        return (
            <div className='modal-body-custom'>
                <Col sm={12} className='modal-custom-body-title'>
                    <p className='title-style' style={titleStyle}>{i18n.t('screens:forgottenEmailScreen.heading')}</p>
                </Col>
                <Col xs={12} className='modal-custom-body-inner-content' style={customBodyInnerContent}>
                    <Col xs={12} md={12} style={subHeading}>{i18n.t('screens:forgottenEmailScreen.subHeading')}</Col>
                    <Col xs={12} md={9} style={bodyColThree}>
                        <InputGroup style={{ ...inputGroup, ...{ marginBottom: 3 } }}>
                            <div style={{ position: 'absolute', zIndex: 4, padding: '13px 8px', pointerEvents: 'none', color: inputsFontColor }}>+</div>
                            <Input
                                id='countryCallingCode'
                                name='countryCallingCode' type='text' autoComplete='none'
                                autoFocus
                                onFocus={e => e.currentTarget.select()}
                                style={ isArabic ? { ...input, ...countryCallingCodeStyleRTL} : { ...input, ...countryCallingCodeStyle}}
                                // onChange={(e) => this.setState({ ...this.state, countryCallingCode: e.target.value })}
                                onChange={this.changeState}
                                value={`${this.state.countryCallingCode}`} />
                            <img src={phoneIconSrc} width="30" height="auto" style={isArabic ? phoneIconStyleRTL : phoneIconStyle} alt='' />
                            <Input
                                id="phone" type="text"
                                name="phone"
                                placeholder='123456789'
                                onFocus={(e) => e.target.placeholder = ''}
                                onBlur={(e) => e.target.placeholder = '123456789'}
                                value={this.state.phone}
                                onChange={this.changeState}
                                style={input}
                                className='addressInputs'
                            />
                            <InputGroupAddon addonType="append">
                                <TickSVG
                                    width={30} height={30}
                                    fill={type === 'DARK' ? secondaryColor : primaryColor}
                                    style={isArabic ? checkIconStyleRTL : checkIconStyle} hidden={isHidden} />
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    <Col xs={12} md={9}
                        hidden={!this.props.customer.phoneNumberErrMsg}
                        style={isPWA ? {color: 'red', padding: '0 20px', textAlign: isArabic ? 'right' : 'left'} : { color: 'red', textAlign: isArabic ? 'right' : 'left', padding: '0 25px', margin: 'auto'  }}
                    >{this.props.customer.phoneNumberErrMsg}</Col>
                    {
                        this.props.customer.captcha ?
                            <Col xs={8} md={6} style={marginAuto}>
                                <img src={`${FRONT_END_URL}/${this.props.customer.captcha.path}`} style={{ width: '100%', height: '50px', marginBottom: '10px' }} />
                                <InputGroup style={inputGroup}>
                                    <Input id="captchaCode" type="text" name="captchaCode" placeholder="Enter Code"
                                        state={this.state.captchaCode}
                                        value={this.state.captchaCode}
                                        onChange={this.changeState}
                                        style={input}
                                        className='addressInputs' />
                                </InputGroup> 
                            </Col> : null
                    }
                </Col>
                { this.renderModalFooter()}
            </div >
        )
    }

    renderModalFooter() {
        const { submitBtn, modalCustomSubmitBody, submitBtnDisabled } = this.props.styles;
        const { primaryColor, buttonsFont, fontFamilyType, fontBold, fontItalic, uppercase, inactiveColor } = this.props.customerThemes.selectedTheme;
        const { phone, countryCallingCode, submitDisabled, captchaCode } = this.state;
        const { isPWA, brand, isOrderDone } = this.props;

        const isDisabled = this.state.captchaCode && this.state.isPhoneTyped ? false : true;
        let selectSubtmitButtonStyle, submitBtnText, submitBtnClassName, activeInactiveBtn;

        if (!isDisabled) {
            selectSubtmitButtonStyle = submitBtn;
            submitBtnClassName = "submitNumber";
            activeInactiveBtn = { backgroundColor: primaryColor, color: buttonsFont };
        } else {
            selectSubtmitButtonStyle = submitBtnDisabled;
            submitBtnClassName = "";
            activeInactiveBtn = { backgroundColor: inactiveColor, color: buttonsFont };
        }

        submitBtnText = i18n.t('screens:phoneNumberScreen.submit');

        const fontWeight = fontBold === 'bold' ? fontBold : 300;
        const submitBtnFontStyle = {
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }
        
        return (
            <React.Fragment>
                <Row className='modal-custom-body-footer' style={modalCustomSubmitBody}>
                    <Col xs={12} sm={10} style={{ display: 'flex', justifyContent: 'center' }} >
                        <Col xs={12} sm={8} className="submitNumberBtn">
                            <Button disabled={isDisabled} className={submitBtnClassName} style={Object.assign({}, selectSubtmitButtonStyle, activeInactiveBtn, submitBtnFontStyle, { padding: 0 })} onClick={() => this.props.forgottenEmail(phone, isOrderDone, isPWA, brand.id, countryCallingCode, captchaCode, () => this.reloadCaptcha()) }>{submitBtnText}</Button>
                        </Col>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    render() {
        
        return (
            <React.Fragment>
                {this.renderContent()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps)(ForgottenEmailCommon);
