import React, { Component } from 'react';

class WizardListItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            span: 0
        }

        this.answerRef = React.createRef();
        this.setSpans = this.setSpans.bind(this);
    }

    componentDidMount() {
        this.setSpans();
    }

    setSpans() {
        let height = this.answerRef.current.clientHeight;
        let spans = Math.round(height / 25);
// console.log('setSpans height ', height, ' spans ', spans);
        this.setState({
            ...this.state,
            span: spans
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resetLayout !== this.props.resetLayout) {
            this.setSpans();
        }
    }

    render() {
        return (
            <div style={{ width: '100%', gridRowEnd: `span ${this.state.span}` }} >
                <div ref={this.answerRef}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default WizardListItem;