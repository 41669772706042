import {
    LOAD_AVAILABLE_DISCOUNTS_SUCCESS,
    SET_APPLIED_PROMOTIONS,
    LOAD_LOYALTY_SUCCESS,
    CLEAR_STATE,
    CLEAR_APP_STATE
} from '../../../actions/types';

const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOAD_AVAILABLE_DISCOUNTS_SUCCESS:
        case SET_APPLIED_PROMOTIONS:
            return action.payload;
        case CLEAR_STATE:
            if (action.fromPwa) {
                return state;
            } else {
                return INITIAL_STATE;
            }
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}