import React from 'react';
import { FRONT_END_URL } from '../../../../utils/Constants';

import { Container } from 'reactstrap';
import PromotionCard from '../../../../components/order/discounts/PromotionCard';
import i18n from '../../../../i18next';

const DiscountsContentPwa = ({ renderColumn, singleDiscount, filteredDiscounts, selectedTheme }) => {
    const {mainContainer,discountContainer, overflowAuto} = styles;

    if (filteredDiscounts.length > 0) {
        const overflowVariable = renderColumn ? {} : overflowAuto;

        return (
            <Container style={Object.assign({}, styles.mobileView, overflowVariable)} className={singleDiscount}>
                {renderColumn ? renderColumn() : null}
                {
                    filteredDiscounts.map(item => {

                        let image = item.imagePath === null ? null : `${FRONT_END_URL}/${item.imagePath}`;

                        return (
                            <PromotionCard
                                promotion={item}
                                promotionImage={image}
                                styles={styles}
                                selectedTheme={selectedTheme}
                            />
                        );
                    })
                }
            </Container>
        );
    } else {
        return (
            <Container style={Object.assign({}, styles.mobileView, mainContainer)} className="discounts-container">
                <div style={discountContainer}>{i18n.t('screens:discountsScreen.noDiscounts')}</div>
            </Container>
        );
    }
}

export default DiscountsContentPwa;

let styles = {
    mainContainer: {
        height: '100%',
        fontSize: '1.25em',
        textAlign: 'center'
    },
    discountContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    discountImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 0
    },
    overflowAuto: {
        overflow: 'auto'
    },
    card: {
        width: '100%',
        border: 'none'
    },
    mobileView: {
        padding: 0,
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'center',
        maxHeight: 'calc(70vh - 85px)'
    },
    headerFirstCol: {
        padding: 0
    },
    subHeading: {
        fontSize: 12
    },
    description: {
        fontSize: 14
    }
}