import axios from '../../utils/AxiosUtils';

import {
    LOAD_ORDER_HISTORY_SUCCESS,
    LOADING_NO_BACKGROUND,
    SHOW_ERROR_DIALOG,
    LOAD_ORDER_PRODUCTS_SUCCESS
} from '../types';
import { transformProductsFromOrderHistory } from '../../utils/DataTransformationUtils';

export const loadOrderHistory = (brandId) => {
    return (dispatch, getState) => {

        dispatch({ type: LOADING_NO_BACKGROUND, payload: true });

        const customerId = getState().customer.id;
        const url = `/customer/${customerId}/order-history?restaurantChainId=${brandId}`;

        axios(getState).get(url)
            .then(response => loadOrderHistorySuccess(dispatch, response))
            .catch(_ => {

                dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
                dispatch({ type: SHOW_ERROR_DIALOG, payload: true });
            });
    };
};

const loadOrderHistorySuccess = (dispatch, response) => {

    dispatch({ type: LOADING_NO_BACKGROUND, payload: false });
    dispatch({ type: LOAD_ORDER_HISTORY_SUCCESS, payload: response.data });
};

export const loadOrderProducts = (orderId, callback) => {
    return (dispatch, getState) => {

        dispatch({ type: LOADING_NO_BACKGROUND, payload: true });
        doLoadOrderProducts(orderId, dispatch, getState, callback);
    };
};

const doLoadOrderProducts = (orderId, dispatch, getState, callback) => {
    let selectedOrderTypeId = getState().orderTypes.selectedOrderType.id;

    const url = `/order/${orderId}/combined?orderTypeId=${selectedOrderTypeId}`;

    axios(getState).get(url)
        .then(response => loadOrderProductsSuccess(dispatch, response, getState, callback))
        .catch(error => console.log('error ', error));
};

const loadOrderProductsSuccess = (dispatch, response, getState, callback) => {
    
    const orderedProducts = response.data;
    const selectedRestaurantData = getState().selectedRestaurant;
    let existingProductsInCart = [];

    orderedProducts.map(orderedProduct => {
        let transformedOrderedProduct = transformProductsFromOrderHistory(orderedProduct, selectedRestaurantData);
        existingProductsInCart.push(transformedOrderedProduct);
    });

    dispatch({ type: LOAD_ORDER_PRODUCTS_SUCCESS, payload: existingProductsInCart });
    callback();
};