import React from 'react';

const FailureSVG = ({ width, height, fill, style }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 84.4 84.4" style={style}>
			<polygon fill={fill} points="84.4,9.2 75.2,0 42.2,33 9.2,0 0,9.2 33,42.2 0,75.2 9.2,84.4 42.2,51.4 75.2,84.4 84.4,75.2 
			51.4,42.2 "/>
		</svg>
	);
}

export default FailureSVG;