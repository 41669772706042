import React from 'react';
import MaterialIcon from 'material-icons-react';
import i18n from '../../../../i18next';

import europeStaticMap from '../../../../images/europe-static-map.jpg';

const OfflineMap = props => {
    return (
        <div id='offlineMainScreenMapContainer' hidden={props.userOnline}>
            <h5>
                {i18n.t('common:alerts.offlineMode')}
                <span>
                    <MaterialIcon icon='cloud_off' color='#ffffff' size={21} />
                </span>
            </h5>
            <img src={europeStaticMap} alt='static map of europe' />
        </div>
    );
};

export default OfflineMap;