import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { isFirefox, isMobileOnly, isTablet } from 'mobile-device-detect';
import i18n from '../../../i18next';

import AnswerGroupsTabs from './AnswerGroupsTabs';
import SingleAnswersStep from './SingleAnswersStep';
import MultiAnswersStep from './MultiAnswersStep';
import { getSelectedProductWithSelectedSize } from '../../../actions';
import { areAllGroupsEmpty, getProductQuestions, newDeepCopy, areAllItemsDefault, getAnswerPrice } from '../../../utils/ProductWizardUtils';
import { ARABIC } from '../../../utils/Constants';

class WizardStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentQuestion: {},
            selectedProduct: {}
        };

        this.selectItem = this.selectItem.bind(this);
    }

    componentDidMount() { // reg products
        const { product, productMainQuestion, currentQuestion } = this.props;
        this.setState({
            ...this.state,
            currentQuestion: product.mealDeal ? currentQuestion : productMainQuestion
        });
    }

    componentDidUpdate(prevProps) {
        const { product, productMainQuestion, currentQuestion } = this.props;

        if (prevProps !== this.props) {
            this.setState({
                ...this.state,
                currentQuestion: product.mealDeal ? currentQuestion : productMainQuestion // REG PRODUCTS 
            });
        }
    }

    selectItem(event, item) {
        const { product, productMainQuestion, restaurant, step, innerStep, useInnerStep } = this.props;
        const { currentQuestion } = this.state;
        let callNextStep = false;// for products with both min/max === 1
        let callNewSize = null;
        let eventTargetChecked = event.target.checked;

        this.setState({
            ...this.state,
            selectedProduct: item
        });

        if ((currentQuestion.minimumQuantity === 1 && currentQuestion.maximumQuantity === 1) 
        || (product.mealDeal && currentQuestion.minimumQuantity === null && currentQuestion.maximumQuantity === null && !useInnerStep)) {

            let productCopy = this.getProductCopy(product, step, innerStep, eventTargetChecked, item);
            callNextStep = this.moveToNextStep(productCopy, restaurant.allowToppingSubstitution, step, innerStep, useInnerStep, item);
        }
        // if sizeQuestion === true call the new size
        if (!product.mealDeal && productMainQuestion.id === -111 && item.posId !== product.id) {
            let quantity = product.quantity ? product.quantity : 1;
            this.props.getSelectedProductWithSelectedSize(
                +item.posId,
                quantity,
                () => {
                    // third param resets the min/max qty flag
                    this.props.changeProductState(eventTargetChecked, item, false, callNextStep);
                });
        } else {
            // third param resets the min/max qty flag
            this.props.changeProductState(eventTargetChecked, item, false, callNextStep, callNewSize);
        }
    }

    getProductCopy(product, step, innerStep, eventTargetChecked, item) {
        let productCopy = newDeepCopy(product);
        let questionsCopy = productCopy.questions.slice();

        let allItemsDefault = false;

        if (product.mealDeal && !item.mealDealProduct) {
            let copyOfSubQuesitons = questionsCopy[step].selectedProduct.subQuestions.slice();
            allItemsDefault = copyOfSubQuesitons.length ? areAllItemsDefault(copyOfSubQuesitons[innerStep]) : false;
            copyOfSubQuesitons = getProductQuestions({ questionsCopy: copyOfSubQuesitons, step: innerStep, eventTargetChecked, allItemsDefault, item });
            productCopy.questions[step].selectedProduct.subQuestions = copyOfSubQuesitons;
        } else {
            questionsCopy = productCopy.questions.slice();
            allItemsDefault = questionsCopy.length ? areAllItemsDefault(questionsCopy[step]) : false;
            questionsCopy = getProductQuestions({ questionsCopy, step, eventTargetChecked, allItemsDefault, item });
            productCopy.questions = questionsCopy;
        }

        return productCopy;
    }

    moveToNextStep(productCopy, allowToppingSubstitution, step, innerStep, useInnerStep, item) {
        let itemCopy = { ...item };
        if (!item.count) {
            itemCopy.count = 1;
        }

        const itemPrice = getAnswerPrice({
            item: itemCopy,
            allowToppingSubstitution,
            product: productCopy,
            productMainQuestion: productCopy.questions[step],
            step,
            innerStep,
            useInnerStep
        });

        if (!itemPrice) {
            return true;
        }
        
        return false;
    }

    selectStepToRender() {
        const { product, productMainQuestion, currentQuestion } = this.props;
        let isSingleAnswersStep = false;
        let currQ = product.mealDeal ? currentQuestion : productMainQuestion;
        if (product.mealDeal) {
            // check if selectedProduct is question
            product.questions.map(question => {
                if (question.id === currQ.id) {
                    isSingleAnswersStep = true
                }
            });
            if (currQ.minimumQuantity === 1 && currQ.maximumQuantity === 1 && this.props.useInnerStep) {
                isSingleAnswersStep = true;
            }
        } else {
            if (currQ.minimumQuantity === 1 && currQ.maximumQuantity === 1) {
                isSingleAnswersStep = true;
            }
        }

        return isSingleAnswersStep;
    }

    renderHeader() {
        const { menuItemsBackground, primaryFontColor, mainTextColor } = this.props.selectedTheme;
        const { product, currentQuestion, selectedTheme, isMinMaxValid } = this.props;
        const isArabic = this.props.language === ARABIC;
        let text = '', isSubQuestion = true;
        let headerStyles = {};
        let hiddenMin = !currentQuestion.minimumQuantity;
        let hiddenMax = !currentQuestion.maximumQuantity;
        product.questions.map(question => {
            if (question.id === currentQuestion.id) {
                isSubQuestion = false;
            }
        });
        let isRequired = (currentQuestion.minimumQuantity === 1 && currentQuestion.maximumQuantity === 1) || (!isSubQuestion && product.mealDeal);

        // Checks if it is subQuestion and relates to ingredients
        if (areAllItemsDefault(currentQuestion) && isSubQuestion) { // min/max
            text = `Remove ${currentQuestion.name} ?`;
            headerStyles = { fontSize: '1.15rem', opacity: '.7' };

        } else if (isSubQuestion || !product.mealDeal) {
            text = currentQuestion.name;
            headerStyles = { fontSize: '1.15rem', opacity: '.7' };
        } else { // min/max
            text = currentQuestion.name;
            headerStyles = { color: selectedTheme.primaryColor };
        }

        if ((!hiddenMin && !hiddenMax && window.innerWidth <= 650) || (window.innerWidth <= 400 && (!hiddenMin || !hiddenMax))) {
            headerStyles = {
                ...headerStyles,
                maxWidth: '55%'
            }
        }

        let minStyle = { backgroundColor: menuItemsBackground, color: mainTextColor, whiteSpace: 'nowrap' }, maxStyle, minMaxContainerStyle = {};
        maxStyle = { paddingLeft: 4, backgroundColor: menuItemsBackground, color: mainTextColor, whiteSpace: 'nowrap' };

        if (isMinMaxValid) {
            minMaxContainerStyle.border = '1px solid #fa1106';
            minStyle.color = '#fa1106';
            maxStyle.color = '#fa1106';
        }
        let questionHeaderClassNames = this.getQuestionHeaderClassNames(isSubQuestion, product.mealDeal, hiddenMin, hiddenMax);

        return (
            <div className={questionHeaderClassNames} style={{
                backgroundColor: menuItemsBackground,
                width: '100%',
                display: isSubQuestion ? 'flex' : `${!product.mealDeal && (!hiddenMin || !hiddenMax) ? 'flex' : isRequired ? 'flex' : 'block'}`
            }}>
                <h5 style={{ ...headerStyles, ...{ color: primaryFontColor } }}>
                    {text}
                    {
                        !currentQuestion.selectedProduct ? null :
                            <span style={isArabic ? { fontSize: 14, color: '#ACABAC', float: 'right', marginTop: '6px', marginLeft: '5px' } : { fontSize: 14, color: '#ACABAC' }}>{` (${currentQuestion.selectedProduct.name})`}</span>
                    }
                </h5>
                <span
                    hidden={isSubQuestion ? hiddenMin && hiddenMax : !isRequired && hiddenMin && hiddenMax}
                    style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>

                    <div style={minMaxContainerStyle}>
                        {
                            isRequired ?
                                <span style={minStyle}>
                                    {i18n.t('common:wizard.required')}
                                </span> :
                                <Fragment>
                                    <span
                                        hidden={hiddenMin}
                                        style={minStyle}>
                                        {i18n.t('common:wizard.minQuantity') + ' '}{currentQuestion.minimumQuantity}
                                    </span>
                                    <span
                                        hidden={hiddenMax}
                                        style={maxStyle}>
                                        {i18n.t('common:wizard.maxQuantity') + ' '}{currentQuestion.maximumQuantity}
                                    </span>
                                </Fragment>
                        }
                    </div>

                </span>
            </div>
        );
    }

    getQuestionHeaderClassNames(isSubQuestion, mealDeal, hiddenMin, hiddenMax) {
        let classlist = isSubQuestion ? 'sub' : `head${!mealDeal && (!hiddenMin || !hiddenMax) ? ' regProduct' : ''}`;
        if (mealDeal) {
            classlist += ' mealDealHeader';
        }
        return classlist;
    }

    renderContent() {
        const { currentQuestion } = this.state;
        const hasAnswerGroups = currentQuestion.answerGroups ? currentQuestion.answerGroups.length : false;
        const areGroupsEmpty = hasAnswerGroups ? areAllGroupsEmpty(currentQuestion) : true;
        let contentWrapper = null;
        let contentItems = this.returnMultiAnswersStep.bind(this);

        if (this.selectStepToRender()) {
            contentItems = this.returnSingleAnswerStep.bind(this);
        }

        if (hasAnswerGroups && !areGroupsEmpty) {
            contentWrapper = <AnswerGroupsTabs currentQuestion={currentQuestion} renderInList={contentItems} />;
        } else {
            contentWrapper = contentItems();
        }

        return contentWrapper;
    }

    returnMultiAnswersStep(group) {
        const { currentQuestion, selectedProduct } = this.state;
        const { product, productMainQuestion, selectedTheme, currency, restaurant, changeAnswerQty, step, innerStep, useInnerStep, iOSDetected } = this.props;

        return <MultiAnswersStep
            step={step}
            innerStep={innerStep}
            useInnerStep={useInnerStep}
            group={group}
            product={product}
            productMainQuestion={productMainQuestion}
            currentQuestion={currentQuestion}
            selectedProduct={selectedProduct}
            selectedTheme={selectedTheme}
            currency={currency}
            restaurant={restaurant}
            changeAnswerQty={changeAnswerQty}
            iOSDetected={iOSDetected}
            selectItem={this.selectItem}
            areAllItemsDefault={areAllItemsDefault(currentQuestion)} />;
    }

    returnSingleAnswerStep(group) {
        const { currentQuestion, selectedProduct } = this.state;
        const { product, productMainQuestion, currency, restaurant, selectedTheme, step, innerStep, useInnerStep } = this.props;

        return <SingleAnswersStep
            step={step}
            innerStep={innerStep}
            useInnerStep={useInnerStep}
            group={group}
            product={product}
            productMainQuestion={productMainQuestion}
            currentQuestion={currentQuestion}
            selectedProduct={selectedProduct}
            currency={currency}
            restaurant={restaurant}
            selectedTheme={selectedTheme}
            selectItem={this.selectItem} />;
    }

    render() {
        const { currentQuestion, iOSDetected, product } = this.props;

        if (!currentQuestion || !this.state.currentQuestion || !this.state.currentQuestion.id) return <div className='wizardStep' style={{ backgroundColor: this.props.selectedTheme.menuItemsBackground }}></div>
        // if (!this.state.currentQuestion.id) return null;
        const { menuItemsBackground } = this.props.selectedTheme;

        let wizardStepStyle = {
            display: iOSDetected ? 'block' : 'flex',
            flexDirection: 'column',
            // height: 250,
            width: '100%',
            backgroundColor: menuItemsBackground,
            // overflow: 'hidden visible', // Fallback 'x y' values for Firefox because 'overlay' is not a valid property
            // overflowY: 'overlay'
            overflow: 'visible'
        }

        if (iOSDetected && !product.mealDeal && !isMobileOnly && !isTablet) {
            wizardStepStyle = {
                ...wizardStepStyle,
                // minHeight: 250
            }
        }

        if (this.props.isLowResTabletPortraitMode) {
            wizardStepStyle = {
                overflow: 'inital'
            };
        }

        if (isFirefox) {
            wizardStepStyle = {
                ...wizardStepStyle,
                paddingBottom: 111
            }
        }

        return (
            <div
                className='wizardStep'
                style={wizardStepStyle}>

                {this.renderHeader()}
                {this.renderContent()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps, { getSelectedProductWithSelectedSize })(WizardStep);