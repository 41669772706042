import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import i18n from '../../../../i18next';

import ComoPromotionsList from './ComoPromotionsList';
import ComoPayment from './ComoPayment';

const UserLoggedIn = props => {

    const {
        type,
        secondaryColor,
        fontFamilyType,
        fontBold,
        fontItalic,
        uppercase,
        checkoutScreenNames
    } = props.customerThemes.selectedTheme;

    const hasCreditBalance = props.state.creditBalance;
    const noPointShopItems = props.como.membership ? props.como.membership.assets.filter(asset => asset.key.substring(0, 3) !== 'ps_') : [];

    if (!props.promotionPartners.isUserLoggedInPartner) return null;

    return (
        <Fragment>
            <Col style={props.isArabic ? {
                display: 'initial',
                color: type === "LIGHT" ? checkoutScreenNames : secondaryColor,
                fontSize: 18,
                fontFamily: fontFamilyType,
                fontWeight: fontBold === 'bold' ? fontBold : 300,
                fontStyle: fontItalic,
                textTransform: uppercase,
                marginBottom: 4,
                paddingRight: 30
            } :
                {
                    display: 'initial',
                    color: type === "LIGHT" ? checkoutScreenNames : secondaryColor,
                    fontSize: 18,
                    fontFamily: fontFamilyType,
                    fontWeight: fontBold === 'bold' ? fontBold : 300,
                    fontStyle: fontItalic,
                    textTransform: uppercase,
                    marginBottom: 4,
                    paddingLeft: 30
                }}>
                {i18n.t('screens:como.promotionsSectionHeader')}
            </Col>
            { hasCreditBalance && props.sendInfoToShoppingCart({hasCreditBalance, ...props}) }
            {/* {
                hasCreditBalance && <Row><ComoPayment {...props} /></Row>
            } */}
            <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', padding: props.isArabic ? '0 10px 0 0' : '0 0 0 10px' }}>
                {
                    noPointShopItems &&
                    <ComoPromotionsList
                        {...props}
                        assets={noPointShopItems} />
                }
            </Row>
        </Fragment>
    );
}

export default UserLoggedIn;