import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import MaterialIcon from 'material-icons-react';
import i18n from '../../i18next';

import ProfileScreen from './ProfileScreen';
import { loadCustomerFromLocalStorage, saveCurrentPathToStore } from "../../actions/index";
import { updateCSSVariables } from '../../utils/ThemesSelector';

class ProfileMainContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backRoutePathname: '',
            alreadyCoppied: false
        }
    }

    componentWillMount() {
        // if (this.props.selectedRestaurant.restaurantId) { 
        // this.props.saveCurrentPathToStore('/profile/');
        // } else 
        // Prevents annoying app behaviour in case of broken data
        if (!this.props.selectedRestaurant.restaurantId) {
            this.props.history.push('/start-order');
        }
        this.props.loadCustomerFromLocalStorage();
        if (this.props.customerThemes.selectedTheme.colorsCss) {
            this.applyTheme();
        }

    }

    componentDidMount() {
        const { menuItemsBackground } = this.props.customerThemes.selectedTheme;

        // if we want image for background
        // if (imageBackground) {
        //     document.getElementById('root').style.backgroundImage = `url(${FRONT_END_URL}/${backGroundImageFilePath})`;
        //     document.getElementById('root').style.backgroundRepeat = 'repeat';
        // } else {
        //     document.getElementById('root').style.backgroundColor = menuBackground;
        // }

        document.getElementsByTagName('html')[0].style.backgroundColor = menuItemsBackground;
        document.getElementById('root').style.backgroundColor = menuItemsBackground;
    }

    componentDidUpdate(prevProps) {
        if (this.props.customerThemes.selectedTheme.colorsCss !== prevProps.customerThemes.selectedTheme.colorsCss && this.props.customerThemes.selectedTheme.colorsCss) {
            this.applyTheme();
        }
    }

    componentWillUnmount() {
        document.getElementsByTagName('html')[0].removeAttribute('style');
        document.getElementById('root').removeAttribute('style');
    }

    goBack() {
        this.props.history.push(this.props.temporaryOrderTime.profileBackButtonPath);
    }

    applyTheme() {
        updateCSSVariables(this.props.customerThemes.selectedTheme.colorsCss);
    }

    render() {

        const { primaryFontColor, menuItemsBackground } = this.props.customerThemes.selectedTheme;

        return (
            <Fragment>
                <div className='fixedContainer profileScreen' style={{ backgroundColor: menuItemsBackground, paddingTop: '2rem' }}>
                    <Button
                        className="backButton"
                        style={{ color: primaryFontColor, zIndex: 10 }}
                        onClick={() => this.goBack()}>
                        <span className="backButtonIcon" style={{ zIndex: 9 }}>
                            <MaterialIcon icon="navigate_before" color="#000" size={32} />
                        </span>
                        <span className="backButtonText" style={{ fontWeight: 300, zIndex: 9 }}>{i18n.t('common:buttons.backBtn')}</span>
                    </Button>
                    <ProfileScreen history={this.props.history} />
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        customerThemes: state.customerThemes,
        selectedRestaurant: state.selectedRestaurant,
        temporaryOrderTime: state.temporaryOrderTime,
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps, { loadCustomerFromLocalStorage, saveCurrentPathToStore })(ProfileMainContainer);